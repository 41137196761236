import React, { createContext, useEffect, useState } from 'react'
import Component from './Component'
import { CollectionTheme, CollectionType } from '../../lib/types'
import api from '../../lib/api'

// @ts-ignore
export const CreateCollectionContext = createContext<{
  actions: {
    open: (callback: (collection: CollectionType | null) => void, title?: string) => void
    close: (collection?: CollectionType) => void
  }
}>({
  // @ts-ignore
  actions: {}
})

export const CreateCollectionProvider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [initialTitle, setInitialTitle] = useState<string>('')
  const [thms, setThemes] = useState<CollectionTheme[]>([])

  const [callBack, setCallBack] = useState<((collection: CollectionType | null) => void) | undefined>(undefined)

  const open = (callback: (collection: CollectionType | null) => void, title?: string) => {
    setIsOpen(true)
    if (title) setInitialTitle(title)
    setCallBack(() => callback)
  }

  useEffect(() => {
    const getThemes = async () => {
      try {
        const response = await api.getCollectionThemes()
        setThemes(response)
      } catch (e) {
        console.log(e)
      }
    }
    getThemes()
  }, [])

  const close = (collection?: CollectionType) => {
    setIsOpen(false)
    setInitialTitle('')
    if (callBack) callBack(collection || null)
    setCallBack(undefined)
  }

  return (
    <CreateCollectionContext.Provider
      value={{
        actions: {
          open,
          close
        }
      }}
    >
      {isOpen && <Component initialTitle={initialTitle} themes={thms} />}
      {children}
    </CreateCollectionContext.Provider>
  )
}
