import React, { useContext, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { CollectionTheme, CollectionThemeProps, CollectionType } from '../../../../lib/types'
import CollectionThemeCreator from './components/CollectionThemeCreator'
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'
import { SimpleDialogContext } from '../../../../components/SimpleDialog'
import api from '../../../../lib/api'
import { SimpleSnackbarContext } from '../../../../components/SimpleSnackbar'
import Cookies from 'js-cookie'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { UserContext } from '../../../../components/UserProvider'
import { useHistory } from 'react-router-dom'
import { usePermissions } from '../../../../components/hooks/usePermissions'

const CollectionThemes: React.FC = () => {
  const [selectedTheme, setSelectedTheme] = useState<CollectionTheme | undefined>(undefined)
  const [items, setItems] = useState<CollectionTheme[]>([])
  const { actions: simpleDialogActions } = useContext(SimpleDialogContext)
  const { actions: snackActions } = useContext(SimpleSnackbarContext)
  const history = useHistory()
  const { isAdministrator } = usePermissions()

  const { user } = useContext(UserContext)
  const isNotEnterprise = user?.tier < 4

  const token = Cookies.get('token')

  const getRandomColorIndices = () => {
    const nums = [0, 1, 2, 3, 4, 5, 6, 7, 8]
    for (let i = nums.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[nums[i], nums[j]] = [nums[j], nums[i]]
    }
    return [
      [nums[0], nums[2], nums[4]],
      [nums[1], nums[3], nums[5]],
      [nums[7], nums[6], nums[8]]
    ]
  }

  const getTiles = (theme: CollectionThemeProps) => {
    const numRows = 3
    const numCols = 3
    const tileSize = 60
    const tiles: any = []
    const colors = [
      theme.textColor ?? '#FFFFFF',
      theme.backgroundColor ?? '#1D211F',
      theme.storyTitleBGColor ?? '#2E2E2E',
      theme.storyCountBGColor ?? '#141414',
      theme.videoButtonBGColor ?? '#6EC069',
      theme.videoButtonTextColor ?? '#000000',
      theme.barBackgroundColor ?? '#232323',
      theme.barButtonsTextColor ?? '#6EC069',
      theme.signupInputOutlineColor ?? '#6EC069'
    ]
    const colorIndices = getRandomColorIndices()

    for (let row = 0; row < numRows; row++) {
      const rowColors = colorIndices[row % 3].map(i => colors[i])
      for (let col = 0; col < numCols; col++) {
        const colorIndex = col % rowColors.length
        const color = rowColors[colorIndex]
        const tile = (
          <Box
            key={`${row}-${col}`}
            sx={{
              backgroundColor: color,
              width: tileSize,
              height: tileSize,
              display: 'inline-block'
            }}
          />
        )
        tiles.push(tile)
      }
    }
    return tiles
  }

  const contentIfThemeIsUsed = (collections: CollectionType[]) => {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <Typography sx={{ marginBottom: '16px' }}>
          You need to remove this theme of the following collections before deleting this theme:
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          {collections.map((collection, idx) => {
            return <Typography key={idx}>{collection.title}</Typography>
          })}
        </Box>
      </Box>
    )
  }

  const handleDelete = async (id?: string) => {
    if (!id || !token) return
    const collections = await api.getCollectionsByThemeId(id, token)
    if (collections.length > 0) {
      simpleDialogActions.open('Delete theme', contentIfThemeIsUsed(collections), { showOk: false, cancelText: 'Back' })
    } else {
      simpleDialogActions.open(
        'Delete theme',
        'Are you sure want to delete this theme?',
        { okText: 'Yes, delete', showOk: true, cancelText: 'Back' },
        async success => {
          try {
            if (success) {
              await api.deleteCollectionTheme(id)
              snackActions.open(`Theme deleted with success!`)
              setItems(items.filter(p => p.id !== id))
            }
          } catch (error) {
            snackActions.open('Something was wrong! Please try again.')
          }
        }
      )
    }
  }

  const handleChangeDefault = async (colTheme: CollectionTheme) => {
    const data: CollectionTheme = { id: colTheme.id, properties: colTheme.properties, standard: !colTheme.standard }
    await api.updateCollectionTheme(data).then(() => {
      try {
        api.getCollectionThemes().then(res => setItems(res))
      } catch (e) {
        console.log(e)
      }
    })
  }

  useEffect(() => {
    const getThemes = async () => {
      try {
        const response = await api.getCollectionThemes()
        setItems(response)
      } catch (e) {
        console.log(e)
      }
    }
    getThemes()
  }, [selectedTheme])

  useEffect(() => {
    if (!isAdministrator) {
      history.push('a/my-library')
    }
  }, [location])

  return selectedTheme ? (
    <CollectionThemeCreator colTheme={selectedTheme} setSelectedTheme={setSelectedTheme} />
  ) : (
    <Box
      sx={{
        backgroundColor: 'background.paper',
        height: '100%',
        padding: 2
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '32px' }}>
        <Box sx={{ height: '56px', minWidth: '120px' }} />
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '32px'
          }}
        >
          Collection Themes
        </Typography>
        <Button
          variant="outlined"
          sx={{ height: '56px', minWidth: '120px' }}
          disabled={isNotEnterprise}
          onClick={() => setSelectedTheme({})}
        >
          Add Theme
        </Button>
      </Box>
      <Box sx={{ display: 'flex', gap: '16px', flexWrap: 'wrap' }}>
        {isNotEnterprise ? (
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '16px',
              marginTop: '64px',
              width: '100%'
            }}
          >
            To have access to collection themes you must be at Enterprise level of subscription!
          </Typography>
        ) : (
          <>
            {items.length === 0 && (
              <Typography
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '16px',
                  marginTop: '64px',
                  width: '100%'
                }}
              >
                You do not have any themes yet. Add themes to personalize your collections!
              </Typography>
            )}
            {items.map((item, idx) => {
              return (
                <Box key={idx} sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                  <Typography
                    sx={{
                      display: 'flex',
                      gap: '8px',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    {item?.properties?.themeName}
                    <IconButton onClick={() => handleDelete(item.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Typography>

                  <Button
                    onClick={() => setSelectedTheme(item)}
                    sx={{
                      height: '208px',
                      width: '200px',
                      borderRadius: '8px',
                      position: 'relative',
                      display: 'inline-block',
                      margin: '8px'
                    }}
                  >
                    {getTiles(item?.properties ? item?.properties : {})}
                  </Button>
                  <FormGroup>
                    <FormControlLabel
                      sx={{ display: 'flex', justifyContent: 'center' }}
                      label={'Default'}
                      control={<Switch checked={item?.standard} onChange={() => handleChangeDefault(item)} />}
                    />
                  </FormGroup>
                </Box>
              )
            })}
          </>
        )}
      </Box>
    </Box>
  )
}

export default CollectionThemes
