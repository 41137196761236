import React, { useContext, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import api from '../../../../lib/api'
import { TranscriptionType } from '../../../../lib/types'
import TranscriptEditor from '../../../../components/TranscriptEditor'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { saveAs } from 'file-saver'
import _ from 'lodash'
import { SimpleSnackbarContext } from '../../../../components/SimpleSnackbar'
import { SimpleDialogContext } from '../../../../components/SimpleDialog'
import LoadingButton from '@mui/lab/LoadingButton'
import FindReplace from '../../../../components/TranscriptEditor/FindReplace'

interface iTranscript {
  isEditor: boolean
  storyId: string
  storyTitle: string | undefined
  refetch: () => void
  time: number
  status?: string
  questionnaireId?: string
  collectionId?: string
  jobTitle?: string
  functionalArea?: string
  recorderName?: string
}

const Transcript: React.FC<iTranscript> = ({
  isEditor,
  storyTitle,
  storyId,
  refetch: refetchStory,
  time,
  status,
  questionnaireId,
  collectionId,
  jobTitle = '',
  functionalArea = '',
  recorderName = ''
}) => {
  const { actions: snackActions } = useContext(SimpleSnackbarContext)
  const { actions: simpleDialogActions } = useContext(SimpleDialogContext)
  const [localTranscription, setLocalTranscription] = useState<TranscriptionType | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [editMode, setEditMode] = useState<boolean>(false)
  const [cooked, setCooked] = useState<unknown>(null)
  const [sendingTranscriptToDam, setSendingTranscriptToDam] = useState(false)
  const [downloadingTranscripts, setDownloadingTranscripts] = useState(false)

  const fetch = async () => {
    if (!storyId) return
    try {
      const transcripts = await api.getTranscriptions(0, 1000, [storyId])
      if (transcripts.content && transcripts.content.length > 0) {
        setLocalTranscription(transcripts.content[0])
        transcripts.content[0].cooked && setCooked(transcripts.content[0].cooked)
      }
    } catch (error) {
      console.log(error)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    setIsLoading(true)
    fetch()
  }, [])

  // save button flow
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleSaveAsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const resetTranscript = async () => {
    if (!storyId) return
    try {
      await api.storyReprocessTranscript(storyId)
      snackActions.open('Requested with success.')
    } catch (error) {
      // @ts-ignore
      const { data } = error.response
      if (data.code) {
        snackActions.open(data.message)
        return
      }
      snackActions.open('Something was wrong! please try again.')
    }
  }

  const handleResetTranscript = async () => {
    if (status === 'SUCCESS') {
      simpleDialogActions.open(
        'Reprocessing transcript',
        'Are you sure? You will lose any editon on transcript.',
        { okText: 'Yes, reprocess', showOk: true, cancelText: 'cancel' },
        async success => {
          if (success) await resetTranscript()
        }
      )
    } else {
      await resetTranscript()
    }
  }

  const handleRefreshPageOnEditSuccessDialog = () => {
    simpleDialogActions.open(
      'Transcription Updated',
      'Your edits have been saved. To view your edits in the captions, please refresh your browser.',
      { okText: 'Refresh the page now', cancelText: 'I will refresh later', showOk: true },
      success => {
        if (success) window.location.reload()
      }
    )
  }
  const update = async () => {
    try {
      setIsSaving(true)
      await api.updateTranscriptions(localTranscription!.id, {
        content: localTranscription!.content,
        storyId: localTranscription!.id,
        cooked
      })
      setEditMode(false)
      handleRefreshPageOnEditSuccessDialog()
    } catch (error) {
      // @ts-ignore
      const { data } = error.response
      if (data.code) {
        snackActions.open(data.message)
        return
      }
      snackActions.open('Something was wrong! please try again.')
    } finally {
      setIsSaving(false)
    }
  }
  const handleExport = async (id: string, type: 'json' | 'pdf' | 'docx' | 'plain') => {
    try {
      const blob = await api.downloadTranscription(id, type)
      saveAs(blob, `${storyTitle || id}.${type === 'plain' ? 'txt' : type}`)
      snackActions.open('Transcription downloaded successfully!')
      handleClose()
    } catch (e) {
      // @ts-ignore
      if (e && e?.response && e?.response?.status && e?.response?.status === 403) {
        snackActions.open(`Ops! You don't have permission to download this transcript`)
      } else {
        snackActions.open('Ops! Something went wrong, please try again.')
      }
    }
  }
  const handleSendTranscriptToDam = async () => {
    if (questionnaireId && collectionId) {
      setSendingTranscriptToDam(true)
      try {
        await api.sendQuestionnaireTranscriptionToDam(collectionId)
      } catch {
        snackActions.open('Ops! Something went wrong, please try again.')
      } finally {
        setSendingTranscriptToDam(false)
      }
    }
  }
  const handleDownloadTranscripts = async () => {
    if (questionnaireId && collectionId) {
      setDownloadingTranscripts(true)
      try {
        await api.generateInterviewTranscription(questionnaireId, collectionId, jobTitle, functionalArea, recorderName)
      } catch {
        snackActions.open('Ops! Something went wrong, please try again.')
      } finally {
        setDownloadingTranscripts(false)
      }
    }
  }
  return (
    <Box
      component={Paper}
      sx={{
        width: '100%',
        display: 'flex',
        padding: 3,
        flexFlow: 'column'
      }}
    >
      <Box
        sx={{
          padding: '0 24px',
          minHeight: '40vh'
        }}
      >
        {isLoading ? (
          <CircularProgress variant="indeterminate" />
        ) : (
          <>
            {!localTranscription ? (
              <>
                <Typography component="pre" align="justify" paragraph>
                  We haven&apos;t finished generating the transcript. Please check back later.
                </Typography>
                <Button variant="outlined" onClick={handleResetTranscript} sx={{ mt: 1, mr: 1 }}>
                  {'Retry transcript'}
                </Button>
              </>
            ) : (
              <>
                {_.isEmpty(localTranscription.cooked) ? (
                  <Typography component="pre" align="justify" paragraph>
                    {localTranscription!.content}
                  </Typography>
                ) : (
                  <>
                    {isEditor && (
                      <>
                        <div>
                          <Box sx={{ display: 'flex' }}>
                            <Button variant="outlined" onClick={handleSaveAsClick} sx={{ mt: 1, mr: 1 }}>
                              Export as
                            </Button>
                            {questionnaireId && (
                              <>
                                <LoadingButton
                                  variant="outlined"
                                  onClick={handleDownloadTranscripts}
                                  sx={{ mt: 1, mr: 1 }}
                                  loading={downloadingTranscripts}
                                >
                                  Download interview pdf
                                </LoadingButton>
                                <LoadingButton
                                  loading={sendingTranscriptToDam}
                                  variant="outlined"
                                  onClick={handleSendTranscriptToDam}
                                  sx={{ mt: 1, mr: 1 }}
                                >
                                  Send Transcript to DAM
                                </LoadingButton>
                              </>
                            )}

                            {editMode ? (
                              <>
                                <Button color="secondary" onClick={() => setEditMode(false)} sx={{ mt: 1, mr: 1 }}>
                                  Cancel
                                </Button>
                                <LoadingButton
                                  variant="outlined"
                                  onClick={update}
                                  sx={{ mt: 1, mr: 1 }}
                                  loading={isSaving}
                                >
                                  Save
                                </LoadingButton>
                              </>
                            ) : (
                              <Button variant="outlined" onClick={() => setEditMode(true)} sx={{ mt: 1, mr: 1 }}>
                                Edit
                              </Button>
                            )}
                            <Box flex="1" />
                            <Button variant="outlined" onClick={handleResetTranscript} sx={{ mt: 1, mr: 1 }}>
                              {'Reset'}
                            </Button>
                          </Box>
                          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                            <MenuItem onClick={() => handleExport(localTranscription!.id, 'pdf')}>PDF</MenuItem>
                            <MenuItem onClick={() => handleExport(localTranscription!.id, 'docx')}>DOCX</MenuItem>
                            <MenuItem onClick={() => handleExport(localTranscription!.id, 'plain')}>TXT</MenuItem>
                            <MenuItem onClick={() => handleExport(localTranscription!.id, 'json')}>JSON</MenuItem>
                          </Menu>
                        </div>
                        <Divider sx={{ margin: '24px 0' }} />
                      </>
                    )}
                    <TranscriptEditor
                      transcriptJson={localTranscription!.cooked}
                      currentTime={time}
                      isEditable={editMode}
                      onChange={t => setCooked(t)}
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  )
}

export default Transcript
