import React, { useContext, useEffect, useState } from 'react'
import { AssetTypes, StoryType, StoryUpdateType } from '../../../lib/types'
import Player from '../../../components/Player'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import About from './About'
import api from '../../../lib/api'
import MetaTags from 'react-meta-tags'
import { RouteComponentProps } from 'react-router-dom'
import CircularLoadingCentred from '../../../components/CircularLoadingCentred'
import Cover from '../../../components/Cover'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import Tab from '@mui/material/Tab'
import TabPanel from '@mui/lab/TabPanel'
import Transcript from './Transcript'
import Photos from './Photos'
import Files from './Files'
import Subtitles from './Subtitles'
import Links from './Links'
import { UserContext } from '../../../components/UserProvider'
import { SimpleSnackbarContext } from '../../../components/SimpleSnackbar'
import Comments from '../../../components/Comments'
import EditBar from './EditBar'
import { useMitt } from 'react-mitt'
import { usePermissions } from '../../../components/hooks/usePermissions'
import StoryMetadata from '../../../components/StoryMetadata/StoryMetadata'
import StorySummary from '../../../components/StorySummary/StorySummary'
import { createChapters } from '../../../components/VideoBookmarkDialog/BookmarkUtils'
import LateralTranscript from './LateralTranscript'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'

const Story: React.FC<RouteComponentProps<{ storyId: string }>> = ({ match, history }) => {
  const { user } = useContext(UserContext)
  const [time, setTime] = useState<number>(0)
  const { actions: snackActions } = useContext(SimpleSnackbarContext)
  const { storyId } = match.params
  const [tab, setTab] = useState<string>('transcript')
  const [play, setPlay] = useState<boolean>(false)
  const [lateralTranscript, setLateralTranscript] = useState<boolean>(true)
  const [story, setStory] = useState<StoryType | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isOwner, setIsOwner] = useState<boolean>(false)
  const [isEditor, setIsEditor] = useState<boolean>(false)
  const [editMode, setEditMode] = useState<boolean>(false)
  const { emitter } = useMitt()
  const { isEditor: isTeamMemberEditor } = usePermissions()
  const [chapters, setChapters] = useState(undefined)
  useEffect(() => {
    const getChapters = async () => {
      const id = story?.video.id || story?.audio.id
      if (!id) return
      const cpts = await api.getChapters(id)
      // @ts-ignore
      setChapters(createChapters(cpts))
    }
    getChapters()
  }, [story])

  const fetch = async () => {
    try {
      const result = await api.getStory(storyId)
      setStory(result)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      history.push('/a/my-library')
    }
  }

  useEffect(() => {
    if (story?.owner?.id === user?.id || isTeamMemberEditor) {
      setIsOwner(true)
    }
    if (story?.watchers?.find(e => user?.email === e.email && e.type === 'EDITOR')) {
      setIsEditor(true)
    }
  }, [story, user])

  useEffect(() => {
    setIsLoading(true)
    fetch()
  }, [])

  useEffect(() => {
    emitter.on('story-media-success', event => {
      api.getStory(event.id).then(() => {
        setIsLoading(true)
        fetch()
      })
    })
  }, [])

  const updateStory = async (formData: StoryUpdateType) => {
    try {
      const result = await api.updateStory(storyId, formData)
      setStory(result)
    } catch (error) {
      // @ts-ignore
      const { data } = error.response
      if (data.code === '1018') {
        snackActions.open('This story cannot be edited!')
        throw error
      }
      snackActions.open('Something was wrong! please try again.')
      throw error
    }
  }

  if (isLoading) {
    return <CircularLoadingCentred />
  }

  const {
    type,
    video,
    audio,
    thumbnailUrl,
    subtitles,
    owner,
    title,
    description,
    collections,
    published,
    watchers,
    createdAt,
    mergeStatus
  } = story as StoryType

  const handlePlay = () => {
    setPlay(true)
  }

  const onTabClick = (tab = '') => {
    setTab(tab)
  }

  const handleLateralTranscript = () => {
    setLateralTranscript(!lateralTranscript)
  }

  const collectionId = collections[0].id
  const questionnaireId = story?.questionnaireId
  const recorderName = story?.recorderName
  const recorderJobTitle = story?.recorderJobTitle
  const recorderFunctionalArea = story?.recorderFunctionalArea

  return (
    <>
      <MetaTags>
        <title>Rakonto - {title}</title>
        <meta property="description" content={description || ''} />
        <meta property="creator" content={owner.firstName || ''} />
        <meta property="publisher" content={'Rakonto'} />
        <meta property="og:image" content={thumbnailUrl} />
      </MetaTags>
      <Box
        sx={{
          width: '100%',
          height: `100%`,
          display: 'flex',
          flexFlow: 'column'
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={lateralTranscript ? 9 : 12}
            sx={{
              display: {
                xs: 'block', // Aparece como 12 em telas pequenas
                md: 'block' // Ajusta para 9 ou 12 em telas médias ou maiores baseado em lateralTranscript
              }
            }}
          >
            {' '}
            {/* Condicional baseado em lateralTranscript */}
            <Box sx={{ position: 'relative' }}>
              {' '}
              {/* Contêiner com posição relativa */}
              {lateralTranscript === false && (
                <Button
                  sx={{
                    display: { xs: 'none', md: 'flex' },
                    position: 'absolute', // Posicionamento absoluto faz o botão flutuar
                    top: 8, // Distância do topo do contêiner
                    right: 8, // Distância da direita do contêiner
                    width: '10em',
                    zIndex: 1000 // Garante que o botão fique sobre outros elementos
                  }}
                  onClick={handleLateralTranscript}
                >
                  Show Transcript
                </Button>
              )}
              <Box sx={{ margin: 'auto', backgroundColor: 'black', width: '100%', maxHeight: '720px' }}>
                {play ? (
                  <Player
                    subtitles={subtitles || []}
                    type={type}
                    media={video || audio}
                    cover={thumbnailUrl}
                    onPlay={time => setTime(time)}
                    chapters={chapters}
                  />
                ) : (
                  <Cover
                    author={owner}
                    src={thumbnailUrl}
                    title={title}
                    description={description}
                    onClick={handlePlay}
                    buttonLabel="View video"
                    date={createdAt}
                  />
                )}
              </Box>
            </Box>
          </Grid>
          {lateralTranscript && ( // Renderiza este Grid item apenas se lateralTranscript for true
            <Grid item xs={3} sx={{ display: { xs: 'none', md: 'flex' }, flexDirection: 'column', height: '100%' }}>
              <Box sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1, marginBottom: 'auto', overflow: 'scroll' }}>
                {' '}
                {/* Este Box ocupará a maior parte do espaço */}
                <LateralTranscript storyId={storyId} handleView={handleLateralTranscript} refetch={fetch} time={time} />
              </Box>
              <Box sx={{ display: { xs: 'none', md: 'flex' }, height: '5em', width: '100%' }}>
                {' '}
                {/* Este Box ficará na base com altura de 5em */}
                <Box
                  component={Paper}
                  sx={{
                    width: '100%',
                    display: { xs: 'none', md: 'flex' },
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%'
                  }}
                >
                  <Button sx={{ minWidth: '10em' }} onClick={handleLateralTranscript}>
                    Hide Transcript
                  </Button>
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
        <Box
          component={TabContext}
          value={(tab as string) || 'about'}
          sx={{
            width: '100%',
            height: '100%'
          }}
        >
          <Box
            component={TabList}
            variant="scrollable"
            sx={{
              backgroundColor: 'background.paper',
              boxShadow: 6
            }}
          >
            <Tab label="Transcript" value="transcript" onClick={() => onTabClick('transcript')} />
            <Tab label="About" value="about" onClick={() => onTabClick('about')} />
            {/* <Tab label="People" value="people" onClick={() => onTabClick('people')} /> */}
            {/* <Tab label="Places" value="places" onClick={() => onTabClick('places')} /> */}
            {/* <Tab label="Timelines" value="timelines" onClick={() => onTabClick('timelines')} /> */}
            <Tab label="Photos" value="photos" onClick={() => onTabClick('photos')} />
            <Tab label="Files" value="files" onClick={() => onTabClick('files')} />
            <Tab label="Links" value="links" onClick={() => onTabClick('links')} />
            <Tab label="Subtitles" value="subtitles" onClick={() => onTabClick('subtitles')} />
          </Box>
          <Box
            sx={{
              width: '100%',
              padding: 3
            }}
          >
            <EditBar
              id={storyId}
              story={story}
              isOwner={isOwner}
              isEditor={isEditor}
              reload={fetch}
              media={video || audio}
              user={user}
              setEditMode={setEditMode}
              setTab={() => onTabClick('about')}
            />
            <TabPanel sx={{ height: '100%', padding: 'unset' }} value="transcript">
              <Transcript
                storyTitle={story?.title}
                storyId={storyId}
                isEditor={isOwner || isEditor}
                refetch={fetch}
                time={time}
                status={story?.transcriptStatus}
                collectionId={collectionId}
                questionnaireId={questionnaireId}
                jobTitle={recorderJobTitle}
                recorderName={recorderName}
                functionalArea={recorderFunctionalArea}
              />
            </TabPanel>
            <TabPanel sx={{ height: '100%', padding: 'unset' }} value="about">
              <About
                update={updateStory}
                isEditor={isOwner || isEditor}
                title={title}
                id={storyId}
                description={description || ''}
                isPlaying={play}
                editMode={editMode}
                setEditMode={setEditMode}
              >
                <StoryMetadata
                  storyId={storyId}
                  recorderName={story?.recorderName}
                  recorderEmail={story?.recorderEmail}
                  recorderJobTitle={story?.recorderJobTitle}
                  recorderFunctionalArea={story?.recorderFunctionalArea}
                />
                <StorySummary summary={story?.summary} />
                <Comments type={AssetTypes.story} id={storyId} watchers={watchers} />
              </About>
            </TabPanel>
            {/* <TabPanel sx={{ height: '100%', padding: 'unset' }} value="people">
              <People storyId={storyId} isOwner={isOwner} isEditor={false} />
            </TabPanel>
            <TabPanel sx={{ height: '100%', padding: 'unset' }} value="timelines">
              <Timeline storyId={storyId} isEditor={isOwner || isEditor} />
            </TabPanel>
            <TabPanel sx={{ height: '100%', padding: 'unset' }} value="places">
              <Places storyId={storyId} isOwner={isOwner} isEditor={false} />
            </TabPanel> */}
            <TabPanel sx={{ height: '100%', padding: 'unset' }} value="photos">
              <Photos storyId={storyId} isEditor={isOwner || isEditor} />
            </TabPanel>
            <TabPanel sx={{ height: '100%', padding: 'unset' }} value="files">
              <Files storyId={storyId} isEditor={isOwner || isEditor} />
            </TabPanel>
            <TabPanel sx={{ height: '100%', padding: 'unset' }} value="links">
              <Links storyId={storyId} isEditor={isOwner || isEditor} />
            </TabPanel>
            {(isOwner || isEditor) && (
              <TabPanel sx={{ height: '100%', padding: 'unset' }} value="subtitles">
                <Subtitles storyId={storyId} isEditor={isOwner || isEditor} refetch={fetch} />
              </TabPanel>
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default Story
