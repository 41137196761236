import React, { useState, useCallback, useContext } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { SimpleSnackbarContext } from '../SimpleSnackbar'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'

interface iStoryMetadata {
  summary?: string
}

const StorySummary: React.FC<iStoryMetadata> = ({ summary }) => {
  const hasSummary = summary && summary != null

  return hasSummary ? (
    <Box
      component={Paper}
      sx={{
        width: '100%',
        padding: 2
      }}
    >
      <Typography variant={'body2'}>Summary:</Typography>
      <Typography variant={'body1'}>{summary}</Typography>
    </Box>
  ) : (
    <></>
  )
}
export default StorySummary
