import React, { useContext, useEffect, useRef } from 'react'
import api from '../../../../lib/api'
import { SearchResultType, TeamMemberRoleEnum } from '../../../../lib/types'
import useInfiniteScroll from '../../../../components/hooks/useInfiniteScrool'
import { usePageableRequest } from '../../../../components/hooks/usePageableRequest'
import Card from '../../../../components/Card'
import StoryCard from '../../../../components/StoryCard'
import { useHistory } from 'react-router-dom'
import { useMitt } from 'react-mitt'
import Typography from '@mui/material/Typography'
import { Box, Grid } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import IconButton from '@mui/material/IconButton'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { UserContext } from '../../../../components/UserProvider'

interface iStoriesSliderTiler {
  q: string
}

const StoriesSliderTile: React.FC<iStoriesSliderTiler> = ({ q }) => {
  const { emitter } = useMitt()
  const history = useHistory()
  const { loading, items, hasNextPage, error, loadMore, reload } = usePageableRequest<SearchResultType>({
    size: 15,
    q: q,
    request: api.searchStories
  })
  const { member } = useContext(UserContext)

  const gridRef = useRef<HTMLDivElement>(null)

  const scrollLeft = () => {
    if (!gridRef.current) return
    gridRef.current.scrollBy({
      left: -gridRef.current.clientWidth,
      behavior: 'smooth'
    })
  }

  const scrollRight = () => {
    if (!gridRef.current) return
    gridRef.current.scrollBy({
      left: gridRef.current.clientWidth,
      behavior: 'smooth'
    })
  }
  useEffect(() => {
    emitter.on('story-media-success', event => {
      setTimeout(reload, 1000)
    })

    emitter.on('story-media-processing', event => {
      setTimeout(reload, 1000)
    })
  }, [])

  // @ts-ignore
  if (error?.status === 401) {
    history.push('/u/signin')
  }

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: !!error,
    rootMargin: '0px 0px 400px 0px'
  })

  const permittedStories = member?.permissions?.map(p => p?.storyIds).flatMap(ids => (ids ? ids.split(',') : [])) ?? []
  const filteredItems =
    member?.role === TeamMemberRoleEnum.VIEWER || member?.role === TeamMemberRoleEnum.EDITOR
      ? items.filter(item => permittedStories.includes(item.id))
      : items

  return (
    <>
      <Typography variant="h5" sx={{ fontWeight: 700 }} gutterBottom>
        Stories
      </Typography>
      <Box sx={{ display: 'flex', gap: '16px' }}>
        {items.length !== 0 && (
          <Box sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'center' }}>
            <IconButton onClick={scrollLeft}>
              <ChevronLeftIcon />
            </IconButton>
          </Box>
        )}

        <Grid
          ref={gridRef}
          wrap="nowrap"
          sx={{
            overflowX: 'auto',
            '::-webkit-scrollbar': {
              display: 'none'
            }
          }}
          container
        >
          {filteredItems.map(item => (
            <StoryCard key={item.id} data={item} />
          ))}
          {hasNextPage && (
            <Grid>
              <Card loading={true} title={''} subTitle={''} thumbnail={''} preview={''} />
              <div ref={sentryRef} />
            </Grid>
          )}
        </Grid>
        {items.length !== 0 && (
          <Box sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'center' }}>
            <IconButton onClick={scrollRight}>
              <ChevronRightIcon />
            </IconButton>
          </Box>
        )}
      </Box>
    </>
  )
}

export default StoriesSliderTile
