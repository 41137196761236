import React, { useContext, useEffect, useState } from 'react'

import '../../lib/videojs/skins/treso/videojs.css'
import './overrides.css'

import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js'
import '../../lib/videojs/components/nuevo'
import '../../lib/videojs/components/visualizer'
import '../../lib/videojs/components/playlist'
import { SubtitleType } from '../../lib/types'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { useFormik } from 'formik'
import { videoChapterSchema } from '../../pages/a/Profile/schemas'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import LoadingButton from '@mui/lab/LoadingButton'
import DeleteIcon from '@mui/icons-material/Delete'
import api from '../../lib/api'
import { SimpleSnackbarContext } from '../SimpleSnackbar'
import { VideoBookmarkDialogContext } from './Context'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

const defaultNuevoOptions = {
  contextText: 'Powered by Rakonto',
  contextUrl: '//app.rakonto.io',
  contextIcon: '/images/logo.svg',
  buttonRewind: true,
  buttonForward: true,
  resume: true,
  snapshot: true,
  autoplay: true,
  singlePlay: false
}

interface iVideoJs {
  embedded?: boolean
  options?: VideoJsPlayerOptions
  playlist?: any
  nuevoOptions?: any
  onReady?: any
  type?: 'audio' | 'video' | 'playlist'
  handleEnd?: () => void
  subtitles?: SubtitleType[]
  onPlay?: (time: number) => void
  onLoadedMetaData?: (item: any) => void
  onPause?: () => void
  onPressPlay?: () => void
  borderRadius?: string
  playsInline?: boolean
  chapters?: { kind: string; src: string }
  mediaId?: string
}

export type VideoChapter = {
  begin: string
  end: string
  title: string
  id: string
  videoId: string
}

export const BookmarkVideoJS: React.FC<iVideoJs> = ({
  subtitles,
  options,
  handleEnd,
  onReady,
  type,
  nuevoOptions,
  embedded,
  playlist,
  onPlay,
  onLoadedMetaData,
  onPause,
  onPressPlay,
  borderRadius,
  playsInline,
  chapters,
  mediaId
}) => {
  const playerRef = React.useRef<VideoJsPlayer | null>(null)
  const playerId = 'player-' + Math.floor(Math.random() * 1000000) + 1
  const [playerChapters, setPlayerChapters] = useState(chapters)
  const { store } = useContext(VideoBookmarkDialogContext)
  const [chapterList, setChapterList] = useState<VideoChapter[]>(store.chapters ?? [])
  const { actions: snackActions } = useContext(SimpleSnackbarContext)
  const [ready, setReady] = useState(false)
  const [currentVideoTime, setCurrentVideoTime] = useState('')

  const handleSetCurrentVideoTimeFormatted = (seconds: number) => {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const secs = Math.floor(seconds % 60)
    const milliseconds = Math.floor((seconds - Math.floor(seconds)) * 1000)

    const formattedHours = hours.toString().padStart(2, '0')
    const formattedMinutes = minutes.toString().padStart(2, '0')
    const formattedSeconds = secs.toString().padStart(2, '0')
    const formattedMilliseconds = milliseconds.toString().padStart(3, '0')

    const formattedTime = `${formattedHours}:${formattedMinutes}:${formattedSeconds}.${formattedMilliseconds}`

    setCurrentVideoTime(formattedTime)
  }

  useEffect(() => {
    let localOptions = {
      ...options
    }
    let localNuevoOptions = {
      ...defaultNuevoOptions,
      ...nuevoOptions
    }
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      if (embedded) {
        localOptions = {
          ...localOptions,
          fill: true
        }
      }

      playerRef.current = videojs(playerId, localOptions, () => {
        onReady && onReady(playerRef.current)
        // @ts-ignore
        handleEnd && playerRef.current.on('ended', handleEnd)
        // @ts-ignore
        playerRef.current.on('timeupdate', e => {
          // @ts-ignore
          handleSetCurrentVideoTimeFormatted(playerRef.current?.currentTime())
        })
        // @ts-ignore
        onPressPlay && playerRef.current.on('play', onPressPlay)
        // @ts-ignore
        onLoadedMetaData &&
          // @ts-ignore
          playerRef.current.on('loadedmetadata', () => onLoadedMetaData(playerRef.current?.currentSource()))
        // @ts-ignore
        onPause && playerRef.current.on('pause', onPause)

        chapters &&
          // @ts-ignore
          playerRef.current.on('loadeddata', () => playerRef.current?.loadTracks(chapters))
      })

      const captions =
        subtitles?.map(subtitle => ({
          // If develop mode need replace proxy port = subtitle.url.replace('8080', '3000')
          kind: 'captions',
          src: subtitle.url,
          srclang: subtitle.language,
          label: subtitle.language,
          default: '1'
        })) || []

      playerRef.current!.on('nuevoReady', () => {
        if (captions.length) {
          // @ts-ignore
          playerRef.current.loadTracks(captions)
        }
      })

      if (type === 'audio') {
        //  @ts-ignore
        playerRef.current.visualizer({ video: true })
      }

      if (type === 'playlist') {
        //  @ts-ignore
        playerRef.current.playsinline(true)

        // @ts-ignore
        localNuevoOptions = {
          ...localNuevoOptions,
          playlistUI: true, // set to disable playlist UI completely
          playlistShow: false, // set to hide playlist UI on start
          playlistAutoHide: false, // Disable playlist UI autohide on video play event
          playlistNavigation: true, // set to show playlist navigation arrows
          playlistRepeat: false, // set to repeat playlist playback
          singlePlay: false,
          contextText: 'Powered by Rakonto'
        }
      }

      // @ts-ignore
      playerRef.current.nuevo({
        ...localNuevoOptions
      })

      // @ts-ignore
      if (type === 'playlist') playerRef.current.playlist(playlist)
      setReady(true)
    } else {
      // const player = playerRef.current
      // player.options(options)
    }
  }, [options, nuevoOptions])

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current.pause()
        playerRef.current.dispose()
        playerRef.current = null
      }
    }
  }, [])

  useEffect(() => {
    if (chapterList.length === 0 || !ready) return
    const generateVttContent = () => {
      const chapters = chapterList.map(chapter => {
        return `
        ${chapter.begin} --> ${chapter.end}
        ${chapter.title}
      `
      })

      return `WEBVTT

      ${chapters.join('\n\n')}
    `
    }

    const vttContent = generateVttContent()

    const vttBlob = new Blob([vttContent], { type: 'text/vtt' })

    const vttURL = URL.createObjectURL(vttBlob)
    setPlayerChapters({ kind: 'chapters', src: vttURL })
    if (playerRef.current) {
      console.log(playerRef.current)
      // @ts-ignore
      playerRef.current?.loadTracks({ kind: 'chapters', src: vttURL })
    }
  }, [chapterList])

  const onSubmit = async (data: any) => {
    console.log(data)
  }

  const initialValues: any = {
    begin: '',
    end: '',
    title: ''
  }

  const { handleBlur, values, handleChange, errors, touched, isValid, resetForm, setFieldValue } = useFormik({
    initialValues,
    validationSchema: videoChapterSchema,
    onSubmit,
    enableReinitialize: true
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const addChapter = async () => {
    if (!mediaId) return
    try {
      setIsSubmitting(true)
      const chapter = await api.addChapter(values.title, values.begin, values.end, mediaId)
      setChapterList([
        ...chapterList,
        {
          begin: chapter.begin,
          end: chapter.end,
          title: chapter.title,
          videoId: chapter.videoId ?? '',
          id: chapter.id
        }
      ])
      resetForm()
      snackActions.open('Chapter added successfully!')
    } catch (e) {
      console.log(e)
      snackActions.open('Something went wrong adding the Chapter, please try again')
    } finally {
      setIsSubmitting(false)
    }
  }

  const deleteChapter = async (chapterId: string) => {
    try {
      setIsDeleting(true)
      await api.deleteChapter(chapterId)
      const updatedChapterList = chapterList.filter(cpt => cpt.id !== chapterId)
      setChapterList(updatedChapterList)
      if (updatedChapterList.length === 0 && playerRef.current) {
        // @ts-ignore
        playerRef.current?.loadTracks({ kind: 'chapters', src: '' })
      }
      snackActions.open('Chapter removed successfully!')
    } catch (e) {
      console.log(e)
      snackActions.open('Something went wrong removing the Chapter, please try again')
    } finally {
      setIsDeleting(false)
    }
  }

  if (type === 'audio') {
    return <audio id={playerId} className="video-js" crossOrigin="anonymous" style={{ borderRadius: borderRadius }} />
  }

  const handleFocus = (field: 'begin' | 'end') => {
    setFieldValue(field, currentVideoTime)
  }

  const copyCurrentVideoTimeToClipboard = () => {
    navigator.clipboard
      .writeText(currentVideoTime)
      .then(() => {
        snackActions.open('Time copied to clipboard!')
      })
      .catch(err => {
        console.error('Error copying video time to clipboard', err)
      })
  }

  return (
    <Box sx={{ display: 'grid' }}>
      <video
        id={playerId}
        className="video-js"
        crossOrigin="anonymous"
        style={{ maxWidth: '100%', maxHeight: '99%', borderRadius: '20px', height: '400px' }}
        playsInline={playsInline}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px', py: '12px' }}>
        <Typography>Whenever you focus on the input, it will update to the current time of the video.</Typography>
        <Typography>
          Additionally, you can click{' '}
          <Link style={{ cursor: 'pointer' }} onClick={copyCurrentVideoTimeToClipboard}>
            here
          </Link>{' '}
          to copy the video time
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: '12px', py: '12px' }}>
        <TextField
          name="title"
          fullWidth
          placeholder="Chapter name"
          label="Chapter name"
          type="text"
          value={values.title}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.title && Boolean(errors.title)}
          helperText={(touched.title && errors.title) || ' '}
        />
        <TextField
          name="begin"
          fullWidth
          placeholder="00:00:00.000"
          label="Begin"
          type="text"
          value={values.begin}
          onChange={handleChange}
          onFocus={() => handleFocus('begin')}
          onBlur={handleBlur}
          error={touched.begin && Boolean(errors.begin)}
          helperText={(touched.begin && errors.begin) || ' '}
        />
        <TextField
          name="end"
          fullWidth
          placeholder="00:00:00.000"
          label="End"
          type="text"
          value={values.end}
          onChange={handleChange}
          onFocus={() => handleFocus('end')}
          onBlur={handleBlur}
          error={touched.end && Boolean(errors.end)}
          helperText={(touched.end && errors.end) || ' '}
        />
        <LoadingButton
          loading={isSubmitting}
          disabled={isSubmitting || !isValid}
          variant="contained"
          onClick={() => addChapter()}
          sx={{ flexShrink: 0, height: '56px' }}
        >
          Add chapter
        </LoadingButton>
      </Box>
      {chapterList.length > 0 && (
        <Box sx={{ display: 'grid' }}>
          <table style={{ width: '100%', textAlign: 'center' }}>
            <thead>
              <tr>
                <th>Title</th>
                <th>Begin</th>
                <th>End</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {chapterList.map((chapter, i) => (
                <tr key={i}>
                  <td>{chapter.title}</td>
                  <td>{chapter.begin}</td>
                  <td>{chapter.end}</td>
                  <td>
                    <LoadingButton
                      loading={isDeleting}
                      disabled={isDeleting}
                      variant="contained"
                      onClick={() => deleteChapter(chapter.id)}
                      sx={{ flexShrink: 0, height: '28px', width: '28px' }}
                    >
                      <DeleteIcon />
                    </LoadingButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      )}
    </Box>
  )
}

export const BookmarkVideoJsWrapper: React.FC<{
  subtitles?: SubtitleType[]
  options: VideoJsPlayerOptions
  preview?: string
  handleEnd?: () => void
  onPlay?: (time: number) => void
  playlist?: [] | any
  onLoadedMetaData?: (item: any) => void
  onPause?: () => void
  onPressPlay?: () => void
  borderRadius?: string
  playsInline?: boolean
  chapters?: { kind: string; src: string }
  mediaId?: string
}> = ({
  options,
  preview,
  subtitles,
  handleEnd,
  onPlay,
  playlist,
  onLoadedMetaData,
  onPressPlay,
  onPause,
  borderRadius,
  playsInline,
  chapters,
  mediaId
}) => {
  const _options = {
    ...options
  }

  const nuevoOptions = {
    logo: null,
    videoInfo: playlist && playlist?.length > 0
  }

  return (
    <BookmarkVideoJS
      subtitles={subtitles}
      handleEnd={handleEnd}
      options={_options}
      type={playlist ? 'playlist' : 'video'}
      nuevoOptions={nuevoOptions}
      onPlay={onPlay}
      playlist={playlist}
      onLoadedMetaData={onLoadedMetaData}
      onPause={onPause}
      onPressPlay={onPressPlay}
      borderRadius={borderRadius}
      playsInline={playsInline}
      chapters={chapters}
      mediaId={mediaId}
    />
  )
}

export const BookmarkAudioJsWrapper: React.FC<{
  subtitles?: SubtitleType[]
  options: VideoJsPlayerOptions
  id: string
  handleEnd?: () => void
  onPlay?: (time: number) => void
  playlist?: any
  onLoadedMetaData?: (item: any) => void
  onPause?: () => void
  onPressPlay?: () => void
  borderRadius?: string
}> = ({
  options,
  subtitles,
  handleEnd,
  onPlay,
  id,
  playlist,
  onLoadedMetaData,
  onPause,
  onPressPlay,
  borderRadius
}) => {
  const nuevoOptions = {
    logo: options.poster as string
  }
  const _options = {
    ...options,
    poster: '/images/CoverDefault.png'
  }

  function ErrorFallback(props: FallbackProps) {
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre>{props.error.message}</pre>
        <button onClick={props.resetErrorBoundary}>Try again</button>
      </div>
    )
  }

  const myErrorHandler = (error: Error, info: { componentStack: string }) => {
    console.error(error)
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={myErrorHandler}>
      <BookmarkVideoJS
        subtitles={subtitles}
        handleEnd={handleEnd}
        options={_options}
        type={playlist ? 'playlist' : 'audio'}
        nuevoOptions={nuevoOptions}
        onPlay={onPlay}
        playlist={playlist}
        onLoadedMetaData={onLoadedMetaData}
        onPause={onPause}
        onPressPlay={onPressPlay}
        borderRadius={borderRadius}
      />
    </ErrorBoundary>
  )
}
