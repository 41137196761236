import React, { useContext } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { PublicRequestsDialogContext } from './Context'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { usePageableRequest } from '../hooks/usePageableRequest'
import { CollectionThemeProps, InviteType } from '../../lib/types'
import api from '../../lib/api'
import useInfiniteScroll from '../hooks/useInfiniteScrool'
const PublicRequestsDialog: React.FC<{ collectionTheme: CollectionThemeProps }> = ({ collectionTheme }) => {
  const { actions, store } = useContext(PublicRequestsDialogContext)
  const { loading, items, hasNextPage, error, loadMore } = usePageableRequest<InviteType>({
    size: 10,
    q: store.collectionId,
    request: api.getEmbedStoryInvitesByCollectionId
  })

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: !!error,
    rootMargin: '0px 0px 400px 0px'
  })
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="md"
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          actions.close()
        }
      }}
      open
    >
      <DialogTitle
        sx={{
          backgroundColor: collectionTheme.requestsBackgroundColor,
          color: collectionTheme.requestsTextColor,
          fontFamily: collectionTheme.requestsTextFontFamily
        }}
      >
        Add your voice
        <IconButton
          aria-label="close"
          onClick={actions.close}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: collectionTheme.requestsTextColor
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ backgroundColor: collectionTheme.requestsBackgroundColor }}>
        <Grid
          container
          sx={{
            padding: '24px'
          }}
          spacing={4}
        >
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{ color: collectionTheme.requestsTextColor, fontFamily: collectionTheme.requestsTextFontFamily }}
            >
              Want to share your story? Select a story request and record your own experience!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              {items.map(invite => (
                <Box
                  key={invite.id}
                  sx={{
                    minHeight: '120px',
                    width: '100%',
                    bgcolor: '#313131',
                    borderRadius: '20px',
                    marginBottom: 3,
                    display: 'flex',
                    alignItems: 'center',
                    flexFlow: { xs: 'column', md: 'row' },
                    backgroundColor: collectionTheme.cardBackgroundColor
                  }}
                >
                  <Button
                    href={invite.url}
                    target="_blank"
                    sx={{
                      borderRadius: '20px',
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: { xs: 'column', md: 'row' },
                      padding: 2,
                      ':hover': { backgroundColor: collectionTheme.cardHoverColor }
                    }}
                  >
                    <Box
                      sx={{
                        flex: { xs: 'unset', md: '1' },
                        width: { xs: '100%', md: 'unset' },
                        paddingX: '8px'
                      }}
                    >
                      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                        <Typography
                          sx={{
                            color: collectionTheme.requestsTextColor ?? 'white',
                            fontFamily: collectionTheme.requestsTextFontFamily
                          }}
                        >
                          <b>{invite.title}</b>
                        </Typography>
                      </Box>
                    </Box>
                    <div
                      className={'text-overflow-box'}
                      style={{
                        color: collectionTheme.requestsTextColor ?? 'white',
                        fontFamily: collectionTheme.requestsTextFontFamily
                      }}
                    >
                      {invite.description}
                    </div>
                  </Button>
                </Box>
              ))}
              {hasNextPage && (
                <Grid item xs>
                  <div ref={sentryRef} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default PublicRequestsDialog
