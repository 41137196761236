import React, { useCallback, useContext, useEffect, useState } from 'react'
import StoriesSlider from './StoriesSlider'
import CollectionsTile from './CollectionsSlider'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import Typography from '@mui/material/Typography'
import SearchBox from '../../../components/SearchBox'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { StepStoryUploadContext } from '../../../components/StepStoryUpload'
import { StepInviteRecorderContext } from '../../../components/StepInviteRecorder'
import { CollectionTheme, CollectionType, SearchResultType } from '../../../lib/types'
import { CreateEventContext } from '../../../components/CreateEvent'
import { usePageableRequest } from '../../../components/hooks/usePageableRequest'
import api from '../../../lib/api'
import { UserContext } from '../../../components/UserProvider'
import { capitalizeFirstLetter } from '../../../components/Capitalize'
import { usePermissions } from '../../../components/hooks/usePermissions'

const MyLibrary: React.FC<RouteComponentProps> = () => {
  const { actions: newStoryActions } = useContext(StepStoryUploadContext)
  const { actions: recorderActions } = useContext(StepInviteRecorderContext)
  const { actions: createEventActions } = useContext(CreateEventContext)
  const history = useHistory()
  const { items, setItems } = usePageableRequest<SearchResultType>({
    size: 15,
    q: '',
    request: api.searchCollections
  })
  const [themes, setThemes] = useState<CollectionTheme[]>([])
  const { member } = useContext(UserContext)
  const { isCreator } = usePermissions()

  const getTenantsName = () => {
    if (member && member.tenant) {
      const firstName = capitalizeFirstLetter(member.tenant.firstName)
      const lastName = capitalizeFirstLetter(member.tenant.lastName)
      const fullName = firstName + ' ' + lastName
      return fullName
    }
    return null
  }

  const handleCallback = useCallback(
    (collection: CollectionType | null) => {
      if (!collection) return
      setItems([
        {
          kind: 'COLLECTION',
          title: collection.title,
          id: collection.id
        },
        ...items
      ])
    },
    [items, setItems]
  )

  useEffect(() => {
    const getThemes = async () => {
      try {
        const response = await api.getCollectionThemes()
        setThemes(response)
      } catch (e) {
        console.log(e)
      }
    }
    getThemes()
  }, [])

  return (
    <Grid
      container
      sx={{
        padding: '24px'
      }}
      spacing={4}
    >
      <Grid item xs={12}>
        <Typography variant="h6">{getTenantsName() ? getTenantsName() + `'s Library` : 'My Library'}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm>
            <SearchBox onSearch={value => history.push({ pathname: '/a/search', search: `q=${value}` })} />
          </Grid>
          <Grid item xs={12} sm minWidth={180} textAlign="right">
            <ButtonGroup sx={{ height: '54px' }} size="large" variant="outlined" disabled={!isCreator}>
              <Button onClick={() => newStoryActions.open()}>Add a new story</Button>
              <Button onClick={() => recorderActions.open(null)}>Request stories</Button>
              <Button onClick={() => createEventActions.open(handleCallback, undefined, themes)}>Add an event</Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CollectionsTile q={''} />
      </Grid>
      <Grid item xs={12}>
        <StoriesSlider q={''} />
      </Grid>
    </Grid>
  )
}

export default MyLibrary
