import React, { useContext, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useFormik, FormikValues, FormikProvider } from 'formik'
import { schema } from './schema'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { SimpleSnackbarContext } from '../SimpleSnackbar'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { InviteType, MediaType } from '../../lib/types'
import api from '../../lib/api'
import LoadingButton from '@mui/lab/LoadingButton'
import { UserContext } from '../UserProvider'
import Form from './Form'

type initialValuesType = {
  instructions: string
  recordingType: MediaType | 'NONE'
  expire: Date
  size: number
  title: string
  allowExpire: boolean
  allowOrganization: boolean
  callToActionInstructions: string
  callToActionButtonLabel: string
  callToAction: string
  hardStop: boolean
  template: boolean
  requestFiles: boolean
  requestImages: boolean
}

const StepInviteRecorderEdit: React.FC<{ invite: InviteType; onClose: (invite?: InviteType | null) => void }> = ({
  invite,
  onClose
}) => {
  const { user } = useContext(UserContext)
  const { actions: snackActions } = useContext(SimpleSnackbarContext)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const initialValues: initialValuesType = {
    instructions: invite.description,
    recordingType: invite.requestedMediaType || 'NONE',
    expire: invite.dueAt,
    size: invite.requestedMediaLength,
    title: invite.title,
    allowExpire: !!invite.dueAt,
    allowOrganization: !!invite.organization,
    callToActionInstructions: invite.callToActionInstructions,
    callToActionButtonLabel: invite.callToActionButtonLabel,
    callToAction: invite.callToAction,
    hardStop: invite.hardStop,
    template: invite.template,
    requestFiles: invite.requestFiles,
    requestImages: invite.requestImages
  }

  const onSubmit = async (values: FormikValues) => {
    try {
      const newInvite = await api.updateInvite(invite.id, {
        storyId: '',
        collectionId: invite.collectionId,
        organizationId: values.allowOrganization ? user.organizations[0].id : null,
        title: values!.title,
        description: values!.instructions,
        dueAt: values.allowExpire ? values!.expire : null,
        requestedMediaLength: Number(values!.size),
        requestedMediaType: values.recordingType === 'NONE' ? null : values.recordingType,
        callToActionInstructions: values?.callToActionInstructions,
        callToActionButtonLabel: values?.callToActionButtonLabel,
        callToAction: values?.callToAction,
        hardStop: values?.hardStop,
        template: values?.template,
        requestFiles: values?.requestFiles,
        requestImages: values?.requestImages
      })
      onClose(newInvite)

      snackActions.open('Invite updated.')
    } catch (error) {
      // @ts-ignore
      const { data, message } = error.response
      if (data?.code) {
        snackActions.open(message)
        throw error
      }
      snackActions.open('Something was wrong! please try again.')
      throw error
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    validateOnBlur: true,
    onSubmit
  })

  useEffect(() => {
    formik.handleReset(initialValues)
  }, [])

  return (
    <form>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth="md"
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            onClose()
          }
        }}
        open
      >
        <DialogTitle>
          Edit invite recorders
          <IconButton
            aria-label="close"
            onClick={() => onClose()}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ width: '100%', marginY: 3, minHeight: 328 }}>
            <FormikProvider value={formik}>
              <Form />
            </FormikProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => onClose()} sx={{ mt: 1, mr: 1 }}>
            Close
          </Button>
          <LoadingButton
            variant="contained"
            loading={formik.isSubmitting}
            disabled={!formik.isValid}
            onClick={() => formik.handleSubmit()}
            sx={{ mt: 1, mr: 1 }}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </form>
  )
}

export default StepInviteRecorderEdit
