import React, { useState, createContext, useEffect } from 'react'
import { iGreetingsDialog } from './index'
import Component from './Component'
import { useLocation, useHistory } from 'react-router-dom'
import { parse, stringify } from 'qs'

// @ts-ignore
export const GreetingsDialogContext = createContext<{
  actions: {
    open: () => void
    close: () => void
  }
  store: iGreetingsDialog
}>({
  // @ts-ignore
  actions: {},
  store: {
    isOpen: false
  }
})

export const GreetingsDialogProvider: React.FC = ({ children }) => {
  const location = useLocation()
  const history = useHistory()

  const queryString: any = parse(location.search, { ignoreQueryPrefix: true })

  const [stepStoryUpload, setGreetingsDialog] = useState<iGreetingsDialog>({
    isOpen: false
  })

  const open = () => {
    history.replace({
      ...location,
      search: stringify(
        {
          ...queryString,
          showGreetings: true
        },
        { addQueryPrefix: true }
      )
    })
  }

  const close = () => {
    const { showGreetings, ...qs } = queryString
    history.replace({
      ...location,
      search: stringify(qs, { addQueryPrefix: true })
    })
  }

  useEffect(() => {
    setGreetingsDialog({
      isOpen: queryString.showGreetings
    })
  }, [location])

  return (
    <GreetingsDialogContext.Provider
      value={{
        actions: {
          open,
          close
        },
        store: stepStoryUpload
      }}
    >
      <Component />
      {children}
    </GreetingsDialogContext.Provider>
  )
}
