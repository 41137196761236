import React, { useContext, useState } from 'react'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import api from '../../../lib/api'
import { SimpleSnackbarContext } from '../../../components/SimpleSnackbar'
import { UserContext } from '../../../components/UserProvider'
import { UserFormType } from '../../../lib/types'
import { FormikValues, useFormik } from 'formik'
import { closeAccountSchema, updateUserSchema } from './schemas'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Cookies from 'js-cookie'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import CreateIcon from '@mui/icons-material/Create'
import CloseIcon from '@mui/icons-material/Close'
import Link from '@mui/material/Link'
// @ts-ignore
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone'
import { FormDialogContext } from '../../../components/FormDialog'
import Tooltip from '@mui/material/Tooltip'
import { ChangeReferralCodeDialogContext } from '../../../components/ChangeReferralCodeDialog'

const Info: React.FC = () => {
  const { actions: snackActions } = useContext(SimpleSnackbarContext)
  const { actions: formDialogActions } = useContext(FormDialogContext)
  const [progress, setProgress] = useState<number>(0)
  const { user, setUser } = useContext(UserContext)
  const { actions: changeReferralCodeDialogActions } = useContext(ChangeReferralCodeDialogContext)

  const handleCloseSubmit = async (data: FormikValues) => {
    try {
      await api.closeAccount(data)
      snackActions.open('Account closed with success!')
    } catch (error) {
      // @ts-ignore
      const { data } = error.response
      if (data.code) {
        snackActions.open('Wrong password.')
        return
      }
      snackActions.open('Something was wrong! please try again.')
    }
  }

  const handleCloseAccount = () => {
    formDialogActions.open(
      'Close account',
      'Are you sure you want to close your account? \n' +
        'We will maintain your account for 30 days. After that, your account will be removed along with your data and cannot be recovered. Please enter your password to confirm you wish to close your account.',
      [{ label: 'Password', name: 'password', placeholder: 'password', type: 'password' }],
      { password: '' },
      closeAccountSchema,
      handleCloseSubmit,
      { okText: 'Yes, close my account', cancelText: `No, keep my account` }
    )
  }

  const updateProfile = async (data: UserFormType) => {
    try {
      const newUser = await api.updateMe({ ...user, ...data })
      snackActions.open('User info updated!')
      setUser(newUser)
    } catch (error) {
      // @ts-ignore
      const { data } = error.response
      if (data.code === '1004') {
        snackActions.open('Wrong password.')
        return
      }
      snackActions.open('Something was wrong! please try again.')
    }
  }

  const onSubmit = async (data: UserFormType) => {
    updateProfile(data)
  }

  const initialValues: UserFormType = {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    phone: user?.phone || '',
    about: user?.about || '',
    facebook: user?.facebook || '',
    twitter: user?.twitter || '',
    instagram: user?.instagram || '',
    linkedin: user?.linkedin || '',
    whatsapp: user?.whatsapp || ''
  }

  const { handleSubmit, handleBlur, values, handleChange, errors, touched } = useFormik({
    initialValues,
    validationSchema: updateUserSchema,
    onSubmit,
    enableReinitialize: true
  })

  const onDrop: <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void =
    async acceptedFiles => {
      const selectedFile = acceptedFiles[0]
      const image = await api.uploadImage(selectedFile, event => {
        setProgress(Math.round((event.loaded * 100) / event.total))
      })
      setProgress(0)
      await api.updateMeCover(image.id)
      const userInfo = await api.getMe()
      Cookies.set('user', JSON.stringify(userInfo))
      snackActions.open('User picture updated!')
    }

  const onRemove = async () => {
    await api.updateMeCover(null)
    const userInfo = await api.getMe()
    Cookies.set('user', JSON.stringify(userInfo))
    snackActions.open('User picture removed!')
  }

  const { getRootProps, getInputProps, open } = useDropzone({ onDrop, noClick: true, accept: 'image/png, image/jpeg' })

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const key = event.key
    if (!/^[0-9]$/.test(key)) {
      event.preventDefault()
    }
  }

  return (
    <Box component="form" sx={{ width: '100%', bgcolor: 'background.paper', padding: 2 }}>
      {(user.hasOwnLibrary || (!user.tenant && !user.userOfTenant)) && (
        <>
          <Box sx={{ paddingY: 2 }}>
            <Typography sx={{ paddingBottom: 2 }} variant="h6">
              Your referral code:
            </Typography>
            <Typography sx={{ paddingBottom: 2 }} variant="subtitle2">
              Refer others to sign up for Rakonto accounts and we&apos;ll pay you for each user that upgrades their
              subscription!
              {/* <Link sx={{ cursor: 'pointer' }}>Click here</Link> for more information. */}
            </Typography>
            <Typography sx={{ paddingBottom: 2 }} variant="subtitle2">
              Click the button to copy your code, then share it with others.
            </Typography>
            <CopyToClipboard
              text={user?.referralCode || ''}
              options={{ format: 'text' }}
              onCopy={() => snackActions.open('Referral code copied to clipboard!')}
            >
              <Button variant="contained">{user.referralCode}</Button>
            </CopyToClipboard>
            <Tooltip
              placement="bottom-end"
              title="Click to input your custom referral code."
              sx={{
                height: '2.8em',
                paddingLeft: '2em',
                marginLeft: '1em'
              }}
            >
              <Button
                onClick={() => changeReferralCodeDialogActions.open()}
                startIcon={<CreateIcon />}
                variant="outlined"
              />
            </Tooltip>
          </Box>
          <Divider />
        </>
      )}
      <Typography sx={{ paddingBottom: 2, paddingTop: 2 }} variant="h6">
        Let people know about you!{' '}
      </Typography>

      <Box sx={{ minWidth: '320px', maxWidth: '422px' }}>
        <Box
          sx={{
            paddingTop: 2,
            paddingBottom: 4,
            display: 'flex',
            flexFlow: 'row',
            alignItems: 'center'
          }}
        >
          <Box
            {...getRootProps()}
            sx={{
              height: '120px',
              minWidth: '120px',
              borderRadius: '50%',
              border: '1px solid',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundImage: user?.picture?.url ? `url(${user.picture.url})` : 'none',
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat'
            }}
          >
            <input {...getInputProps()} />
            {!user?.picture?.url && <CameraAltIcon sx={{ fontSize: 40 }} />}
          </Box>
          <Box
            sx={{
              paddingLeft: 2
            }}
          >
            <div>
              <Button onClick={open} startIcon={<CreateIcon />} variant="outlined">
                Change
              </Button>
              {user?.picture?.url && (
                <Button onClick={onRemove} color="secondary" startIcon={<CloseIcon />}>
                  Remove
                </Button>
              )}
            </div>
            <Typography sx={{ paddingTop: 1 }} variant="h6">
              JPG or PNG. Max size of 5 MB.
            </Typography>
          </Box>
        </Box>

        <TextField
          autoFocus
          name="firstName"
          fullWidth
          placeholder="First name"
          label="First name"
          type="text"
          value={values.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.firstName && Boolean(errors.firstName)}
          helperText={(touched.firstName && errors.firstName) || ' '}
        />
        <TextField
          name="lastName"
          fullWidth
          placeholder="Last name"
          label="Last name"
          type="text"
          value={values.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.lastName && Boolean(errors.lastName)}
          helperText={(touched.lastName && errors.lastName) || ' '}
        />
        <TextField
          name="phone"
          fullWidth
          placeholder="Phone Number"
          label="Phone Number"
          type="text"
          value={values.phone}
          onKeyPress={handleKeyPress}
          onChange={handleChange}
          onBlur={handleBlur}
          InputProps={{
            startAdornment: '+',
            disableUnderline: true
          }}
          inputProps={{
            maxLength: 15
          }}
        />
        <Typography sx={{ paddingBottom: 4, paddingTop: 2 }} variant="h6">
          Your profile will appear on stories and collections you create, as well as any comments you post.
        </Typography>
        <TextField
          name="about"
          fullWidth
          placeholder="About"
          multiline
          rows={6}
          label="About"
          type="text"
          value={values.about}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.about && Boolean(errors.about)}
          helperText={(touched.about && errors.about) || ' '}
        />
        <Typography sx={{ pb: 2, ml: 2 }} variant="h6">
          Social
        </Typography>
        <TextField
          label="Facebook"
          name="facebook"
          type="text"
          value={values.facebook}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.facebook && Boolean(errors.facebook)}
          helperText={(touched.facebook && errors.facebook) || ' '}
          fullWidth
        />
        <TextField
          label="Twitter"
          name="twitter"
          type="text"
          value={values.twitter}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.twitter && Boolean(errors.twitter)}
          helperText={(touched.twitter && errors.twitter) || ' '}
          fullWidth
        />
        <TextField
          label="Instagram"
          name="instagram"
          type="text"
          value={values.instagram}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.instagram && Boolean(errors.instagram)}
          helperText={(touched.instagram && errors.instagram) || ' '}
          fullWidth
        />
        <TextField
          label="Linkedin"
          name="linkedin"
          type="text"
          value={values.linkedin}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.linkedin && Boolean(errors.linkedin)}
          helperText={(touched.linkedin && errors.linkedin) || ' '}
          fullWidth
        />
        <TextField
          label="Whatsapp"
          name="whatsapp"
          type="text"
          value={values.whatsapp}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.whatsapp && Boolean(errors.whatsapp)}
          helperText={(touched.whatsapp && errors.whatsapp) || ' '}
          fullWidth
        />
        <Box sx={{ textAlign: 'end' }}>
          <Button color={'primary'} variant="contained" onClick={() => handleSubmit()}>
            Save
          </Button>
        </Box>
      </Box>
      <Box sx={{ mt: 4, padding: 2 }}>
        <Typography variant="body1">
          <Link component="button" onClick={handleCloseAccount} underline="hover" variant="body1">
            Close account
          </Link>
        </Typography>
      </Box>
    </Box>
  )
}

export default Info
