import * as yup from 'yup'

export const updatePasswordSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      'Must contain at least 8 characters and include uppercase, lowercase, numbers and special characters'
    )
    .label('Password'),
  confirmation: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
    .required()
    .label('Password confirmation')
})

export const updateUserSchema = yup.object().shape({
  firstName: yup.string().required().label('First name'),
  lastName: yup.string().required().label('Last name'),
  about: yup.string().label('About'),
  phone: yup
    .string()
    .matches(/^\+(?:[0-9] ?){6,14}[0-9]$/, 'Phone number is not valid format try (+14095555555)')
    .label('Phone'),
  facebook: yup.string().url().label('Facebook'),
  twitter: yup.string().url().label('Twitter'),
  instagram: yup.string().url().label('Instagram'),
  linkedin: yup.string().url().label('Linkedin'),
  whatsapp: yup.string().label('Whatsapp')
})

export const closeAccountSchema = yup.object().shape({
  password: yup.string().required().label('Password')
})

export const emailSchema = yup.object().shape({
  email: yup.string().required().email().label('Email')
})

export const videoChapterSchema = yup.object().shape({
  title: yup.string().required().label('Title'),
  begin: yup
    .string()
    .matches(/^([0-9][0-9]:[0-5][0-9]:[0-5][0-9]\.\d{3})$/, 'Must be in the format hh:mm:ss.000')
    .required()
    .label('Begin'),
  end: yup
    .string()
    .matches(/^([0-9][0-9]:[0-5][0-9]:[0-5][0-9]\.\d{3})$/, 'Must be in the format hh:mm:ss.000')
    .required()
    .label('End')
})
