import React from 'react'
import { GoogleLogin } from '@react-oauth/google'
import Cookies from 'js-cookie'
import { SimpleSnackbarContext } from '../../../components/SimpleSnackbar'
import api from '../../../lib/api'
import { useHistory } from 'react-router-dom'
import { parse } from 'qs'
import Box from '@mui/material/Box'
import handleTenantSignIn from '../../../components/HandleTenantSignIn'

const Component = () => {
  const history = useHistory()
  const { returnUrl } = parse(location.search, { ignoreQueryPrefix: true })
  const { actions: snackActions } = React.useContext(SimpleSnackbarContext)

  const ok = async (resp: any) => {
    try {
      const userInfo = await api.signinGoogle({ token: resp.credential })
      Cookies.set('token', userInfo.token)
      Cookies.set('backupToken', userInfo.token)
      if (userInfo.user) {
        await handleTenantSignIn(userInfo.user)
      }
      if (!userInfo.user.onboardAt) {
        history.push('/a/onboarding')
        return
      }
      if (returnUrl) {
        await history.push(returnUrl as string)
        return
      }
      history.push('/a/my-library')
    } catch (error) {
      snackActions.open('Something was wrong! please try again.')
    }
  }
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            opacity: 0,
            maxWidth: '40px',
            maxHeight: '40px',
            overflow: 'hidden'
          }}
        >
          <GoogleLogin onSuccess={ok} />
        </div>
        <Box
          style={{
            cursor: 'pointer',
            width: '40px',
            height: '40px',
            borderRadius: '8px',
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img width={'28px'} height={'28px'} src={'/images/Google__G__Logo.png'} alt="Google" />
        </Box>
      </div>
    </div>
  )
}

export default Component
