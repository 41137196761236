// import { VideoJsPlayer } from 'video.js'
// @ts-ignore

import { LatLngExpression } from 'leaflet'
import { ReactNode } from 'react'
import { VideoChapter } from '../components/VideoBookmarkDialog/BookmarkVideoJs'

export type apiOptions = {
  errorBoundary: boolean
}

export type markerType = {
  id: string
  title?: string
  content?: string | ReactNode
  marker: LatLngExpression
  place?: PlaceType
}

export type Pageable<T> = {
  totalPages: number
  totalElements: number
  size: number
  content: T[]
  number: number
  sort: {
    sorted: boolean
    unsorted: boolean
    empty: boolean
  }
  numberOfElements: number
  pageable: {
    offset: number
    sort: {
      sorted: boolean
      unsorted: boolean
      empty: boolean
    }
    pageNumber: 0
    unpaged: boolean
    paged: boolean
    pageSize: 0
  }
  first: boolean
  last: boolean
  empty: boolean
}

export type SearchResultKindType = 'COLLECTION' | 'STORY'

export type SearchResultType = {
  id: string
  kind: SearchResultKindType
  title: string
  createdAt?: string
  editors?: string[]
  owner?: UserType
  ownerFirstName?: string
  ownerPicture?: string
  ownerLastname?: string
  ownerDeletedAt?: string
  publicAcl?: boolean
  publishedAt?: string
  ready?: boolean
  items?: string[]
  viewers?: string[]
  picture?: string
  storyType?: MediaType
  storyGif?: string
  storyCollectionTitles?: string[]
}

export type StatisticType = {
  collectionCountPublic: string
  collectionCountPrivate: string
  storyCountDraft: string
  storyCountPublished: string
  storageByCollection: {
    // eslint-disable-next-line camelcase
    audio_total_storage: string
    id: string
    title: string
    total: string
    // eslint-disable-next-line camelcase
    video_total_storage: string
  }[]
}

export type UserQuotaType = {
  available: string
  used: string
  free: string
  percentual: number
}

export type UserReferral = {
  id: string
  picture: ImageType
  email: string
  firstName: string
  lastName: string
}

export enum TeamMemberRoleEnum {
  ADMINISTRATOR = 'ADMINISTRATOR',
  MANAGER = 'MANAGER',
  CREATOR = 'CREATOR',
  EDITOR = 'EDITOR',
  VIEWER = 'VIEWER'
}

export type TeamMemberType = {
  userId: string
  role: TeamMemberRoleEnum
  id: string
  teamMemberIsActive: boolean
  name: string
  email: string
  tenant: UserType
  permissions: any[]
}

export type UserType = {
  id: string
  email: string
  firstName: string
  lastName: string
  phone: string
  picture: ImageType
  onboardAt: string
  keepOnlyOptimized: boolean
  about: string
  referralCode: string
  referrals: UserReferral[]
  referredBy: string
  referredAt: string
  location: string
  tier: number
  trial: boolean
  freeTrialUntilAt: string
  organizations: OrganizationType[]
  teams: UserType[]
  facebook: string
  twitter: string
  instagram: string
  linkedin: string
  whatsapp: string
  industry: string
  personalPreferences: string[]
  professionalPreferences: string[]
  teamMembers: TeamMemberType[]
  userOfTenant: boolean
  rakontoAdmin: boolean
  tenant: boolean
  role: TeamMemberRoleEnum
  tenantList: Tenant[]
  hasOwnLibrary: boolean
}

export type UserFormType = {
  firstName?: string
  lastName?: string
  phone?: string
  about?: string
  pictureId?: string | null
  facebook?: string
  twitter?: string
  instagram?: string
  linkedin?: string
  whatsapp?: string
  industry?: string
  personalPreferences?: string[]
  professionalPreferences?: string[]
}

export type AuthType = {
  token: string
  user: UserType
}

export type TeamMemberAuthType = {
  tenantToken: string
  member: TeamMemberType
}

export type ShortIdType = {
  url: string
}

export type SigninFormType = {
  email: string
  password: string
}

export type SigninFormFacebook = {
  token: string
}

export type SigninFormGoogle = {
  token: string
}

export type SingupFormType = {
  // firstName: string
  // lastName: string
  // password: string
  // confirmation: string
  email: string
  allowEmail: boolean
  allowShareInfo: boolean
}

export type PasswordResetForm = {
  token: string
  confirmation: string
  password: string
}

export type PasswordChangeForm = {
  newPassword: string
  confirmation: string
  password: string
}

export type MediaType = 'AUDIO' | 'VIDEO'

export enum Resolutions {
  '360p' = '360p',
  '480p' = '480p',
  '720p' = '720p',
  '1028p' = '1028p'
}

export type Media = {
  id: string
  info: Partial<{
    [key: string]: string | number | null
  }>
  url: string
}

export type Gif = {
  id: string
  resolution: string
  url: string
}

export type Thumbnail = {
  id: string
  num: number
  resolution: string
  url: string
}

export type VideoDetails = {
  id: string
  url?: string
  gifUrl?: string
  thumbnailUrl?: string
}

export type AudioDetails = {
  id: string
  url?: string
  gifUrl?: string
  thumbnailUrl?: string
}

export type StoryType = {
  audio: AudioDetails
  collections: CollectionType[]
  cover: ImageType
  description: string
  id: string
  thumbnailUrl: string
  downloadUrl: string
  downloadImagesUrl: string
  downloadFilesUrl: string
  ready: boolean
  error: boolean
  transcriptStatus: string | undefined
  publicAcl: boolean
  published: boolean
  title: string
  owner: UserType
  type: MediaType
  video: VideoDetails
  watchers: Watcher[]
  persons: PersonType[]
  files: FileType[]
  links: LinkType[]
  transcription: TranscriptionType
  galleryEntries: GalleryType[]
  timelineEntries: TimelineType[]
  places: PlaceType[]
  subtitles: SubtitleType[]
  submission: SubmissionType
  updatedAt: string
  createdAt: string
  duration: string
  mimeType: string
  url: string
  requiredEmail: boolean
  recorderName: string
  recorderEmail: string
  recorderJobTitle: string
  recorderFunctionalArea: string
  summary: string
  chapters?: VideoChapter[]
  questionnaireId?: string
  mergeStatus: string
  processingIntroAndOutro: boolean
  graphicsProcessed: boolean
  sentToDam: boolean
}

export type StoryStatusType = {
  storyId: string
  processingGraphics: boolean
  processingTranscript: boolean
  processingSubtitle: boolean
}

export type StoryCreateType = {
  title: string
  description: string
}

export type StoryUpdateType = {
  title?: string
  description?: string
  coverId?: string
  collections?: string[]
}

export type StoryRecorderUpdateType = {
  recorderName?: string
  recorderJobTitle?: string
  recorderFunctionalArea?: string
}

// /api/a/transcriptions/collection/{id}/{format:pdf|docx|plain|zip}
export type CollectionType = {
  entity: string
  id: string
  title: string
  description: string
  cover: ImageType
  owner: UserType
  thumbnailUrl: string
  downloadUrl: string
  downloadTranscriptsUrl: string
  stories: StoryType[]
  publicAcl: boolean
  watchers: Watcher[]
  updatedAt: string
  createdAt: string
  type: string
  requiredEmail: boolean
  collectionInvite?: InviteType
  theme?: CollectionTheme
}

export type CollectionFormType = {
  title?: string
  description?: string
  coverId?: string
  type?: string
  theme?: CollectionTheme
}

export type ImageType = {
  id: string
  url: string
  thumbnailUrl: string
  originalName: string
}

export type GalleryType = {
  id: string
  image: ImageType
  updatedAt: Date
  createdAt: Date
}

export type FileType = {
  id: string
  createdAt: Date
  name: string
  originalName: string
  url: string
  size: number
  mimeType: string
}

export type SubtitleType = FileType & {
  language: LanguageEnum
}

export type LinkType = {
  id: string
  url: string
}

export type TranscriptionType = {
  id: string
  content: string
  cooked: unknown
  storyId: string
  updatedAt: Date
  createdAt: Date
}

export type WatcherType = 'VIEWER' | 'EDITOR'

export type Watcher = {
  id: string
  email: string
  notifiedAt: Date
  updatedAt: Date
  createdAt: Date
  user: UserType
  type: WatcherType
}

export type PersonFormType = {
  name: string
  link: string
  pictureId: string | null
}

export type LinkFormType = {
  url: string
  storyId: string
}

export type TranscriptionFormType = {
  content: string
  storyId: string
  cooked: unknown
}

export type PersonType = {
  id: string
  name: string
  link: string
  picture: ImageType | null
}

export type TimelineType = {
  id: string
  title: string
  description: string
  at: Date
}

export type TimelineFormType = {
  storyId: string
  title: string
  description: string
  at: Date
}

export type PlaceType = {
  id: string
  name: string
  description?: string
  location?: string
  latitude?: string
  longitude?: string
}

export type PlaceFormType = {
  name: string
  description: string
  location: string
  latitude: string
  longitude: string
}

export type addWatcherType = {
  id: string
  email: string
  type: WatcherType
}

export enum AssetTypes {
  'collection' = 'collection',
  'story' = 'story'
}

export type CommentType = {
  id: string
  parentId: string
  author: UserType
  body: string
  mentions: UserType[]
  updatedAt: Date
}

export type CommentFormType = {
  commentableId: string
  commentableType: 'collection' | 'story'
  body: string
  parentId?: string
}

export type LocationSearchType = {
  boundingbox?: string[]
  class?: string
  // eslint-disable-next-line camelcase
  display_name: string
  icon?: string
  importance?: number
  lat: string
  licence?: string
  lon: string
  osmId?: number
  osmType?: string
  placeId?: number
  type?: string
}

export type InviteInput = {
  collectionId: string
  organizationId: string | null
  title: string
  storyId: string | null
  description: string
  instructions?: string
  requestedMediaType: MediaType | null
  requestedMediaLength: number
  dueAt: Date
  callToActionInstructions: string | null
  callToActionButtonLabel: string | null
  callToAction: string | null
  hardStop: boolean
  template: boolean
  requestFiles: boolean
  requestImages: boolean
}

export type InviteType = {
  id: string
  collectionId: string
  collectionTitle: string
  organization: OrganizationType
  video: {
    id: string
    url: string
    thumbnailUrl: string
    gifUrl: string
  }
  title: string
  description: string
  instructions: string
  requestedMediaType: MediaType
  requestedMediaLength: number
  user: UserType
  token: string
  url: string
  dueAt: Date
  createdAt: string
  ready: boolean
  hardStop: boolean
  callToActionInstructions: string
  callToActionButtonLabel: string
  callToAction: string
  questionnaireId: string
  questions: QuizQuestionType[]
  template: boolean
  storyId: string
  recorderName: string
  recorderEmail: string
  recorderJobTitle: string
  recorderFunctionalArea: string
  requestFiles: boolean
  requestImages: boolean
}

export type InviteContributorInput = {
  storyId: string
  title: string
  description: string
  requestedMediaType: 'FILE' | 'GALLERY_ENTRY' | null
  dueAt: Date
}

export type InviteContributorType = {
  id: string
  storyId: string
  title: string
  description: string
  requestedMediaType: 'FILE' | 'GALLERY_ENTRY' | null
  user: UserType
  token: string
  url: string
  dueAt: Date
  allowExpire: boolean
  createdAt: string
}

export type SubmissionType = {
  createdAt: Date
  id: string
  invite: InviteType
  email: string
  name: string
  storyId: string
}

export enum LanguageEnum {
  'EN-GB' = 'English (GB)',
  'EN-UK' = 'English (UK)',
  'EN-US' = 'English (US)',
  'FR' = 'French',
  'IT' = 'Italian',
  'EN-AU' = 'English (AU)',
  'ES' = 'Spanish',
  'PT-PT' = 'Portuguese',
  'PT-BR' = 'Portuguese (Brazil)',
  'DE' = 'German',
  'NL' = 'Dutch',
  'RU' = 'Russian',
  'JA' = 'Japanesse',
  'ZH' = 'Chinese'
}

export type ProductSubscriptionType = {
  id: string
  stripePriceId: string
  stripeSubscriptionId: string
  updatedAt: Date
  createdAt: Date
}

export type OrganizationType = {
  id: string
  memberships: OrganizationMemberType[]
  logo: ImageType
  name: string
  addressLine1: string
  addressLine2: string
  city: string
  state: string
  postalCode: string
  country: string
  phone: string
  email: string
  socialFacebook?: string
  socialTwitter?: string
  socialInstagram?: string
  socialLinkedin?: string
  socialWhatsapp?: string
  updatedAt: Date
  createdAt: Date
  privacyPolicy?: string
  termsAndConditions?: string
}

export type OrganizationInput = {
  logoId?: string | null
  name: string
  addressLine1: string
  addressLine2: string
  city: string
  state: string
  postalCode: string
  country: string
  phone: string
  email: string
  socialFacebook?: string
  socialTwitter?: string
  socialInstagram?: string
  socialLinkedin?: string
  socialWhatsapp?: string
  privacyPolicy?: string
  termsAndConditions?: string
}

export type OrganizationMemberType = {
  id: string
  user: UserType
  status: 'ÁCCEPETD' | 'PENDING' | 'DECLINED'
}

export type RakontoAdminType = {
  id: string
  user: UserType
  updatedAt: Date
  createdAt: Date
}

export type QuizInputType = {
  title: string
  description: string
  language: LanguageEnum
  questionnairesIds?: string[]
}

export type QuestionnaireType = {
  id: string
  title: string
  description: string
  language: LanguageEnum
  questions: QuizQuestionType[]
}

export type QuestionnaireTemplateType = QuestionnaireType & {
  questionnaires: QuestionnaireType[]
}

export type QuizQuestionInputType = {
  questionnaireTemplateId: string
  question: string
  recommendation?: string
  title: string
  number?: number
  questionnaireIds?: string[]
}

export type QuizQuestionType = {
  id: string
  questionnaireTemplateId: string
  question: string
  recommendation?: string
  title: string
  number: number
}

export type QuestionnaireReviewer = {
  reviewerId: string
  reviewerName: string
  isApproved: boolean
  isReviewed: boolean
}
export type QuizInviteType = {
  id: string
  title: string
  description: string
  language: LanguageEnum
  questions: QuizInviteQuestionType[]
  published: boolean
  reviewers: QuestionnaireReviewer[]
  questionnaireId?: string
  privateQuestionnaire?: boolean
}

export type QuizInviteQuestionType = {
  id: string
  questionnaireTemplateId: string
  question: string
  number: number
  title: string
  recommendation: string
  collectionInvite: InviteType
  collectionInviteId: string
  questionnaireId: string
}

export type TokenResponse = {
  token: string
  valid: boolean
  message: string
}

export type ValidateCollectionEmailRequest = {
  email: string
}

export type CollectionRecordLaterResponse = {
  email: string
}

export type CollectionCreateEventRequest = {
  collectionRequest: CollectionFormType
  collectionInviteId?: string
}

export type CollectionThemeProps = {
  themeName?: string
  textColor?: string
  backgroundColor?: string
  storyTitleBGColor?: string
  storyCountBGColor?: string
  videoButtonBGColor?: string
  videoButtonHoverColor?: string
  videoButtonTextColor?: string
  videoButtonFontFamily?: string
  barBackgroundColor?: string
  barButtonsFontFamily?: string
  barButtonsTextColor?: string
  barButtonsHoverColor?: string
  collectionViewerFontFamily?: string
  collectionTitleFontSize?: string
  collectionTitleFontFamily?: string
  collectionTitleColor?: string
  bodyFontFamily?: string
  bodyTextColor?: string
  currentStoryTitleFontSize?: string
  currentStoryTitleFontFamily?: string
  currentStoryTitleColor?: string
  buttonOnBarFontSize?: string

  signupTextFontFamily?: string
  signupTextColor?: string
  signupBackgroundColor?: string
  signupButtonBGColor?: string
  signupButtonHoverColor?: string
  signupButtonTextColor?: string
  signupCheckBoxColor?: string
  signupLinksColor?: string
  signupInputTextColor?: string
  signupInputBGColor?: string
  signupInputOutlineColor?: string

  requestsTextFontFamily?: string
  requestsTextColor?: string
  requestsBackgroundColor?: string
  cardTextColor?: string
  cardBackgroundColor?: string
  cardHoverColor?: string

  notificationTextFontFamily?: string
  notificationTextColor?: string
  notificationBackgroundColor?: string
  notificationCancelButtonColor?: string
  notificationCancelButtonHoverColor?: string
  notificationConfirmButtonColor?: string
  notificationConfirmButtonHoverColor?: string
  notificationCloseIconColor?: string

  questionnaireTextFontFamily?: string
  questionnaireHeaderBackgroundColor?: string
  questionnaireBackgroundColor?: string
  questionnaireBodyTextColor?: string
  questionnaireLinksColor?: string
  questionnaireInputBackgroundColor?: string
  questionnaireInputTextColor?: string
  questionnaireInputOutlineColor?: string
  questionnaireInputLabelColor?: string
  questionnaireContainedButtonTextColor?: string
  questionnaireContainedButtonBackgroundColor?: string
  questionnaireContainedButtonHoverColor?: string
  questionnaireContainedDisabledButtonColor?: string
  questionnaireContainedDisabledButtonBackgroundColor?: string
  questionnaireRecordIconColor?: string
  questionnaireRecordIconBackgroundColor?: string
  questionnaireRecordButtonColor?: string
  questionnaireRecordButtonHoverColor?: string
  questionnaireOutlinedButtonColor?: string
  questionnaireOutlinedButtonHoverColor?: string
}

export type VerifyCollectionInviteType = {
  exist: boolean
}

export type CountStoriesPublishedType = {
  count: number
}

export type CollectionTheme = {
  id?: string
  properties?: CollectionThemeProps
  standard?: boolean
}

export type Tenant = {
  id: string
  name: string
}

export type EmailRecords = {
  email: string
  name: string
  jobTitle: string
  functionalArea: string
}

export type QuestionnaireConsent = {
  email: string
  name: string
  jobTitle: string
  functionalArea: string
  questionnaireId: string
}

export type QuestionnaireInvite = {
  id: string
  questionnaireId: string
  email: string
  name: string
  jobTitle: string
  functionalArea: string
  finishedAt: string
  finished: boolean
  numberOfAnswers: number
  createdAt: string
  updatedAt: string
}

export type QuestionnaireSubmission = {
  name: string
  email: string
  jobTitle: string
  functionalArea: string
  invite: InviteType
}

export type QuestionnaireStats = {
  questionnaire: QuestionnaireType
  responses: QuestionnaireSubmission[]
  invites: QuestionnaireInvite[]
  completed: number
  percentage: number
  skipped: number
  inProcess: number
  notStarted: number
}

export type Metadata = {
  recorderFunctionalArea?: string
  recorderName?: string
  storyDescription?: string
  storyId?: string
  jobRole?: string
  storyTitle?: string
  videoUrl?: string
  thumbnailUrl?: string
  subtitleUrl?: string
  subtitleLanguage?: string
}

export enum QuestionVideoTypeEnum {
  INTRO,
  OUTRO,
  MEDIA
}

export const titleByType: Record<QuestionVideoTypeEnum, string> = {
  [QuestionVideoTypeEnum.INTRO]: 'Add or change an Intro Video',
  [QuestionVideoTypeEnum.MEDIA]: 'Add or change invite media instructions',
  [QuestionVideoTypeEnum.OUTRO]: 'Add or change an Outro Video'
}
