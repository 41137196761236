import Box from '@mui/material/Box'
import { TeamMemberRoleEnum, TeamMemberType } from '../../lib/types'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import FormControl from '@mui/material/FormControl'
import React, { useContext, useState } from 'react'
import FormLabel from '@mui/material/FormLabel'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import api from '../../lib/api'
import { SimpleSnackbarContext } from '../SimpleSnackbar'

const SwitchRole: React.FC<{
  id: string
  email: string
  currentRole: TeamMemberRoleEnum
  setMembers: (value: ((prevState: TeamMemberType[]) => TeamMemberType[]) | TeamMemberType[]) => void
}> = ({ id, email, currentRole, setMembers }) => {
  const [selectedRole, setSelectedRole] = useState(currentRole)

  const { actions: snackActions } = useContext(SimpleSnackbarContext)
  const updateTeamMember = async (role: TeamMemberRoleEnum) => {
    try {
      await api.updateTeamMember({ id: id, email: email, role: role })
      setSelectedRole(role)
      setMembers(prev => {
        return prev.map(item => (item.id === id ? { ...item, role: role } : item))
      })
      snackActions.open('Changed member role successfully!')
    } catch (e) {
      snackActions.open('Something went wrong! Please try again.')
      console.log(e)
    }
  }

  const handleRoleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const role = event.target.value as TeamMemberRoleEnum
    await updateTeamMember(role)
  }

  return (
    <Box>
      <FormControl sx={{ display: { xs: 'none', md: 'flex' } }}>
        <RadioGroup
          value={selectedRole}
          defaultValue={currentRole}
          onChange={handleRoleChange}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '8px',
            alignItems: 'center',
            flexWrap: 'nowrap'
          }}
        >
          <FormLabel sx={{ color: '#fff', marginX: '8px' }}>Role:</FormLabel>
          <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-end' }}>
            {Object.keys(TeamMemberRoleEnum).map((key, index) => {
              return (
                <FormControlLabel
                  key={index}
                  value={key}
                  control={<Radio />}
                  label={key.charAt(0) + key.slice(1).toLowerCase()}
                />
              )
            })}
          </Box>
        </RadioGroup>
      </FormControl>
      <Box sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center', gap: '8px' }}>
        <FormLabel sx={{ color: '#fff' }}>Role:</FormLabel>
        <TextField select type="text" fullWidth hiddenLabel value={selectedRole} onChange={handleRoleChange}>
          {Object.keys(TeamMemberRoleEnum).map((key, index) => (
            <MenuItem key={index} value={key}>
              {key.charAt(0) + key.slice(1).toLowerCase()}
            </MenuItem>
          ))}
        </TextField>
      </Box>
    </Box>
  )
}

export default SwitchRole
