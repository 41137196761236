import React, { createContext, useState } from 'react'
import Component from './Component'
import { CollectionThemeProps } from '../../lib/types'

export const PublicRequestsDialogContext = createContext<{
  actions: {
    open: () => void
    close: () => void
    setCollectionId: (id: string) => void
    openWithStyles: (style: CollectionThemeProps) => void
  }
  store: {
    collectionId: string
  }
}>({
  // @ts-ignore
  actions: {},
  collectionId: ''
})

export const PublicRequestsDialogProvider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [collectionId, setCollectionId] = useState<string>('')
  const [collectionTheme, setCollectionTheme] = useState<CollectionThemeProps>({})

  const open = () => {
    setIsOpen(true)
  }

  const openWithStyles = (style: CollectionThemeProps) => {
    setCollectionTheme(style)
    setIsOpen(true)
  }

  const close = () => {
    setIsOpen(false)
  }

  return (
    <PublicRequestsDialogContext.Provider
      value={{
        actions: {
          open,
          close,
          setCollectionId,
          openWithStyles
        },
        store: {
          collectionId
        }
      }}
    >
      {isOpen && <Component collectionTheme={collectionTheme} />}
      {children}
    </PublicRequestsDialogContext.Provider>
  )
}
