import React, { useState, createContext } from 'react'

export type OrderType = 'asc' | 'desc'
export type OrderField = {
  title: string
  value: string
}

const options: OrderField[] = [
  { title: 'Created', value: 'created' },
  { title: 'Name', value: 'title' }
]

// @ts-ignore
export const SortControlContext = createContext<{
  updateSort: (field: number, order: OrderType) => void
  options: OrderField[]
  selectedIndex: number
  sort: OrderType
  value: {
    sort: OrderType
    field: OrderField
  }
}>({
  // @ts-ignore
  refetch: {}
})

export const SortControlProvider: React.FC = ({ children }) => {
  const [sort, setSort] = useState<OrderType>(() => {
    const sort = localStorage.getItem('sort') as OrderType
    return sort || 'asc'
  })
  const [selectedIndex, setSelectedIndex] = useState<number>(() => {
    const sortField = localStorage.getItem('sort-field')
    return parseInt(sortField || '0')
  })

  const updateSort = (fieldIndex: number, order: OrderType) => {
    localStorage.setItem('sort-field', String(fieldIndex))
    localStorage.setItem('sort', order)
    setSelectedIndex(fieldIndex)
    setSort(order)
  }

  return (
    <SortControlContext.Provider
      value={{
        updateSort,
        options,
        sort,
        selectedIndex,
        value: {
          sort,
          field: options[selectedIndex]
        }
      }}
    >
      {children}
    </SortControlContext.Provider>
  )
}
