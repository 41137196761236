import React, { useContext, useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import api from '../../../lib/api'
import { SimpleSnackbarContext } from '../../../components/SimpleSnackbar'
import { UserContext } from '../../../components/UserProvider'
import { UserFormType } from '../../../lib/types'
import { useFormik } from 'formik'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import FormLabel from '@mui/material/FormLabel'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/lab/LoadingButton'

const options = [
  'Personal Use / No Industry',
  'Consulting / Coaching / Speaking',
  'Sports and Entertainment',
  'Funeral Homes / Mortuaries',
  'Assisted Living',
  'Real Estate',
  'Trade Association',
  'Charitable Organization',
  'Church / Religious Organization',
  'Private Foundation',
  'Political Organization',
  'Other Nonprofit',
  'Education',
  'Software',
  'Healthcare',
  'Banking and Finance',
  'Insurance',
  'Retail',
  'Restaurant / Food Services',
  'Manufacturing',
  'Energy',
  'Agriculture',
  'Genealogy / Family History',
  'Other'
]

const personalOptions = [
  'My life stories',
  'Life stories of loved ones and friends',
  'Life events (weddings, graduations, memorials, etc.',
  'Personal journal',
  'Pre-recorded stories (recorded outside of Rakonto)',
  'Community stories / oral histories'
]

const professionalOptions = [
  'Video testimonials',
  'Client, employee, partner and stakeholder feedback',
  'Debriefs, retrospectives, lessons learned',
  'Education, training, coaching and mentoring',
  'Organization history and stories',
  'Recruitment and retention'
]

const Preferences: React.FC = () => {
  const { actions: snackActions } = useContext(SimpleSnackbarContext)
  const { user, setUser } = useContext(UserContext)

  const [personalPreferences, setPersonalPreferences] = useState<{ [key: string]: boolean }>({})
  const [professionalPreferences, setProfessionalPreferences] = useState<{ [key: string]: boolean }>({})
  const [personalOther, setPersonalOther] = useState<string>('')
  const [professionalOther, setProfessionalOther] = useState<string>('')

  useEffect(() => {
    const initialPersonalSelectedOptions = user?.personalPreferences?.reduce((acc, option) => {
      if (personalOptions.some(value => option === value)) {
        acc = { ...acc, [option]: true }
      } else {
        setPersonalOther(option)
      }
      return acc
    }, {})
    setPersonalPreferences(initialPersonalSelectedOptions)

    const initialProfessionalSelectedOptions = user?.professionalPreferences?.reduce((acc, option) => {
      if (professionalOptions.some(value => option === value)) {
        acc = { ...acc, [option]: true }
      } else {
        setProfessionalOther(option)
      }
      return acc
    }, {})
    setProfessionalPreferences(initialProfessionalSelectedOptions)
  }, [user])

  const handleChangePersonal = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPersonalPreferences({ ...personalPreferences, [event.target.name]: event.target.checked })
  }

  const handleChangeOrganization = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProfessionalPreferences({ ...professionalPreferences, [event.target.name]: event.target.checked })
  }

  const updateProfile = async (data: UserFormType) => {
    try {
      const newUser = await api.updateMe({ ...user, ...data })
      snackActions.open('User info updated!')
      setUser(newUser)
    } catch (error) {
      // @ts-ignore
      const { data } = error.response
      snackActions.open('Something was wrong! please try again.')
    }
  }

  const initialValues: UserFormType = {
    industry: user?.industry || ''
  }

  const infoFormik = useFormik({
    initialValues,
    onSubmit: async (data: UserFormType) => {
      updateProfile(data)
    },
    enableReinitialize: true
  })

  useEffect(() => {
    const localPersonalPreferences = Object.entries(personalPreferences || {}).reduce<string[]>((acc, [key, value]) => {
      if (value) {
        acc.push(key)
      }
      return acc
    }, [])

    if (personalOther) {
      localPersonalPreferences.push(personalOther)
    }

    const localProfessionalPreferences = Object.entries(professionalPreferences || {}).reduce<string[]>(
      (acc, [key, value]) => {
        if (value) {
          acc.push(key)
        }
        return acc
      },
      []
    )

    if (professionalOther) {
      localProfessionalPreferences.push(professionalOther)
    }

    infoFormik.setFieldValue('personalPreferences', localPersonalPreferences)
    infoFormik.setFieldValue('professionalPreferences', localProfessionalPreferences)
  }, [personalPreferences, professionalPreferences, personalOther, professionalOther])

  return (
    <Box component="form" sx={{ width: '100%', height: 'inherit', bgcolor: 'background.paper', padding: 2 }}>
      <Grid container spacing={3} mb={16}>
        <Grid item xs={12}>
          <Typography variant="h6" mb={2}>
            What kinds of stories are you interested in requesting, recording and sharing? Please choose as many as you
            like. Of course, you can create or request other kinds of stories too, this will just help you get going.
            And you can always define and change these at any time.
          </Typography>
        </Grid>
        <Grid item xs={12} md>
          <Typography variant="h6">Personal and family stories</Typography>
          <FormGroup>
            {personalOptions.map(option => (
              <FormControlLabel
                key={option}
                control={
                  <Checkbox
                    checked={(personalPreferences && personalPreferences[option]) || false}
                    onChange={handleChangePersonal}
                    name={option}
                  />
                }
                label={option}
              />
            ))}
          </FormGroup>
          <Box mr={2} mt={2}>
            <FormLabel id="demo-radio-buttons-group-label">Other:</FormLabel>
            <TextField
              placeholder="Identify other personal story types"
              name="otherPersonal"
              type="text"
              fullWidth
              hiddenLabel
              value={personalOther}
              onChange={e => setPersonalOther(e.target.value)}
            />
          </Box>
        </Grid>
        <Divider flexItem orientation="vertical" />
        <Grid item xs={12} md>
          <Typography variant="h6">Professional and organization stories</Typography>
          <FormGroup>
            {professionalOptions.map(option => (
              <FormControlLabel
                key={option}
                control={
                  <Checkbox
                    checked={(professionalPreferences && professionalPreferences[option]) || false}
                    onChange={handleChangeOrganization}
                    name={option}
                  />
                }
                label={option}
              />
            ))}
          </FormGroup>
          <Box mr={2} mt={2}>
            <FormLabel id="demo-radio-buttons-group-label">Other:</FormLabel>
            <TextField
              name="otherProfessional"
              placeholder="Identify other professional story types"
              type="text"
              fullWidth
              hiddenLabel
              value={professionalOther}
              onChange={e => setProfessionalOther(e.target.value)}
            />
          </Box>
          <Box mr={2} mt={2}>
            <FormLabel id="demo-radio-buttons-group-label">
              What industry or line of business are you engaged in?
            </FormLabel>
            <TextField
              select
              name="industry"
              placeholder="Identify other professional story types"
              type="text"
              fullWidth
              hiddenLabel
              value={infoFormik.values.industry}
              onChange={infoFormik.handleChange}
              onBlur={infoFormik.handleBlur}
              error={infoFormik.touched.industry && Boolean(infoFormik.errors.industry)}
              helperText={(infoFormik.touched.industry && infoFormik.errors.industry) || ' '}
            >
              {options.map(item => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ textAlign: 'end' }}>
        <Button
          color={'primary'}
          variant="contained"
          onClick={() => infoFormik.handleSubmit()}
          loading={infoFormik.isSubmitting}
        >
          Save
        </Button>
      </Box>
    </Box>
  )
}

export default Preferences
