import React, { useContext, useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'
import { useTheme } from '@mui/material/styles'
import api from '../../lib/api'
import axios from 'axios'
import { SimpleSnackbarContext } from '../SimpleSnackbar'
import { UserContext } from '../UserProvider'

const ChangeReferralCodeDialog: React.FC<{ handleClose: () => void }> = ({ handleClose }) => {
  const theme = useTheme()
  const { refetch } = useContext(UserContext)
  const { actions: snackActions } = useContext(SimpleSnackbarContext)
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [code, setCode] = useState<string>('')
  const [error, setError] = useState<string>('')

  const validate = async () => {
    try {
      await api.changeReferralCode(code)
      await refetch()
      handleClose()
      snackActions.open('Referral code updated!')
      window.location.reload()
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // @ts-ignore
        const { code, message } = error.response.data
        if (code === '1027') {
          setError('The user logged or informed on request is invalid!')
          return
        }
        if (code === '1042') {
          setError('Referral code informed is already in use, inform another one.')
          return
        }
        if (code === '1035') {
          setError('Referral informed is Invalid.')
          return
        }

        if (code) {
          setError(
            'Internal server error, Try again latter and if error persist contact support on support@rakonto.io.'
          )
          return
        }
      }
      handleClose()
      window.location.reload()
    }
  }

  return (
    <Dialog fullScreen={fullScreen} open={true} fullWidth maxWidth="sm">
      <DialogTitle>Update your referral code</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ paddingBottom: 3 }}>
          Input your customized referral code and use. No worries with older ones, all referrals that used your current
          will be updated.
        </DialogContentText>
        <TextField
          inputProps={{ maxLength: 15 }}
          name="code"
          placeholder="Type your custom code"
          fullWidth
          margin="dense"
          variant="outlined"
          value={code}
          onChange={e => setCode(e.target.value)}
          error={!!error}
          helperText={error || ' '}
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="outlined" autoFocus onClick={() => validate()} disabled={!code}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ChangeReferralCodeDialog
