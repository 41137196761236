import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App, { history } from './App'
import { hotjar } from 'react-hotjar'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import Error500 from './pages/Error500'
import GitInfo from 'react-git-info/macro'

let Appp = App

// Sentry
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'production') {
  const gitInfo = GitInfo()
  const release = gitInfo.tags[0] || gitInfo.commit.shortHash

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
      })
    ],
    environment: process.env.NODE_ENV,
    release: release,
    tracesSampleRate: 1.0
  })

  Appp = Sentry.withErrorBoundary(App, {
    fallback: Error500,
    showDialog: true
  })
}

// hotjar
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'production') {
  hotjar.initialize(2806820, 6)
  history.listen(location => {
    try {
      hotjar.stateChange(location.pathname + location.search)
    } catch (e) {
      // nope
    }
  })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

ReactDOM.render(
  <React.StrictMode>
    <Appp />
  </React.StrictMode>,
  document.getElementById('root')
)
