import React, { useState, createContext } from 'react'
import Component from './Component'
import { CollectionType, StoryType } from '../../lib/types'

// @ts-ignore
export const StepInviteRecorderContext = createContext<{
  actions: {
    open: (collection: CollectionType | null, story?: StoryType) => void
    close: () => void
  }
}>({
  // @ts-ignore
  actions: {}
})

export const StepInviteRecorderProvider: React.FC = ({ children }) => {
  const [stepStoryUpload, setStepInviteRecorder] = useState<boolean>(false)
  const [collection, setCollection] = useState<CollectionType | null>(null)
  const [story, setStory] = useState<StoryType | null>(null)

  const open = (collection: CollectionType | null, story?: StoryType) => {
    setStepInviteRecorder(true)
    setCollection(collection)
    setStory(story || null)
  }

  const close = () => {
    setStepInviteRecorder(false)
  }

  return (
    <StepInviteRecorderContext.Provider
      value={{
        actions: {
          open,
          close
        }
      }}
    >
      {stepStoryUpload && <Component initialCollection={collection} initialStory={story} />}
      {children}
    </StepInviteRecorderContext.Provider>
  )
}
