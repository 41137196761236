import React, { useContext, useState } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import CreateIcon from '@mui/icons-material/Create'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { useFormik } from 'formik'
import schema from '../../pages/a/Story/About/schema'
import api from '../../lib/api'
import { SimpleSnackbarContext } from '../SimpleSnackbar'

interface iStoryMetadata {
  storyId?: string
  recorderName?: string
  recorderEmail?: string
  recorderJobTitle?: string
  recorderFunctionalArea?: string
}

const StoryMetadata: React.FC<iStoryMetadata> = ({
  storyId,
  recorderName,
  recorderEmail,
  recorderJobTitle,
  recorderFunctionalArea
}) => {
  const hasValues = recorderName && recorderName != null
  const [isEditing, setIsEditing] = useState(false)
  const [recorderNm, setRecorderNm] = useState(recorderName)
  const [recorderJbTtl, setRecorderJbTtl] = useState(recorderJobTitle)
  const [recorderFArea, setRecorderFArea] = useState(recorderFunctionalArea)
  const { actions: snackActions } = useContext(SimpleSnackbarContext)
  const initialValues = {
    recorderName: recorderName ?? '',
    recorderJobTitle: recorderJobTitle ?? '',
    recorderFunctionalArea: recorderFunctionalArea ?? ''
  }

  const onSubmit = async (values: {
    recorderName: string
    recorderJobTitle: string
    recorderFunctionalArea: string
  }) => {
    if (!storyId) return
    try {
      await api.updateStoryRecorder(storyId, { ...values })
      setIsEditing(false)
    } catch (error) {}
  }

  const { isSubmitting, values, handleBlur, handleChange, touched, errors, handleSubmit } = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit
  })

  const submit = async () => {
    if (!storyId) return
    try {
      await api.updateStoryRecorder(storyId, { ...values })
      setRecorderFArea(values.recorderFunctionalArea)
      setRecorderNm(values.recorderName)
      setRecorderJbTtl(values.recorderJobTitle)
      setIsEditing(false)
      snackActions.open('Story recorder updated successfully!')
    } catch (error) {
      snackActions.open('Something went wrong updating the recorder, please try again')
    }
  }

  return hasValues ? (
    <>
      <Box
        component={Paper}
        sx={{
          width: '100%',
          padding: 2,
          height: '100%',
          marginBottom: '1em'
        }}
      >
        <Box display={'flex'}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography color={'lightgray'}>Recorder Name(Author):</Typography>
              <Typography paddingLeft={'0.5em'}>{recorderNm}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography color={'lightgray'}>Recorder Email:</Typography>
              <Typography paddingLeft={'0.5em'}>{recorderEmail}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography color={'lightgray'}>Job Title:</Typography>
              <Typography paddingLeft={'0.5em'}>{recorderJbTtl}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography color={'lightgray'}>Functional Area:</Typography>
              <Typography paddingLeft={'0.5em'}>{recorderFArea}</Typography>
            </Grid>
          </Grid>
          <IconButton size="large" aria-haspopup="true" onClick={() => setIsEditing(true)} color="inherit">
            <CreateIcon />
          </IconButton>
        </Box>
        {isEditing && (
          <form>
            <Grid container spacing={4} direction="row" justifyContent="center" alignItems="center" mt={2}>
              <Grid item xs={12}>
                <TextField
                  name="recorderName"
                  fullWidth
                  label="Recorder Name"
                  value={values.recorderName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.recorderName && Boolean(errors.recorderName)}
                  helperText={(touched.recorderName && errors.recorderName) || ' '}
                />
                <TextField
                  name="recorderJobTitle"
                  fullWidth
                  label="Recorder Job Title"
                  value={values.recorderJobTitle}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.recorderJobTitle && Boolean(errors.recorderJobTitle)}
                  helperText={(touched.recorderJobTitle && errors.recorderJobTitle) || ' '}
                />
                <TextField
                  name="recorderFunctionalArea"
                  fullWidth
                  label="Recorder Functional Area"
                  value={values.recorderFunctionalArea}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.recorderFunctionalArea && Boolean(errors.recorderFunctionalArea)}
                  helperText={(touched.recorderFunctionalArea && errors.recorderFunctionalArea) || ' '}
                />
              </Grid>
              <Grid
                sx={{
                  textAlign: 'end'
                }}
                item
                xs={12}
              >
                <Button onClick={() => setIsEditing(false)}>Cancel</Button>
                <Button color={'primary'} disabled={isSubmitting} variant="contained" onClick={() => submit()}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Box>
    </>
  ) : (
    <></>
  )
}
export default StoryMetadata
