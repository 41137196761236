import React, { ChangeEvent, useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { StoryType } from '../../../../lib/types'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import PublishIcon from '@mui/icons-material/Publish'
import UnpublishedIcon from '@mui/icons-material/Unpublished'
import SelectAllIcon from '@mui/icons-material/SelectAll'
import Checkbox from '@mui/material/Checkbox'
import { isMobile } from 'react-device-detect'
import { format, parseJSON } from 'date-fns'
import Paper from '@mui/material/Paper'
import DeleteIcon from '@mui/icons-material/Delete'
import CollectionMove from './CollectionMove'
import api from '../../../../lib/api'
import { SimpleSnackbarContext } from '../../../../components/SimpleSnackbar'
import { SimpleDialogContext } from '../../../../components/SimpleDialog'
import MoreIcon from '@mui/icons-material/MoreVert'
import MenuItem from '@mui/material/MenuItem'
import SortControl from './SortControl'
import { SortControlContext } from '../../../../components/SortControlProvider'
import TextField from '@mui/material/TextField'
import { CopyAll, Photo, PictureInPicture, Restore, Upload } from '@mui/icons-material'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import Grid from '@mui/material/Grid'
import { cloneStoriesToCollection, recreateThumbs, storyReprocessTranscript } from '../../../../lib/api/services'
import Thumbnails from '../../../../lib/videojs/components/thumbnails'
import EditIcon from '@mui/icons-material/Edit'
import FormControl from '@mui/material/FormControl'
import { InputLabel, Select, SelectChangeEvent } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'

interface iStory {
  story: StoryType
  collectionId: string
  playing: boolean
  canSeletecForEdit: boolean
  selectForEdit: boolean
  handleSelectForEdit: (id: string, isToAdd: boolean) => void
  isSelected: boolean
  mergeStatus: string
  graphicsProcessed: boolean
  sentToDam: boolean
}

const StoryCard: React.FC<iStory> = ({
  story,
  collectionId,
  playing,
  isSelected,
  canSeletecForEdit,
  handleSelectForEdit,
  selectForEdit,
  mergeStatus,
  graphicsProcessed,
  sentToDam
}) => {
  const history = useHistory()
  const [hover, setHover] = useState<boolean>(isMobile)

  return (
    <Box sx={{ display: 'flex', width: { sm: 'unset', xs: '100%' }, maxHeight: 200, marginBottom: 1 }} key={story.id}>
      <Box sx={{ position: 'relative', width: { sm: 'unset', xs: '100%' } }}>
        <Box
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          sx={{ position: 'relative', width: { sm: 327, xs: '100%' }, flex: { sm: 'unset', xs: 1 }, height: 200 }}
        >
          <Box
            component="img"
            sx={{
              position: 'absolute',
              backgroundColor: 'action.selected',
              objectFit: 'cover',
              height: '100%',
              width: '100%'
            }}
            src={story.thumbnailUrl || '/images/CoverDefault.png'}
            alt={story.title}
          />
          {isSelected && (
            <>
              <Box
                sx={{
                  position: 'absolute',
                  backgroundColor: 'action.selected',
                  height: '100%',
                  width: '100%',
                  top: 0
                }}
              />
              <Box
                sx={{
                  cursor: 'default',
                  color: 'common.black',
                  position: 'absolute',
                  backgroundColor: 'primary.main',
                  minWidth: 48,
                  paddingRight: 2,
                  paddingTop: '5px',
                  paddingBottom: '5px',
                  borderRadius: '0px 40px 40px 0px',
                  top: '10%',
                  left: 0,
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <PlayArrowIcon color="inherit" /> Playing
              </Box>
            </>
          )}
          {!isSelected && hover && (
            <Button
              variant="contained"
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                padding: '20px 16px'
              }}
              onClick={() => {
                history.push(`/a/collections/${collectionId}?storyId=${story.id}&autoplay=true`)
              }}
            >
              <PlayArrowIcon />
            </Button>
          )}
        </Box>
        {canSeletecForEdit && (
          <Box
            sx={{
              display: { xs: 'block', sm: 'none' },
              position: 'absolute',
              top: '8px',
              right: '8px'
            }}
          >
            <Checkbox
              checked={selectForEdit}
              onChange={e => handleSelectForEdit(story.id, e.target.checked)}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
            />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          flex: 1,
          display: { xs: 'none', sm: 'flex' },
          flexFlow: 'row',
          backgroundColor: playing ? 'action.selected' : 'default'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'column',
            width: '100%',
            padding: 3
          }}
        >
          <Box mb="0.35em">
            <Typography sx={{ fontWeight: '700', mb: 'unset' }} variant="h5" gutterBottom>
              {`${story.title}`}
            </Typography>
            <Typography component="div" variant="caption" sx={{ fontWeight: '700' }}>
              Recorder: {`${story.recorderName}, ${story.recorderEmail} `}
            </Typography>
            {story.createdAt && (
              <Typography component="div" variant="caption">{`On ${format(
                parseJSON(story.createdAt),
                'PPPp'
              )}`}</Typography>
            )}
            <Typography component="div" variant="caption" sx={{ color: story.published ? '#6EC069' : 'white' }}>
              Status: {`${story.published ? 'Published' : 'Draft'}`}
            </Typography>
            {story.mergeStatus && (
              <Typography component="div" variant="caption" sx={{ color: '#6EC069' }}>
                {`${story.mergeStatus}`}
              </Typography>
            )}
            {story.sentToDam && (
              <Typography component="div" variant="caption" sx={{ color: 'cyan' }}>
                Already sent to DAM
              </Typography>
            )}
          </Box>
          <Typography
            sx={{
              fontWeight: '400'
            }}
            className="line-clamp"
            variant="h6"
          >
            {story.description}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginRight: 3
          }}
        >
          {canSeletecForEdit ? (
            <Checkbox
              checked={selectForEdit}
              onChange={e => handleSelectForEdit(story.id, e.target.checked)}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
            />
          ) : (
            <IconButton component={Link} to={`/a/stories/${story.id}`}>
              <ArrowForwardIcon />
            </IconButton>
          )}
        </Box>
      </Box>
    </Box>
  )
}

interface iStories {
  collectionId: string
  stories: StoryType[]
  playing: boolean
  canEdit: boolean
  selectedStory: string | undefined
  reload: () => void
}

const Stories: React.FC<iStories> = ({ selectedStory, collectionId, stories, playing, canEdit, reload }) => {
  const { actions: snackActions } = useContext(SimpleSnackbarContext)
  const { actions: dialogActions } = useContext(SimpleDialogContext)
  const { value: sortValue } = useContext(SortControlContext)
  const [editMode, setEditMode] = useState<boolean>(false)
  const [selectedStories, setSelectedStories] = useState<string[]>([])
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null)
  let [deleteText, setDeleteText] = useState<string>()
  let [deleteTextIsRight, setDeleteTextIsRight] = useState<boolean>()
  const [storiesPublished, setStoriesPublished] = useState(0)
  const [storiesWithGraphics, setStoriesWithGraphics] = useState(0)
  const [storiesSentToDam, setStoriesSentToDam] = useState(0)
  const [recorders, setRecorders] = useState<string[]>([])
  const [recorderFilter, setRecorderFilter] = useState<string>()
  const [collectionToClone, setCollectionToClone] = useState<string>('')

  const deleteTextExpected = 'delete the selected ' + selectedStories.length + ''

  const handleDeleteTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setDeleteText(value)
    deleteText = value
    setDeleteTextIsRight(deleteText === deleteTextExpected || value === deleteTextExpected)
    deleteTextIsRight = deleteText === deleteTextExpected || value === deleteTextExpected
  }

  const handleCollectionIdToClone = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setCollectionToClone(value)
  }

  if (!selectedStory) return null

  const handleDelete = () => {
    dialogActions.open(
      'Delete forever',
      <>
        <Typography fontWeight="700" fontSize={'2rem'}>
          Are you sure you want to delete permanently all {selectedStories.length} stories?
        </Typography>
        <Typography color={'#b45b41'} fontWeight={200} margin={'1em'} fontSize={'1.4rem'}>
          This action cannot be undone!
        </Typography>
        <br></br>
        <TextField
          color="warning"
          size="medium"
          name="deleteConfirmation"
          fullWidth
          required
          onInput={(e: React.ChangeEvent<HTMLInputElement>): void => handleDeleteTextChange(e)}
          placeholder={`${deleteTextExpected}`}
        />
        <br></br>
        <Typography
          fontWeight={100}
          margin={'1em'}
        >{`Type the expected phrase to confirme deletion: ${deleteTextExpected}`}</Typography>
      </>,
      {
        okText: 'Yes, delete',
        showOk: true,
        cancelText: 'No, keep as is'
      },
      async value => {
        if (value) {
          try {
            if (!deleteTextIsRight) {
              snackActions.open('Type the correct phrase on modal input to delete permanentely the Story.')
            } else {
              await Promise.all(selectedStories.map(async storyId => await api.deleteStory(storyId)))
              reload()
              snackActions.open('Selected stories was deleted!')
            }
          } catch (error) {
            snackActions.open('Something was wrong! please try again.')
          }
        }
      }
    )
  }

  const handlePublish = () => {
    dialogActions.open(
      'Publish stories',
      <>
        <Typography fontWeight="700">
          Are you sure you want to update the status of selected stories from Draft to Published?
        </Typography>
      </>,
      {
        okText: 'Yes, update status',
        showOk: true,
        cancelText: 'No, keep as is'
      },
      async value => {
        if (value) {
          try {
            await Promise.all(selectedStories.map(async storyId => await api.updateStoryStatus(storyId, true)))
            reload()
            snackActions.open('Selected stories successfully updated to Published status!')
          } catch (error) {
            snackActions.open('Something was wrong! please try again.')
          }
        }
      }
    )
  }

  const handleThumbnails = () => {
    dialogActions.open(
      'Thumbnails',
      <>
        <Typography fontWeight="700">Are you sure you want to re-create the thumbnails of selected stories?</Typography>
      </>,
      {
        okText: 'Yes, regenerate thumbnails',
        showOk: true,
        cancelText: 'No, keep as is'
      },
      async value => {
        if (value) {
          try {
            await Promise.all(selectedStories.map(async storyId => await api.recreateThumbs(storyId)))
            reload()
            snackActions.open('Selected stories successfully sent to thumbnail recreate processing!')
          } catch (error) {
            snackActions.open('Something was wrong! please try again.')
          }
        }
      }
    )
  }

  const handleTranscript = () => {
    dialogActions.open(
      'Transcripts',
      <>
        <Typography fontWeight="700">
          Are you sure you want to reprocess the transcripts of selected stories?
        </Typography>
      </>,
      {
        okText: 'Yes, reprocess transcripts',
        showOk: true,
        cancelText: 'No, keep as is'
      },
      async value => {
        if (value) {
          try {
            await Promise.all(selectedStories.map(async storyId => await api.storyReprocessTranscript(storyId)))
            reload()
            snackActions.open('Selected stories successfully sent to transcript reprocessing!')
          } catch (error) {
            snackActions.open('Something was wrong! please try again.')
          }
        }
      }
    )
  }

  const handleSendToDam = () => {
    dialogActions.open(
      'Export to DAM',
      <>
        <Typography fontWeight="700">Are you sure you want to export the selected stories to DAM?</Typography>
        <Typography fontWeight="700" fontSize={'small'}>
          Only stories with status published will be send.
        </Typography>
      </>,
      {
        okText: 'Yes, publish and send to DAM',
        showOk: true,
        cancelText: 'No, keep as is'
      },
      async value => {
        if (value) {
          try {
            await Promise.all(selectedStories.map(async storyId => await api.sendStoryToDam(storyId)))
            reload()
            snackActions.open('Selected stories successfully published and queued to send to DAM!')
          } catch (error) {
            snackActions.open('Something was wrong! please try again.')
          }
        }
      }
    )
  }

  const handleApplyGraphics = () => {
    dialogActions.open(
      'Apply Graphics',
      <>
        <Typography fontWeight="700">Are you certain you wish to apply graphics to all selected stories?</Typography>
        {/* <Typography fontWeight="500" fontSize={'small'}> */}
        {/*  These functions apply to all stories within the Collection, not just the selected ones. Only stories without */}
        {/*  graphics will undergo the process. Please note that this process will be queued and may take some time to be */}
        {/*  visually ready. */}
        {/* </Typography> */}
      </>,
      {
        okText: 'Yes, apply graphics',
        showOk: true,
        cancelText: 'No, keep as is'
      },
      async value => {
        if (value) {
          try {
            await Promise.all(selectedStories.map(async storyId => await api.applyGraphics(storyId)))
            reload()
            snackActions.open('Selected stories successfully queued to applying graphics!')
          } catch (error) {
            snackActions.open('Something was wrong! please try again.')
          }
        }
      }
      // async value => {
      //   if (value) {
      //     try {
      //       await api.applyGraphicsCollection(collectionId)
      //       reload()
      //       snackActions.open('All stories is successfully send to graphics applying!')
      //     } catch (error) {
      //       snackActions.open('Something was wrong! please try again.')
      //     }
      //   }
      // }
    )
  }

  const handleRestoreMedia = () => {
    dialogActions.open(
      'Restore media',
      <>
        <Typography fontWeight="700">Are you sure you want to restore selected media?.</Typography>
        {/* <Typography fontWeight="500" fontSize={'small'}> */}
        {/*  These functions apply to all stories within the Collection, not just the selected ones. They will revert all */}
        {/*  media to their original state before applying graphics. Furthermore, transcripts and captions will be queued */}
        {/*  for reprocessing. */}
        {/* </Typography> */}
      </>,
      {
        okText: 'Yes, restore media(s)',
        showOk: true,
        cancelText: 'No, keep as is'
      },
      async value => {
        if (value) {
          try {
            await Promise.all(selectedStories.map(async storyId => await api.restoreMedia(storyId)))
            reload()
            snackActions.open('Selected stories successfully queued to restore media!')
          } catch (error) {
            snackActions.open('Something was wrong! please try again.')
          }
        }
      }
      // async value => {
      //   if (value) {
      //     try {
      //       await api.restoreMediaCollection(collectionId)
      //       reload()
      //       snackActions.open('All stories is successfully send to restoring process!')
      //     } catch (error) {
      //       snackActions.open('Something was wrong! please try again.')
      //     }
      //   }
      // }
    )
  }

  const handleDraft = () => {
    dialogActions.open(
      'Set status to draft',
      <>
        <Typography fontWeight="700">
          Are you sure you want to update the status of selected stories from Published to Draft?
        </Typography>
      </>,
      {
        okText: 'Yes, update status',
        showOk: true,
        cancelText: 'No, keep as is'
      },
      async value => {
        if (value) {
          try {
            await Promise.all(selectedStories.map(async storyId => await api.updateStoryStatus(storyId, false)))
            reload()
            snackActions.open('Selected stories successfully updated to Draft status')
          } catch (error) {
            snackActions.open('Something was wrong! please try again.')
          }
        }
      }
    )
  }

  const handleCancel = () => {
    setEditMode(false)
    setSelectedStories([])
  }

  const handleSelectAll = () => {
    !selectedStories.length ? setSelectedStories(() => stories.map(item => item.id)) : setSelectedStories([])
  }

  const handleCloneStories = () => {
    dialogActions.open(
      'Clone Stories to new Collection',
      <>
        <Typography fontWeight="700">Enter the collection ID to clone the selected stories</Typography>
        <br />
        <TextField
          color="warning"
          size="medium"
          name="collectionIdToClone"
          fullWidth
          required
          onInput={(e: React.ChangeEvent<HTMLInputElement>): void => handleCollectionIdToClone(e)}
          placeholder={`Collection ID`}
        />
        <br />
        <Typography variant={'body2'}>Collection: {collectionToClone}</Typography>
      </>,
      {
        okText: 'Clone stories',
        showOk: true,
        cancelText: 'Cancel'
      },
      async value => {
        if (value) {
          try {
            if (!collectionToClone) {
              alert('Invalid or blank Collection ID')
              snackActions.open('No collection informed. - Canceling the clone action.')
              return
            }
            await Promise.all(
              selectedStories.map(async storyId => await api.cloneStoriesToCollection(storyId, collectionToClone))
            )
            snackActions.open('Selected stories successfully cloned to informed collection!')
          } catch (error) {
            snackActions.open('Something was wrong! please try again.')
          }
        }
      }
    )
  }

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  useEffect(() => {
    let publishedCount = 0
    let graphicsCount = 0
    let sentToDam = 0

    const recorders: string[] = []

    stories.forEach(story => {
      if (story.published) publishedCount += 1
      if (story.graphicsProcessed) graphicsCount += 1
      if (story.sentToDam) sentToDam += 1

      const recorderName = story.recorderName
      if (!recorders.includes(recorderName)) {
        recorders.push(recorderName)
      }
    })
    recorders.sort()

    setStoriesPublished(publishedCount)
    setStoriesWithGraphics(graphicsCount)
    setStoriesSentToDam(sentToDam)
    setRecorders(recorders)
  }, [stories])

  const handleSelectionChange = (event: SelectChangeEvent<string>) => {
    setRecorderFilter(event.target.value as string)
  }

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <CollectionMove
        isMenu
        disabled={!selectedStories.length}
        reload={reload}
        stories={selectedStories}
        currentCollectionId={collectionId}
      />
      <MenuItem
        onClick={() => {
          handlePublish()
          handleMobileMenuClose()
        }}
      >
        Publish
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleDraft()
          handleMobileMenuClose()
        }}
      >
        Unpublish
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleDelete()
          handleMobileMenuClose()
        }}
      >
        Delete
      </MenuItem>
    </Menu>
  )

  return (
    <>
      <Box width="80%">
        <Grid container spacing={2} style={{ width: '100%', margin: '0 auto' }}>
          {' '}
          {/* Adiciona um espaçamento entre as colunas */}
          <Grid item xs={2.4}>
            <Paper
              style={{
                height: 100,
                display: 'flex',
                alignItems: 'center',
                verticalAlign: 'center',
                justifyContent: 'center'
              }}
            >
              <Typography variant="body2">Stories count: {stories.length}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={2.4}>
            <Paper
              style={{
                height: 100,
                display: 'flex',
                alignItems: 'center',
                verticalAlign: 'center',
                justifyContent: 'center'
              }}
            >
              <Typography variant="body2">Stories published: {storiesPublished}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={2.4}>
            <Paper
              style={{
                height: 100,
                display: 'flex',
                alignItems: 'center',
                verticalAlign: 'center',
                justifyContent: 'center'
              }}
            >
              <Typography variant="body2">Stories with Graphics: {storiesWithGraphics}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={2.4}>
            <Paper
              style={{
                height: 100,
                display: 'flex',
                alignItems: 'center',
                verticalAlign: 'center',
                justifyContent: 'center'
              }}
            >
              <Typography variant="body2">Stories sent to DAM: {storiesSentToDam}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={2.4}>
            <Paper
              style={{
                height: 100,
                display: 'flex',
                alignItems: 'center',
                verticalAlign: 'center',
                justifyContent: 'center'
              }}
            >
              <FormControl fullWidth>
                <InputLabel id="recorder-select-label">Filter by recorder</InputLabel>
                <Select
                  labelId="recorder-select-label"
                  value={recorderFilter}
                  label="Filter by recorder"
                  onChange={handleSelectionChange}
                >
                  <MenuItem value="">
                    <em>Select a recorder to filter</em>
                  </MenuItem>
                  {recorders.map((recorder, index) => (
                    <MenuItem key={index} value={recorder}>
                      {recorder}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          width: '100%',
          padding: '0 24px',
          marginBottom: 3,
          height: 65,
          display: 'flex',
          alignItems: 'center'
        }}
        component={editMode ? Paper : 'div'}
      >
        {editMode ? (
          <>
            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <Button onClick={handleApplyGraphics} color="secondary" startIcon={<PictureInPicture />}>
                  Apply graphics
                </Button>
              </Box>
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <Button onClick={handleRestoreMedia} color="secondary" startIcon={<Restore />}>
                  Restore media
                </Button>
              </Box>
              <Box>
                <Button onClick={handleSelectAll} color="secondary" startIcon={<SelectAllIcon />}>
                  {!selectedStories.length ? 'Select all' : 'Deselect all'}
                </Button>
              </Box>
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <Button
                  onClick={handleThumbnails}
                  disabled={!selectedStories.length}
                  color="secondary"
                  startIcon={<Photo />}
                >
                  Recreate Thumbnails
                </Button>
              </Box>
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <Button
                  onClick={handleTranscript}
                  disabled={!selectedStories.length}
                  color="secondary"
                  startIcon={<EditIcon />}
                >
                  Reprocess Transcripts
                </Button>
              </Box>
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <Button
                  onClick={handleCloneStories}
                  disabled={!selectedStories.length}
                  color="secondary"
                  startIcon={<CopyAll />}
                >
                  Clone stories
                </Button>
              </Box>
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <CollectionMove
                  disabled={!selectedStories.length}
                  reload={reload}
                  stories={selectedStories}
                  currentCollectionId={collectionId}
                />
              </Box>
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <Button
                  onClick={handlePublish}
                  disabled={!selectedStories.length}
                  color="secondary"
                  startIcon={<PublishIcon />}
                >
                  Publish
                </Button>
              </Box>
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <Button
                  onClick={handleDraft}
                  disabled={!selectedStories.length}
                  color="secondary"
                  startIcon={<UnpublishedIcon />}
                >
                  Unpublish
                </Button>
              </Box>
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <Button
                  onClick={handleSendToDam}
                  disabled={!selectedStories.length}
                  color="secondary"
                  startIcon={<UploadFileIcon />}
                >
                  Export to DAM
                </Button>
              </Box>
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <Button
                  onClick={handleDelete}
                  disabled={!selectedStories.length}
                  color="secondary"
                  startIcon={<DeleteIcon />}
                >
                  Delete
                </Button>
              </Box>
              <Box sx={{ display: { xs: 'none', md: 'flex' }, flex: 1 }} />
              <Box display="flex" alignItems="center">
                <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 2 }}>
                  <IconButton size="large" onClick={handleMobileMenuOpen} color="inherit">
                    <MoreIcon />
                  </IconButton>
                </Box>
                <div>
                  <Button onClick={handleCancel} color="secondary" variant="outlined">
                    Cancel
                  </Button>
                </div>
              </Box>
            </Stack>
            {renderMobileMenu}
          </>
        ) : (
          <>
            <Box>
              <SortControl />
            </Box>
          </>
        )}
        <div style={{ flex: 1 }} />
        {canEdit && !editMode && (
          <Button onClick={() => setEditMode(true)} color="secondary" variant="outlined">
            Select stories
          </Button>
        )}
      </Box>
      <Box component={Paper}>
        <Box sx={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
          {stories
            .filter(story => story.ready)
            .filter(story => (recorderFilter ? story.recorderName === recorderFilter : story.ready))
            .sort((a, b) => {
              if (sortValue.field.value === 'created') {
                if (sortValue.sort === 'asc') {
                  return a.createdAt > b.createdAt ? 1 : b.createdAt > a.createdAt ? -1 : 0
                }
                return b.createdAt > a.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0
              }
              if (sortValue.field.value === 'title') {
                if (sortValue.sort === 'asc') {
                  return a.title > b.title ? 1 : b.title > a.title ? -1 : 0
                }
                return b.title > a.title ? 1 : a.title > b.title ? -1 : 0
              }
              if (sortValue.sort === 'asc') {
                return a.createdAt > b.createdAt ? 1 : b.createdAt > a.createdAt ? -1 : 0
              }
              return b.createdAt > a.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0
            })
            .map(story => (
              <StoryCard
                collectionId={collectionId}
                key={story.id}
                story={story}
                isSelected={story.id === selectedStory}
                playing={playing && story.id === selectedStory}
                canSeletecForEdit={editMode}
                selectForEdit={selectedStories.some(item => item === story.id)}
                mergeStatus={story.mergeStatus}
                graphicsProcessed={story.graphicsProcessed}
                sentToDam={story.sentToDam}
                handleSelectForEdit={(id, isToAdd) => {
                  if (isToAdd) {
                    setSelectedStories([...selectedStories, id])
                    return
                  }
                  setSelectedStories(selectedStories.filter(item => item !== id))
                }}
              />
            ))}
        </Box>
      </Box>
    </>
  )
}

export default Stories
