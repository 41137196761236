import React, { useContext, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { FormikProvider, FormikValues, useFormik } from 'formik'
import { schema } from './schema'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { SimpleSnackbarContext } from '../SimpleSnackbar'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { InviteContributorType } from '../../lib/types'
import api from '../../lib/api'
import LoadingButton from '@mui/lab/LoadingButton'
import Form from './Form'

const StepInviteContributorEdit: React.FC<{
  invite: InviteContributorType
  onClose: (invite?: InviteContributorType | null) => void
}> = ({ invite, onClose }) => {
  const { actions: snackActions } = useContext(SimpleSnackbarContext)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const initialValues: any = {
    id: invite.id,
    storyId: invite.storyId,
    title: invite.title,
    description: invite.description,
    requestedMediaType: invite.requestedMediaType || 'NONE',
    user: invite.user,
    token: invite.token,
    url: invite.url,
    dueAt: invite.dueAt,
    allowExpire: !!invite.dueAt,
    createdAt: invite.createdAt
  }

  const onSubmit = async (values: FormikValues) => {
    try {
      const newInvite = await api.editInviteContributor(invite.id, {
        storyId: values!.storyId,
        title: values!.title,
        description: values!.description,
        dueAt: values.allowExpire ? values!.dueAt : null,
        requestedMediaType: values.requestedMediaType === 'NONE' ? null : values.requestedMediaType
      })
      onClose(newInvite)
      snackActions.open('Invite updated.')
    } catch (error) {
      // @ts-ignore
      const { data, message } = error.response
      if (data?.code) {
        snackActions.open(message)
        throw error
      }
      snackActions.open('Something was wrong! please try again.')
      throw error
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    validateOnBlur: true,
    onSubmit
  })

  useEffect(() => {
    formik.handleReset(initialValues)
  }, [])

  return (
    <form>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth="md"
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            onClose()
          }
        }}
        open
      >
        <DialogTitle>
          Edit content request
          <IconButton
            aria-label="close"
            onClick={() => onClose()}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ width: '100%', marginY: 3, minHeight: 328 }}>
            <FormikProvider value={formik}>
              <Form />
            </FormikProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => onClose()} sx={{ mt: 1, mr: 1 }}>
            Close
          </Button>
          <LoadingButton
            variant="contained"
            loading={formik.isSubmitting}
            disabled={!formik.isValid}
            onClick={() => formik.handleSubmit()}
            sx={{ mt: 1, mr: 1 }}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </form>
  )
}

export default StepInviteContributorEdit
