import React, { memo, useEffect, useState } from 'react'
import convertFromTranscript from './helpers/convertFromTranscript'
import convertToTranscript from './helpers/convertToTranscript'
import { withTime } from './helpers/decorators'
import TranscriptEditor from './TranscriptEditor'
import { EditorState } from 'draft-js'
import './TranscriptEditor.css'
import Immutable from 'immutable'
// @ts-ignore
import { Transcript } from 'transcript-model'
import FindReplace from './FindReplace'

interface iTranscriptEditorWrapper {
  currentTime: number
  isEditable?: boolean
  transcriptJson: unknown
  onChange: (transcript: unknown) => void
}

const TranscriptEditorWrapper: React.FC<iTranscriptEditorWrapper> = ({
  currentTime,
  transcriptJson,
  isEditable = false,
  onChange
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty())
  const [speakers, setSpeakers] = useState<Immutable.List<string>>(Immutable.List())

  const handleSetEditorState = (newState: React.SetStateAction<EditorState>) => {
    // Update the state of your component or perform other actions with the new editor state
    setEditorState(newState) // Assuming you have a useState hook or similar state management
  }

  useEffect(() => {
    setIsLoading(true)
    const transcript = Transcript.fromJson(transcriptJson)
    const { editorState, speakers } = convertFromTranscript(transcript)
    setEditorState(editorState)
    setSpeakers(speakers)
    setIsLoading(false)
  }, [transcriptJson])

  const handleChange = ({
    newEditorState,
    newSpeakers
  }: {
    newEditorState: EditorState
    newSpeakers: Immutable.List<string>
  }) => {
    setEditorState(newEditorState)
    setSpeakers(newSpeakers)
    const transcript = convertToTranscript(newEditorState.getCurrentContent(), newSpeakers)
    onChange(transcript.toJSON())
  }

  if (isLoading) return null
  return (
    <>
      <TranscriptEditor
        isEditable={isEditable}
        editorState={withTime(editorState, currentTime)}
        speakers={speakers}
        onChange={handleChange}
        showSpeakers
        setEditorState={handleSetEditorState}
      />
    </>
  )
}

export default memo(TranscriptEditorWrapper)
