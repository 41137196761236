import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import Typography from '@mui/material/Typography'
import MovieIcon from '@mui/icons-material/Movie'
import HeadphonesIcon from '@mui/icons-material/Headphones'
import CameraIndoorIcon from '@mui/icons-material/CameraIndoor'
import Wrapper from './Wrapper'
import DropArea from '../DropArea'
import { CollectionThemeProps } from '../../../lib/types'

interface iRecorder {
  onSelected?: (isSelected: boolean) => void
  onDrop: (value: File | null) => void
  type?: 'AUDIO' | 'VIDEO' | null
  disableChangeMediaType?: boolean
  countdown?: number
  hardStop?: boolean
  clearMedia?: () => void
  collectionTheme?: CollectionThemeProps
}

const Recorder: React.FC<iRecorder> = ({
  onSelected,
  type,
  clearMedia,
  onDrop,
  disableChangeMediaType,
  hardStop,
  countdown,
  collectionTheme
}) => {
  const [isRecordingType, setIsRecordingType] = useState<'AUDIO' | 'VIDEO' | null>(null)

  const handleSelectType = (type: 'AUDIO' | 'VIDEO' | null) => {
    setIsRecordingType(type)
    onSelected && onSelected(true)
  }

  return (
    <>
      {!isRecordingType && (
        <Box
          sx={{
            width: { xs: '100%', md: 422 },
            height: 422,
            border: '1px dashed #7b7b7c',
            borderRadius: '20px'
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexFlow: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '0px 85px'
            }}
          >
            <Box
              sx={{
                width: 72,
                height: 72,
                borderRadius: 50,
                backgroundColor: collectionTheme?.questionnaireRecordIconBackgroundColor ?? 'primary.main',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 2
              }}
            >
              <CameraIndoorIcon
                sx={{
                  fontSize: 52,
                  color: collectionTheme?.questionnaireRecordIconColor ?? 'common.black'
                }}
              />
            </Box>
            <Typography
              sx={{
                marginBottom: 6,
                fontFamily: collectionTheme?.questionnaireTextFontFamily,
                color: collectionTheme?.questionnaireBodyTextColor
              }}
              fontWeight="700"
              align="center"
              variant="h6"
              gutterBottom
            >
              {`Record ${!type ? 'video or audio' : type.toLowerCase()} from your device`}
            </Typography>
            <ButtonGroup disableElevation size="large" variant="outlined">
              {(!type || type === 'VIDEO') && (
                <Button
                  sx={{
                    fontFamily: collectionTheme?.questionnaireTextFontFamily,
                    color: collectionTheme?.questionnaireRecordButtonColor,
                    borderColor: collectionTheme?.questionnaireRecordButtonColor,
                    ':hover': {
                      borderColor: collectionTheme?.questionnaireRecordButtonColor,
                      backgroundColor: collectionTheme?.questionnaireRecordButtonHoverColor
                    }
                  }}
                  onClick={() => handleSelectType('VIDEO')}
                  startIcon={<MovieIcon />}
                >
                  Video
                </Button>
              )}
              {(!type || type === 'AUDIO') && (
                <Button
                  sx={{
                    fontFamily: collectionTheme?.questionnaireTextFontFamily,
                    color: collectionTheme?.questionnaireRecordButtonColor,
                    borderColor: collectionTheme?.questionnaireRecordButtonColor,
                    ':hover': {
                      borderColor: collectionTheme?.questionnaireRecordButtonColor,
                      backgroundColor: collectionTheme?.questionnaireRecordButtonHoverColor
                    }
                  }}
                  onClick={() => handleSelectType('AUDIO')}
                  endIcon={<HeadphonesIcon />}
                >
                  Audio
                </Button>
              )}
            </ButtonGroup>
            <DropArea
              collectionTheme={collectionTheme}
              onDrop={acceptedFiles => {
                const file = acceptedFiles[0]
                onDrop(file)
              }}
            />
          </Box>
        </Box>
      )}
      {isRecordingType && (
        <Wrapper
          onDrop={onDrop}
          isRecordingType={isRecordingType}
          countdown={countdown}
          hardStop={hardStop}
          onChangeRecordingType={() => {
            setIsRecordingType(null)
            onSelected && onSelected(false)
          }}
          collectionTheme={collectionTheme}
        />
      )}
    </>
  )
}

export default Recorder
