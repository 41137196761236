import React from 'react'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import FormGroup from '@mui/material/FormGroup'
import { Field, FieldProps, Form, Formik } from 'formik'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Link from '@mui/material/Link'
import Divider from '@mui/material/Divider'
import { CollectionThemeProps, SingupFormType } from '../../../../../lib/types'
import schema from '../../../../u/Signup/schema'
import { Box } from '@mui/material'

const SignupModal: React.FC<{ collectionTheme: CollectionThemeProps }> = ({ collectionTheme }) => {
  const handleSubmit = () => {
    console.log('submit')
  }

  const initialValues: SingupFormType = {
    email: '',
    allowEmail: true,
    allowShareInfo: false
  }

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      {({ handleBlur, values, handleChange, errors, touched }) => (
        <Form>
          <Box
            sx={{
              maxWidth: '800px',
              border: '1px gray dashed',
              backgroundColor: collectionTheme.signupBackgroundColor,
              padding: '16px',
              borderRadius: '8px'
            }}
          >
            <Typography
              variant={'h5'}
              textAlign="center"
              sx={{ color: collectionTheme.signupTextColor, fontFamily: collectionTheme.signupTextFontFamily }}
            >
              Hi! Accessing this collection requires a Rakonto account. Simply enter your email address here - no
              password required. If you don&apos;t yet have an account, we&apos;ll create a free account for you.
            </Typography>
            <Grid container mt={2} spacing={4} direction="row" justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                <TextField
                  name="email"
                  fullWidth
                  placeholder="Email address"
                  InputProps={{
                    style: {
                      width: '100%',
                      height: '48px',
                      borderWidth: '1px',
                      color: collectionTheme.signupTextColor,
                      backgroundColor: collectionTheme.signupInputBGColor,
                      fontFamily: collectionTheme.signupTextFontFamily
                    }
                  }}
                  inputProps={{
                    style: {
                      height: '16px',
                      WebkitTextFillColor: collectionTheme.signupInputTextColor
                    }
                  }}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={(touched.email && errors.email) || ' '}
                  sx={{
                    '& .MuiOutlinedInput-root': collectionTheme && {
                      '& fieldset.MuiOutlinedInput-notchedOutline': {
                        borderColor:
                          touched.email && Boolean(errors.email) ? '#FF8A80' : collectionTheme.signupInputOutlineColor
                      }
                    },
                    '& .MuiOutlinedInput-input:-webkit-autofill': {
                      '-webkit-box-shadow': `0 0 0 30px ${collectionTheme.signupBackgroundColor ?? '#424242'} inset`
                    }
                  }}
                />
                <Button
                  color={'primary'}
                  variant="contained"
                  fullWidth
                  type="submit"
                  sx={{
                    color: collectionTheme.signupButtonTextColor,
                    backgroundColor: collectionTheme.signupButtonBGColor,
                    ':hover': { backgroundColor: collectionTheme.signupButtonHoverColor },
                    fontFamily: collectionTheme.signupTextFontFamily
                  }}
                >
                  View Collection
                </Button>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <Field name="allowEmail" type="checkbox">
                    {({ field }: FieldProps) => (
                      <>
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...field}
                              sx={{
                                color: collectionTheme.signupCheckBoxColor,
                                '&.Mui-checked': { color: collectionTheme.signupCheckBoxColor }
                              }}
                            />
                          }
                          label={
                            <Typography
                              sx={{
                                color: collectionTheme.signupTextColor,
                                fontFamily: collectionTheme.signupTextFontFamily
                              }}
                            >
                              Rakonto may send me emails about product updates and promotions
                            </Typography>
                          }
                        />
                      </>
                    )}
                  </Field>
                  <Field name="allowShareInfo" type="checkbox">
                    {({ field }: FieldProps) => (
                      <>
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...field}
                              sx={{
                                color: collectionTheme.signupCheckBoxColor,
                                '&.Mui-checked': { color: collectionTheme.signupCheckBoxColor }
                              }}
                            />
                          }
                          label={
                            <Typography
                              sx={{
                                color: collectionTheme.signupTextColor,
                                fontFamily: collectionTheme.signupTextFontFamily
                              }}
                            >
                              Rakonto may share my information with its marketing partners
                            </Typography>
                          }
                        />
                      </>
                    )}
                  </Field>
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  textAlign="center"
                  sx={{ color: collectionTheme.signupTextColor, fontFamily: collectionTheme.signupTextFontFamily }}
                >
                  By viewing this collection you agree to our{' '}
                  <Link
                    href="https://rakonto.io/terms-and-conditions"
                    target="_blank"
                    sx={{ color: collectionTheme.signupLinksColor }}
                  >
                    terms
                  </Link>{' '}
                  and{' '}
                  <Link
                    href=" https://rakonto.io/privacy-policy"
                    target="_blank"
                    sx={{ color: collectionTheme.signupLinksColor }}
                  >
                    {' '}
                    privacy policy
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs>
                <Typography
                  textAlign="center"
                  sx={{ color: collectionTheme.signupTextColor, fontFamily: collectionTheme.signupTextFontFamily }}
                >
                  If you don&apos;t already have an account, we will send you an email so you can verify your email
                  address. Enjoy viewing the collection now, then please check your email and confirm within 24 hours.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

export default SignupModal
