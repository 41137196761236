import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { capitalize } from '../Capitalize'
import { SimpleMultiSelectDialogContext } from './Context'
import Typography from '@mui/material/Typography'

const SimpleMultiSelectDialog = () => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const { actions, store } = useContext(SimpleMultiSelectDialogContext)
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([])
  const [checkAll, setCheckAll] = useState(false)

  useEffect(() => {
    setSelectedItemIds(store.selectedItemsIds)
  }, [store.selectedItemsIds])

  useEffect(() => {
    if (store.items.length === 0) return
    if (selectedItemIds.length === 0 && checkAll) {
      setCheckAll(false)
    }
    if (selectedItemIds.length === store.items.length && !checkAll) {
      setCheckAll(true)
    }
  }, [selectedItemIds])

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = event.target.value

    setSelectedItemIds(prevSelectedIds => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter(selectedId => selectedId !== id)
      } else {
        return [...prevSelectedIds, id]
      }
    })
  }
  const handleCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setCheckAll(true)
      setSelectedItemIds(store.items.map(i => i.id))
    } else {
      setCheckAll(false)
      setSelectedItemIds([])
    }
  }

  const handleSubmit = async () => {
    actions.callback && actions.callback(selectedItemIds)
    setSelectedItemIds([])
    actions.close(true)
  }

  return (
    <Dialog fullScreen={fullScreen} maxWidth="md" fullWidth open={store.isOpen} onClose={() => actions.close()}>
      <DialogTitle id="alert-dialog-title">
        {store.title}
        <IconButton
          aria-label="close"
          onClick={() => {
            setSelectedItemIds([])
            actions.close()
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {store.content}
        <Box sx={{ paddingY: '12px', display: 'flex', flexDirection: 'column' }}>
          <FormControlLabel
            control={<Switch checked={checkAll} onChange={handleCheckAll} />}
            label={checkAll ? 'Uncheck all' : 'Check all'}
          />
          {store.items.length > 0 &&
            store.items.map(item => {
              const label = item.email ? capitalize(item.name) + ' - ' + item.email : capitalize(item.name)
              return (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} key={item.id}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={selectedItemIds.includes(item.id)}
                        onChange={handleCheckboxChange}
                        value={item.id}
                      />
                    }
                    label={label}
                  />
                  {item?.alreadyInvited && (
                    <Box sx={{ display: 'flex', padding: '6px', backgroundColor: '#4C8648', borderRadius: '4px' }}>
                      <Typography sx={{ fontSize: '12px' }}>Already Invited</Typography>
                    </Box>
                  )}
                </Box>
              )
            })}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={() => {
            setSelectedItemIds([])
            actions.close()
          }}
        >
          Cancel
        </Button>
        <Button variant="outlined" onClick={handleSubmit}>
          {store.okButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SimpleMultiSelectDialog
