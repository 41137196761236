import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { intervalToDuration } from 'date-fns'
import Box from '@mui/material/Box'
import ButtonGroup from '@mui/material/ButtonGroup'
import CircularProgress from '@mui/material/CircularProgress'
import LoadingButton from '@mui/lab/LoadingButton'
import QuestionnaireInputFileArea from '../../../../../../components/QuestionnaireInputFileArea'
import { CollectionThemeProps } from '../../../../../../lib/types'

const Step2: React.FC<{
  collectionTheme: CollectionThemeProps
}> = ({ collectionTheme }) => {
  const [file, setFile] = useState<File | null>(null)
  const duration = intervalToDuration({ start: 0, end: 60 * 1000 })
  const [progress, setProgress] = useState(0)

  return (
    <Grid
      container
      sx={{ backgroundColor: collectionTheme.questionnaireBackgroundColor, padding: '24px', borderRadius: '4px' }}
    >
      <Grid
        item
        xs={12}
        textAlign="center"
        sx={{
          color: collectionTheme?.questionnaireBodyTextColor,
          fontFamily: collectionTheme?.questionnaireTextFontFamily
        }}
      >
        <Box mb={1} mt={3} textAlign="center">
          <Typography
            variant="subtitle1"
            textAlign="center"
            fontSize={'0.9em'}
            fontFamily={collectionTheme?.questionnaireTextFontFamily}
          >
            {`Question 1 of n - due 01 Jan`}
          </Typography>
          <Typography
            textAlign={'center'}
            variant={'h4'}
            marginY={2}
            fontSize={'1.0em'}
            fontFamily={collectionTheme?.questionnaireTextFontFamily}
          >
            Here is where your question will be displayed
          </Typography>
        </Box>
      </Grid>
      {!progress ? (
        <Grid
          item
          xs={12}
          mt={4}
          mb={9}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            margin: '10px'
          }}
        >
          <QuestionnaireInputFileArea
            file={file}
            callback={file => {
              setFile(file)
            }}
            collectionTheme={collectionTheme}
          />
        </Grid>
      ) : (
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 10, flexFlow: 'column' }}
        >
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress size={100} variant="determinate" value={progress || 100} />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Typography variant="h6" component="div" color="text.secondary">
                {`${Math.round(progress || 100)}%`}
              </Typography>
            </Box>
          </Box>
          <Typography
            mt={2}
            variant="h6"
            component="div"
            color="text.secondary"
            sx={{
              color: collectionTheme?.questionnaireBodyTextColor,
              fontFamily: collectionTheme?.questionnaireTextFontFamily
            }}
          >
            uploading the answer...
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} textAlign="center">
        <Box
          mb={1}
          mt={3}
          textAlign="center"
          sx={{
            color: collectionTheme?.questionnaireBodyTextColor
          }}
        >
          <Typography
            textAlign={'center'}
            variant={'h6'}
            marginY={2}
            fontSize={'1em'}
            fontFamily={collectionTheme?.questionnaireTextFontFamily}
          >
            {'Recommendations for a helpful response: ' + 'Question recommendation/instructions'}
          </Typography>
          <Grid item xs={12} textAlign="center">
            <Typography
              variant="caption"
              mb={2}
              textAlign="center"
              fontSize="0.9rem"
              fontFamily={collectionTheme?.questionnaireTextFontFamily}
            >
              {`If possible, please keep your recording duration under ${String(
                (duration.hours || 0) * 60 + (duration.minutes || 0)
              )} minute${(duration.minutes || 0) > 1 ? 's' : ''}${
                duration.seconds ? ` and ${String(duration.seconds).padStart(2, '0')} seconds` : ''
              }.`}
            </Typography>
          </Grid>
        </Box>
      </Grid>
      <Box
        sx={{
          fontSize: '0.8rem',
          display: 'flex',
          width: '100%'
        }}
      >
        <Box sx={{ flex: 1 }} />
        <ButtonGroup>
          <LoadingButton
            disabled={!!progress}
            sx={{
              fontSize: '0.8rem',
              color: collectionTheme?.questionnaireContainedButtonTextColor,
              backgroundColor: collectionTheme?.questionnaireContainedButtonBackgroundColor,
              ':hover': { backgroundColor: collectionTheme?.questionnaireContainedButtonHoverColor },
              fontFamily: collectionTheme?.questionnaireTextFontFamily
            }}
            variant="contained"
            size="medium"
          >
            Skip
          </LoadingButton>
          <LoadingButton
            disabled={!file}
            loading={!!progress}
            type="submit"
            sx={{
              fontSize: '0.8rem',
              color: collectionTheme?.questionnaireContainedButtonTextColor,
              backgroundColor: collectionTheme?.questionnaireContainedButtonBackgroundColor,
              ':hover': { backgroundColor: collectionTheme?.questionnaireContainedButtonHoverColor },
              fontFamily: collectionTheme?.questionnaireTextFontFamily,
              ':disabled': {
                backgroundColor: collectionTheme?.questionnaireContainedDisabledButtonBackgroundColor,
                color: collectionTheme?.questionnaireContainedDisabledButtonColor
              }
            }}
            variant="contained"
            size="medium"
          >
            Submit
          </LoadingButton>
        </ButtonGroup>
      </Box>
    </Grid>
  )
}

export default Step2
