import React, { ChangeEvent, useContext, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import { Link, useHistory } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import DeleteIcon from '@mui/icons-material/Delete'
import DownloadIcon from '@mui/icons-material/Download'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import AddToQueueIcon from '@mui/icons-material/AddToQueue'
import ImageIcon from '@mui/icons-material/Image'
import BookmarkIcon from '@mui/icons-material/BookmarkAdd'
import VideoSettingsIcon from '@mui/icons-material/VideoSettings'
import {
  AssetTypes,
  AudioDetails,
  ImageType,
  Metadata,
  StoryStatusType,
  StoryType,
  UserType,
  VideoDetails
} from '../../../../lib/types'
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone'
import api from '../../../../lib/api'
import { SimpleDialogContext } from '../../../../components/SimpleDialog'
import { SimpleSnackbarContext } from '../../../../components/SimpleSnackbar'
import { StepInviteRecorderContext } from '../../../../components/StepInviteRecorder'
import CollectionMove from './CollectionMove'
import { ChangeMediaContext } from '../../../../components/ChangeMedia'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreIcon from '@mui/icons-material/MoreVert'
import CodeIcon from '@mui/icons-material/Code'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import Cookies from 'js-cookie'
import Share from '../../../../components/Share'

// @ts-ignore
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Tooltip from '@mui/material/Tooltip'
import CreateIcon from '@mui/icons-material/Create'
import Trimmer from '../../../../components/Trimmer'
import { Divider, TextField } from '@mui/material'
import { VideoEditDialogContext } from '../../../../components/VideoEditCodeDialog'
import { RequestContentDialogContext } from '../../../../components/RequestContentDialog'
import { usePermissions } from '../../../../components/hooks/usePermissions'
import LoadingButton from '@mui/lab/LoadingButton'
import { VideoBookmarkDialogContext } from '../../../../components/VideoBookmarkDialog'
import { VideoChapter } from '../../../../components/VideoBookmarkDialog/BookmarkVideoJs'
import CircularProgress from '@mui/material/CircularProgress'
import { updateStoryStatus } from '../../../../lib/api/services'

interface iEditBar {
  isEditor: boolean
  isOwner: boolean
  media: VideoDetails | AudioDetails
  id: string
  reload: () => void
  story?: StoryType
  user: UserType
  setEditMode: (editMode: boolean) => void
  setTab?: () => void
}

const EditBar: React.FC<iEditBar> = ({ isEditor, user, isOwner, id, reload, media, story, setEditMode, setTab }) => {
  const embedCode = `
  <iframe
    width='640'
    height='360'
    src='${window.location.origin}/embed/stories/${id}'
    title='Rakonto'
    frameBorder='0'
    allow='camera; microphone; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
    allowFullScreen
  ></iframe>
  `
  const { actions: snackActions } = useContext(SimpleSnackbarContext)
  const { actions: dialogActions } = useContext(SimpleDialogContext)
  const { actions: mediaActions } = useContext(ChangeMediaContext)
  const { actions: stepInviteRecorderActions } = useContext(StepInviteRecorderContext)
  const { actions: requestContentActions } = useContext(RequestContentDialogContext)
  const { actions: videoBookmarkDialogActions } = useContext(VideoBookmarkDialogContext)
  const { actions: simpleDialogActions } = useContext(SimpleDialogContext)
  const history = useHistory()
  const [progress, setProgress] = useState<number>(0)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null)
  const token = Cookies.get('token') as string
  const [showShare, setShowShare] = useState<boolean>(false)
  const [isPublished, setIsPublished] = useState<boolean>(story?.published || false)
  const canDownload = user.tier > 0 && isOwner
  const canCopyEmbed = isOwner && isPublished
  const [trimMode, setTrimMode] = useState<boolean>(false)
  const [isEmailRequiredToView, setIsEmailRequiredToView] = useState<boolean>(story?.requiredEmail || false)
  let [deleteText, setDeleteText] = useState<string>()
  let [deleteTextIsRight, setDeleteTextIsRight] = useState<boolean>()
  const [chapters, setChapters] = useState<VideoChapter[] | undefined>(undefined)
  const { actions: videoEditDialogActions } = useContext(VideoEditDialogContext)
  const [isSubmittingExport, setIsSubmittingExport] = useState(false)
  const { isCreator, isEditor: tenantEditor, isAdministrator, isTenant } = usePermissions()
  const canEditVideo = (user.tier >= 4 && isOwner) || tenantEditor
  const [isLoadingChapters, setIsLoadingChapters] = useState(true)
  const [graphicsStatus, setGraphicsStatus] = useState<string | undefined>(story?.mergeStatus)
  const [shouldStopPolling, setShouldStopPolling] = useState(false)
  const [finishedProcessing, setFinishedProcessing] = useState(false)

  const [processingStatuses, setProcessingStatuses] = useState<StoryStatusType>({
    storyId: story ? story?.id : '',
    processingGraphics: false,
    processingTranscript: false,
    processingSubtitle: false
  })

  const isFinishedProcessing = (status: StoryStatusType | undefined) => {
    if (status) {
      return setFinishedProcessing(
        !status.processingGraphics && !status.processingTranscript && !status.processingSubtitle
      )
    } else {
      return setFinishedProcessing(
        !processingStatuses.processingGraphics &&
          !processingStatuses.processingTranscript &&
          !processingStatuses.processingSubtitle
      )
    }
  }

  const pollingInterval = 30000 // 30secs / 60000 1 in milliseconds

  const canGoToCollection = story!.collections[0]!.watchers.find(e => e.email === user.email) || isOwner

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const deleteTextExpected = 'delete'

  const handleDeleteTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setDeleteText(value)
    deleteText = value
    setDeleteTextIsRight(deleteText === deleteTextExpected || value === deleteTextExpected)
    deleteTextIsRight = deleteText === deleteTextExpected || value === deleteTextExpected
  }

  const handleTrim = () => {
    setTrimMode(true)
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const handlePublished = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      await api.updateStoryStatus(id, event.target.checked)
      setIsPublished(!isPublished)
    } catch (error) {
      // @ts-ignore
      const { data } = error.response

      if (data.code) {
        snackActions.open(data.message)
        return
      }
      snackActions.open(`Problem to make this ${event.target.checked ? 'draft' : 'published'}`)
    }
  }

  const handleChangeRequireEmail = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      await api.changeRequiredEmailFlag(id, AssetTypes.story)
      setIsEmailRequiredToView(!isEmailRequiredToView)
    } catch (error) {
      // @ts-ignore
      const { data } = error.response

      if (data.code) {
        snackActions.open(data.message)
        return
      }
      snackActions.open(`Problem to make this ${event.target.checked ? 'draft' : 'published'}`)
    }
  }

  const updateCover = async (image: ImageType) => {
    try {
      await api.updateStoryCover(id, image.id)
      reload()
    } catch (error) {
      // @ts-ignore
      const { data } = error.response
      if (data.code === '1018') {
        snackActions.open('This story cannot be edited!')
        throw error
      }
      snackActions.open('Something was wrong! please try again.')
      throw error
    }
  }

  function getFormattedDate() {
    const now = new Date()
    const month = (now.getMonth() + 1).toString().padStart(2, '0') // Janeiro é 0!
    const day = now.getDate().toString().padStart(2, '0')
    const year = now.getFullYear()
    return `${month}-${day}-${year}`
  }

  const refreshScreen = () => {
    simpleDialogActions.open(
      'Refresh Screen',
      'To see your changes applied reflected in the Story Video, you will need to refresh the page. Do you want to see your changes?',
      { okText: 'Yes, refresh the page now', cancelText: 'No, I will refresh later', showOk: true },
      success => {
        if (success) window.location.reload()
      }
    )
  }

  const checkProcessing = async () => {
    try {
      await api.checkProcessing(story?.id).then(status => {
        if (status && status.storyId === story?.id) {
          setProcessingStatuses(status)
          isFinishedProcessing(status)
        }
      })
    } catch (error) {
      snackActions.open('Error to check the processing status')
      throw error
    }
  }
  const applyGraphics = async () => {
    try {
      if (!story?.questionnaireId) {
        snackActions.open(
          'WARN: You cannot incorporate visuals into stories that were not captured through a questionnaire.'
        )
        return
      }
      setGraphicsStatus('Applying Graphics')

      setProcessingStatuses({
        storyId: story ? story?.id : '',
        processingGraphics: true,
        processingTranscript: true,
        processingSubtitle: true
      })

      setFinishedProcessing(false)

      await api.applyGraphics(story?.id)
      snackActions.open('The story is currently being rendered. Please wait while the process completes.')
    } catch (error) {
      snackActions.open(
        "An error occurred while sending the story for video rendering. Please try again or reach out to Rakonto's support for assistance."
      )
      console.error(error, error)
      throw error
    }
  }

  const restoreMedia = async () => {
    setProcessingStatuses({
      storyId: story ? story?.id : '',
      processingGraphics: false,
      processingTranscript: true,
      processingSubtitle: true
    })

    setFinishedProcessing(false)

    await api.restoreMedia(story?.id)
    snackActions.open('Restoration of the media is underway. Please wait until the process is fully complete.')
  }
  // @ts-ignore
  // prettier-ignore
  const onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void = async acceptedFiles => {
    try {
      const selectedFile = acceptedFiles[0]
      const image = await api.uploadImage(selectedFile, event => {
        setProgress(Math.round((event.loaded * 100) / event.total))
      })
      setProgress(0)
      updateCover(image)
    } catch (error) {
      snackActions.open('Something was wrong! please try again.')
      setProgress(0)
    }
  }

  const {
    getRootProps,
    getInputProps,
    open: openUpload
  } = useDropzone({
    onDrop,
    noClick: true,
    noDrag: true,
    accept: 'image/png, image/jpeg, image/jpg'
  })

  useEffect(() => {
    console.log('Use Effect to update the screen')
    setGraphicsStatus(story?.mergeStatus)
    isFinishedProcessing(processingStatuses)
  }, [])

  useEffect(() => {
    // eslint-disable-next-line prefer-const
    let intervalId: NodeJS.Timeout

    const performPolling = async () => {
      if (shouldStopPolling) {
        // If the variable indicates that polling should be stopped, clear the interval
        clearInterval(intervalId)
        return
      }

      try {
        // Make the request to your backend endpoint
        await checkProcessing()
        // Here, you could set conditions to update shouldStopPolling
        // Example: setShouldStopPolling(true) if a certain condition is met
      } catch (error) {
        console.error('Error during polling to check Story Processing:', error)
      }
    }

    // Start polling immediately
    performPolling()

    // Schedule the next polling every 1 minute
    intervalId = setInterval(performPolling, pollingInterval)

    // Make sure to clear the interval when the component is unmounted
    return () => clearInterval(intervalId)
  }, [shouldStopPolling]) // Add shouldStopPolling to the dependency array

  useEffect(() => {
    const getChapters = async () => {
      const id = story?.video.id || story?.audio.id
      if (!id) return
      const chapters = await api.getChapters(id)
      // @ts-ignore
      setChapters(chapters)
      setIsLoadingChapters(false)
    }
    getChapters()
  }, [])

  const handleDelete = () => {
    dialogActions.open(
      'Delete forever',
      <>
        <Typography fontWeight="700" fontSize={'1.5rem'}>
          Are you sure you want delete permanently the Story {story?.title}?
        </Typography>
        <Typography color={'#b45b41'} fontWeight={200} margin={'1em'} fontSize={'1rem'}>
          This action cannot be undone!
        </Typography>
        <br></br>
        <TextField
          color="warning"
          size="medium"
          name="deleteConfirmation"
          fullWidth
          required
          onInput={(e: React.ChangeEvent<HTMLInputElement>): void => handleDeleteTextChange(e)}
          placeholder={`${deleteTextExpected}`}
        />
        <br></br>
        <Typography
          fontWeight={100}
          margin={'1em'}
        >{`Type the expected phrase to confirme deletion: ${deleteTextExpected}`}</Typography>
      </>,
      {
        okText: 'Yes, delete',
        showOk: true,
        cancelText: 'No, keep as is'
      },
      async value => {
        if (value) {
          try {
            if (!deleteTextIsRight) {
              snackActions.open('Type the correct phrase on modal input to delete permanentely the Story.')
            } else {
              await api.deleteStory(id)
              history.push('/a/my-library')
            }
          } catch (error) {
            // @ts-ignore
            const { data } = error.response
            if (data.code) {
              snackActions.open(data.message)
              return
            }
            snackActions.open('Something was wrong! please try again.')
          }
        }
      }
    )
  }

  const handleEdit = () => {
    setTab && setTab()
    setEditMode(true)
  }

  const handleExportToDAM = async () => {
    try {
      setIsSubmittingExport(true)
      const storyId = story?.id
      if (storyId) {
        await api.sendStoryToDam(storyId)
        snackActions.open('Story has been sent to DAM!')
      } else {
        snackActions.open('The Story is not ready to send to dam - ' + storyId)
      }
    } catch (e) {
      console.log(e)
      snackActions.open('Ops! Something went wrong, please try again')
    } finally {
      setIsSubmittingExport(false)
    }
  }

  const mobileMenuId = 'primary-search-account-menu-mobile'
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <CollectionMove
        onClick={() => {
          handleMobileMenuClose()
        }}
        currentCollectionId={story!.collections[0]!.id}
        id={id}
        reload={reload}
        isMenu
      />
      {canCopyEmbed && (
        <CopyToClipboard
          text={embedCode}
          options={{ format: 'text' }}
          onCopy={() => snackActions.open('Embded code copied to clipboard!')}
        >
          <MenuItem>Embed</MenuItem>
        </CopyToClipboard>
      )}
      <MenuItem onClick={() => requestContentActions.open(id)}>Request content</MenuItem>
      {/* !story!.submission?.id && */}
      {isOwner && (
        <MenuItem
          onClick={() => {
            applyGraphics()
            handleMobileMenuClose()
          }}
        >
          <Box {...getRootProps()}>
            <input {...getInputProps()} />
            Apply graphics
          </Box>
        </MenuItem>
      )}
      {isOwner && (
        <MenuItem
          onClick={() => {
            restoreMedia()
            handleMobileMenuClose()
          }}
        >
          <Box {...getRootProps()}>
            <input {...getInputProps()} />
            Restore media
          </Box>
        </MenuItem>
      )}
      {isOwner && (
        <MenuItem
          onClick={() => {
            mediaActions.open(id)
            handleMobileMenuClose()
          }}
        >
          Replace recording
        </MenuItem>
      )}
      {isOwner && (
        <MenuItem
          onClick={() => {
            openUpload()
            handleMobileMenuClose()
          }}
        >
          <Box {...getRootProps()}>
            <input {...getInputProps()} />
            Replace thumbnail
          </Box>
        </MenuItem>
      )}
      {canDownload && (
        <MenuItem
          onClick={() => {
            const url = new URL(story!.downloadUrl)
            url.search = new URLSearchParams({ original: 'f', jwt: token }).toString()
            window.location.assign(url)
          }}
        >
          Download optimized media
        </MenuItem>
      )}
      {canDownload && (
        <MenuItem
          onClick={() => {
            const url = new URL(story!.downloadUrl)
            url.search = new URLSearchParams({ original: 't', jwt: token }).toString()
            window.location.assign(url)
          }}
        >
          Download original media
        </MenuItem>
      )}
      {canDownload && <Divider />}
      {canDownload && (
        <MenuItem
          onClick={() => {
            const url = new URL(story!.downloadImagesUrl)
            url.search = new URLSearchParams({ jwt: token }).toString()
            window.location.assign(url)
          }}
        >
          Download Photos
        </MenuItem>
      )}
      {canDownload && (
        <MenuItem
          onClick={() => {
            const url = new URL(story!.downloadFilesUrl)
            url.search = new URLSearchParams({ jwt: token }).toString()
            window.location.assign(url)
          }}
        >
          Download Files
        </MenuItem>
      )}
      {isOwner && (
        <MenuItem
          onClick={() => {
            setShowShare(true)
            handleMobileMenuClose()
          }}
        >
          Invite editor
        </MenuItem>
      )}
      {isOwner && (
        <MenuItem
          onClick={() => {
            stepInviteRecorderActions.open(story!.collections[0], story)
            handleMobileMenuClose()
          }}
        >
          Request story
        </MenuItem>
      )}
      {isOwner && (
        <MenuItem
          onClick={() => {
            handleDelete()
            handleMobileMenuClose()
          }}
          disabled={!isCreator}
        >
          Delete
        </MenuItem>
      )}
      {isOwner && (
        <MenuItem
          onClick={() => {
            handleEdit()
            handleMobileMenuClose()
          }}
        >
          Edit
        </MenuItem>
      )}
    </Menu>
  )

  const insertBookmarkAction = () => {
    videoBookmarkDialogActions.open(story, chapters)
  }

  return (
    <>
      {trimMode && (
        <Trimmer
          id={id}
          title="Trim Story Media"
          assetType={AssetTypes.story}
          type="EDITOR"
          handleRefresh={function (): void {
            throw new Error('Function not implemented.')
          }}
          onCloseClick={function (): void {
            throw new Error('Function not implemented.')
          }}
        />
      )}
      {isOwner && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end'
          }}
        >
          {showShare && (
            <Share
              title="Invite editor"
              id={id}
              handleRefresh={reload}
              published={!!story?.publicAcl}
              assetType={AssetTypes.story}
              type="EDITOR"
              onCloseClick={() => {
                reload()
                setShowShare(false)
              }}
            />
          )}
          {processingStatuses.processingGraphics && (
            <>
              <Box>
                <CircularProgress size={'1.5em'} />
              </Box>
              <Box>
                <Typography fontSize={'1em'} sx={{ paddingLeft: 1, margin: '5px', marginRight: '3em' }}>
                  Processing Graphics
                </Typography>
              </Box>
            </>
          )}
          {processingStatuses.processingTranscript && (
            <>
              <Box>
                <CircularProgress size={'1.5em'} />
              </Box>
              <Box>
                <Typography fontSize={'1em'} sx={{ paddingLeft: 1, margin: '5px', marginRight: '3em' }}>
                  Processing Transcript
                </Typography>
              </Box>
            </>
          )}
          {processingStatuses.processingSubtitle && (
            <>
              <Box>
                <CircularProgress size={'1.5em'} />
              </Box>
              <Box>
                <Typography fontSize={'1em'} sx={{ paddingLeft: 1, margin: '5px', marginRight: '3em' }}>
                  Processing Subtitles
                </Typography>
              </Box>
            </>
          )}
          {finishedProcessing && (
            <Box>
              <Typography sx={{ paddingLeft: 1, margin: '5px', marginRight: '3em' }}>{graphicsStatus}</Typography>
            </Box>
          )}
          {finishedProcessing && (
            <Box marginRight={'1em'}>
              <Button onClick={refreshScreen} variant={'outlined'}>
                Refresh story to view changes
              </Button>
            </Box>
          )}
          <FormGroup>
            <FormControlLabel
              control={<Switch checked={isPublished} onChange={handlePublished} />}
              label={isPublished ? 'Published' : 'Draft'}
            />
          </FormGroup>
          {isPublished && (
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={isEmailRequiredToView} onChange={handleChangeRequireEmail} />}
                label={isEmailRequiredToView ? 'Require email to see story' : 'Do not require email to see story'}
              />
            </FormGroup>
          )}
          {isPublished && isAdministrator && isTenant && finishedProcessing && (
            <LoadingButton loading={isSubmittingExport} variant={'outlined'} onClick={handleExportToDAM}>
              Export to DAM
            </LoadingButton>
          )}
        </Box>
      )}
      <Box
        sx={{
          width: '100%',
          padding: '0 24px',
          marginBottom: 3,
          height: 65,
          display: 'flex',
          alignItems: 'center'
        }}
        component={Paper}
      >
        <Box
          component={canGoToCollection ? Link : 'div'}
          to={`/a/collections/${story!.collections[0]!.id}?storyId=${id}`}
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {canGoToCollection && <ArrowBackIcon />}
          <Typography sx={{ paddingLeft: 1 }}>{story!.collections[0]!.title}</Typography>
        </Box>
        {isOwner && (
          <Stack direction="row">
            {canCopyEmbed && (
              <Box sx={{ display: { xs: 'none', md: 'flex' } }} component="span">
                <CopyToClipboard
                  text={embedCode}
                  startIcon={<CodeIcon />}
                  options={{ format: 'text' }}
                  onCopy={() => snackActions.open('Embded code copied to clipboard!')}
                >
                  <Button color="secondary">Embed</Button>
                </CopyToClipboard>
              </Box>
            )}
            {canEditVideo && (
              <Box sx={{ display: { xs: 'none', md: 'flex' } }} component="span">
                <Button
                  color="secondary"
                  disabled={isLoadingChapters}
                  onClick={insertBookmarkAction}
                  startIcon={<BookmarkIcon />}
                >
                  Insert bookmark
                </Button>
              </Box>
            )}
            {canEditVideo && (
              <Box sx={{ display: { xs: 'none', md: 'flex' } }} component="span">
                <Button
                  color="secondary"
                  onClick={() => videoEditDialogActions.open(story?.url, story?.id, user.id)}
                  startIcon={<VideoSettingsIcon />}
                >
                  Edit video
                </Button>
              </Box>
            )}
            {isOwner && (
              <Box {...getRootProps()} sx={{ display: { xs: 'none', md: 'flex' } }} component="span">
                <input {...getInputProps()} />
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {popupState => (
                    <React.Fragment>
                      {/* {!story!.submission?.id && ( */}
                      {isOwner && (
                        <Button
                          startIcon={<UploadFileIcon />}
                          sx={{ display: { xs: 'none', md: 'flex' } }}
                          color="secondary"
                          {...bindTrigger(popupState)}
                        >
                          Update media
                        </Button>
                      )}
                      <Menu {...bindMenu(popupState)}>
                        <MenuItem
                          onClick={() => {
                            applyGraphics()
                            popupState.close()
                          }}
                        >
                          Apply graphics
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            restoreMedia()
                            popupState.close()
                          }}
                        >
                          Restore Media
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            mediaActions.open(id)
                            popupState.close()
                          }}
                        >
                          Replace recording
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            openUpload()
                            popupState.close()
                          }}
                        >
                          Replace thumbnail
                        </MenuItem>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              </Box>
            )}
            <Box sx={{ display: { xs: 'none', md: 'flex' } }} component="span">
              <Button color="secondary" onClick={() => requestContentActions.open(id)} startIcon={<ImageIcon />}>
                Request content
              </Button>
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'flex' } }} component="span">
              <CollectionMove currentCollectionId={story!.collections[0]!.id} id={id} reload={reload} />
            </Box>
            {canDownload && (
              <Box sx={{ display: { xs: 'none', md: 'flex' } }} component="span">
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {popupState => (
                    <React.Fragment>
                      <Button
                        startIcon={<DownloadIcon />}
                        sx={{ display: { xs: 'none', md: 'flex' } }}
                        color="secondary"
                        {...bindTrigger(popupState)}
                      >
                        Download
                      </Button>
                      <Menu {...bindMenu(popupState)}>
                        <MenuItem
                          onClick={() => {
                            const url = new URL(story!.downloadUrl)
                            url.search = new URLSearchParams({ original: 't', jwt: token }).toString()
                            window.location.assign(url)
                            popupState.close()
                          }}
                        >
                          Download original media
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            const url = new URL(story!.downloadUrl)
                            url.search = new URLSearchParams({ original: 'f', jwt: token }).toString()
                            window.location.assign(url)
                            popupState.close()
                          }}
                        >
                          Download optimized media
                        </MenuItem>
                        <Divider />
                        <MenuItem
                          onClick={() => {
                            const url = new URL(story!.downloadImagesUrl)
                            url.search = new URLSearchParams({ jwt: token }).toString()
                            window.location.assign(url)
                            popupState.close()
                          }}
                        >
                          Download Photos
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            const url = new URL(story!.downloadFilesUrl)
                            url.search = new URLSearchParams({ jwt: token }).toString()
                            window.location.assign(url)
                            popupState.close()
                          }}
                        >
                          Download Files
                        </MenuItem>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              </Box>
            )}
            {isOwner && (
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <Button color="secondary" onClick={() => setShowShare(true)} startIcon={<PersonAddIcon />}>
                  Invite editor
                </Button>
              </Box>
            )}
            {isOwner && (
              <Box sx={{ display: { xs: 'none', md: 'flex' } }} component="span">
                <Button
                  color="secondary"
                  onClick={() => stepInviteRecorderActions.open(story!.collections[0], story)}
                  startIcon={<AddToQueueIcon />}
                >
                  Request story
                </Button>
              </Box>
            )}
            {isOwner && (
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <Button color="secondary" onClick={handleDelete} disabled={!isCreator} startIcon={<DeleteIcon />}>
                  Delete
                </Button>
              </Box>
            )}
            {isOwner && (
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <Tooltip arrow title="Edit Story Title and Description">
                  <Button color="secondary" onClick={handleEdit} startIcon={<CreateIcon />}>
                    Edit
                  </Button>
                </Tooltip>
              </Box>
            )}
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Stack>
        )}
        {renderMobileMenu}
      </Box>
    </>
  )
}

export default EditBar
