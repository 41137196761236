import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, RouteComponentProps, useLocation } from 'react-router-dom'
import api from '../../../lib/api'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import { CollectionThemeProps, CollectionType, StoryType } from '../../../lib/types'
import Player from '../../../components/Player'
import Cover from '../../../components/Cover'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Cookies from 'js-cookie'
import AuthorAvatar from '../../../components/AuthorAvatar'
import { format, parseJSON } from 'date-fns'
import { SignupDialogContext } from '../../../components/SignupDialog'
// import Photos from '../../../pages/a/Collection/Photos'
import PhotosPublic from './PhotosPublic'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import { SimpleDialogContext } from '../../../components/SimpleDialog'
import { PublicRequestsDialogContext } from '../../../components/PublicRequestsDialog'
import { parse } from 'qs'
import { SimpleSnackbarContext } from '../../../components/SimpleSnackbar'
import { VideoChapter } from '../../../components/VideoBookmarkDialog/BookmarkVideoJs'

const Public: React.FC<RouteComponentProps<{ type: string; id: string }>> = ({ match, history }) => {
  const { type, id } = match.params
  const [story, setStory] = useState<StoryType | null>(null)
  const [playlist, setPlaylist] = useState<any[]>([])
  const [isLoading, setLoading] = useState<boolean>(true)
  const [play, setPlay] = useState<boolean>(false)
  const [collection, setCollection] = useState<CollectionType | null>(null)
  const [title, setTitle] = useState<string>('')
  const [item, setItem] = useState<any>({})
  const [fade, setFade] = useState<string>('fade-in')
  const [requireEmail, setRequireEmail] = useState(false)
  const [hasRequests, setHasRequests] = useState(false)
  const [publishedStoriesCount, setPublishedStoriesCount] = useState(0)
  const [collectionTheme, setCollectionTheme] = useState<CollectionThemeProps | undefined>(undefined)
  const [storyChapters, setStoryChapters] = useState<VideoChapter[] | undefined>(undefined)

  const logoUrl = collection?.owner?.organizations[0]?.logo?.url

  const cookiesToken = Cookies.get('token')
  const { actions, store } = useContext(SignupDialogContext)
  const { actions: dialogActions } = useContext(SimpleDialogContext)
  const { actions: publicRequestsDialogActions } = useContext(PublicRequestsDialogContext)
  const { actions: snackActions } = useContext(SimpleSnackbarContext)
  const location = useLocation()

  const [token, setToken] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [validated, setValidated] = useState(false)

  const validateToken = async () => {
    try {
      const inviteResult = await api.validateToken(email, token)
      setValidated(inviteResult.valid)
    } catch (e) {
      snackActions.open('This link was expired.')
    }
  }

  useEffect(() => {
    if (token && email) {
      validateToken().then()
    }
  }, [token, email])

  useEffect(() => {
    const { email, token } = parse(location?.search as string, {
      ignoreQueryPrefix: true
    })
    setToken(token as string)
    setEmail(email as string)
  }, [location])

  const descriptionFadeTimeOut = useRef<NodeJS.Timeout>()

  const currentStory =
    type === 'stories' ? story : collection?.stories?.filter(story => story.title === item.metatitle)[0]
  const currentStoryCount = () => {
    if (currentStory && collection?.stories && collection?.stories.indexOf(currentStory)) {
      return collection?.stories.indexOf(currentStory) + 1
    }
    return 1
  }

  let stories: StoryType[] | any = type === 'stories' ? [story] : collection?.stories

  const onPressPlay = () => {
    descriptionFadeTimeOut.current = setTimeout(() => {
      setFade('fade-out')
    }, 5000)
  }

  const onPause = () => {
    if (descriptionFadeTimeOut.current) {
      clearTimeout(descriptionFadeTimeOut.current)
    }

    descriptionFadeTimeOut.current = setTimeout(() => {
      setFade('fade-in')
    }, 200)
  }

  const handleViewMyLibrary = () => {
    if (cookiesToken) {
      window.open('/a/my-library', '_blank', 'noreferrer')
      return
    }
    if (store.isNewAccount) {
      window.open('/u/signin', '_blank', 'noreferrer')
      dialogActions.open('Confirm email', 'We sent an email to you to confirm your account. Please check this.', {
        cancelText: 'Close'
      })
    }
    window.open('/u/signin', '_blank', 'noreferrer')
  }

  const handleAddYourVoice = () => {
    collectionTheme ? publicRequestsDialogActions.openWithStyles(collectionTheme) : publicRequestsDialogActions.open()
  }

  useEffect(() => {
    setFade('fade-in')
  }, [item])

  useEffect(() => {
    if (
      (collection && !cookiesToken && !store.signedUp && requireEmail) ||
      (collection && !collection?.publicAcl && !validated)
    ) {
      collectionTheme ? actions.openWithStyles(collectionTheme, logoUrl) : actions.open()
    }
  }, [collection, requireEmail])

  useEffect(() => {
    const getPublic = async () => {
      try {
        setLoading(true)
        const response = type === 'stories' ? await api.getEmbedStory(id) : await api.getCollectionBySharedLink(id)
        let hasRequestResponse
        let publishedStoriesCountResponse

        if (type === 'stories') {
          const story = response as StoryType
          stories = [story]
          setStory(story)
          setTitle(story.title)
          actions.setStoryAsType()
          publicRequestsDialogActions.setCollectionId(story.collections[0].id)
          setCollectionTheme(story.collections[0]?.theme?.properties)
          setCollection(story.collections[0])
          setRequireEmail(story.requiredEmail)
          hasRequestResponse = await api.verifyCollectionInvite(story.collections[0].id)
          publishedStoriesCountResponse = await api.countStoriesPublished(story.collections[0].id)
          let chps
          if (story.video.id) {
            chps = await api.getChapters(story.video.id)
            setStoryChapters(chps)
          }
        }

        if (type === 'collections') {
          let collection
          if (response.length === 0) {
            try {
              collection = await api.getEmbedCollection(id)
            } catch (e) {
              console.log(e)
            }
          } else {
            collection = response[0] as CollectionType
          }
          if (!collection) return

          if (collection?.stories) {
            const playlist = collection?.stories
              ?.map(story => {
                if (story.published) {
                  const chapters = story.chapters
                    ? story.chapters.map(chapter => {
                        return `
                        ${chapter.begin} --> ${chapter.end}
                        ${chapter.title}
                        `
                      })
                    : []

                  const vttContent = `WEBVTT

                    ${chapters.join('\n\n')}
                    `

                  const vttBlob = new Blob([vttContent], { type: 'text/vtt' })
                  const vttURL = URL.createObjectURL(vttBlob)

                  return {
                    sources: [
                      {
                        src: story.url,
                        type: story.mimeType
                      }
                    ],
                    thumb: story.thumbnailUrl,
                    title: `${story.title}`,
                    duration: story.duration,
                    tracks: [
                      ...(story.subtitles?.map(subtitle => ({
                        kind: 'captions',
                        src: subtitle.url,
                        srclang: subtitle.language,
                        label: subtitle.language,
                        default: '1'
                      })) || []),
                      {
                        kind: 'chapters',
                        src: vttURL
                      }
                    ]
                  }
                } else {
                  return null
                }
              })
              .filter(item => item !== null)
            setPlaylist(playlist)
            setStory(collection.stories[0])
          }
          setCollectionTheme(collection?.theme?.properties)
          setCollection(collection)
          setTitle(collection.title)
          actions.setCollectionAsType()
          actions.setIsCollectionPrivate(!collection.publicAcl)
          actions.setCollectionId(collection.id)
          setRequireEmail(collection.requiredEmail)
          publicRequestsDialogActions.setCollectionId(collection.id)
          hasRequestResponse = await api.verifyCollectionInvite(collection.id)
          publishedStoriesCountResponse = await api.countStoriesPublished(collection.id)
        }
        setHasRequests(hasRequestResponse?.exist ?? false)
        setPublishedStoriesCount(publishedStoriesCountResponse?.count ?? 0)
      } catch (e) {
        history.push('/500')
      } finally {
        setLoading(false)
      }
    }

    getPublic()
  }, [type, id])

  if (isLoading) {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress size={60} />
      </div>
    )
  }

  const chptrs =
    storyChapters && storyChapters?.length > 0
      ? storyChapters?.map(chapter => {
          return `
                        ${chapter.begin} --> ${chapter.end}
                        ${chapter.title}
                        `
        })
      : []

  const vttContent = `WEBVTT

                    ${chptrs.join('\n\n')}
                    `

  const vttBlob = new Blob([vttContent], { type: 'text/vtt' })
  const vttURL = URL.createObjectURL(vttBlob)
  const chapters = { kind: 'chapters', src: vttURL }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
        background: collectionTheme?.backgroundColor,
        margin: 0
      }}
    >
      <Box
        sx={{
          backgroundColor: collectionTheme?.backgroundColor,
          width: '100%',
          position: 'relative'
        }}
      >
        <Box>
          <AppBar position={'static'} sx={{ backgroundColor: collectionTheme?.storyTitleBGColor }}>
            <Toolbar>
              <Box
                sx={{
                  display: 'flex',
                  width: '100%'
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Box
                    sx={{
                      marginRight: 'auto'
                    }}
                  >
                    <Link to="/a/my-library">
                      {logoUrl ? (
                        <Box
                          sx={{
                            height: 57,
                            width: 192.5,
                            backgroundImage: `url(${logoUrl})`,
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat'
                          }}
                        />
                      ) : (
                        <Box
                          component="img"
                          sx={{
                            width: 135,
                            height: 40,
                            alignItems: 'center',
                            pb: '1px'
                          }}
                          src={'/images/logo-withe.svg'}
                          alt="logo-white"
                        />
                      )}
                    </Link>
                  </Box>
                </Box>
                <Box
                  sx={{
                    flexShrink: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center'
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: collectionTheme?.collectionTitleColor,
                      fontFamily: collectionTheme?.collectionTitleFontFamily,
                      fontSize: {
                        sm:
                          collectionTheme?.collectionTitleFontSize &&
                          collectionTheme?.collectionTitleFontSize.length > 0
                            ? collectionTheme?.collectionTitleFontSize
                            : '20px'
                      }
                    }}
                  >
                    {title}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Typography variant="h6" sx={{ marginLeft: 'auto' }}>
                    {''}
                  </Typography>
                </Box>
              </Box>
            </Toolbar>
          </AppBar>
        </Box>
        {play ? (
          <Box position={'relative'}>
            <Box
              sx={{
                position: 'absolute',
                top: { xs: '20%', md: '55%' },
                left: '4%',
                zIndex: 1,
                width: '100%'
              }}
              className={fade}
            >
              <Typography
                sx={{
                  fontWeight: 700,
                  width: { xs: '60%', md: '35%' },
                  fontSize: { xs: '20px', md: '32px' },
                  marginBottom: 1,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: { xs: 2, md: 3 },
                  WebkitBoxOrient: 'vertical'
                }}
                variant="h3"
              >
                {currentStory?.title}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 700,
                  width: { xs: '60%', md: '35%' },
                  marginBottom: '6px',
                  fontSize: { xs: '15px', md: '20px' },
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: { xs: 2, md: 3 },
                  WebkitBoxOrient: 'vertical'
                }}
                variant="h3"
              >
                {currentStory?.description}
              </Typography>
              <Box
                className={fade}
                sx={{
                  width: { xs: '60%', md: '35%' }
                }}
              >
                <AuthorAvatar
                  prefix={'By'}
                  fullName={`${currentStory?.owner?.firstName} ${currentStory?.owner?.lastName}`}
                  thumbnail={currentStory?.owner?.picture?.url}
                />
                {currentStory?.createdAt && (
                  <Typography variant="caption">{`on ${format(
                    parseJSON(currentStory?.createdAt),
                    'PPPp'
                  )}`}</Typography>
                )}
              </Box>
            </Box>
            <Box sx={{ position: 'relative', height: '100%' }}>
              <Player
                subtitles={story!.subtitles || []}
                type={story!.type}
                media={story!.video || story!.audio}
                cover={story!.thumbnailUrl}
                playlist={playlist}
                onLoadedMetaData={setItem}
                onPressPlay={onPressPlay}
                onPause={onPause}
                chapters={chapters}
              />
            </Box>
          </Box>
        ) : type === 'stories' ? (
          <Cover
            author={story!.owner}
            src={story!.thumbnailUrl}
            title={story!.title}
            description={story!.description}
            onClick={() => setPlay(true)}
            buttonLabel="View video"
            date={story!.createdAt}
            hidePlayButton={publishedStoriesCount === 0}
            theme={collectionTheme}
          />
        ) : (
          collection && (
            <Cover
              author={collection!.owner}
              src={collection!.thumbnailUrl}
              title={collection!.title}
              description={collection!.description}
              onClick={() => setPlay(true)}
              buttonLabel="View stories"
              date={collection!.createdAt}
              hidePlayButton={publishedStoriesCount === 0}
              theme={collectionTheme}
            />
          )
        )}
        <Box
          sx={{
            width: '100%',
            padding: '0 24px',
            height: 65,
            display: 'flex',
            alignItems: 'center',
            backgroundColor: collectionTheme?.storyCountBGColor ?? 'background.paper',
            boxShadow: 6
          }}
        >
          {publishedStoriesCount > 0 ? (
            currentStory ? (
              <Typography
                variant="h6"
                sx={{
                  width: '100%',
                  color: collectionTheme?.currentStoryTitleColor,
                  fontFamily: collectionTheme?.currentStoryTitleFontFamily,
                  fontSize: {
                    sm:
                      collectionTheme?.currentStoryTitleFontSize &&
                      collectionTheme?.currentStoryTitleFontSize.length > 0
                        ? collectionTheme?.currentStoryTitleFontSize
                        : '20px'
                  }
                }}
              >
                Story {currentStoryCount()} of {publishedStoriesCount} | {currentStory.title}
              </Typography>
            ) : (
              <Typography
                variant="h6"
                sx={{
                  width: '100%',
                  color: collectionTheme?.currentStoryTitleColor,
                  fontFamily: collectionTheme?.currentStoryTitleFontFamily,
                  fontSize: {
                    sm:
                      collectionTheme?.currentStoryTitleFontSize &&
                      collectionTheme?.currentStoryTitleFontSize.length > 0
                        ? collectionTheme?.currentStoryTitleFontSize
                        : '20px'
                  }
                }}
              >
                Story {currentStoryCount()} of {publishedStoriesCount} | {story?.title}
              </Typography>
            )
          ) : (
            <Typography
              variant="h6"
              sx={{
                width: '100%',
                color: collectionTheme?.currentStoryTitleColor,
                fontFamily: collectionTheme?.currentStoryTitleFontFamily,
                fontSize: {
                  sm:
                    collectionTheme?.currentStoryTitleFontSize && collectionTheme?.currentStoryTitleFontSize.length > 0
                      ? collectionTheme?.currentStoryTitleFontSize
                      : '20px'
                }
              }}
            >
              This collection currently has no published stories
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            position: 'relative',
            width: '98%',
            left: '1%',
            height: 65,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginY: 2,
            paddingX: 3,
            backgroundColor: collectionTheme?.barBackgroundColor
          }}
          component={Paper}
        >
          {hasRequests && (
            <Button
              onClick={handleAddYourVoice}
              variant="outlined"
              sx={{
                color: collectionTheme?.barButtonsTextColor,
                borderColor: collectionTheme?.barButtonsTextColor,
                ':hover': {
                  borderColor: collectionTheme?.barButtonsTextColor,
                  backgroundColor: collectionTheme?.barButtonsHoverColor
                }
              }}
            >
              <Typography
                variant="body1"
                fontSize="1.1rem"
                textAlign="center"
                padding="0.2rem"
                sx={{
                  fontFamily: collectionTheme?.barButtonsFontFamily,
                  fontSize: {
                    sm:
                      collectionTheme?.buttonOnBarFontSize && collectionTheme?.buttonOnBarFontSize.length > 0
                        ? collectionTheme?.buttonOnBarFontSize
                        : '18px'
                  }
                }}
              >
                Add your voice
              </Typography>
            </Button>
          )}
          <Button
            onClick={handleViewMyLibrary}
            variant="outlined"
            sx={{
              color: collectionTheme?.barButtonsTextColor,
              borderColor: collectionTheme?.barButtonsTextColor,
              ':hover': {
                borderColor: collectionTheme?.barButtonsTextColor,
                backgroundColor: collectionTheme?.barButtonsHoverColor
              }
            }}
          >
            <Typography
              variant="body1"
              fontSize="1.1rem"
              textAlign="center"
              padding="0.2rem"
              sx={{
                fontFamily: collectionTheme?.barButtonsFontFamily,
                fontSize: {
                  sm:
                    collectionTheme?.buttonOnBarFontSize && collectionTheme?.buttonOnBarFontSize.length > 0
                      ? collectionTheme?.buttonOnBarFontSize
                      : '18px'
                }
              }}
            >
              View My Library
            </Typography>
          </Button>
        </Box>
        <Box sx={{ backgroundColor: collectionTheme?.backgroundColor }}>
          <Typography
            variant="h5"
            textAlign="center"
            sx={{ color: collectionTheme?.textColor, fontFamily: collectionTheme?.bodyFontFamily }}
          >
            Photos
          </Typography>
          <PhotosPublic
            list={stories}
            textColor={collectionTheme?.textColor}
            backgroundColor={collectionTheme?.backgroundColor}
            collectionTheme={collectionTheme}
          />
        </Box>
      </Box>
    </div>
  )
}

export default Public
