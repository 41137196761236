import React, { useContext, useState } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import api from '../../../../lib/api'
import { SimpleSnackbarContext } from '../../../../components/SimpleSnackbar'
import { UserContext } from '../../../../components/UserProvider'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Visibility from '@mui/icons-material/Visibility'
import { FormikContextType } from 'formik'
import axios from 'axios'

const Step1: React.FC<{ infoFormik: FormikContextType<any>; passwordFormik: FormikContextType<any> }> = ({
  infoFormik,
  passwordFormik
}) => {
  const [code, setCode] = useState<string>('')
  const [error, setError] = useState<string>('')

  const { actions: snackActions } = useContext(SimpleSnackbarContext)
  const { user } = useContext(UserContext)

  const [showPassword, setShowPassword] = useState<{ password: boolean; newPassword: boolean; confirmation: boolean }>({
    password: false,
    newPassword: false,
    confirmation: false
  })

  const handleClickShowPassword = (field: 'password' | 'newPassword' | 'confirmation') => {
    setShowPassword({
      ...showPassword,
      [field]: !showPassword[field]
    })
  }

  const validate = async () => {
    try {
      await api.addInviteCode(code)
      snackActions.open('This code is valid!')
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // @ts-ignore
        const { code, message } = error.response.data
        if (code === '1034') {
          setError('You already input a code')
          return
        }
        if (code === '1035') {
          setError('This code is invalid')
          return
        }
        if (code) {
          setError(message)
          return
        }
      }
      setError('Internal server error.')
    }
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ width: { sm: '70%' } }}>
        <Typography variant="h6">
          Thank you for signing up for Rakonto! We’ll get you up and running in no time.
        </Typography>
        <Typography variant="h6" mb={6}>
          To get started, enter your name and create a password.
        </Typography>
        <TextField
          autoFocus
          name="firstName"
          fullWidth
          placeholder="First name"
          label="First name"
          type="text"
          value={infoFormik.values.firstName}
          onChange={infoFormik.handleChange}
          onBlur={infoFormik.handleBlur}
          error={infoFormik.touched.firstName && Boolean(infoFormik.errors.firstName)}
          helperText={(infoFormik.touched.firstName && infoFormik.errors.firstName) || ' '}
        />
        <TextField
          name="lastName"
          fullWidth
          placeholder="Last name"
          label="Last name"
          type="text"
          value={infoFormik.values.lastName}
          onChange={infoFormik.handleChange}
          onBlur={infoFormik.handleBlur}
          error={infoFormik.touched.lastName && Boolean(infoFormik.errors.lastName)}
          helperText={(infoFormik.touched.lastName && infoFormik.errors.lastName) || ' '}
        />
        <TextField
          name="newPassword"
          fullWidth
          placeholder="New password"
          label="New password"
          type={showPassword.newPassword ? 'text' : 'password'}
          value={passwordFormik.values.newPassword}
          onChange={passwordFormik.handleChange}
          onBlur={passwordFormik.handleBlur}
          error={passwordFormik.touched.newPassword && Boolean(passwordFormik.errors.newPassword)}
          helperText={(passwordFormik.touched.newPassword && passwordFormik.errors.newPassword) || ' '}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => handleClickShowPassword('newPassword')} edge="end">
                  {showPassword.newPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <TextField
          name="confirmation"
          fullWidth
          placeholder="Re-type your new password"
          label="Re-type your new password"
          type={showPassword.confirmation ? 'text' : 'password'}
          value={passwordFormik.values.confirmation}
          onChange={passwordFormik.handleChange}
          onBlur={passwordFormik.handleBlur}
          error={passwordFormik.touched.confirmation && Boolean(passwordFormik.errors.confirmation)}
          helperText={(passwordFormik.touched.confirmation && passwordFormik.errors.confirmation) || ' '}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => handleClickShowPassword('confirmation')} edge="end">
                  {showPassword.confirmation ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        {!user?.referredBy && (
          <Box sx={{ mt: 0, display: 'none' }}>
            <Typography variant="h6" mb={3}>
              If you received a referral code from another Rakonto user or affiliate, please enter the code here
            </Typography>
            <TextField
              name="code"
              placeholder="Type the code"
              fullWidth
              margin="dense"
              variant="outlined"
              value={code}
              onChange={e => setCode(e.target.value)}
              error={!!error}
              helperText={error || ' '}
            />
            <Button variant="outlined" onClick={() => validate()} disabled={!code}>
              Validate
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default Step1
