import React from 'react'
import { InviteType } from '../../../../../lib/types'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import FilesPublic from '../../../../a/Story/FilesPublic'
import PhotosPublic from '../../../../a/Story/PhotosPublic'

const StepFiles: React.FC<{ invite: InviteType; storyId: string; token: string }> = ({ invite, storyId, token }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" mb={2}>
          Want to add any photos or files to your story?
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {invite?.requestImages && <PhotosPublic storyId={storyId} isEditor={true} token={token} />}
      </Grid>
      <Grid item xs={12}>
        {invite?.requestFiles && <FilesPublic storyId={storyId} isEditor={true} token={token} />}
      </Grid>
    </Grid>
  )
}

export default StepFiles
