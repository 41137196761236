import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import React from 'react'
import Player from '../Player'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import { CollectionThemeProps } from '../../lib/types'

interface RemoveFile {
  file: File
  onRemove: () => void
  onSubscriptionClicked?: () => void
  quotaError: boolean
  collectionTheme?: CollectionThemeProps
}

const RemoveFile: React.FC<RemoveFile> = ({ file, onRemove, quotaError, onSubscriptionClicked, collectionTheme }) => {
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexFlow: 'column',
        width: '100%'
      }}
    >
      {quotaError && (
        <Typography color="error" mb="unset" fontWeight="700" align="center" variant="subtitle1" gutterBottom>
          You have exceeded your total library capacity.{' '}
          <Link
            to="/a/profile?tab=subscription"
            component={RouterLink}
            onClick={() => {
              if (onSubscriptionClicked) {
                onSubscriptionClicked()
              }
            }}
          >
            Upgrade my plan.
          </Link>
        </Typography>
      )}
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          paddingLeft: '4px'
        }}
      >
        <Typography mb="unset" fontWeight="700" align="center" variant="subtitle1" gutterBottom>
          {file.name}
        </Typography>
        <Box flex={1} />
        <Box>
          <Button onClick={onRemove} sx={{ color: collectionTheme?.questionnaireLinksColor }}>
            Re-record
          </Button>
        </Box>
      </Box>
      <Player
        maxHeight="380px"
        borderRadius="20px"
        subtitles={[]}
        type={'VIDEO'}
        media={{ id: 'local', url: URL.createObjectURL(file) }}
      />
    </Box>
  )
}

export default RemoveFile
