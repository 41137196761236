import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import React from 'react'
import { LanguageEnum, QuestionnaireTemplateType, QuizInviteType } from '../../lib/types'

interface iQuizItem {
  quiz: QuestionnaireTemplateType | QuizInviteType
}

const QuizItem: React.FC<iQuizItem> = ({ quiz, children }) => {
  const formatLanguage = (language: string): string | undefined => {
    // @ts-ignore
    return LanguageEnum[language]
  }
  return (
    <Box
      sx={{
        minHeight: '120px',
        width: '100%',
        padding: 2,
        bgcolor: '#313131',
        borderRadius: '20px',
        marginBottom: 3,
        display: 'flex',
        alignItems: 'center',
        flexFlow: { xs: 'column', md: 'row' }
      }}
    >
      <Box
        sx={{
          flex: { xs: 'unset', md: '1' },
          width: { xs: '100%', md: 'unset' },
          paddingX: '8px'
        }}
      >
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <Typography>{quiz.title}</Typography>
          <Typography component="div" variant="caption" color="secondary">
            {quiz.description}
          </Typography>
          <Typography variant="overline">Questions: {quiz.questions.length}</Typography>
          <Typography variant="overline" ml={3}>
            {(quiz as QuestionnaireTemplateType).language &&
              `Language: ${
                formatLanguage((quiz as QuestionnaireTemplateType).language) ??
                (quiz as QuestionnaireTemplateType).language
              }`}
          </Typography>
        </Box>
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <Typography component="div">{quiz.title}</Typography>
          <Typography component="div" variant="caption" color="secondary">
            {quiz.description}
          </Typography>
          <Typography component="div" variant="caption">
            Questions : {quiz.questions.length}
          </Typography>
          <Typography component="div" variant="caption">
            {(quiz as QuestionnaireTemplateType).language &&
              `Language: ${(quiz as QuestionnaireTemplateType).language}`}
          </Typography>
        </Box>
      </Box>
      <Box>{children}</Box>
    </Box>
  )
}

export default QuizItem
