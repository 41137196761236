import React, { useContext, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import { SearchResultType, TeamMemberRoleEnum, TeamMemberType } from '../../../lib/types'
import Typography from '@mui/material/Typography'
import api from '../../../lib/api'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import TeamMemberItem from '../../../components/TeamMemberItem'
import DeleteIcon from '@mui/icons-material/Delete'
import { FormDialogContext } from '../../../components/FormDialog'
import { SimpleDialogContext } from '../../../components/SimpleDialog'
import { SimpleSnackbarContext } from '../../../components/SimpleSnackbar'
import { emailSchema } from './schemas'
import MenuItem from '@mui/material/MenuItem'
import SwitchRole from '../../../components/SwitchRole'
import useInfiniteScroll from '../../../components/hooks/useInfiniteScrool'
import { usePageableRequest } from '../../../components/hooks/usePageableRequest'
import { UserContext } from '../../../components/UserProvider'
import { useHistory } from 'react-router-dom'
import { SimpleMultiSelectDialogContext } from '../../../components/SimpleMultiSelectDialog'

const TeamMembers: React.FC = () => {
  const { actions: formDialogActions } = useContext(FormDialogContext)
  const { actions: simpleDialogActions } = useContext(SimpleDialogContext)
  const { actions: snackActions } = useContext(SimpleSnackbarContext)
  const { actions: simpleMultiSelectDialogActions } = useContext(SimpleMultiSelectDialogContext)
  const [searchValue, setSearchValue] = useState<string>('')
  const { user, member } = useContext(UserContext)
  const history = useHistory()

  const [collections, setCollections] = useState<SearchResultType[]>([])

  const { loading, items, hasNextPage, error, loadMore, setItems, reload } = usePageableRequest<TeamMemberType>({
    size: 15,
    q: '',
    request: api.getTeamMembers
  })

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: !!error,
    rootMargin: '0px 0px 400px 0px'
  })

  const teamMemberRoleDescription: Record<TeamMemberRoleEnum, string> = {
    [TeamMemberRoleEnum.ADMINISTRATOR]:
      'Full administrative access to the library and settings including organization, billing, etc.',
    [TeamMemberRoleEnum.MANAGER]:
      'Can create / edit / delete collections, stories, requests and questionnaires; can add / edit / remove users',
    [TeamMemberRoleEnum.CREATOR]:
      'Can create collections, stories, requests and questionnaires, and edit /delete items that they created (cannot edit / delete those created by others)',
    [TeamMemberRoleEnum.EDITOR]: 'Can edit collections, stories, requests and questionnaires assigned to them',
    [TeamMemberRoleEnum.VIEWER]: 'Can view collections / stories assigned to them'
  }

  const getEnumDescription = (en: TeamMemberRoleEnum) => {
    return teamMemberRoleDescription[en]
  }

  const addTeamMember = () => {
    formDialogActions.open(
      'Add Team Member',
      'Enter the email address of the person you would like to add to your team.',
      [
        {
          label: 'Email',
          name: 'email',
          placeholder: 'Type email',
          type: 'text'
        },
        {
          label: 'Role',
          name: 'role',
          placeholder: 'Select the role for the team member',
          type: 'select',
          options: Object.keys(TeamMemberRoleEnum).map((key, index) => (
            <MenuItem key={index} value={key}>
              {key.charAt(0) + key.slice(1).toLowerCase()}
            </MenuItem>
          ))
        }
      ],
      { email: '', role: 'VIEWER' },
      emailSchema,
      async ({ email, role }: { email: string; role: TeamMemberRoleEnum }) => {
        try {
          const newTeamMember = await api.addTeamMember({ email: email, role: role })
          setItems([...items, newTeamMember])
          snackActions.open('User successfully added to your team!')
        } catch (error) {
          snackActions.open('This user cannot be added to your team.')
        }
      },
      { okText: 'Add user', cancelText: `cancel` },
      getEnumDescription,
      'Permissions:'
    )
  }

  const removeTeamMember = async (id: string) => {
    simpleDialogActions.open(
      'Remove Team Member',
      "Are you sure? This team member will be removed and can't access your library.",
      { okText: 'Yes, remove', showOk: true, cancelText: 'cancel' },
      async success => {
        if (success) {
          try {
            await api.deleteTeamMember(id)
            setItems(items.filter(item => item.id !== id))
            snackActions.open('Team member removed with success.')
          } catch (error) {
            snackActions.open('Error when trying to remove the team member.')
          }
        }
      }
    )
  }

  const transformCollections = () => {
    return collections.map(collection => ({
      id: collection.id,
      name: collection.title
    }))
  }
  const handleAssignCollection = (selectedMember: TeamMemberType) => {
    const transformedCollections = transformCollections()
    const assignedCollections = transformedCollections
      .filter(collection => selectedMember?.permissions.map(p => p.collectionId).includes(collection.id))
      .map(c => c.id)
    if (!transformedCollections || transformedCollections.length === 0) {
      snackActions.open('Oops! It seems there are no team members you can invite right now.')
      return
    }
    simpleMultiSelectDialogActions.open(
      async (ids: string[] | null) => {
        if (ids === null) ids = []
        try {
          await api.assignCollectionsToTeamMember(selectedMember.id, ids)
          reload()
          snackActions.open(`Assigned collections successfully!`)
        } catch {
          snackActions.open('Ops! Something went wrong assigning collections, please try again.')
        }
      },
      'Invite Reviewers',
      `Below is a list of your available collections you can select.`,
      transformedCollections,
      'Assign',
      assignedCollections
    )
  }

  const fetchCollections = async () => {
    try {
      const { content } = await api.searchCollections(0, 100)
      setCollections(content)
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    fetchCollections()
  }, [])

  useEffect(() => {
    const isTeamManager =
      user?.tenant || member?.role === TeamMemberRoleEnum.ADMINISTRATOR || member?.role === TeamMemberRoleEnum.MANAGER
    if (!isTeamManager) {
      history.push('a/my-library')
    }
  }, [])

  return (
    <Box sx={{ width: '100%', minHeight: '100%', bgcolor: 'background.paper', padding: 2 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography sx={{ mr: 2 }} variant="h6">
            Team Members
          </Typography>
          <Button onClick={addTeamMember} startIcon={<GroupAddIcon />} size="large" variant="outlined">
            Add team member
          </Button>
        </Grid>
        <Grid item xs={12} marginBottom={2}>
          <Typography color="secondary">
            Grant others access to the library. Add team members and select one of the following roles:
            <br />
            <Box
              marginTop={1}
              sx={{
                backgroundColor: '#202020',
                padding: '16px',
                fontSize: '12px',
                borderRadius: '4px'
              }}
            >
              <li>Administrator - {teamMemberRoleDescription[TeamMemberRoleEnum.ADMINISTRATOR]}</li>
              <li>Manager - {teamMemberRoleDescription[TeamMemberRoleEnum.MANAGER]}</li>
              <li>Creator - {teamMemberRoleDescription[TeamMemberRoleEnum.CREATOR]}</li>
              <li>Editor - {teamMemberRoleDescription[TeamMemberRoleEnum.EDITOR]}</li>
              <li>Viewer - {teamMemberRoleDescription[TeamMemberRoleEnum.VIEWER]}</li>
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={12} marginBottom={2}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <TextField
              key="search"
              name="search"
              fullWidth
              sx={{
                maxWidth: '422px'
              }}
              autoComplete="off"
              placeholder="Search for a team member"
              margin="dense"
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            {items
              .filter(member => member.email?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
              .map(member => (
                <TeamMemberItem key={member.id} member={member}>
                  <Box sx={{ display: 'flex', gap: '16px' }}>
                    <SwitchRole id={member.id} email={member.email} currentRole={member.role} setMembers={setItems} />
                    <IconButton onClick={() => removeTeamMember(member.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                  {(member.role === TeamMemberRoleEnum.VIEWER || member.role === TeamMemberRoleEnum.EDITOR) && (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button variant={'outlined'} onClick={() => handleAssignCollection(member)}>
                        Assign Collections
                      </Button>
                    </Box>
                  )}
                </TeamMemberItem>
              ))}
            {hasNextPage && (
              <Grid item xs>
                <div ref={sentryRef} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default TeamMembers
