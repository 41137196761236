import { StoryType } from '../../../../../lib/types'

export const galleryEntries = [
  {
    id: '1df00c8a-fd9b-4142-b843-b53a5b9e145a',
    image: {
      id: '90cf1a13-d1ab-47e4-b209-a16d4028604d',
      url: 'https://app.rakonto.io/media/rakonto/users/ef7c9c6e-f89f-430b-9ed4-187631866247/images/6782899d-710f-41bc-a9d1-00bbe2c9e0dd.jpg',
      thumbnailUrl:
        'https://app.rakonto.io/media/rakonto/users/ef7c9c6e-f89f-430b-9ed4-187631866247/images/bdd1404c-dd10-4a3a-bac4-1417fce0276c.jpg',
      originalName: 'freeimage-15928199-web.jpg'
    }
  },
  {
    id: '24628d4e-0240-4bff-a68f-69daf1b7f750',
    image: {
      id: 'b7bc7474-9c1e-476a-9807-55e271de2c91',
      url: 'https://app.rakonto.io/media/rakonto/users/ef7c9c6e-f89f-430b-9ed4-187631866247/images/ca1fb72a-d361-4f3d-967c-abd878fc36bb.jpg',
      thumbnailUrl:
        'https://app.rakonto.io/media/rakonto/users/ef7c9c6e-f89f-430b-9ed4-187631866247/images/95351602-160f-421a-9c8c-9ef3e8c8ee97.jpg',
      originalName: 'freeimage-14768900-web.jpg'
    }
  },
  {
    id: 'bb25d14a-d3fb-46cd-a90c-88b314125e24',
    image: {
      id: 'a8e26724-9c7d-4d90-85d5-7713929ffde4',
      url: 'https://app.rakonto.io/media/rakonto/users/ef7c9c6e-f89f-430b-9ed4-187631866247/images/647ed2ac-1330-4c78-9458-6a072db02324.jpg',
      thumbnailUrl:
        'https://app.rakonto.io/media/rakonto/users/ef7c9c6e-f89f-430b-9ed4-187631866247/images/c56fef59-4368-443f-8c74-f1a07c4bc109.jpg',
      originalName: 'freeimage-2988094-web.jpg'
    }
  }
]
