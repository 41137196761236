import React, { useContext } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import { useTheme } from '@mui/material/styles'
import { VideoBookmarkDialogContext } from './Context'
import { StoryType } from '../../lib/types'
import BookmarkPlayer from './BookmarkPlayer'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const VideoBookmarkDialog: React.FC<{ handleClose: () => void }> = ({ handleClose }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xl'))
  const { store } = useContext(VideoBookmarkDialogContext)

  const exit = () => {
    handleClose()
  }

  const chapters = store.chapters
    ? store.chapters.map(chapter => {
        return `
        ${chapter.begin} --> ${chapter.end}
        ${chapter.title}
      `
      })
    : []

  const vttContent = `WEBVTT

      ${chapters.join('\n\n')}
    `

  const vttBlob = new Blob([vttContent], { type: 'text/vtt' })

  const vttURL = URL.createObjectURL(vttBlob)
  const cptrs = { kind: 'chapters', src: vttURL }

  const { type, video, audio, thumbnailUrl, subtitles } = store.story as StoryType

  return (
    <Dialog fullScreen={fullScreen} open={true} fullWidth maxWidth="xl">
      <Box sx={{ display: 'flex', flexDirection: 'column', padding: '12px', gap: '24px' }}>
        <Typography sx={{ fontSize: '24px' }}>Video bookmarks</Typography>
        <BookmarkPlayer
          subtitles={subtitles || []}
          type={type}
          media={video || audio}
          cover={thumbnailUrl}
          chapters={cptrs}
        />
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <Button color="secondary" onClick={exit}>
            Close
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

export default VideoBookmarkDialog
