import React, { useContext, useEffect, useState } from 'react'
import { parse } from 'qs'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import api from '../../../lib/api'
import { CollectionTheme, InviteType, QuizInviteType } from '../../../lib/types'
import CircularLoadingCentred from '../../../components/CircularLoadingCentred'
import { SimpleSnackbarContext } from '../../../components/SimpleSnackbar'
import { GuestLayoutContext } from '../GuestLayout'
import Invite from './Invite'
import Questionnaire from './Questionnaire'

const CollectionInvite: React.FC = () => {
  const location = useLocation()
  const { id } = useParams<{ id: string }>()
  const [token, setToken] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [jobTitle, setJobTitle] = useState<string>('')
  const [functionalArea, setFunctionalArea] = useState<string>('')
  const [invite, setInvite] = useState<InviteType | null>(null)
  const [quizInvite, setQuizInvite] = useState<QuizInviteType | null>(null)
  const [loading, setLoading] = useState(true)
  const { actions: snackActions } = useContext(SimpleSnackbarContext)
  const { setLogo, setIsloading: setIsLoadingHeader, setHeaderBG } = useContext(GuestLayoutContext)
  const history = useHistory()
  const [theme, setTheme] = useState<CollectionTheme | null>(null)
  const fetch = async () => {
    try {
      const inviteResult = await api.getInviteSubmission(id, token)
      if (inviteResult!.questions.length) {
        const questionnaire = await api.getQuestionnarie(inviteResult.questions[0].questionnaireTemplateId, token)
        setQuizInvite(questionnaire)
      } else if (inviteResult!.questionnaireId) {
        const questionnaire = await api.getQuestionnarie(inviteResult!.questionnaireId, token)
        setQuizInvite(questionnaire)
      }
      const collectionResponse = await api.getEmbedCollection(inviteResult!.collectionId)
      if (collectionResponse.theme) {
        collectionResponse.theme.properties?.questionnaireHeaderBackgroundColor &&
          setHeaderBG(collectionResponse.theme.properties?.questionnaireHeaderBackgroundColor)
        setTheme(collectionResponse.theme)
      }
      if (inviteResult.organization) setLogo(inviteResult.organization.logo.url)
      setInvite(inviteResult)
      setIsLoadingHeader(false)
      setLoading(false)
    } catch (e) {
      snackActions.open('This link was expired.')
      history.push('u/signin')
    }
  }

  useEffect(() => {
    if (token) {
      fetch()
    }
  }, [token])

  useEffect(() => {
    const { token, email, name } = parse(location?.search as string, {
      ignoreQueryPrefix: true
    })
    setToken(token as string)
    setEmail(email as string)
    setName(name as string)
  }, [location])

  if (loading) return <CircularLoadingCentred />

  if (invite!.questions.length || quizInvite?.questionnaireId || quizInvite?.questions.length) {
    return (
      <Questionnaire
        invite={invite!}
        token={token}
        id={id}
        recorderName={name}
        recorderEmail={email}
        recorderJobTitle={jobTitle}
        recorderFunctionalArea={functionalArea}
        quiz={quizInvite!}
        collectionTheme={theme?.properties}
      />
    )
  }

  return <Invite invite={invite!} token={token} id={id} />
}

export default CollectionInvite
