import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import React from 'react'
import { QuizInviteQuestionType, QuizQuestionType } from '../../lib/types'

interface iQuizQuestionItem {
  question: QuizInviteQuestionType | QuizQuestionType
}

const QuizQuestionItem: React.FC<iQuizQuestionItem> = ({ question, children }) => {
  return (
    <Box
      sx={{
        minHeight: '120px',
        width: '100%',
        padding: 2,
        bgcolor: '#313131',
        borderRadius: '20px',
        marginBottom: 3,
        display: 'flex',
        alignItems: 'center',
        flexFlow: { xs: 'column', md: 'row' }
      }}
    >
      <Box
        sx={{
          flex: { xs: 'unset', md: '1' },
          width: { xs: '100%', md: 'unset' },
          paddingX: '8px'
        }}
      >
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <Typography>
            {question.number} - {question.title}
          </Typography>
        </Box>
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <Typography component="div">
            {question.number} - {question.title}
          </Typography>
        </Box>
      </Box>
      <Box>{children}</Box>
    </Box>
  )
}

export default QuizQuestionItem
