import React, { useContext, useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import { useFormik } from 'formik'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import schema from './schema'
import { CollectionFormType, CollectionTheme, ImageType, StoryUpdateType } from '../../../../lib/types'
import collectionTypes from '../../../../lib/collectionTypes.json'
import MenuItem from '@mui/material/MenuItem'
import ListSubheader from '@mui/material/ListSubheader'
import ListItemText from '@mui/material/ListItemText'
import api from '../../../../lib/api'
import { UserContext } from '../../../../components/UserProvider'

interface iContent {
  title: string
  type?: string
  id: string
  description: string
  canEdit: boolean
  update: (formData: StoryUpdateType | CollectionFormType) => void
  onChange?: (image: ImageType) => void
  isPlaying?: boolean
  editMode: boolean
  setEditMode: (editMode: boolean) => void
  themeId?: string
}

const Content: React.FC<iContent> = ({
  update,
  title,
  id,
  description,
  type,
  canEdit,
  children,
  isPlaying,
  editMode,
  setEditMode,
  themeId
}) => {
  const initialValues = { title, description, type, themeId }
  const [themes, setThemes] = useState<CollectionTheme[]>([])
  const { user } = useContext(UserContext)
  const isNotEnterprise = user?.tier < 4
  useEffect(() => {
    const getThemes = async () => {
      try {
        const response = await api.getCollectionThemes()
        setThemes(response)
      } catch (e) {
        console.log(e)
      }
    }
    getThemes()
  }, [])

  const onSubmit = async (values: {
    title: string
    description: string
    type?: string
    themeId?: string
    theme?: CollectionTheme | null
  }) => {
    if (!isNotEnterprise && themes.find(t => t.id === values.themeId)) {
      values.theme = themes.find(t => t.id === values.themeId)
    } else {
      values.theme = undefined
    }
    delete values.themeId

    try {
      await update({ ...values })
      setEditMode(false)
    } catch (error) {}
  }

  const { isSubmitting, values, handleBlur, handleChange, touched, errors, handleSubmit } = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit
  })

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexFlow: 'column',
        '&>*': {
          margin: '12px 0'
        }
      }}
    >
      <Box
        component={Paper}
        sx={{
          width: '100%',
          padding: 3,
          display: isPlaying || editMode ? 'static' : 'none'
        }}
      >
        {!editMode && (
          <>
            <Box
              sx={{
                width: '100%',
                display: 'flex'
              }}
            >
              <Typography
                sx={{
                  flex: '1'
                }}
                variant="h4"
                gutterBottom
              >
                {title}
              </Typography>
            </Box>
            {description && (
              <Typography
                variant="h5"
                paragraph
                sx={{
                  flex: '1',
                  opacity: !isPlaying ? '0' : '1'
                }}
              >
                {'    ' + description}
              </Typography>
            )}
          </>
        )}
        {editMode && (
          <form>
            <Grid container spacing={4} direction="row" justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                <TextField
                  name="type"
                  fullWidth
                  label="Type"
                  select
                  value={values.type}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.type && Boolean(errors.type)}
                  helperText={(touched.type && errors.type) || ' '}
                >
                  <ListSubheader>{collectionTypes[0].name}</ListSubheader>
                  {collectionTypes[0].options.map(option => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                  <ListSubheader>{collectionTypes[1].name}</ListSubheader>
                  {collectionTypes[1].options.map(option => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  name="title"
                  fullWidth
                  label="Title"
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.title && Boolean(errors.title)}
                  helperText={(touched.title && errors.title) || ' '}
                />
                <TextField
                  name="description"
                  fullWidth
                  multiline
                  rows={4}
                  label="Description"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.description && Boolean(errors.description)}
                  helperText={(touched.description && errors.description) || ' '}
                />
                {!isNotEnterprise && (
                  <TextField
                    name="themeId"
                    fullWidth
                    select
                    margin="dense"
                    variant="outlined"
                    placeholder="Select a theme"
                    label="Select a theme"
                    value={values.themeId}
                    onChange={handleChange}
                  >
                    <MenuItem value={''}>
                      <ListItemText primary={'None'} />
                    </MenuItem>
                    {themes.map(option => (
                      <MenuItem key={option.id} value={option.id}>
                        <ListItemText primary={option?.properties?.themeName} />
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </Grid>
              <Grid
                sx={{
                  textAlign: 'end'
                }}
                item
                xs={12}
              >
                <Button onClick={() => setEditMode(false)}>Cancel</Button>
                <Button color={'primary'} disabled={isSubmitting} variant="contained" onClick={() => handleSubmit()}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Box>
      {children}
    </Box>
  )
}

export default Content
