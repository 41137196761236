import React, { createContext, useState } from 'react'
import Component from './Component'

export const RequestContentDialogContext = createContext<{
  actions: {
    open: (storyId: string) => void
    close: () => void
  }
  store: {
    storyId: string
  }
}>({
  // @ts-ignore
  actions: {},
  storyId: ''
})

export const RequestContentDialogProvider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [storyId, setStoryId] = useState<string>('')

  const open = (id: string) => {
    setStoryId(id)
    setIsOpen(true)
  }

  const close = () => {
    setIsOpen(false)
  }

  return (
    <RequestContentDialogContext.Provider
      value={{
        actions: {
          open,
          close
        },
        store: {
          storyId
        }
      }}
    >
      {isOpen && <Component />}
      {children}
    </RequestContentDialogContext.Provider>
  )
}
