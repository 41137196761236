import React, { useRef } from 'react'
import Box from '@mui/material/Box'
import Header from './Header'
import { StepStoryUploadProvider } from '../../../components/StepStoryUpload'
import { StepInviteRecorderProvider } from '../../../components/StepInviteRecorder'
import { StepInviteContributorProvider } from '../../../components/StepInviteContributor'
import { GreetingsDialogProvider } from '../../../components/GreetingsDialog'
import { QueueProcessorProvider } from '../../../components/QueueProcessor'
import { CreateCollectionProvider } from '../../../components/CreateCollection'
import { SocketConnectorProvider } from '../../../components/SocketConnector'
import { ChangeMediaProvider } from '../../../components/ChangeMedia'
import { SortControlProvider } from '../../../components/SortControlProvider'
import { UserProvider } from '../../../components/UserProvider'
import { AddReferralCodeDialogProvider } from '../../../components/AddReferralCodeDialog'
import { CreateQuestionnaireProvider } from '../../../components/CreateQuestionnaire'
import { CreateEventProvider } from '../../../components/CreateEvent'
import { RequestContentDialogProvider } from '../../../components/RequestContentDialog'
import { SwitchLibraryDialogProvider } from '../../../components/SwitchLibraryDialog'
import { UpdateQuestionnairesDialogProvider } from '../../../components/UpdateQuestionnaireDialog'
import { SimpleMultiSelectDialogProvider } from '../../../components/SimpleMultiSelectDialog'

const AuthenticatedLayout: React.FC = ({ children }) => {
  const headerRef = useRef<HTMLDivElement>(null)

  return (
    <>
      <UserProvider>
        <SortControlProvider>
          <SocketConnectorProvider>
            <QueueProcessorProvider>
              <CreateEventProvider>
                <CreateCollectionProvider>
                  <StepStoryUploadProvider>
                    <StepInviteContributorProvider>
                      <StepInviteRecorderProvider>
                        <ChangeMediaProvider>
                          <GreetingsDialogProvider>
                            <AddReferralCodeDialogProvider>
                              <CreateQuestionnaireProvider>
                                <RequestContentDialogProvider>
                                  <SwitchLibraryDialogProvider>
                                    <UpdateQuestionnairesDialogProvider>
                                      <SimpleMultiSelectDialogProvider>
                                        <Header ref={headerRef} />
                                        <Box
                                          sx={{
                                            width: '100%',
                                            height: `calc(100% - ${headerRef?.current?.clientHeight || 0}px)`
                                          }}
                                        >
                                          {children}
                                        </Box>
                                      </SimpleMultiSelectDialogProvider>
                                    </UpdateQuestionnairesDialogProvider>
                                  </SwitchLibraryDialogProvider>
                                </RequestContentDialogProvider>
                              </CreateQuestionnaireProvider>
                            </AddReferralCodeDialogProvider>
                          </GreetingsDialogProvider>
                        </ChangeMediaProvider>
                      </StepInviteRecorderProvider>
                    </StepInviteContributorProvider>
                  </StepStoryUploadProvider>
                </CreateCollectionProvider>
              </CreateEventProvider>
            </QueueProcessorProvider>
          </SocketConnectorProvider>
        </SortControlProvider>
      </UserProvider>
    </>
  )
}

export default AuthenticatedLayout
