import React, { useContext, useEffect, useRef, useState } from 'react'
import { RouteComponentProps, useLocation } from 'react-router-dom'
import api from '../../../lib/api'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import { CollectionThemeProps, CollectionType, OrganizationType, StoryType } from '../../../lib/types'
import Player from '../../../components/Player'
import Cover from '../../../components/Cover'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Cookies from 'js-cookie'
import AuthorAvatar from '../../../components/AuthorAvatar'
import { format, parseJSON } from 'date-fns'
import { SignupDialogContext } from '../../../components/SignupDialog'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import { SimpleDialogContext } from '../../../components/SimpleDialog'
import { PublicRequestsDialogContext } from '../../../components/PublicRequestsDialog'
import { parse } from 'qs'
import { SimpleSnackbarContext } from '../../../components/SimpleSnackbar'
import PhotosPublic from '../Public/PhotosPublic'
import { Link } from '@mui/material'

const Theme: React.FC<RouteComponentProps<{ type: string; id: string }>> = ({ match, history }) => {
  const { type, id } = match.params
  const [story, setStory] = useState<StoryType | null>(null)
  const [playlist, setPlaylist] = useState<any[]>([])
  const [isLoading, setLoading] = useState<boolean>(true)
  const [play, setPlay] = useState<boolean>(false)
  const [collection, setCollection] = useState<CollectionType | null>(null)
  const [title, setTitle] = useState<string>('')
  const [item, setItem] = useState<any>({})
  const [organization, setOrganization] = useState<OrganizationType | null>(null)
  const [fade, setFade] = useState<string>('fade-in')
  const [totalStories, setTotalStories] = useState(1)
  const [requireEmail, setRequireEmail] = useState(false)

  const cookiesToken = Cookies.get('token')
  const { actions, store } = useContext(SignupDialogContext)
  const { actions: dialogActions } = useContext(SimpleDialogContext)
  const { actions: publicRequestsDialogActions } = useContext(PublicRequestsDialogContext)
  const { actions: snackActions } = useContext(SimpleSnackbarContext)
  const location = useLocation()

  const [token, setToken] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [validated, setValidated] = useState(false)

  const themeStyles: any = {
    mainBackgroundColor: '#EEEBE6',
    playerBackgroundColor: '#E7F2F2',
    barBackgroundColor: 'white',
    photosBackgroundColor: '#EEEBE6',
    buttonColor: '#2EC3BC',
    textColor: '#4A3C31',
    fontFamily: 'Mrs Eaves, Georgia, Times, Times New Roman, serif',
    logoUrl: '/images/mortuary-logo.svg'
  }

  const validateToken = async () => {
    try {
      const inviteResult = await api.validateToken(email, token)
      setValidated(inviteResult.valid)
    } catch (e) {
      snackActions.open('This link was expired.')
    }
  }

  useEffect(() => {
    if (token && email) {
      validateToken().then()
    }
  }, [token, email])

  useEffect(() => {
    const { email, token } = parse(location?.search as string, {
      ignoreQueryPrefix: true
    })
    setToken(token as string)
    setEmail(email as string)
  }, [location])

  const descriptionFadeTimeOut = useRef<NodeJS.Timeout>()

  const currentStory =
    type === 'stories' ? story : collection?.stories.filter(story => story.title === item.metatitle)[0]
  const currentStoryCount = () => {
    if (currentStory && collection?.stories.indexOf(currentStory)) {
      return collection?.stories.indexOf(currentStory) + 1
    }
    return 1
  }

  let stories: StoryType[] | any = type === 'stories' ? [story] : collection?.stories

  const onPressPlay = () => {
    descriptionFadeTimeOut.current = setTimeout(() => {
      setFade('fade-out')
    }, 5000)
  }

  const onPause = () => {
    if (descriptionFadeTimeOut.current) {
      clearTimeout(descriptionFadeTimeOut.current)
    }

    descriptionFadeTimeOut.current = setTimeout(() => {
      setFade('fade-in')
    }, 200)
  }

  const handleViewMyLibrary = () => {
    if (cookiesToken) {
      window.open('/a/my-library', '_blank', 'noreferrer')
      return
    }
    if (store.isNewAccount) {
      window.open('/u/signin', '_blank', 'noreferrer')
      dialogActions.open('Confirm email', 'We sent an email to you to confirm your account. Please check this.', {
        cancelText: 'Close'
      })
    }
    window.open('/u/signin', '_blank', 'noreferrer')
  }

  const handleAddYourVoice = () => {
    themeStyles ? publicRequestsDialogActions.openWithStyles(themeStyles) : publicRequestsDialogActions.open()
  }

  useEffect(() => {
    setFade('fade-in')
  }, [item])

  useEffect(() => {
    if (!cookiesToken && !store.signedUp && requireEmail && !validated) {
      themeStyles ? actions.openWithStyles(themeStyles) : actions.open()
    }
  }, [requireEmail])

  useEffect(() => {
    const getPublic = async () => {
      try {
        setLoading(true)
        const response = type === 'stories' ? await api.getEmbedStory(id) : await api.getEmbedCollection(id)

        if (type === 'stories') {
          const story = response as StoryType
          stories = [story]
          setStory(story)
          setTitle(story.title)
          actions.setStoryAsType()
          publicRequestsDialogActions.setCollectionId(story.collections[0].id)
          setRequireEmail(story.requiredEmail)
        }

        if (type === 'collections') {
          const collection = response as CollectionType

          if (collection?.stories) {
            const playlist = collection?.stories?.map(story => ({
              sources: [
                {
                  src: story.url,
                  type: story.mimeType
                }
              ],
              thumb: story.thumbnailUrl,
              title: `${story.title}`,
              duration: story.duration,
              tracks:
                story.subtitles?.map(subtitle => ({
                  kind: 'captions',
                  src: subtitle.url,
                  srclang: subtitle.language,
                  label: subtitle.language,
                  default: '1'
                })) || []
            }))
            setPlaylist(playlist)
            setStory(collection.stories[0])
          }
          setTotalStories(collection.stories.length)
          setCollection(collection)
          setTitle(collection.title)
          actions.setCollectionAsType()
          actions.setIsCollectionPrivate(!collection.publicAcl)
          actions.setCollectionId(collection.id)
          setRequireEmail(collection.requiredEmail)
          publicRequestsDialogActions.setCollectionId(collection.id)
        }
      } catch (e) {
        history.push('/500')
      } finally {
        setLoading(false)
      }
    }
    // const response = api.validateIsWatcher({ email: 'guidouglas1@gmail.com' }, '2a6f3125-81b7-4be4-a7ac-ea8669493679')
    // console.log(response)

    getPublic()
  }, [type, id])

  if (isLoading) {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress size={60} />
      </div>
    )
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
        backgroundColor: themeStyles.mainBackgroundColor
      }}
    >
      <Box
        sx={{
          margin: 'auto',
          width: '100%',
          maxHeight: '720px',
          position: 'relative'
        }}
      >
        <Box sx={{ backgroundColor: themeStyles.mainBackgroundColor }}>
          <AppBar position={'static'}>
            <Toolbar sx={{ backgroundColor: themeStyles.playerBackgroundColor }}>
              <Box
                sx={{
                  display: 'flex',
                  width: '100%'
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Box
                    sx={{
                      marginRight: 'auto'
                    }}
                  >
                    <Link href="https://www.dignitymemorial.com/" target="_blank">
                      {organization?.logo ? (
                        <Box
                          sx={{
                            mr: 2,
                            maxHeight: '57px',
                            height: '100vh',
                            width: '192.5px',
                            justifySelf: 'left',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundImage: organization?.logo?.url ? `url(${organization?.logo.url})` : 'none',
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat'
                          }}
                        />
                      ) : (
                        <Box
                          component="img"
                          sx={{
                            width: 135,
                            height: 40,
                            alignItems: 'center',
                            pb: '1px'
                          }}
                          src={themeStyles.logoUrl ?? '/images/logo-withe.svg'}
                          alt="logo-white"
                        />
                      )}
                    </Link>
                  </Box>
                </Box>
                <Box
                  sx={{
                    flexShrink: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center'
                  }}
                >
                  <Typography variant="h6" sx={{ color: themeStyles.textColor, fontFamily: themeStyles.fontFamily }}>
                    {title}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Typography variant="h6" sx={{ marginLeft: 'auto' }}>
                    {''}
                  </Typography>
                </Box>
              </Box>
            </Toolbar>
          </AppBar>
        </Box>
        {play ? (
          <Box position={'relative'}>
            <Box
              sx={{
                position: 'absolute',
                top: { xs: '10%', md: '55%' },
                left: '4%',
                zIndex: 1,
                width: '100%'
              }}
              className={fade}
            >
              <Typography
                sx={{
                  fontWeight: 700,
                  width: { xs: '60%', md: '35%' },
                  fontSize: { xs: '20px', md: '32px' },
                  marginBottom: 1,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: { xs: 2, md: 3 },
                  WebkitBoxOrient: 'vertical'
                }}
                variant="h3"
              >
                {currentStory?.title}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 700,
                  width: { xs: '60%', md: '35%' },
                  marginBottom: '6px',
                  fontSize: { xs: '15px', md: '20px' },
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: { xs: 2, md: 3 },
                  WebkitBoxOrient: 'vertical'
                }}
                variant="h3"
              >
                {currentStory?.description}
              </Typography>
              <Box
                className={fade}
                sx={{
                  width: { xs: '60%', md: '35%' }
                }}
              >
                <AuthorAvatar
                  prefix={'By'}
                  fullName={`${currentStory?.owner?.firstName} ${currentStory?.owner?.lastName}`}
                  thumbnail={currentStory?.owner?.picture?.url}
                />
                {currentStory?.createdAt && (
                  <Typography variant="caption">{`on ${format(
                    parseJSON(currentStory?.createdAt),
                    'PPPp'
                  )}`}</Typography>
                )}
              </Box>
            </Box>
            <Box sx={{ position: 'relative', height: '100%' }}>
              <Player
                subtitles={story!.subtitles || []}
                type={story!.type}
                media={story!.video || story!.audio}
                cover={story!.thumbnailUrl}
                playlist={playlist}
                onLoadedMetaData={setItem}
                onPressPlay={onPressPlay}
                onPause={onPause}
              />
            </Box>
          </Box>
        ) : type === 'stories' ? (
          <Cover
            author={story!.owner}
            src={story!.thumbnailUrl}
            title={story!.title}
            description={story!.description}
            onClick={() => setPlay(true)}
            buttonLabel="View video"
            date={story!.createdAt}
          />
        ) : (
          collection && (
            <Cover
              author={collection!.owner}
              src={collection!.thumbnailUrl}
              title={collection!.title}
              description={collection!.description}
              onClick={() => setPlay(true)}
              buttonLabel="View stories"
              date={collection!.createdAt}
            />
          )
        )}
        <Box
          sx={{
            width: '100%',
            padding: '0 24px',
            height: 65,
            display: 'flex',
            alignItems: 'center',
            backgroundColor: themeStyles.playerBackgroundColor,
            boxShadow: 6
          }}
        >
          {currentStory ? (
            <Typography
              variant="h6"
              sx={{ width: '100%', fontFamily: themeStyles.fontFamily, color: themeStyles.textColor }}
            >
              Story {currentStoryCount()} of {totalStories} | {currentStory.title}
            </Typography>
          ) : (
            <Typography
              variant="h6"
              sx={{ width: '100%', fontFamily: themeStyles.fontFamily, color: themeStyles.textColor }}
            >
              Story {currentStoryCount()} of {totalStories} | {story?.title}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            position: 'relative',
            width: '98%',
            left: '1%',
            height: 65,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginY: 2,
            paddingX: 3,
            backgroundColor: themeStyles.barBackgroundColor
          }}
          component={Paper}
        >
          <Button onClick={handleAddYourVoice} variant="outlined" sx={{ borderColor: themeStyles.buttonColor }}>
            <Typography
              variant="body1"
              fontSize="1.1rem"
              textAlign="center"
              padding="0.2rem"
              sx={{ color: themeStyles.buttonColor }}
            >
              Add your voice
            </Typography>
          </Button>
          <Button onClick={handleViewMyLibrary} variant="outlined" sx={{ borderColor: themeStyles.buttonColor }}>
            <Typography
              variant="body1"
              fontSize="1.1rem"
              textAlign="center"
              padding="0.2rem"
              sx={{ color: themeStyles.buttonColor }}
            >
              View My Library
            </Typography>
          </Button>
        </Box>
        <Box
          width="100%"
          height="100%"
          position="relative"
          top="2em"
          sx={{ backgroundColor: themeStyles.photosBackgroundColor }}
        >
          <Typography variant="h5" textAlign="center" sx={{ color: themeStyles.textColor }}>
            Photos
          </Typography>
          <PhotosPublic list={stories} textColor={themeStyles.textColor} />
        </Box>
      </Box>
    </div>
  )
}

export default Theme
