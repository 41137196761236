import React, { useContext, useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { FormikProvider, FormikValues, useFormik } from 'formik'
import { schema } from './schema'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { SimpleSnackbarContext } from '../SimpleSnackbar'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { InviteType, QuestionVideoTypeEnum, QuizInviteQuestionType, titleByType } from '../../lib/types'
import api from '../../lib/api'
import LoadingButton from '@mui/lab/LoadingButton'
import CircularProgress from '@mui/material/CircularProgress'
import InputFileArea from '../InputFileArea'
import FormHelperText from '@mui/material/FormHelperText'

type initialValuesType = {
  file: File | null
}

const StepInviteRecorderChangeMedia: React.FC<{
  invite: InviteType
  question?: QuizInviteQuestionType
  onClose: (invite?: InviteType | null) => void
  type?: QuestionVideoTypeEnum
}> = ({ invite, question, onClose, type = QuestionVideoTypeEnum.MEDIA }) => {
  const { actions: snackActions } = useContext(SimpleSnackbarContext)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [progress, setProgress] = useState<number>(0)
  const [loading, setLoading] = useState(false)
  const [edited, setEdited] = useState(false)

  const initialValues: initialValuesType = {
    file: null
  }

  const onSubmit = async (values: FormikValues) => {
    if (type === QuestionVideoTypeEnum.MEDIA) {
      try {
        const newInvite = await api.changeInviteMedia(invite.id, values.file, event => {
          setProgress(Math.round((event.loaded * 100) / event.total))
        })
        onClose(newInvite)
        snackActions.open('Invite media added/changed.')
      } catch (error) {
        // @ts-ignore
        const { data, message } = error.response
        if (data?.code) {
          snackActions.open(message)
          throw error
        }
        snackActions.open('Something was wrong! please try again.')
        throw error
      }
    } else {
      try {
        await api.changeIntroOutroMedia(question!.id, type, values.file, event => {
          setProgress(Math.round((event.loaded * 100) / event.total))
        })
        onClose(null)
        snackActions.open(`Question ${type === QuestionVideoTypeEnum.INTRO ? 'intro' : 'outro'} media added/changed.`)
      } catch (e) {
        console.log(e)
        snackActions.open('Something went wrong! please try again.')
      }
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    validateOnBlur: true,
    onSubmit
  })

  const handleDeleteMedia = async () => {
    setLoading(true)
    if (!invite.id || !invite.video.id) {
      snackActions.open('Something was wrong! please try again.')
      return
    }
    try {
      await api.deleteInviteMedia(invite.id, invite.video.id)
      snackActions.open('Invite media deleted.')
      setEdited(true)
    } catch (error) {
      // @ts-ignore
      const { data, message } = error.response
      if (data?.code) {
        snackActions.open(message)
        throw error
      }
      snackActions.open('Something was wrong! please try again.')
      throw error
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    formik.handleReset(initialValues)
  }, [])

  return (
    <form>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth="md"
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            edited ? onClose(invite) : onClose()
          }
        }}
        open
      >
        <DialogTitle>
          {titleByType[type]}
          <IconButton
            aria-label="close"
            onClick={() => (edited ? onClose(invite) : onClose())}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ width: '100%', marginY: 3, minHeight: 328 }}>
            <FormikProvider value={formik}>
              {!progress ? (
                <div>
                  <InputFileArea
                    file={formik.values!.file}
                    callback={file => formik.setFieldValue('file', file)}
                    disableChangeMediaType={true}
                    startType="VIDEO"
                  />
                  {formik.errors.file && <FormHelperText error>{formik.errors.file}</FormHelperText>}
                </div>
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 'inherit' }}>
                  <CircularProgress size={100} variant="determinate" value={progress || 100} />
                </Box>
              )}
            </FormikProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          {invite?.video?.id && !edited && type === QuestionVideoTypeEnum.MEDIA && (
            <LoadingButton loading={loading} variant="outlined" onClick={handleDeleteMedia} sx={{ mt: 1, mr: 1 }}>
              Delete Media
            </LoadingButton>
          )}
          <Button variant="outlined" onClick={() => (edited ? onClose(invite) : onClose())} sx={{ mt: 1, mr: 1 }}>
            Close
          </Button>
          <LoadingButton
            variant="contained"
            loading={formik.isSubmitting}
            disabled={!formik.isValid}
            onClick={() => formik.handleSubmit()}
            sx={{ mt: 1, mr: 1 }}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </form>
  )
}

export default StepInviteRecorderChangeMedia
