import React, { useContext } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { SignupDialogContext } from './Context'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import PublicSignup from './PublicSignup'
import { CollectionThemeProps } from '../../lib/types'

const SignupDialog: React.FC<{ collectionTheme: CollectionThemeProps; logoUrl: string }> = ({
  collectionTheme,
  logoUrl
}) => {
  const { actions } = useContext(SignupDialogContext)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <form>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth="md"
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            actions.close()
          }
        }}
        open
      >
        <DialogContent dividers sx={{ backgroundColor: collectionTheme.signupBackgroundColor }}>
          <Box paddingBottom={5} textAlign="center" sx={{ display: 'flex', justifyContent: 'center' }}>
            {logoUrl ? (
              <Box
                sx={{
                  height: 90,
                  width: 230,
                  backgroundImage: `url(${logoUrl})`,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat'
                }}
              />
            ) : (
              <img width={211} height={62} src={'/images/logo2.svg'} alt="rakonto" />
            )}
          </Box>
          <PublicSignup collectionTheme={collectionTheme} />
        </DialogContent>
      </Dialog>
    </form>
  )
}

export default SignupDialog
