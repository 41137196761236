import request from './request'
import {
  addChapter,
  addInviteCode,
  addPersonToStory,
  addPlaceToStory,
  addQuestionnaireTemplate,
  addQuestionnaireTemplateQuestion,
  addRakontoAdmin,
  addTeamMember,
  addWatcher,
  applyGraphics,
  applyGraphicsCollection,
  restoreMedia,
  restoreMediaCollection,
  recreateThumbs,
  assignCollectionsToTeamMember,
  bulkEditQuestionnaire,
  cancelProductSubscription,
  changeIntroOutroMedia,
  changeInviteMedia,
  changeInviteToCollection,
  changeReferralCode,
  changeRequiredEmailFlag,
  changeStoryMedia,
  changeStoryToCollection,
  checkProcessing,
  cloneInvite,
  closeAccount,
  confirmEmail,
  countStoriesPublished,
  createCollection,
  createCollectionTheme,
  createComment,
  createContributorInvite,
  createEvent,
  createGallery,
  createGalleryPublic,
  createInvite,
  createLink,
  createOrganization,
  createPerson,
  createPlace,
  createQuestionnarie,
  createStory,
  createSubtitleFromStory,
  createSubtitleFromTranscript,
  createTimeline,
  createTranscriptions,
  deleteChapter,
  deleteCollection,
  deleteCollectionTheme,
  deleteComment,
  deleteFile,
  deleteGallery,
  deleteGalleryPublic,
  deleteFilePublic,
  deleteInvite,
  deleteInviteContributor,
  deleteInviteMedia,
  deleteLink,
  deleteOrganization,
  deletePlace,
  deleteQuestionnaireTemplate,
  deleteQuestionnaireTemplateQuestion,
  deleteQuestionnarie,
  deleteRakontoAdmin,
  deleteStory,
  deleteSubtitle,
  deleteTeamMember,
  deleteTimeline,
  deleteTranscriptions,
  doReview,
  downloadInvitesSubmissions,
  downloadQuestionnaireCompleteReport,
  downloadQuestionnaireStats,
  downloadTranscription,
  duplicateInviteContributor,
  duplicateQuestionnaireTemplate,
  editComment,
  editInviteContributor,
  editPlace,
  editVideo,
  editVideoTest,
  sendStoryToDam,
  sendCollectionToDam,
  exportQuestionnaireTemplateDocx,
  finishOnboarding,
  generateInterviewTranscription,
  getChapters,
  getCollection,
  getCollectionBySharedLink,
  getCollections,
  getCollectionsByThemeId,
  getCollectionThemes,
  getComments,
  getContributorInviteSubmission,
  getEmbedCollection,
  getEmbedStory,
  getEmbedStoryInvitesByCollectionId,
  getFile,
  getFiles,
  getGallery,
  getGalleryPublic,
  getGalleryItem,
  getGalleryItemPublic,
  getImage,
  uploadSpeedTest,
  getFilePublic,
  getFilesPublic,
  getImagePublic,
  getInviteContributorByStoryId,
  getInvites,
  getInviteSubmission,
  getJobTitleAndFunctionalAreaByUserEmail,
  finishQuestionnaire,
  getLink,
  getLinks,
  getMe,
  getOrganization,
  getOrganizations,
  getPersons,
  getPlaces,
  getProductSubscriptions,
  getQuestionnaireQuestion,
  getQuestionnaireStats,
  getQuestionnaireTemplate,
  getQuestionnaireTemplateQuestions,
  getQuestionnaireTemplates,
  getQuestionnarie,
  getQuestionnaries,
  getRakontoAdmin,
  getRakontoAdmins,
  getSharedLink,
  getShortId,
  getStorageQuota,
  getStories,
  getStory,
  getSubtitleByStoryId,
  getSubtitles,
  getSubtitlesByVideoId,
  getTeamMember,
  getTeamMembers,
  getTemplates,
  getTimeline,
  getTimelines,
  getTranscriptions,
  getWatchers,
  inviteQuestionnaireReviewers,
  isPublished,
  isStoryPublished,
  moveStoryToCollection,
  notifyWatcher,
  optimizeStorage,
  organizationAddMembers,
  organizationDeleteMembers,
  passwordChange,
  passwordReset,
  publish,
  recordLater,
  removePersonFromStory,
  removePlaceFromStory,
  removeWatcher,
  requestConfirmEmail,
  requestPasswordReset,
  search,
  searchCollections,
  searchLocation,
  searchStories,
  sendContributorInviteSubmission,
  sendInviteContributorEmails,
  sendInviteEmails,
  sendInviteSubmission,
  sendQuestionEmail,
  sendQuestionnaireCompletionConfirmationEmail,
  sendQuestionnaireReminder,
  sendQuestionnaireTranscriptionToDam,
  sendQuestionSkipp,
  sendQuestionSubmission,
  shareCollectionInviteByCsv,
  signin,
  signinFacebook,
  signinGoogle,
  signinLinkedin,
  signinMicrosoft,
  singout,
  singup,
  statistics,
  storyReprocessTranscript,
  switchAccount,
  switchLibrary,
  toogleOptimizeStorage,
  transcodeAgain,
  transcriptAgain,
  updateCollection,
  updateCollectionCover,
  updateCollectionTheme,
  updateInvite,
  updateMe,
  updateMeCover,
  updateOrganization,
  updatePerson,
  updateQuestionnaire,
  updateQuestionnaireQuestion,
  updateQuestionnaireTemplate,
  updateQuestionnaireTemplateQuestion,
  updateStory,
  updateStoryCover,
  updateStoryRecorder,
  updateStoryStatus,
  updateTeamMember,
  updateTranscriptions,
  uploadFile,
  uploadImage,
  uploadFilePublic,
  uploadImagePublic,
  uploadQuestionnaireTemplateCsv,
  uploadSubtitle,
  validateIsWatcher,
  validateToken,
  verifyCollectionInvite,
  verifyStorageUsage,
  cloneStoriesToCollection,
  saveConsentQuestionnaire
} from './services'

export default {
  getEmbedStory: getEmbedStory(request),
  getEmbedCollection: getEmbedCollection(request),
  getEmbedStoryInvitesByCollectionId: getEmbedStoryInvitesByCollectionId(request),
  verifyStorageUsage: verifyStorageUsage(request),
  singup: singup(request),
  singout: singout(request),
  switchAccount: switchAccount(request),
  requestPasswordReset: requestPasswordReset(request),
  getMe: getMe(request),
  getProductSubscriptions: getProductSubscriptions(request),
  cancelProductSubscription: cancelProductSubscription(request),
  updateMe: updateMe(request),
  addInviteCode: addInviteCode(request),
  changeReferralCode: changeReferralCode(request),
  closeAccount: closeAccount(request),
  updateMeCover: updateMeCover(request),
  passwordReset: passwordReset(request),
  passwordChange: passwordChange(request),
  signin: signin(request),
  signinGoogle: signinGoogle(request),
  signinFacebook: signinFacebook(request),
  signinLinkedin: signinLinkedin(request),
  signinMicrosoft: signinMicrosoft(request),
  confirmEmail: confirmEmail(request),
  requestConfirmEmail: requestConfirmEmail(request),
  getStories: getStories(request),
  deleteStory: deleteStory(request),
  storyReprocessTranscript: storyReprocessTranscript(request),
  getStory: getStory(request),
  createStory: createStory(request),
  changeStoryMedia: changeStoryMedia(request),
  updateStory: updateStory(request),
  updateStoryRecorder: updateStoryRecorder(request),
  updateStoryStatus: updateStoryStatus(request),
  moveStoryToCollection: moveStoryToCollection(request),
  changeStoryToCollection: changeStoryToCollection(request),
  changeInviteToCollection: changeInviteToCollection(request),
  isStoryPublished: isStoryPublished(request),
  updateStoryCover: updateStoryCover(request),
  publish: publish(request),
  isPublished: isPublished(request),
  uploadImage: uploadImage(request),
  getImage: getImage(request),
  uploadImagePublic: uploadImagePublic(request),
  getImagePublic: getImagePublic(request),
  getCollections: getCollections(request),
  getCollection: getCollection(request),
  updateCollection: updateCollection(request),
  updateCollectionCover: updateCollectionCover(request),
  createCollection: createCollection(request),
  deleteCollection: deleteCollection(request),
  getPersons: getPersons(request),
  updatePerson: updatePerson(request),
  createPerson: createPerson(request),
  addPersonToStory: addPersonToStory(request),
  addPlaceToStory: addPlaceToStory(request),
  removePersonFromStory: removePersonFromStory(request),
  removePlaceFromStory: removePlaceFromStory(request),
  uploadFile: uploadFile(request),
  deleteFile: deleteFile(request),
  getFiles: getFiles(request),
  getFile: getFile(request),
  uploadFilePublic: uploadFilePublic(request),
  deleteFilePublic: deleteFilePublic(request),
  uploadSpeedTest: uploadSpeedTest(request),
  getFilesPublic: getFilesPublic(request),
  getFilePublic: getFilePublic(request),
  getLinks: getLinks(request),
  getLink: getLink(request),
  createLink: createLink(request),
  deleteLink: deleteLink(request),
  getTranscriptions: getTranscriptions(request),
  downloadTranscription: downloadTranscription(request),
  deleteTranscriptions: deleteTranscriptions(request),
  updateTranscriptions: updateTranscriptions(request),
  createTranscriptions: createTranscriptions(request),
  getGallery: getGallery(request),
  getGalleryPublic: getGalleryPublic(request),
  getGalleryItem: getGalleryItem(request),
  createGallery: createGallery(request),
  createGalleryPublic: createGalleryPublic(request),
  deleteGallery: deleteGallery(request),
  deleteGalleryPublic: deleteGalleryPublic(request),
  getTimelines: getTimelines(request),
  getTimeline: getTimeline(request),
  createTimeline: createTimeline(request),
  deleteTimeline: deleteTimeline(request),
  getPlaces: getPlaces(request),
  createPlace: createPlace(request),
  editPlace: editPlace(request),
  deletePlace: deletePlace(request),
  searchLocation: searchLocation(request),
  getSubtitles: getSubtitles(request),
  getSubtitleByStoryId: getSubtitleByStoryId(request),
  uploadSubtitle: uploadSubtitle(request),
  deleteSubtitle: deleteSubtitle(request),
  createSubtitleFromTranscript: createSubtitleFromTranscript(request),
  getSubtitlesByVideoId: getSubtitlesByVideoId(request),
  getWatchers: getWatchers(request),
  addWatcher: addWatcher(request),
  removeWatcher: removeWatcher(request),
  notifyWatcher: notifyWatcher(request),
  deleteComment: deleteComment(request),
  createComment: createComment(request),
  editComment: editComment(request),
  search: search(request),
  searchStories: searchStories(request),
  searchCollections: searchCollections(request),
  getComments: getComments(request),
  createInvite: createInvite(request),
  updateInvite: updateInvite(request),
  deleteInvite: deleteInvite(request),
  cloneInvite: cloneInvite(request),
  changeInviteMedia: changeInviteMedia(request),
  deleteInviteMedia: deleteInviteMedia(request),
  getInvites: getInvites(request),
  downloadInvitesSubmissions: downloadInvitesSubmissions(request),
  sendInviteEmails: sendInviteEmails(request),
  sendQuestionnaireCompletionConfirmationEmail: sendQuestionnaireCompletionConfirmationEmail(request),
  sendQuestionEmail: sendQuestionEmail(request),
  getInviteSubmission: getInviteSubmission(request),
  sendInviteSubmission: sendInviteSubmission(request),
  sendQuestionSubmission: sendQuestionSubmission(request),
  sendQuestionSkipp: sendQuestionSkipp(request),
  createContributorInvite: createContributorInvite(request),
  sendInviteContributorEmails: sendInviteContributorEmails(request),
  getContributorInviteSubmission: getContributorInviteSubmission(request),
  sendContributorInviteSubmission: sendContributorInviteSubmission(request),
  getShortId: getShortId(request),
  getStorageQuota: getStorageQuota(request),
  optimizeStorage: optimizeStorage(request),
  toogleOptimizeStorage: toogleOptimizeStorage(request),
  getOrganization: getOrganization(request),
  getOrganizations: getOrganizations(request),
  createOrganization: createOrganization(request),
  updateOrganization: updateOrganization(request),
  organizationAddMembers: organizationAddMembers(request),
  organizationDeleteMembers: organizationDeleteMembers(request),
  deleteOrganization: deleteOrganization(request),
  deleteRakontoAdmin: deleteRakontoAdmin(request),
  getRakontoAdmin: getRakontoAdmin(request),
  getRakontoAdmins: getRakontoAdmins(request),
  addRakontoAdmin: addRakontoAdmin(request),
  statistics: statistics(request),
  finishOnboarding: finishOnboarding(request),
  transcodeAgain: transcodeAgain(request),
  transcriptAgain: transcriptAgain(request),
  addQuestionnaireTemplate: addQuestionnaireTemplate(request),
  duplicateQuestionnaireTemplate: duplicateQuestionnaireTemplate(request),
  deleteQuestionnaireTemplate: deleteQuestionnaireTemplate(request),
  addQuestionnaireTemplateQuestion: addQuestionnaireTemplateQuestion(request),
  getQuestionnaireTemplates: getQuestionnaireTemplates(request),
  getQuestionnaireTemplate: getQuestionnaireTemplate(request),
  updateQuestionnaireTemplate: updateQuestionnaireTemplate(request),
  deleteQuestionnaireTemplateQuestion: deleteQuestionnaireTemplateQuestion(request),
  updateQuestionnaireTemplateQuestion: updateQuestionnaireTemplateQuestion(request),
  getQuestionnaireTemplateQuestions: getQuestionnaireTemplateQuestions(request),
  createQuestionnarie: createQuestionnarie(request),
  deleteQuestionnarie: deleteQuestionnarie(request),
  updateQuestionnaire: updateQuestionnaire(request),
  getQuestionnarie: getQuestionnarie(request),
  getQuestionnaries: getQuestionnaries(request),
  getQuestionnaireStats: getQuestionnaireStats(request),
  downloadQuestionnaireStats: downloadQuestionnaireStats(request),
  downloadQuestionnaireCompleteReport: downloadQuestionnaireCompleteReport(request),
  getQuestionnaireQuestion: getQuestionnaireQuestion(request),
  updateQuestionnaireQuestion: updateQuestionnaireQuestion(request),
  changeRequiredEmailFlag: changeRequiredEmailFlag(request),
  validateToken: validateToken(request),
  validateIsWatcher: validateIsWatcher(request),
  recordLater: recordLater(request),
  getTemplates: getTemplates(request),
  createEvent: createEvent(request),
  verifyCollectionInvite: verifyCollectionInvite(request),
  countStoriesPublished: countStoriesPublished(request),
  createCollectionTheme: createCollectionTheme(request),
  getCollectionThemes: getCollectionThemes(request),
  updateCollectionTheme: updateCollectionTheme(request),
  deleteCollectionTheme: deleteCollectionTheme(request),
  getCollectionsByThemeId: getCollectionsByThemeId(request),
  createSubtitleFromStory: createSubtitleFromStory(request),
  getInviteContributorByStoryId: getInviteContributorByStoryId(request),
  deleteInviteContributor: deleteInviteContributor(request),
  editInviteContributor: editInviteContributor(request),
  duplicateInviteContributor: duplicateInviteContributor(request),
  getCollectionBySharedLink: getCollectionBySharedLink(request),
  getSharedLink: getSharedLink(request),
  getTeamMembers: getTeamMembers(request),
  getTeamMember: getTeamMember(request),
  addTeamMember: addTeamMember(request),
  updateTeamMember: updateTeamMember(request),
  deleteTeamMember: deleteTeamMember(request),
  uploadQuestionnaireTemplateCsv: uploadQuestionnaireTemplateCsv(request),
  exportQuestionnaireTemplateDocx: exportQuestionnaireTemplateDocx(request),
  switchLibrary: switchLibrary(request),
  inviteQuestionnaireReviewers: inviteQuestionnaireReviewers(request),
  doReview: doReview(request),
  bulkEditQuestionnaire: bulkEditQuestionnaire(request),
  shareCollectionInviteByCsv: shareCollectionInviteByCsv(request),
  sendQuestionnaireReminder: sendQuestionnaireReminder(request),
  getJobTitleAndFunctionalAreaByUserEmail: getJobTitleAndFunctionalAreaByUserEmail(request),
  finishQuestionnaire: finishQuestionnaire(request),
  assignCollectionsToTeamMember: assignCollectionsToTeamMember(request),
  editVideo: editVideo(request),
  editVideoTest: editVideoTest(request),
  sendStoryToDam: sendStoryToDam(request),
  sendCollectionToDam: sendCollectionToDam(request),
  changeIntroOutroMedia: changeIntroOutroMedia(request),
  addChapter: addChapter(request),
  deleteChapter: deleteChapter(request),
  getChapters: getChapters(request),
  applyGraphics: applyGraphics(request),
  applyGraphicsCollection: applyGraphicsCollection(request),
  restoreMedia: restoreMedia(request),
  restoreMediaCollection: restoreMediaCollection(request),
  recreateThumbs: recreateThumbs(request),
  checkProcessing: checkProcessing(request),
  generateInterviewTranscription: generateInterviewTranscription(request),
  sendQuestionnaireTranscriptionToDam: sendQuestionnaireTranscriptionToDam(request),
  cloneStoriesToCollection: cloneStoriesToCollection(request),
  saveConsentQuestionnaire: saveConsentQuestionnaire(request)
}
