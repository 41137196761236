import React, { useContext } from 'react'
import { SwitchLibraryDialogContext } from './Context'
import TextField from '@mui/material/TextField'
import { UserContext } from '../UserProvider'
import { Formik, FormikValues } from 'formik'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import { capitalize } from '../Capitalize'

const SwitchLibraryDialog: React.FC = () => {
  const { store, actions } = useContext(SwitchLibraryDialogContext)
  const { user } = useContext(UserContext)

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const submit = async (values: FormikValues) => {
    actions.switchLibrary(values.tenantId)
  }

  return (
    <Formik initialValues={{ tenantId: '' }} enableReinitialize onSubmit={submit}>
      {({ values, handleChange, handleSubmit }) => (
        <Dialog fullScreen={fullScreen} open={store.isOpen} fullWidth maxWidth="sm">
          <DialogTitle>Library Selection</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ paddingBottom: 3 }}>Select the library you want to log in</DialogContentText>
            <TextField
              name="tenantId"
              fullWidth
              select
              margin="dense"
              variant="outlined"
              type="select"
              label="Tenant"
              value={values.tenantId}
              onChange={handleChange}
            >
              {user?.hasOwnLibrary && <MenuItem value={'default'}>My Library</MenuItem>}
              {user?.tenantList.length > 0 &&
                user?.tenantList.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    {capitalize(option.name)}
                  </MenuItem>
                ))}
            </TextField>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={() => actions.close()}>
              Cancel
            </Button>
            <Button variant="outlined" disabled={values.tenantId.length === 0} autoFocus onClick={() => handleSubmit()}>
              Enter library
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  )
}

export default SwitchLibraryDialog
