import { AxiosInstance } from 'axios'
import { stringify } from 'qs'
import {
  addWatcherType,
  AssetTypes,
  AuthType,
  CollectionCreateEventRequest,
  CollectionFormType,
  CollectionRecordLaterResponse,
  CollectionTheme,
  CollectionType,
  CommentFormType,
  CommentType,
  CountStoriesPublishedType,
  EmailRecords,
  FileType,
  GalleryType,
  ImageType,
  InviteContributorInput,
  InviteContributorType,
  InviteInput,
  InviteType,
  LanguageEnum,
  LinkFormType,
  LinkType,
  LocationSearchType,
  Metadata,
  OrganizationInput,
  OrganizationMemberType,
  OrganizationType,
  Pageable,
  PasswordChangeForm,
  PasswordResetForm,
  PersonFormType,
  PersonType,
  PlaceFormType,
  PlaceType,
  ProductSubscriptionType,
  QuestionnaireConsent,
  QuestionnaireInvite,
  QuestionnaireStats,
  QuestionnaireTemplateType,
  QuestionVideoTypeEnum,
  QuizInputType,
  QuizInviteQuestionType,
  QuizInviteType,
  QuizQuestionInputType,
  QuizQuestionType,
  RakontoAdminType,
  SearchResultType,
  ShortIdType,
  SigninFormFacebook,
  SigninFormGoogle,
  SigninFormType,
  SingupFormType,
  StatisticType,
  StoryCreateType,
  StoryRecorderUpdateType,
  StoryStatusType,
  StoryType,
  StoryUpdateType,
  SubtitleType,
  TeamMemberAuthType,
  TeamMemberRoleEnum,
  TeamMemberType,
  TimelineFormType,
  TimelineType,
  TokenResponse,
  TranscriptionFormType,
  TranscriptionType,
  UserFormType,
  UserQuotaType,
  UserType,
  ValidateCollectionEmailRequest,
  VerifyCollectionInviteType,
  Watcher
} from '../types'
import Cookies from 'js-cookie'
import { VideoChapter } from '../../components/VideoBookmarkDialog/BookmarkVideoJs'

class CustomError extends Error {
  data:
    | {
        code: string
        message: string
      }
    | undefined

  constructor(object: { data: { code: string; message: string } }) {
    super(object.data.message)
    this.data = object.data
  }
}

// Check Storage api
export const verifyStorageUsage =
  (request: AxiosInstance) =>
  async (previewQuota: number): Promise<void> => {
    const quota = await request
      .get<{
        available: number
        used: number
        free: number
      }>('a/storage-quota')
      .then(res => res.data)
    if (quota.free < previewQuota) {
      throw new CustomError({
        data: {
          code: '1024',
          message: 'User Storage quota exceeded.'
        }
      })
    }
  }

export const getProductSubscriptions =
  (request: AxiosInstance) => async (): Promise<Array<ProductSubscriptionType>> => {
    return await request.get('a/stripe/product-subscriptions').then(res => res.data)
  }

export const cancelProductSubscription =
  (request: AxiosInstance) =>
  async (id: string): Promise<void> => {
    return await request.delete(`a/stripe/product-subscriptions/${id}`).then(res => res.data)
  }

// User api
export const getMe =
  (request: AxiosInstance) =>
  async (userId?: string): Promise<UserType> => {
    const url = userId ? `a/me?userId=${userId}` : 'a/me'
    return await request.get(url).then(res => res.data)
  }

export const getStorageQuota = (request: AxiosInstance) => async (): Promise<UserQuotaType> => {
  return await request.get('a/storage-quota').then(res => res.data)
}

export const toogleOptimizeStorage =
  (request: AxiosInstance) =>
  async (isToKeep: boolean): Promise<void> => {
    await request.put(`a/account/keep-only-optimized`, { keepOnlyOptimized: isToKeep }).then(res => res.data)
  }

export const optimizeStorage = (request: AxiosInstance) => async (): Promise<void> => {
  await request.post(`a/account/optimize-all`).then(res => res.data)
}

export const updateMe =
  (request: AxiosInstance) =>
  async (data: UserFormType): Promise<UserType> => {
    return await request.put(`a/profile`, data).then(res => res.data)
  }

export const addInviteCode =
  (request: AxiosInstance) =>
  async (code: string): Promise<UserType> => {
    return await request.post(`a/profile/referred-by`, { code }).then(res => res.data)
  }

export const changeReferralCode =
  (request: AxiosInstance) =>
  async (code: string): Promise<UserType> => {
    return await request.post(`a/profile/changeReferral`, { code }).then(res => res.data)
  }

export const statistics = (request: AxiosInstance) => async (): Promise<StatisticType> => {
  return await request.get(`a/statistics`).then(res => res.data)
}

export const closeAccount =
  (request: AxiosInstance) =>
  async (data: UserFormType): Promise<void> => {
    await request.delete(`a/account`, { data }).then(res => res.data)
  }

export const finishOnboarding = (request: AxiosInstance) => async (): Promise<void> => {
  return await request.post(`a/account/finish-onboard`).then(res => res.data)
}

export const updateMeCover =
  (request: AxiosInstance) =>
  async (pictureId: string | null): Promise<UserType> => {
    return await request.post(`a/profile/change-picture`, { pictureId }).then(res => res.data)
  }

export const signin =
  (request: AxiosInstance) =>
  async (data: SigninFormType): Promise<AuthType> => {
    const userInfo = await request.post('u/auth/signin', data).then(res => res.data)
    Cookies.set('token', userInfo.token)
    Cookies.set('backupToken', userInfo.token)
    Cookies.set('user', JSON.stringify(userInfo.user))
    return userInfo
  }

export const singup =
  (request: AxiosInstance) =>
  async (data: SingupFormType): Promise<AuthType> => {
    return await request.post('u/auth/signup', data).then(res => res.data)
  }

export const signinGoogle =
  (request: AxiosInstance) =>
  async (data: SigninFormGoogle): Promise<AuthType> => {
    return await request.post('u/auth/google', data).then(res => res.data)
  }

export const signinFacebook =
  (request: AxiosInstance) =>
  async (data: SigninFormFacebook): Promise<AuthType> => {
    return await request.post('u/auth/facebook', data).then(res => res.data)
  }

export const signinLinkedin =
  (request: AxiosInstance) =>
  async (data: SigninFormFacebook): Promise<AuthType> => {
    return await request.post('u/auth/linkedin', data).then(res => res.data)
  }
export const signinMicrosoft =
  (request: AxiosInstance) =>
  async (data: SigninFormFacebook): Promise<AuthType> => {
    return await request.post('u/auth/ioc', data).then(res => res.data)
  }

export const singout = (request: AxiosInstance) => async (): Promise<void> => {
  await request.post('a/auth/signout').then(res => res.data)
}

export const switchAccount =
  (request: AxiosInstance) =>
  async (email: string): Promise<AuthType> => {
    return await request.post('a/auth/admin-switch', { email }).then(res => res.data)
  }

export const switchLibrary =
  (request: AxiosInstance) =>
  async (userId: string, tenantId: string): Promise<TeamMemberAuthType | void> => {
    const memberInfo = await request
      .post('a/team-members/switch-library', {
        userId: userId,
        tenantId: tenantId
      })
      .then(res => res.data)
    Cookies.set('token', memberInfo.tenantToken)
    return memberInfo
  }

export const confirmEmail =
  (request: AxiosInstance) =>
  async (token: string): Promise<AuthType> => {
    return await request.post(`u/confirmation-email/${token}`).then(res => res.data)
  }

export const requestConfirmEmail =
  (request: AxiosInstance) =>
  async (email: string): Promise<void> => {
    await request.post(`u/confirmation-email`, { email })
  }

export const getShortId =
  (request: AxiosInstance) =>
  async (id: string): Promise<ShortIdType> => {
    return await request.get(`u/short-id/${id}`).then(res => res.data)
  }

export const passwordReset =
  (request: AxiosInstance) =>
  async (data: PasswordResetForm): Promise<AuthType> => {
    const { token, ...rest } = data
    return await request.post(`u/password-reset/${token}`, rest).then(res => res.data)
  }

export const passwordChange =
  (request: AxiosInstance) =>
  async (data: PasswordChangeForm): Promise<AuthType> => {
    return await request.put(`a/password`, data).then(res => res.data)
  }

export const requestPasswordReset =
  (request: AxiosInstance) =>
  async (email: string): Promise<void> => {
    await request.post(`u/password-reset`, { email })
  }

// Story api

export const getStories =
  (request: AxiosInstance) =>
  async (page: number, size: number): Promise<Pageable<StoryType>> => {
    return await request.get(`a/stories?page=${page}&size=${size}`).then(res => res.data)
  }

export const getStory =
  (request: AxiosInstance) =>
  async (id: string): Promise<StoryType> => {
    return await request.get(`a/stories/${id}`).then(res => res.data)
  }

export const storyReprocessTranscript =
  (request: AxiosInstance) =>
  async (id: string): Promise<void> => {
    return await request.post(`a/stories/${id}/transcript`).then(res => res.data)
  }

export const deleteStory =
  (request: AxiosInstance) =>
  async (id: string): Promise<void> => {
    await request.delete(`a/stories/${id}`)
  }

export const createStory =
  (request: AxiosInstance) =>
  async (
    file: File,
    data: StoryCreateType,
    progressCallback: (progress: { total: number; loaded: number }) => void
  ): Promise<StoryType> => {
    await verifyStorageUsage(request)(file.size)
    const formdata = new FormData()
    formdata.append('file', file, file.name)
    formdata.append(
      'story',
      new Blob([JSON.stringify(data)], {
        type: 'application/json'
      })
    )
    return await request
      .post(`a/stories`, formdata, {
        onUploadProgress: e => progressCallback({ total: e.total, loaded: e.loaded })
      })
      .then(res => res.data)
  }

export const changeStoryMedia =
  (request: AxiosInstance) =>
  async (
    id: string,
    file: File,
    progressCallback: (progress: { total: number; loaded: number }) => void
  ): Promise<StoryType> => {
    await verifyStorageUsage(request)(file.size)
    const formdata = new FormData()
    formdata.append('file', file, file.name)
    return await request
      .post(`a/stories/${id}/change-media`, formdata, {
        onUploadProgress: e => progressCallback({ total: e.total, loaded: e.loaded })
      })
      .then(res => res.data)
  }

export const updateStory =
  (request: AxiosInstance) =>
  async (id: string, data: Partial<StoryUpdateType>): Promise<StoryType> => {
    return await request.put(`a/stories/${id}`, data).then(res => res.data)
  }

export const updateStoryRecorder =
  (request: AxiosInstance) =>
  async (id: string, data: Partial<StoryRecorderUpdateType>): Promise<any> => {
    return await request.put(`a/stories/recorder/${id}`, data).then(res => res.data)
  }
//

export const updateStoryStatus =
  (request: AxiosInstance) =>
  async (id: string, publish: boolean): Promise<StoryType> => {
    if (publish) {
      return await request.post(`a/stories/${id}/publish`).then(res => res.data)
    }
    return await request.post(`a/stories/${id}/draft`).then(res => res.data)
  }

export const moveStoryToCollection =
  (request: AxiosInstance) =>
  async (id: string, collectionId: string): Promise<void> => {
    await request.post(`a/stories/${id}/add-collection`, { collectionId }).then(res => res.data)
  }

export const cloneStoriesToCollection =
  (request: AxiosInstance) =>
  async (storyId: string, collectionId: string): Promise<void> => {
    await request
      .get(`a/stories/clone-stories-to-collection?storyId=${storyId}&collectionId=${collectionId}`)
      .then(res => res.data)
  }

export const changeStoryToCollection =
  (request: AxiosInstance) =>
  async (id: string, newCollectionId: string, oldCollectionId: string): Promise<void> => {
    await request.post(`a/stories/${id}/change-collection`, { newCollectionId, oldCollectionId }).then(res => res.data)
  }
export const changeInviteToCollection =
  (request: AxiosInstance) =>
  async (id: string, newCollectionId: string, oldCollectionId: string): Promise<void> => {
    await request
      .post(`a/collection-invites/${id}/change-collection`, { newCollectionId, oldCollectionId })
      .then(res => res.data)
  }

export const isStoryPublished =
  (request: AxiosInstance) =>
  async (id: string): Promise<boolean> => {
    const story = await getStory(request)(id)
    return story.published
  }

export const updateStoryCover =
  (request: AxiosInstance) =>
  async (id: string, coverId: string): Promise<StoryType> => {
    return await request.post(`a/stories/${id}/change-cover`, { coverId }).then(res => res.data)
  }

export const addPersonToStory =
  (request: AxiosInstance) =>
  async (id: string, personId: string): Promise<void> => {
    return await request.post(`a/stories/${id}/add-person`, { personId }).then(res => res.data)
  }

export const removePersonFromStory =
  (request: AxiosInstance) =>
  async (id: string, personId: string): Promise<void> => {
    return await request.post(`a/stories/${id}/remove-person`, { personId }).then(res => res.data)
  }

export const addPlaceToStory =
  (request: AxiosInstance) =>
  async (id: string, placeId: string): Promise<void> => {
    return await request.post(`a/stories/${id}/add-place`, { placeId }).then(res => res.data)
  }

export const removePlaceFromStory =
  (request: AxiosInstance) =>
  async (id: string, placeId: string): Promise<void> => {
    return await request.post(`a/stories/${id}/remove-place`, { placeId }).then(res => res.data)
  }

// File api
export const uploadFile =
  (request: AxiosInstance) =>
  async (
    storyId: string,
    file: File,
    progressCallback?: (progress: { total: number; loaded: number }) => void
  ): Promise<FileType> => {
    await verifyStorageUsage(request)(file.size)
    const data = new FormData()
    data.append('file', file)
    data.append('storyId', storyId)
    return await request
      .post(`a/files`, data, {
        onUploadProgress: e => progressCallback && progressCallback({ total: e.total, loaded: e.loaded })
      })
      .then(res => res.data)
  }

export const getFiles =
  (request: AxiosInstance) =>
  async (page: number, size: number, storyIds?: string[]): Promise<Pageable<FileType>> => {
    const queryString = stringify({ page, size, storyIds }, { indices: false, addQueryPrefix: true })
    return await request.get(`a/files${queryString}`).then(res => res.data)
  }

export const getFile =
  (request: AxiosInstance) =>
  async (id: string): Promise<FileType> => {
    return await request.get(`a/files/${id}`).then(res => res.data)
  }

export const deleteFile =
  (request: AxiosInstance) =>
  async (id: string): Promise<void> => {
    await request.delete(`a/files/${id}`)
  }

// Image api
export const uploadImage =
  (request: AxiosInstance) =>
  async (file: File, progressCallback?: (progress: { total: number; loaded: number }) => void): Promise<ImageType> => {
    await verifyStorageUsage(request)(file.size)
    const data = new FormData()
    data.append('file', file)
    return await request
      .post(`a/images`, data, {
        onUploadProgress: e => progressCallback && progressCallback({ total: e.total, loaded: e.loaded })
      })
      .then(res => res.data)
  }

export const getImage =
  (request: AxiosInstance) =>
  async (id: string): Promise<ImageType> => {
    return await request.get(`a/images/${id}`).then(res => res.data)
  }

// File Public API
export const uploadSpeedTest =
  (request: AxiosInstance) =>
  async (
    file: Blob,
    token: string,
    progressCallback?: (progress: { total: number; loaded: number }) => void
  ): Promise<string> => {
    const data = new FormData()
    data.append('file', file)
    return await request
      .post(`g/files/speedtest`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => res.data)
  }
export const uploadFilePublic =
  (request: AxiosInstance) =>
  async (
    storyId: string,
    file: File,
    token: string,
    progressCallback?: (progress: { total: number; loaded: number }) => void
  ): Promise<FileType> => {
    const data = new FormData()
    data.append('file', file)
    data.append('storyId', storyId)
    return await request
      .post(`g/files`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        onUploadProgress: e => progressCallback && progressCallback({ total: e.total, loaded: e.loaded })
      })
      .then(res => res.data)
  }

export const getFilesPublic =
  (request: AxiosInstance) =>
  async (page: number, size: number, token: string, storyIds?: string[]): Promise<Pageable<FileType>> => {
    const queryString = stringify({ page, size, storyIds }, { indices: false, addQueryPrefix: true })
    return await request
      .get(`g/files${queryString}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => res.data)
  }

export const getFilePublic =
  (request: AxiosInstance) =>
  async (id: string, token: string): Promise<FileType> => {
    return await request
      .get(`g/files/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => res.data)
  }

export const deleteFilePublic =
  (request: AxiosInstance) =>
  async (id: string, token: string): Promise<void> => {
    await request.delete(`g/files/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }

// Image Public API
export const uploadImagePublic =
  (request: AxiosInstance) =>
  async (
    file: File,
    token: string,
    storyId: string,
    progressCallback?: (progress: { total: number; loaded: number }) => void
  ): Promise<ImageType> => {
    const data = new FormData()
    data.append('file', file)
    return await request
      .post(`g/gallery-entries/image/${storyId}`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        onUploadProgress: e => progressCallback && progressCallback({ total: e.total, loaded: e.loaded })
      })
      .then(res => res.data)
  }

export const getImagePublic =
  (request: AxiosInstance) =>
  async (id: string, token: string): Promise<ImageType> => {
    return await request
      .get(`g/gallery-entries/image/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => res.data)
  }

export const deleteGalleryPublic =
  (request: AxiosInstance) =>
  async (id: string, token: string): Promise<void> => {
    await request.delete(`g/gallery-entries/image/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }

// Person api
export const getPersons =
  (request: AxiosInstance) =>
  async (page: number, size: number, q?: string, storyIds?: string[]): Promise<Pageable<PersonType>> => {
    const queryString = stringify({ page, size, name: q, storyIds }, { indices: false, addQueryPrefix: true })
    return await request.get(`a/persons${queryString}`).then(res => res.data)
  }

export const createPerson =
  (request: AxiosInstance) =>
  async (data: PersonFormType): Promise<PersonType> => {
    return await request.post(`a/persons`, data).then(res => res.data)
  }

export const updatePerson =
  (request: AxiosInstance) =>
  async (id: string, data: PersonFormType): Promise<PersonType> => {
    return await request.put(`a/persons/${id}`, data).then(res => res.data)
  }

// Link api
export const getLinks =
  (request: AxiosInstance) =>
  async (page: number, size: number, storyIds?: string[]): Promise<Pageable<LinkType>> => {
    const queryString = stringify({ page, size, storyIds }, { indices: false, addQueryPrefix: true })
    return await request.get(`a/links${queryString}`).then(res => res.data)
  }

export const getLink =
  (request: AxiosInstance) =>
  async (id: string): Promise<LinkType> => {
    return await request.get(`a/links/${id}`).then(res => res.data)
  }

export const createLink =
  (request: AxiosInstance) =>
  async (data: LinkFormType): Promise<LinkType> => {
    return await request.post(`a/links`, data).then(res => res.data)
  }

export const deleteLink =
  (request: AxiosInstance) =>
  async (id: string): Promise<void> => {
    await request.delete(`a/links/${id}`)
  }

// Transcipt api
export const getTranscriptions =
  (request: AxiosInstance) =>
  async (page: number, size: number, storyIds?: string[]): Promise<Pageable<TranscriptionType>> => {
    const queryString = stringify({ page, size, storyIds }, { indices: false, addQueryPrefix: true })
    return await request.get(`a/transcriptions${queryString}`).then(res => res.data)
  }

export const downloadTranscription =
  (request: AxiosInstance) =>
  async (id: string, type: 'json' | 'pdf' | 'docx' | 'plain'): Promise<Blob> =>
    await request.get(`a/transcriptions/${id}/${type}`, { responseType: 'blob' }).then(res => res.data)

export const getTranscription =
  (request: AxiosInstance) =>
  async (id: string): Promise<TranscriptionType> => {
    return await request.get(`a/transcriptions/${id}`, { responseType: 'blob' }).then(res => res.data)
  }

export const updateTranscriptions =
  (request: AxiosInstance) =>
  async (id: string, data: TranscriptionFormType): Promise<TranscriptionType> => {
    return await request.put(`a/transcriptions/${id}`, data).then(res => res.data)
  }

export const createTranscriptions =
  (request: AxiosInstance) =>
  async (data: TranscriptionFormType): Promise<TranscriptionType> => {
    return await request.post(`a/transcriptions`, data).then(res => res.data)
  }

export const deleteTranscriptions =
  (request: AxiosInstance) =>
  async (id: string): Promise<void> => {
    await request.delete(`a/transcriptions/${id}`)
  }

// Gallery api
export const getGallery =
  (request: AxiosInstance) =>
  async (page: number, size: number, storyIds?: string[]): Promise<Pageable<GalleryType>> => {
    const queryString = stringify({ page, size, storyIds }, { indices: false, addQueryPrefix: true })
    return await request.get(`a/gallery-entries${queryString}`).then(res => res.data)
  }

export const getGalleryPublic =
  (request: AxiosInstance) =>
  async (page: number, size: number, token: string, storyIds?: string[]): Promise<Pageable<GalleryType>> => {
    const queryString = stringify({ page, size, storyIds }, { indices: false, addQueryPrefix: true })
    return await request
      .get(`g/gallery-entries${queryString}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => res.data)
  }

export const getGalleryItem =
  (request: AxiosInstance) =>
  async (id: string, token: string): Promise<GalleryType> => {
    return await request
      .get(`a/gallery-entries/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => res.data)
  }

export const getGalleryItemPublic =
  (request: AxiosInstance) =>
  async (id: string, token: string): Promise<GalleryType> => {
    return await request
      .get(`g/gallery-entries/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => res.data)
  }

export const createGallery =
  (request: AxiosInstance) =>
  async (storyId: string, imageId: string): Promise<GalleryType> => {
    return await request
      .post(`a/gallery-entries`, {
        storyId,
        imageId
      })
      .then(res => res.data)
  }

export const createGalleryPublic =
  (request: AxiosInstance) =>
  async (storyId: string, imageId: string, token: string): Promise<GalleryType> => {
    return await request
      .post(
        `g/gallery-entries`,
        {
          storyId,
          imageId
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(res => res.data)
  }

export const deleteGallery =
  (request: AxiosInstance) =>
  async (id: string): Promise<void> => {
    await request.delete(`a/gallery-entries/${id}`)
  }

// Timeline api

export const getTimelines =
  (request: AxiosInstance) =>
  async (page: number, size: number, storyIds?: string[]): Promise<Pageable<TimelineType>> => {
    const queryString = stringify({ page, size, storyIds }, { indices: false, addQueryPrefix: true })
    return await request.get(`a/timeline-entries${queryString}`).then(res => res.data)
  }

export const getTimeline =
  (request: AxiosInstance) =>
  async (id: string): Promise<TimelineType> => {
    return await request.get(`a/timeline-entries/${id}`).then(res => res.data)
  }

export const createTimeline =
  (request: AxiosInstance) =>
  async (data: TimelineFormType): Promise<TimelineType> => {
    return await request
      .post(`a/timeline-entries`, {
        ...data,
        at: new Date(data.at).toJSON()
      })
      .then(res => res.data)
  }

export const deleteTimeline =
  (request: AxiosInstance) =>
  async (id: string): Promise<void> => {
    await request.delete(`a/timeline-entries/${id}`).then(res => res.data)
  }

// Places api
export const getPlaces =
  (request: AxiosInstance) =>
  async (page: number, size: number, q?: string, storyIds?: string[]): Promise<Pageable<PlaceType>> => {
    const queryString = stringify({ page, size, q, storyIds }, { indices: false, addQueryPrefix: true })
    return await request.get(`a/places${queryString}`).then(res => res.data)
  }

export const createPlace =
  (request: AxiosInstance) =>
  async (data: PlaceFormType): Promise<PlaceType> => {
    return await request.post(`a/places`, data).then(res => res.data)
  }

export const editPlace =
  (request: AxiosInstance) =>
  async (id: string, data: PlaceFormType): Promise<PlaceType> => {
    return await request.put(`a/places/${id}`, data).then(res => res.data)
  }

export const deletePlace =
  (request: AxiosInstance) =>
  async (id: string): Promise<void> => {
    await request.delete(`a/places/${id}`)
  }

export const searchLocation =
  (request: AxiosInstance) =>
  async (address: string): Promise<LocationSearchType[]> => {
    return await request.get(`a/geomap/search?format=json&q=${address}`).then(res => res.data)
  }

// Subtitile api
export const getSubtitles =
  (request: AxiosInstance) =>
  async (page: number, size: number, storyIds?: string[]): Promise<Pageable<SubtitleType>> => {
    const queryString = stringify({ page, size, storyIds }, { indices: false, addQueryPrefix: true })
    return await request.get(`a/subtitles${queryString}`).then(res => res.data)
  }

export const getSubtitleByStoryId =
  (request: AxiosInstance) =>
  async (storyId?: string): Promise<Pageable<SubtitleType>> => {
    return await request.get(`u/subtitles/story/${storyId}`).then(res => res.data)
  }

export const uploadSubtitle =
  (request: AxiosInstance) =>
  async (
    storyId: string,
    language: LanguageEnum,
    file: File,
    progressCallback?: (progress: { total: number; loaded: number }) => void
  ): Promise<SubtitleType> => {
    const data = new FormData()
    data.append('file', file)
    data.append('language', language)
    data.append('storyId', storyId)
    return await request
      .post(`a/subtitles`, data, {
        onUploadProgress: e => progressCallback && progressCallback({ total: e.total, loaded: e.loaded })
      })
      .then(res => res.data)
  }
export const createSubtitleFromStory =
  (request: AxiosInstance) =>
  async (storyId: string, language: LanguageEnum): Promise<SubtitleType> => {
    const data = new FormData()
    data.append('language', language)
    data.append('storyId', storyId)
    return await request.post(`a/subtitles/from-story`, data).then(res => res.data)
  }

export const deleteSubtitle =
  (request: AxiosInstance) =>
  async (id: string): Promise<void> => {
    await request.delete(`a/subtitles/${id}`).then(res => res.data)
  }

export const createSubtitleFromTranscript =
  (request: AxiosInstance) =>
  async (storyId: string): Promise<void> => {
    await request.get(`a/subtitles/createfromtranscript/${storyId}`).then(res => res.data)
  }

export const getSubtitlesByVideoId =
  (request: AxiosInstance) =>
  async (videoId: string, token: string): Promise<SubtitleType> => {
    return await request
      .get(`g/collection-invite-submissions/${videoId}/subtitlesByVideo`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => res.data)
  }

// Watcher api

export const getWatchers =
  (request: AxiosInstance) =>
  async (id: string, type: AssetTypes): Promise<Pageable<Watcher>> => {
    if (type === AssetTypes.collection) {
      return await request.get(`a/collection-watchers?collectionId=${id}`).then(res => res.data)
    }
    return await request.get(`a/story-watchers?storyId=${id}`).then(res => res.data)
  }

export const addWatcher =
  (request: AxiosInstance) =>
  async ({ id, email, type }: addWatcherType, assetType: AssetTypes): Promise<Watcher> => {
    if (assetType === AssetTypes.collection) {
      return await request.post(`a/collection-watchers`, { email, collectionId: id, type: type }).then(res => res.data)
    }
    return await request.post(`a/story-watchers`, { email, storyId: id, type: type }).then(res => res.data)
  }

export const removeWatcher =
  (request: AxiosInstance) =>
  async (id: string, type: AssetTypes): Promise<void> => {
    if (type === AssetTypes.collection) {
      return await request.delete(`a/collection-watchers/${id}`).then(res => res.data)
    }
    return await request.delete(`a/story-watchers/${id}`).then(res => res.data)
  }

export const notifyWatcher =
  (request: AxiosInstance) =>
  async (id: string, type: AssetTypes): Promise<void> => {
    if (type === AssetTypes.collection) {
      return await request.post(`a/collection-watchers/${id}/notify`).then(res => res.data)
    }
    return await request.post(`a/story-watchers/${id}/notify`).then(res => res.data)
  }

// Search api
export const search =
  (request: AxiosInstance) =>
  async (page: number, size: number, q?: string): Promise<Pageable<SearchResultType>> => {
    return await request.get(`a/search?q=${q || ''}&page=${page}&size=${size}`).then(res => res.data)
  }

export const searchStories =
  (request: AxiosInstance) =>
  async (page: number, size: number, q?: string): Promise<Pageable<SearchResultType>> => {
    return await request.get(`a/search?q=${q || ''}&page=${page}&size=${size}&t=STORY`).then(res => res.data)
  }

export const searchCollections =
  (request: AxiosInstance) =>
  async (page: number, size: number, q?: string): Promise<Pageable<SearchResultType>> => {
    return await request.get(`a/search?q=${q || ''}&page=${page}&size=${size}&t=COLLECTION`).then(res => res.data)
  }

// Comments api

export const getComments =
  (request: AxiosInstance) =>
  async (commentId: string, type: AssetTypes): Promise<Pageable<CommentType>> => {
    return await request.get(`a/comments?commentableId=${commentId}&commentableType=${type}`).then(res => res.data)
  }

export const createComment =
  (request: AxiosInstance) =>
  async (comment: CommentFormType): Promise<CommentType> => {
    return await request.post(`a/comments`, comment).then(res => res.data)
  }

export const editComment =
  (request: AxiosInstance) =>
  async (id: string, comment: CommentFormType): Promise<CommentType> => {
    return await request.put(`a/comments/${id}`, comment).then(res => res.data)
  }

export const deleteComment =
  (request: AxiosInstance) =>
  async (id: string): Promise<void> => {
    await request.delete(`a/comments/${id}`)
  }

// Collection api

export const getCollections =
  (request: AxiosInstance) =>
  async (page: number, size: number): Promise<Pageable<CollectionType>> => {
    return await request.get(`a/collections?page=${page}&size=${size}`).then(res => res.data)
  }

export const getCollection =
  (request: AxiosInstance) =>
  async (id: string): Promise<CollectionType> => {
    return await request.get(`a/collections/${id}`).then(res => res.data)
  }

export const updateCollection =
  (request: AxiosInstance) =>
  async (id: string, data: CollectionFormType): Promise<CollectionType> => {
    return await request.put(`a/collections/${id}`, data).then(res => res.data)
  }

export const updateCollectionCover =
  (request: AxiosInstance) =>
  async (id: string, coverId: string): Promise<CollectionType> => {
    return await request.post(`a/collections/${id}/change-cover`, { coverId }).then(res => res.data)
  }

export const createCollection =
  (request: AxiosInstance) =>
  async (data: CollectionFormType): Promise<CollectionType> => {
    return await request.post(`a/collections`, data).then(res => res.data)
  }

export const createEvent =
  (request: AxiosInstance) =>
  async (data: CollectionCreateEventRequest): Promise<CollectionType> => {
    return await request.post(`a/collections/event`, data).then(res => res.data)
  }

export const deleteCollection =
  (request: AxiosInstance) =>
  async (id: string): Promise<void> => {
    await request.delete(`a/collections/${id}`)
  }

// Publish api

export const isPublished =
  (request: AxiosInstance) =>
  async (id: string, type: AssetTypes): Promise<boolean> => {
    if (type === AssetTypes.collection) {
      return await getCollection(request)(id).then(res => res.publicAcl)
    }
    return await getStory(request)(id).then(res => res.publicAcl)
  }

export const publish =
  (request: AxiosInstance) =>
  async (id: string, publish: boolean, type: AssetTypes): Promise<void> => {
    if (type === AssetTypes.collection) {
      return publish
        ? await request.post(`a/collections/${id}/make-public`).then(res => res.data)
        : await request.post(`a/collections/${id}/make-private`).then(res => res.data)
    }
    return publish
      ? await request.post(`a/stories/${id}/make-public`).then(res => res.data)
      : await request.post(`a/stories/${id}/make-private`).then(res => res.data)
  }

// Require email to view at public page

export const changeRequiredEmailFlag =
  (request: AxiosInstance) =>
  async (id: string, type: AssetTypes): Promise<void> => {
    if (type === AssetTypes.collection) {
      return await request.patch(`a/collections/${id}`).then(res => res.data)
    }
    return await request.patch(`a/stories/${id}`).then(res => res.data)
  }

// Invites

export const getInvites =
  (request: AxiosInstance) =>
  async (page: number, size: number): Promise<Pageable<InviteType>> => {
    return await request.get(`a/collection-invites?page=${page}&size=${size}`).then(res => res.data)
  }

export const getTemplates = (request: AxiosInstance) => async (): Promise<InviteType[]> => {
  return await request.get('a/collection-invites/templates').then(res => res.data)
}

export const downloadInvitesSubmissions =
  (request: AxiosInstance) =>
  async (id: string): Promise<void> => {
    const file = await request
      .get(`a/collection-invites/${id}/submissions`, { responseType: 'blob' })
      .then(res => res.data)
    const url = window.URL.createObjectURL(new Blob([file]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'file.csv')
    document.body.appendChild(link)
    link.click()
  }

export const createInvite =
  (request: AxiosInstance) =>
  async (
    data: InviteInput,
    file?: File | null,
    progressCallback?: (progress: { total: number; loaded: number }) => void
  ): Promise<InviteType> => {
    const formdata = new FormData()
    if (file) formdata.append('file', file, file.name)
    formdata.append(
      'invite',
      new Blob([JSON.stringify(data)], {
        type: 'application/json'
      })
    )
    return await request
      .post(`a/collection-invites`, formdata, {
        onUploadProgress: e => progressCallback && progressCallback({ total: e.total, loaded: e.loaded })
      })
      .then(res => res.data)
  }

export const changeInviteMedia =
  (request: AxiosInstance) =>
  async (
    id: string,
    file: File,
    progressCallback?: (progress: { total: number; loaded: number }) => void
  ): Promise<InviteType> => {
    const formdata = new FormData()
    formdata.append('file', file, file.name)
    return await request
      .post(`a/collection-invites/${id}/change-media`, formdata, {
        onUploadProgress: e => progressCallback && progressCallback({ total: e.total, loaded: e.loaded })
      })
      .then(res => res.data)
  }

export const changeIntroOutroMedia =
  (request: AxiosInstance) =>
  async (
    id: string,
    type: QuestionVideoTypeEnum,
    file: File,
    progressCallback?: (progress: { total: number; loaded: number }) => void
  ): Promise<InviteType> => {
    const formdata = new FormData()
    formdata.append('file', file)
    formdata.append('questionId', id)
    formdata.append('isIntro', type === QuestionVideoTypeEnum.INTRO ? 'true' : 'false')
    return await request
      .post(`a/questionnaire-questions/intro-outro`, formdata, {
        onUploadProgress: e => progressCallback && progressCallback({ total: e.total, loaded: e.loaded })
      })
      .then(res => res.data)
  }

export const deleteInviteMedia =
  (request: AxiosInstance) =>
  async (inviteId: string, mediaId: string): Promise<void> => {
    return await request.delete(`a/collection-invites/${inviteId}/remove-media/${mediaId}`).then(res => res.data)
  }

export const updateInvite =
  (request: AxiosInstance) =>
  async (id: string, data: InviteInput): Promise<InviteType> => {
    return await request.put(`a/collection-invites/${id}`, data).then(res => res.data)
  }

export const deleteInvite =
  (request: AxiosInstance) =>
  async (id: string): Promise<void> => {
    return await request.delete(`a/collection-invites/${id}`).then(res => res.data)
  }

export const cloneInvite =
  (request: AxiosInstance) =>
  async (id: string): Promise<void> => {
    return await request.post(`a/collection-invites/clone/${id}`).then(res => res.data)
  }

export const sendInviteEmails =
  (request: AxiosInstance) =>
  async (
    id: string,
    records: EmailRecords[],
    customEmailBody: string,
    isQuestionnaire: boolean
  ): Promise<InviteType> => {
    return await request
      .post(`a/collection-invites/${id}/send-email`, {
        records,
        customEmailBody,
        isQuestionnaire
      })
      .then(res => res.data)
  }
export const sendQuestionnaireCompletionConfirmationEmail =
  (request: AxiosInstance) =>
  async (
    id: string,
    record: EmailRecords,
    customEmailBody: string,
    isQuestionnaire: boolean,
    token: string
  ): Promise<InviteType> => {
    if (!record.jobTitle) {
      record.jobTitle = '_'
    }
    if (!record.functionalArea) {
      record.functionalArea = '_'
    }

    console.log(record)
    console.log('Invite ID: ' + id)
    console.log('Record Email: ' + record.email)
    console.log('Record Name: ' + record.name)
    console.log('Record Job: ' + record.jobTitle)
    console.log('Record FunctionalArea: ' + record.functionalArea)
    console.log('Custom Body: ' + customEmailBody)
    console.log('Custom isQuestionnaire: ' + isQuestionnaire)

    return await request
      .post(
        `g/collection-invite-submissions/${id}/send-confirmation-email`,
        {
          record,
          customEmailBody,
          isQuestionnaire
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(res => res.data)
  }

export const sendQuestionEmail =
  (request: AxiosInstance) =>
  async (questionId: string, record: EmailRecords, token: string): Promise<InviteType> => {
    if (!record.jobTitle) {
      record.jobTitle = '_'
    }
    if (!record.functionalArea) {
      record.functionalArea = '_'
    }

    console.log('SENDING QUESTION EMAIL: ' + questionId)
    console.log('Record Email: ' + record.email)
    console.log('Record Name: ' + record.name)
    console.log('Record Job: ' + record.jobTitle)
    console.log('Record FunctionalArea: ' + record.functionalArea)

    return await request
      .post(
        `g/collection-invite-submissions/${questionId}/send-question-email`,
        {
          record
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(res => res.data)
  }

export const recordLater =
  (request: AxiosInstance) =>
  async (id: string, data: CollectionRecordLaterResponse, token: string): Promise<InviteType> => {
    return await request
      .post(`g/collection-invite-submissions/${id}/record-later`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => res.data)
  }

export const getInviteSubmission =
  (request: AxiosInstance) =>
  async (id: string, token: string): Promise<InviteType> => {
    return await request
      .get(`g/collection-invite-submissions/${id}/invite`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => res.data)
  }

export const sendInviteSubmission =
  (request: AxiosInstance) =>
  async (
    id: string,
    token: string,
    data: {
      name: string
      email?: string
      jobTitle?: string
      functionalArea?: string
      allowEmail: boolean
      allowShareInfo: boolean
    },
    file: File,
    progressCallback?: (progress: { total: number; loaded: number }) => void
  ): Promise<InviteType> => {
    const formdata = new FormData()
    formdata.append('file', file, file.name)
    formdata.append(
      'submission',
      new Blob([JSON.stringify(data)], {
        type: 'application/json'
      })
    )
    return await request
      .post(`g/collection-invite-submissions/${id}`, formdata, {
        onUploadProgress: e => progressCallback && progressCallback({ total: e.total, loaded: e.loaded }),
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => res.data)
  }

export const sendQuestionSubmission =
  (request: AxiosInstance) =>
  async (
    id: string,
    questionId: string,
    token: string,
    data: {
      name: string
      email: string
      jobTitle: string
      functionalArea: string
      allowEmail: boolean
      allowShareInfo: boolean
    },
    file: File,
    progressCallback?: (progress: { total: number; loaded: number }) => void
  ): Promise<InviteType> => {
    const formdata = new FormData()
    formdata.append(
      'submission',
      new Blob([JSON.stringify(data)], {
        type: 'application/json'
      })
    )
    formdata.append('file', file, file.name)

    return await request
      .post(`g/collection-invite-submissions/question/${id}/${questionId}`, formdata, {
        onUploadProgress: e => progressCallback && progressCallback({ total: e.total, loaded: e.loaded }),
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => res.data)
  }

export const sendQuestionSkipp =
  (request: AxiosInstance) =>
  async (
    token: string,
    data: {
      questionnaireId: string
      questionId: string
      name: string
      email: string
      jobTitle: string
      functionalArea: string
      skipped: boolean
    },
    progressCallback?: (progress: { total: number; loaded: number }) => void
  ): Promise<InviteType> => {
    return await request
      .post(`g/skipp-question`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => res.data)
  }

export const createContributorInvite =
  (request: AxiosInstance) =>
  async (data: InviteContributorInput): Promise<InviteContributorType> => {
    return await request.post(`a/story-invites`, data).then(res => res.data)
  }

export const sendInviteContributorEmails =
  (request: AxiosInstance) =>
  async (id: string, emails: { [key: string]: string }): Promise<InviteContributorType> => {
    return await request.post(`a/story-invites/${id}/send-email`, { emails }).then(res => res.data)
  }

export const getInviteContributorByStoryId =
  (request: AxiosInstance) =>
  async (page: number, size: number, q?: string): Promise<Pageable<InviteContributorType>> => {
    return await request.get(`a/story-invites?storyIds=${q}&page=${page}&size=${size}`).then(res => res.data)
  }

export const editInviteContributor =
  (request: AxiosInstance) =>
  async (id: string, data: InviteContributorInput): Promise<InviteContributorType> => {
    return await request.put(`a/story-invites/${id}`, data).then(res => res.data)
  }

export const deleteInviteContributor =
  (request: AxiosInstance) =>
  async (id: string): Promise<void> => {
    return await request.delete(`a/story-invites/${id}`).then(res => res.data)
  }

export const duplicateInviteContributor =
  (request: AxiosInstance) =>
  async (id: string): Promise<InviteContributorType> => {
    return await request.post(`a/story-invites/clone/${id}`).then(res => res.data)
  }

export const getContributorInviteSubmission =
  (request: AxiosInstance) =>
  async (id: string, token: string): Promise<InviteContributorType> => {
    return await request
      .get(`g/story-invite-submissions/${id}/invite`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => res.data)
  }

export const sendContributorInviteSubmission =
  (request: AxiosInstance) =>
  async (
    id: string,
    token: string,
    data: { name: string; email?: string },
    files: File[],
    progressCallback?: (progress: { total: number; loaded: number }) => void
  ): Promise<InviteType> => {
    const formdata = new FormData()
    for (const f of files) {
      formdata.append('files', f, f.name)
    }
    formdata.append(
      'submission',
      new Blob([JSON.stringify(data)], {
        type: 'application/json'
      })
    )
    return await request
      .post(`g/story-invite-submissions/${id}`, formdata, {
        onUploadProgress: e => progressCallback && progressCallback({ total: e.total, loaded: e.loaded }),
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => res.data)
  }
export const validateToken =
  (request: AxiosInstance) =>
  async (email: string, token: string): Promise<TokenResponse> => {
    return await request.get(`u/token/${email}/validate?token=${token}`).then(res => res.data)
  }

export const validateIsWatcher =
  (request: AxiosInstance) =>
  async (validateEmail: ValidateCollectionEmailRequest, id: string): Promise<any> => {
    return await request.post(`u/collections/${id}/validate-email`, validateEmail).then(res => res.data)
  }

// Organization
export const getOrganizations = (request: AxiosInstance) => async (): Promise<Pageable<OrganizationType>> => {
  return await request.get(`a/organizations`).then(res => res.data)
}

export const getOrganization =
  (request: AxiosInstance) =>
  async (id: string): Promise<OrganizationType> => {
    return await request.get(`a/organizations/${id}`).then(res => res.data)
  }

export const deleteOrganization =
  (request: AxiosInstance) =>
  async (id: string): Promise<void> => {
    return await request.delete(`a/organizations/${id}`).then(res => res.data)
  }

export const createOrganization =
  (request: AxiosInstance) =>
  async (data: OrganizationInput): Promise<OrganizationType> => {
    return await request.post(`a/organizations`, data).then(res => res.data)
  }

export const updateOrganization =
  (request: AxiosInstance) =>
  async (id: string, data: OrganizationInput): Promise<OrganizationType> => {
    return await request.put(`a/organizations/${id}`, data).then(res => res.data)
  }

export const organizationAddMembers =
  (request: AxiosInstance) =>
  async (id: string, email: string): Promise<OrganizationMemberType> => {
    return await request.post(`a/organizations/${id}/memberships`, { email }).then(res => res.data)
  }

export const organizationDeleteMembers =
  (request: AxiosInstance) =>
  async (id: string, memberId: string): Promise<void> => {
    return await request.delete(`a/organizations/${id}/memberships/${memberId}`).then(res => res.data)
  }

export const getEmbedStory =
  (request: AxiosInstance) =>
  async (id: string): Promise<StoryType> => {
    return await request.get(`u/embed/stories/${id}`).then(res => res.data)
  }

export const getEmbedCollection =
  (request: AxiosInstance) =>
  async (id: string): Promise<CollectionType> => {
    return await request.get(`u/embed/collections/${id}`).then(res => res.data)
  }

export const getEmbedStoryInvitesByCollectionId =
  (request: AxiosInstance) =>
  async (page: number, size: number, q?: string): Promise<Pageable<InviteType>> => {
    return await request.get(`u/embed/collections/invite/${q}?page=${page}&size=${size}`).then(res => res.data)
  }

// team-member
export const getTeamMembers = (request: AxiosInstance) => async (): Promise<Pageable<TeamMemberType>> => {
  return await request.get(`a/team-members`).then(res => res.data)
}

export const getTeamMember =
  (request: AxiosInstance) =>
  async (id: string): Promise<TeamMemberType> => {
    return await request.get(`a/team-members/${id}`).then(res => res.data)
  }

export const addTeamMember =
  (request: AxiosInstance) =>
  async (data: { email: string; role: TeamMemberRoleEnum }): Promise<TeamMemberType> => {
    return await request.post(`a/team-members`, data).then(res => res.data)
  }
export const updateTeamMember =
  (request: AxiosInstance) =>
  async (data: Partial<TeamMemberType>): Promise<TeamMemberType> => {
    return await request.put(`a/team-members/${data.id}`, data).then(res => res.data)
  }

export const deleteTeamMember =
  (request: AxiosInstance) =>
  async (id: string): Promise<void> => {
    return await request.delete(`a/team-members/${id}`).then(res => res.data)
  }
export const getRakontoAdmins = (request: AxiosInstance) => async (): Promise<Pageable<RakontoAdminType>> => {
  return await request.get(`a/admin-switch`).then(res => res.data)
}

export const getRakontoAdmin =
  (request: AxiosInstance) =>
  async (id: string): Promise<RakontoAdminType> => {
    return await request.get(`a/admin-switch/${id}`).then(res => res.data)
  }

export const addRakontoAdmin =
  (request: AxiosInstance) =>
  async (email: string): Promise<RakontoAdminType> => {
    return await request.post(`a/admin-switch`, { email }).then(res => res.data)
  }

export const deleteRakontoAdmin =
  (request: AxiosInstance) =>
  async (id: string): Promise<void> => {
    return await request.delete(`a/admin-switch/${id}`).then(res => res.data)
  }

export const transcodeAgain =
  (request: AxiosInstance) =>
  async (id: string): Promise<void> => {
    return await request.post(`a/stories/${id}/transcode`).then(res => res.data)
  }

export const transcriptAgain =
  (request: AxiosInstance) =>
  async (id: string): Promise<void> => {
    return await request.post(`a/stories/${id}/transcript`).then(res => res.data)
  }

// Quiz

export const getQuestionnaireTemplates =
  (request: AxiosInstance) => async (): Promise<Pageable<QuestionnaireTemplateType>> => {
    return await request.get(`a/questionnaire-templates`).then(res => res.data)
  }

export const getQuestionnaireTemplate =
  (request: AxiosInstance) =>
  async (id: string): Promise<QuestionnaireTemplateType> => {
    return await request.get(`a/questionnaire-templates/${id}`).then(res => res.data)
  }

export const updateQuestionnaireTemplate =
  (request: AxiosInstance) =>
  async (id: string, data: QuizInputType): Promise<QuestionnaireTemplateType> => {
    return await request.put(`a/questionnaire-templates/${id}`, data).then(res => res.data)
  }

export const addQuestionnaireTemplate =
  (request: AxiosInstance) =>
  async (data: QuizInputType): Promise<QuestionnaireTemplateType> => {
    return await request.post(`a/questionnaire-templates`, data).then(res => res.data)
  }

export const duplicateQuestionnaireTemplate =
  (request: AxiosInstance) =>
  async (id: string, language: LanguageEnum): Promise<QuestionnaireTemplateType> => {
    return await request.post(`a/questionnaire-templates/${id}/clone`, { language: language }).then(res => res.data)
  }

export const deleteQuestionnaireTemplate =
  (request: AxiosInstance) =>
  async (id: string): Promise<void> => {
    return await request.delete(`a/questionnaire-templates/${id}`).then(res => res.data)
  }

export const getQuestionnaireTemplateQuestions =
  (request: AxiosInstance) => async (): Promise<Pageable<QuizQuestionType>> => {
    return await request.get(`a/questionnaire-template-questions`).then(res => res.data)
  }

export const addQuestionnaireTemplateQuestion =
  (request: AxiosInstance) =>
  async (data: QuizQuestionInputType): Promise<QuizQuestionType> => {
    return await request.post(`a/questionnaire-template-questions`, data).then(res => res.data)
  }

export const updateQuestionnaireTemplateQuestion =
  (request: AxiosInstance) =>
  async (id: string, data: QuizQuestionInputType): Promise<void> => {
    return await request.put(`a/questionnaire-template-questions/${id}`, data).then(res => res.data)
  }

export const deleteQuestionnaireTemplateQuestion =
  (request: AxiosInstance) =>
  async (id: string): Promise<void> => {
    return await request.delete(`a/questionnaire-template-questions/${id}`).then(res => res.data)
  }

// Quiz Invite

export const getQuestionnaries =
  (request: AxiosInstance) =>
  async (page: number, size: number, q?: string): Promise<Pageable<QuizInviteType>> => {
    return await request.get(`a/questionnaires?page=${page}&size=${size}`).then(res => res.data)
  }

export const getQuestionnarie =
  (request: AxiosInstance) =>
  async (id: string, token?: string): Promise<QuizInviteType> => {
    if (token) {
      return await request
        .get(`g/questionnaires/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then(res => res.data)
    }
    return await request.get(`a/questionnaires/${id}`).then(res => res.data)
  }

export const createQuestionnarie =
  (request: AxiosInstance) =>
  async (data: { questionnaireTemplateId: string; collectionId: string }): Promise<QuizInviteType> => {
    return await request
      .post(`a/questionnaires`, {
        questionnaireTemplateId: data.questionnaireTemplateId,
        collectionId: data.collectionId
      })
      .then(res => res.data)
  }

export const deleteQuestionnarie =
  (request: AxiosInstance) =>
  async (id: string): Promise<void> => {
    return await request.delete(`a/questionnaires/${id}`).then(res => res.data)
  }

export const updateQuestionnaire =
  (request: AxiosInstance) =>
  async (data: QuizInviteType): Promise<void> => {
    return await request.put(`a/questionnaires`, { ...data, questionnaireId: data.id }).then(res => res.data)
  }

export const getQuestionnaireQuestion =
  (request: AxiosInstance) =>
  async (questionId: string): Promise<QuizInviteQuestionType> => {
    return await request.get(`a/questionnaire-questions/${questionId}`).then(res => res.data)
  }
export const updateQuestionnaireQuestion =
  (request: AxiosInstance) =>
  async (question: QuizInviteQuestionType): Promise<QuizInviteQuestionType> => {
    return await request.put(`a/questionnaire-questions/${question.id}`, question).then(res => res.data)
  }

export const verifyCollectionInvite =
  (request: AxiosInstance) =>
  async (collectionId: string): Promise<VerifyCollectionInviteType> => {
    return await request.get(`u/collections/${collectionId}/verify-collection-invite`).then(res => res.data)
  }

export const countStoriesPublished =
  (request: AxiosInstance) =>
  async (collectionId: string): Promise<CountStoriesPublishedType> => {
    return await request.get(`u/collections/${collectionId}/count-published-at-stories`).then(res => res.data)
  }

export const createCollectionTheme =
  (request: AxiosInstance) =>
  async (data: CollectionTheme): Promise<any> => {
    return await request.post(`a/theme/`, { properties: data.properties }).then(res => res.data)
  }

export const getCollectionThemes = (request: AxiosInstance) => async (): Promise<CollectionTheme[]> => {
  return await request.get(`a/theme/`).then(res => res.data)
}
export const updateCollectionTheme =
  (request: AxiosInstance) =>
  async (data: CollectionTheme): Promise<any> => {
    return await request
      .put(`a/theme/${data.id}`, { id: data.id, properties: data.properties, standard: data.standard })
      .then(res => res.data)
  }

export const deleteCollectionTheme =
  (request: AxiosInstance) =>
  async (themeId: string): Promise<any> => {
    return await request.delete(`a/theme/${themeId}`).then(res => res.data)
  }

export const getCollectionsByThemeId =
  (request: AxiosInstance) =>
  async (themeId: string, token: string): Promise<any> => {
    return await request
      .get(`a/collections/theme/${themeId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => res.data)
  }

export const getSharedLink =
  (request: AxiosInstance) =>
  async (sharedLink: string): Promise<any> => {
    return await request.get(`u/collections/${sharedLink}/shared-link`).then(res => res.data)
  }

export const getCollectionBySharedLink =
  (request: AxiosInstance) =>
  async (sharedLink: string): Promise<any> => {
    return await request.get(`u/collections/${sharedLink}`).then(res => res.data)
  }

export const getQuestionnaireStats =
  (request: AxiosInstance) =>
  async (questionnaireId: string): Promise<QuestionnaireStats> => {
    return await request.get(`a/questionnaires/stats/${questionnaireId}`).then(res => res.data)
  }

export const downloadQuestionnaireStats =
  (request: AxiosInstance) =>
  async (questionnaireId: string): Promise<void> => {
    const data = null
    const file = await request
      .get(`a/questionnaires/stats/csv/${questionnaireId}`, { responseType: 'blob' })
      .then(response => response.data)
    const url = window.URL.createObjectURL(new Blob([file]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'Questionnaire_Statistics_' + questionnaireId + '.csv')
    document.body.appendChild(link)
    link.click()
  }

export const downloadQuestionnaireCompleteReport =
  (request: AxiosInstance) =>
  async (questionnaireId: string): Promise<void> => {
    const file = await request
      .get(`a/statistics/questionnaire/${questionnaireId}`, { responseType: 'blob' })
      .then(response => response.data)
    const url = window.URL.createObjectURL(new Blob([file]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'Questionnaire_Complete_Report_' + questionnaireId + '.csv')
    document.body.appendChild(link)
    link.click()
  }

export const uploadQuestionnaireTemplateCsv =
  (request: AxiosInstance) =>
  async (templateId: string, csv: File): Promise<any> => {
    const formdata = new FormData()
    formdata.append('file', csv, csv.name)
    return await request
      .post(`a/questionnaire-template-questions/${templateId}/upload-csv`, formdata)
      .then(res => res.data)
  }

export const exportQuestionnaireTemplateDocx =
  (request: AxiosInstance) =>
  async (templateId: string): Promise<any> => {
    const file = await request
      .get(`a/questionnaire-templates/${templateId}/export-docx`, { responseType: 'blob' })
      .then(res => res.data)
    const url = window.URL.createObjectURL(new Blob([file]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'file.docx')
    document.body.appendChild(link)
    link.click()
  }

export const inviteQuestionnaireReviewers =
  (request: AxiosInstance) =>
  async (data: {
    questionnaireId: string
    questionnaireReviewerIds: string[]
    tenantId: string
    memberId?: string
  }): Promise<any> => {
    return await request
      .post(`a/questionnaires/request-reviewers`, {
        questionnaireId: data.questionnaireId,
        questionnaireReviewerIds: data.questionnaireReviewerIds,
        requesterId: data.memberId,
        tenantId: data.tenantId
      })
      .then(res => res.data)
  }

export const doReview =
  (request: AxiosInstance) =>
  async (data: { questionnaireId: string; approved: boolean; reason: string }): Promise<any> => {
    return await request
      .put(`a/questionnaires/review`, {
        questionnaireId: data.questionnaireId,
        approved: data.approved,
        reason: data.reason
      })
      .then(res => res.data)
  }

export const bulkEditQuestionnaire =
  (request: AxiosInstance) =>
  async (questionnaireId: string, data: InviteInput): Promise<any> => {
    return await request.put(`a/collection-invites/questionnaire/${questionnaireId}`, data).then(res => res.data)
  }

export const shareCollectionInviteByCsv =
  (request: AxiosInstance) =>
  async (inviteId: string, csv: File, customText: string, isQuestionnaire?: boolean): Promise<any> => {
    try {
      const formdata = new FormData()
      formdata.append('file', csv, csv.name)
      formdata.append('custom-email-body', customText)
      formdata.append('isQuestionnaire', String(isQuestionnaire))
      console.log('Sending Emails from CSV: ' + csv.name + ' for the invite:' + inviteId)
      return await request.post(`a/collection-invites/${inviteId}/send-email-csv`, formdata).then(res => res.data)
    } catch (error) {
      console.error('Error to send invite for CSV invitees: ', error)
      console.error(error)
    }
  }

export const sendQuestionnaireReminder =
  (request: AxiosInstance) =>
  async (questionnaireId: string | undefined, inviteId: string | undefined): Promise<any> => {
    try {
      const formdata = new FormData()
      console.log('Questionnaire Reminder Email(s): for the Questionnaire:' + questionnaireId)
      return await request
        .get(`a/collection-invites/${questionnaireId}/${inviteId}/send-reminder`)
        .then(res => res.data)
    } catch (error) {
      console.error('Error to send invite for CSV invitees: ', error)
      console.error(error)
    }
  }

export const getJobTitleAndFunctionalAreaByUserEmail =
  (request: AxiosInstance) =>
  async (questionnaireId: string, email: string, token: string): Promise<QuestionnaireInvite[]> => {
    return await request
      .get(`g/questionnaire-invites/${questionnaireId}/list/${email}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => res.data)
  }

export const finishQuestionnaire = (request: AxiosInstance) => {
  return async (
    questionnaireId: string,
    email: string,
    job: string,
    fa: string,
    token: string
  ): Promise<QuestionnaireInvite[]> => {
    const form = new FormData()
    form.set('questionnaireId', questionnaireId)
    form.set('email', email)
    form.set('jobTitle', job)
    form.set('functionalArea', fa)

    return await request
      .post(`g/questionnaire-invites/finish`, form, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(res => res.data)
  }
}

export const assignCollectionsToTeamMember =
  (request: AxiosInstance) => async (teamMemberId: string, collectionIds?: string[]) => {
    return await request
      .post(`a/team-members/${teamMemberId}`, {
        teamMemberId: teamMemberId,
        collectionIds: collectionIds
      })
      .then(res => res.data)
  }

export const editVideo =
  (request: AxiosInstance) => async (storyId: string, imageStatic: any, file: File, userId: string) => {
    const formdata = new FormData()
    formdata.append('file', file)
    formdata.append('userId', userId)
    formdata.append('imageStatic', imageStatic)
    return await request.post(`u/media-manager/${storyId}`, formdata).then(res => res.data)
  }

export const editVideoTest = (request: AxiosInstance) => async (storyId: string, file: File) => {
  const formdata = new FormData()
  formdata.append('storyId', storyId)
  formdata.append('file', file)
  return await request.post(`u/media-manager/webhook`, formdata).then(res => res.data)
}

export const sendStoryToDam = (request: AxiosInstance) => async (storyId: string) => {
  return await request.get(`u/media-manager/send-to-dam/${storyId}`).then(res => res.data)
}

export const sendCollectionToDam = (request: AxiosInstance) => async (collectionId: string) => {
  return await request.get(`u/media-manager/send-collection-to-dam/${collectionId}`).then(res => res.data)
}

export const sendQuestionnaireTranscriptionToDam =
  (request: AxiosInstance) =>
  async (storyId: string): Promise<void> => {
    try {
      await request.get(`u/media-manager/send-interview-transcription/${storyId}`).then(res => res.data)
    } catch (error) {
      console.error('Error send interview transcript to DAM:', error)
    }
  }
export const addChapter =
  (request: AxiosInstance) =>
  async (title: string, begin: string, end: string, videoId: string): Promise<VideoChapter> => {
    return await request.post(`u/video-chapter`, { title, begin, end, videoId }).then(res => res.data)
  }

export const deleteChapter = (request: AxiosInstance) => async (chapterId: string) => {
  return await request.delete(`u/video-chapter/${chapterId}`).then(res => res.data)
}

export const getChapters =
  (request: AxiosInstance) =>
  async (videoId: string): Promise<VideoChapter[]> => {
    return await request.get(`u/video-chapter/${videoId}`).then(res => res.data)
  }
export const applyGraphics =
  (request: AxiosInstance) =>
  async (storyId: string | undefined): Promise<[]> => {
    return await request.get(`u/video-merge/process-merge?storyId=${storyId}`).then(res => res.data)
  }

export const recreateThumbs =
  (request: AxiosInstance) =>
  async (storyId: string | undefined): Promise<[]> => {
    return await request.get(`u/video-thumbnail/create-thumbnail?storyId=${storyId}`).then(res => res.data)
  }

export const applyGraphicsCollection =
  (request: AxiosInstance) =>
  async (collectionId: string | undefined): Promise<[]> => {
    return await request
      .get(`u/video-merge/process-merge-collection?collectionId=${collectionId}`)
      .then(res => res.data)
  }

export const restoreMedia =
  (request: AxiosInstance) =>
  async (storyId: string | undefined): Promise<[]> => {
    return await request.get(`u/video-merge/restore-media?storyId=${storyId}`).then(res => res.data)
  }

export const restoreMediaCollection =
  (request: AxiosInstance) =>
  async (collectionId: string | undefined): Promise<[]> => {
    return await request
      .get(`u/video-merge/restore-media-collection?collectionId=${collectionId}`)
      .then(res => res.data)
  }

export const checkProcessing =
  (request: AxiosInstance) =>
  async (storyId: string | undefined): Promise<StoryStatusType> => {
    return await request.get(`u/video-merge/check-process?storyId=${storyId}`).then(res => res.data)
  }

export const generateInterviewTranscription =
  (request: AxiosInstance) =>
  async (
    questionnaireId: string,
    collectionId: string,
    jobTitle: string,
    functionalArea: string,
    recorderName: string
  ): Promise<void> => {
    try {
      const response = await request.post(
        `u/generate-interview-transcription`,
        {
          questionnaireId,
          collectionId,
          jobTitle,
          functionalArea,
          recorderName
        },
        {
          responseType: 'blob' // Expecting a binary response
        }
      )

      const filename = response.headers['content-disposition'].split('filename=')[1].split(';')[0].replace(/"/g, '')
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename) // Set the filename from the content-disposition header
      document.body.appendChild(link)
      link.click()

      // Cleanup
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error('Error downloading file:', error)
    }
  }

export const saveConsentQuestionnaire =
  (request: AxiosInstance) =>
  async (data: QuestionnaireConsent, token: string): Promise<void> => {
    return await request
      .get(
        `g/questionnaire-invites/save-consent?email=${data.email}&name=${data.name}&jobTitle=${data.jobTitle}&functionalArea=${data.functionalArea}&questionnaireId=${data.questionnaireId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(res => res.data)
  }
