import React, { createContext, ReactNode, useState } from 'react'
import { iSimpleDialog } from './index'
import Component from './Component'
import { CollectionThemeProps } from '../../lib/types'

// @ts-ignore
export const SimpleDialogContext = createContext<{
  actions: {
    open: (
      title: string,
      content: string | ReactNode,
      buttonsInfo?: { okText?: string; showOk?: boolean; cancelText?: string },
      callback?: (success: boolean) => Promise<void> | void
    ) => void
    openWithStyles: (
      title: string,
      content: string | ReactNode,
      collectionTheme: CollectionThemeProps,
      buttonsInfo?: { okText?: string; showOk?: boolean; cancelText?: string },
      callback?: (success: boolean) => Promise<void> | void
    ) => void
    close: () => void
  }
}>({
  // @ts-ignore
  actions: {}
})

export const SimpleDialogProvider: React.FC = ({ children }) => {
  const [collectionTheme, setCollectionTheme] = useState<CollectionThemeProps>({})
  const [dialog, setDialog] = useState<iSimpleDialog>({
    content: '',
    title: '',
    cancelText: 'Close',
    okText: 'Ok',
    showOk: false
  })

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const open = (
    title: string,
    content: string | ReactNode,
    buttonsInfo?: { okText?: string; showOk?: boolean; cancelText?: string },
    callback?: (success: boolean) => Promise<void> | void
  ) => {
    setDialog({
      ...dialog,
      title,
      content,
      ...buttonsInfo,
      callback
    })
    setIsOpen(true)
  }

  const openWithStyles = (
    title: string,
    content: string | ReactNode,
    collectionTheme: CollectionThemeProps,
    buttonsInfo?: { okText?: string; showOk?: boolean; cancelText?: string },
    callback?: (success: boolean) => Promise<void> | void
  ) => {
    setCollectionTheme(collectionTheme)
    setDialog({
      ...dialog,
      title,
      content,
      ...buttonsInfo,
      callback
    })
    setIsOpen(true)
  }

  const close = () => {
    setIsOpen(false)
  }

  const handleClear = () => {
    setDialog({
      title: '',
      content: '',
      cancelText: 'Close',
      okText: 'Ok'
    })
  }

  return (
    <SimpleDialogContext.Provider
      value={{
        actions: {
          close,
          open,
          openWithStyles
        }
      }}
    >
      {isOpen && <Component store={dialog} collectionTheme={collectionTheme} />}
      {children}
    </SimpleDialogContext.Provider>
  )
}
