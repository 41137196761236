import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { InviteType, SubtitleType } from '../../../../../lib/types'
import Player from '../../../../../components/Player'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import api from '../../../../../lib/api'

const Step1: React.FC<{ invite: InviteType }> = ({ invite }) => {
  const [play, setPlay] = useState(false)
  // const [subtitles, setSubtitles] = useState<SubtitleType[]>([])
  const handlePlay = () => {
    setPlay(true)
  }
  useEffect(() => {
    const getSubtitles = async () => {
      if (invite?.storyId) {
        try {
          console.log('Fetching Subtitle form story ', invite.storyId)
          // const response = await api.getSubtitleByStoryId(invite.storyId)
          // setSubtitles(response.content)
        } catch (e) {
          console.log('Errpr Fetching Subtitle form story ', invite.storyId)
          console.error('Error to fetch Subtitles: ', e)
        }
      }
    }
    getSubtitles()
  }, [])
  return (
    <Grid container>
      <Grid item xs={12}>
        {invite.description &&
          invite.description.split('\n').map((item, index) => (
            <Typography key={index} variant="h6" mb={2}>
              {item}
            </Typography>
          ))}
      </Grid>
      {invite.video &&
        (play ? (
          <Grid item xs={12} mb={12}>
            <Player
              // subtitles={subtitles}
              type={'VIDEO'}
              media={invite.video}
              mobileMaxHeight={'300px'}
              playsInline={true}
              autoplay={true}
            />
          </Grid>
        ) : (
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              height: { xs: 300, md: 720 },
              backgroundColor: 'black',
              backgroundImage: `url(${invite.video.thumbnailUrl || '/images/CoverDefault.png'})`,
              backgroundSize: invite.video.thumbnailUrl ? 'contain' : 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Button onClick={handlePlay} variant="contained" sx={{ backgroundColor: 'primary' }}>
              <PlayArrowIcon />
            </Button>
          </Box>
        ))}
    </Grid>
  )
}

export default Step1
