import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import api from '../../../../lib/api'
import { InviteType } from '../../../../lib/types'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Box from '@mui/material/Box'
import MobileStepper from '@mui/material/MobileStepper'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import LoadingButton from '@mui/lab/LoadingButton'
import { useCacheStorage } from '../../../../components/FileStorage/CacheStorage'

import Step1 from './steps/Step1'
import Step2 from './steps/Step2'
import Step3 from './steps/Step3'
import Step4 from './steps/Step4'
import { FormikProvider, FormikValues, useFormik } from 'formik'
import schema from './schema'
import { SimpleDialogContext } from '../../../../components/SimpleDialog'
import RecordLaterDialogContent from '../../../../components/RecordLaterDialogContent/RecordLaterDialogContent'
import StepFiles from './steps/StepFiles'

interface iInviteProps {
  invite: InviteType
  id: string
  token: string
}

const Invite: React.FC<iInviteProps> = ({ invite, id, token }) => {
  const [activeStep, setActiveStep] = useState(0)
  const [progress, setProgress] = useState(0)
  const { insertFile, removeFile, getFile } = useCacheStorage()
  const [savedFile, setSavedFile] = useState<File | null>(null)
  const fileKey = 'RAKONTO.LOCAL.RECORDING'
  const [rememberName, setRememberName] = useState(localStorage.getItem('app.rakonto.invite.name'))
  const [rememberEmail, setRememberEmail] = useState(localStorage.getItem('app.rakonto.invite.email'))
  const allowDescriptionStep = !!invite?.description || !!invite?.video
  const allowFilesAndImages = invite?.requestFiles || invite?.requestImages
  const [steps] = useState(() => {
    const steps = allowFilesAndImages
      ? [
          { id: 'initial', label: '', error: false },
          { id: 'greenRoom', label: 'The green room', error: false },
          { id: 'submit', label: 'Submit your recording', error: false },
          { id: 'files', label: 'Upload Files and Photos', error: false },
          { id: 'thankYoy', label: 'Thank you!', error: false }
        ]
      : [
          { id: 'initial', label: '', error: false },
          { id: 'greenRoom', label: '', error: false },
          { id: 'submit', label: 'Submit your recording', error: false },
          { id: 'thankYoy', label: 'Thank you!', error: false }
        ]
    if (!allowDescriptionStep) {
      steps.shift()
    }
    return steps
  })
  const { actions: simpleDialogActions } = useContext(SimpleDialogContext)
  const [storyId, setStoryId] = useState(invite.storyId)

  const handleNext = () => {
    setActiveStep(currentStep => currentStep + 1)
  }

  const handleBack = () => {
    setActiveStep(currentStep => currentStep - 1)
  }

  const initialValues: {
    file: File | null
    name: string
    email: string
    allowEmail: boolean
    allowShareInfo: boolean
  } = {
    file: null,
    name: rememberName || '',
    email: rememberEmail || '',
    allowEmail: true,
    allowShareInfo: false
  }

  const handleSubmit = async (values: FormikValues) => {
    try {
      await removeFile(fileKey)
      console.log('File ', fileKey, ' was removed from your local device')
    } catch (e) {
      console.error('Error to remove file: ', fileKey, ' -> ', e)
    }

    const response = await api.sendInviteSubmission(
      id,
      token,
      {
        name: values.name,
        email: values.email,
        allowEmail: values.allowEmail,
        allowShareInfo: values.allowShareInfo
      },
      values.file,
      (event: { loaded: number; total: number }) => {
        const progress = Math.round((event.loaded * 100) / event.total)
        setProgress(progress)
      }
    )
    setStoryId(response.storyId)
    setProgress(0)
    handleNext()
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schema,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: handleSubmit
  })

  const handeRemoveStoredFile = async () => {
    await removeFile(fileKey)
    setSavedFile(null)
    formik.values.file = null
    console.log('File Removed Locally: ', fileKey)
  }

  const saveFileLocally = async (file: File | null) => {
    if (file) {
      console.log('Saving File Locally: ', fileKey, file)
      await insertFile(fileKey, file)
      console.log('File Saved Locally: ', fileKey, file)
    }
  }
  const handleFile = (file: File | null) => {
    // formik.values.file = file
    saveFileLocally(file)
  }

  const isFetchedRef = useRef(false)

  const fetchStoredFile = async () => {
    console.log('Fetching stored file: ', fileKey)
    if (!formik.values.file) {
      const storedFile = await getFile(fileKey)
      if (storedFile) {
        console.log('Fetched stored file: ', fileKey, storedFile)
        // O Problema esta aqui
        // formik.values.file = storedFile as File
        setSavedFile(storedFile)
      } else {
        console.log('No file saved locally: ', fileKey)
      }
    }
  }

  useEffect(() => {
    if (steps[activeStep].id === 'greenRoom') {
      if (!isFetchedRef.current) {
        fetchStoredFile().then(r => (isFetchedRef.current = true))
        isFetchedRef.current = true
      }
    }
  }, [activeStep, steps])

  const handleRecordLater = () => {
    simpleDialogActions.open('Record Later', <RecordLaterDialogContent inviteId={invite.id} token={token} />)
  }

  const buttons = useCallback(
    ({ size = 'large' }: { size: 'small' | 'medium' | 'large' }) => {
      // console.log('Has remember:  ', initialValues.name, ', ', initialValues.email)
      return (
        <ButtonGroup>
          {activeStep > 0 ? (
            <Button size={size} sx={{ fontSize: '1.2em' }} onClick={handleBack}>
              Back
            </Button>
          ) : (
            <div></div>
          )}
          {activeStep === 0 && (
            <Button size={size} sx={{ fontSize: '1.2em' }} onClick={handleRecordLater}>
              Record Later
            </Button>
          )}
          <LoadingButton
            sx={{ fontSize: '1.2em' }}
            loading={steps[activeStep].id === 'submit' ? formik.isSubmitting : undefined}
            disabled={
              (steps[activeStep].id === 'submit' ? !formik.isValid : undefined) ||
              (steps[activeStep].id === 'greenRoom' ? !formik.values?.file : undefined)
            }
            onClick={() => {
              if (steps[activeStep].id === 'submit') {
                formik.handleSubmit()
                return
              }
              handleNext()
            }}
            variant="contained"
            size={size}
          >
            {steps[activeStep].id === 'submit'
              ? 'Submit'
              : steps[activeStep].id === 'greenRoom'
              ? 'Continue'
              : activeStep === 0
              ? 'Record Now'
              : 'Next'}
          </LoadingButton>
        </ButtonGroup>
      )
    },
    // [formik.isSubmitting, formik.isValid, activeStep, steps]
    [formik, activeStep, steps]
  )

  const isNextButtonVisible = () => {
    if (allowDescriptionStep && allowFilesAndImages) {
      return activeStep !== 4
    } else if ((allowDescriptionStep && !allowFilesAndImages) || (!allowDescriptionStep && allowFilesAndImages)) {
      return activeStep !== 3
    } else {
      return activeStep !== 2
    }
  }

  return (
    <>
      <Box sx={{ width: '100%', height: '100%' }}>
        <Box sx={{ width: '100%', paddingY: { xs: 2, md: 5 }, paddingX: 2 }}>
          <Stepper sx={{ display: { xs: 'none', md: 'flex' } }} activeStep={activeStep} alternativeLabel>
            {steps.map(item => (
              <Step key={item.label}>
                <StepLabel error={item.error}>{item.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ width: '100%', maxWidth: 1080, paddingX: { xs: 3, md: 2 } }}>
            <FormikProvider value={formik}>
              <>
                {activeStep === (allowDescriptionStep ? 0 : 1) && allowDescriptionStep && <Step1 invite={invite!} />}
                {activeStep === (allowDescriptionStep ? 1 : 0) && (
                  <Step2
                    invite={invite!}
                    handleNext={handleNext}
                    handleFile={handleFile}
                    handeRemoveStoredFile={handeRemoveStoredFile}
                    savedFile={savedFile}
                    token={token}
                  />
                )}
                {activeStep === (allowDescriptionStep ? 2 : 1) && (
                  <Step3 invite={invite!} progress={progress} file={formik.values.file} token={token} />
                )}
                {allowFilesAndImages && activeStep === (allowDescriptionStep ? 3 : 2) && (
                  <StepFiles invite={invite!} storyId={storyId} token={token} />
                )}
                {activeStep ===
                  (allowFilesAndImages ? (allowDescriptionStep ? 4 : 3) : allowDescriptionStep ? 3 : 2) && (
                  <Step4 invite={invite!} userData={{ name: formik.values.name, email: formik.values.email }} />
                )}
              </>
            </FormikProvider>
          </Box>
        </Box>
      </Box>
      {isNextButtonVisible() && (
        <>
          <MobileStepper
            sx={{ display: { xs: 'flex', md: 'none' }, width: '100%', position: 'fixed', bottom: 0, zIndex: 11 }}
            steps={steps.length}
            position="static"
            activeStep={activeStep}
            backButton={<div />}
            nextButton={
              <Box sx={{ position: 'relative', height: '57px', flex: 1 }}>
                <Box sx={{ position: 'absolute', right: 0 }}>{buttons({ size: 'medium' })}</Box>
              </Box>
            }
          />
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              width: '100%',
              position: 'fixed',
              bottom: 24,
              right: 24,
              zIndex: 11
            }}
          >
            <Box sx={{ flex: 1 }} />
            {buttons({ size: 'large' })}
          </Box>
        </>
      )}
    </>
  )
}

export default Invite
