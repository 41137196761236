import * as yup from 'yup'

export const schema = yup.object().shape(
  {
    instructions: yup.string().label('Instructions'),
    recordingTypeValue: yup.string(),
    expire: yup.date().label('Date').nullable(),
    size: yup.number().min(1).required().label('Time limit'),
    title: yup.string().required().label('Title'),
    callToActionInstructions: yup.string().label('Instructions'),
    callToActionButtonLabel: yup.string().when('callToAction', {
      is: (value: string) => !!value,
      then: yup.string().required().label('Button label'),
      otherwise: yup.string().label('Button label')
    }),
    callToAction: yup.string().when('callToActionButtonLabel', {
      is: (value: string) => !!value,
      then: yup.string().required().url().label('Action link'),
      otherwise: yup.string().url().label('Action link')
    })
  },
  // @ts-ignore
  ['callToAction', 'callToActionButtonLabel']
)
