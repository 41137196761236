import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import Box from '@mui/material/Box'
import Recorder from './Recorder'
import MobileUpload from './MobileUpload'
import RemoveFile from './RemoveFile'
import InstructionArea from './InstructionArea'
import { CollectionThemeProps, SubtitleType, VideoDetails } from '../../lib/types'

interface iQuestionnaireInputFileArea {
  file: File | null
  callback: (file: File | null) => void
  disableChangeMediaType?: boolean
  onSubscriptionClicked?: () => void
  countdown?: number
  hardStop?: boolean
  startType?: 'AUDIO' | 'VIDEO' | null
  quotaError?: boolean
  media?: VideoDetails
  subtitles?: SubtitleType[]
  collectionTheme?: CollectionThemeProps
}

const QuestionnaireInputFileArea: React.FC<iQuestionnaireInputFileArea> = ({
  file,
  callback,
  disableChangeMediaType,
  onSubscriptionClicked,
  countdown,
  startType,
  hardStop,
  quotaError = false,
  media,
  subtitles,
  collectionTheme
}) => {
  const [uploadType, setUploadType] = useState<'FILE' | 'RECORD' | null>(file ? 'FILE' : null)
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        alignItems: {
          xs: 'unset',
          md: 'center'
        },
        flexFlow: {
          xs: 'column',
          md: 'row'
        }
      }}
    >
      {media && <InstructionArea media={media} subtitles={subtitles} collectionTheme={collectionTheme} />}
      {!!file && (
        <Box
          sx={{
            width: { xs: '100%', md: 422 },
            height: 422,
            border: '1px dashed #7b7b7c',
            borderRadius: '20px',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <RemoveFile
            file={file}
            quotaError={quotaError}
            onSubscriptionClicked={onSubscriptionClicked}
            collectionTheme={collectionTheme}
            onRemove={() => {
              callback(null)
              setUploadType(null)
            }}
          />
        </Box>
      )}
      {!file &&
        (isMobile ? (
          <MobileUpload
            filter={startType}
            onDrop={f => {
              callback(f)
            }}
            collectionTheme={collectionTheme}
          />
        ) : (
          <>
            {(uploadType === 'RECORD' || !uploadType) && (
              <Recorder
                type={startType}
                onDrop={file => {
                  callback(file)
                }}
                onSelected={isSelected => {
                  setUploadType(isSelected ? 'RECORD' : null)
                }}
                disableChangeMediaType={disableChangeMediaType}
                countdown={countdown}
                hardStop={hardStop}
                collectionTheme={collectionTheme}
              />
            )}
          </>
        ))}
    </Box>
  )
}

export default QuestionnaireInputFileArea
