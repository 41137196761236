import React, { Fragment, useContext, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import api from '../../../lib/api'
import { StatisticType } from '../../../lib/types'
import CircularLoadingCentred from '../../../components/CircularLoadingCentred'
import { format } from 'bytes'
import { UserContext } from '../../../components/UserProvider'

const CardStatistic: React.FC<{ title: string; value: string }> = ({ title, value }) => (
  <Grid item xs={12} sm={3}>
    <Paper sx={{ padding: 4, height: '100%', display: 'flex', justifyContent: 'center', flexFlow: 'column' }}>
      <Typography variant="h5" align="center" gutterBottom sx={{ height: '60px' }}>
        {title}
      </Typography>
      <Typography variant="h5" align="center" gutterBottom>
        {value}
      </Typography>
    </Paper>
  </Grid>
)

const Statistics: React.FC = () => {
  const [statistics, setStatistics] = useState<StatisticType | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { user } = useContext(UserContext)

  const fetch = async () => {
    setIsLoading(true)
    const result = await api.statistics()
    setStatistics(result)
    setIsLoading(false)
  }

  useEffect(() => {
    fetch()
  }, [])

  return (
    <Box sx={{ width: '100%', minHeight: '100%', bgcolor: 'background.paper', padding: 2 }}>
      {isLoading ? (
        <CircularLoadingCentred />
      ) : (
        <Box sx={{ minWidth: '320px', width: '100%' }}>
          <Box sx={{ marginY: 4, maxWidth: '422px' }}>
            <Typography component="span" variant="h5">
              Accepeted referrals:{' '}
            </Typography>
            <Typography component="span" variant="h5" color="primary">
              {user.referrals.length || 0}
            </Typography>
          </Box>
          <Box sx={{ marginY: 4, maxWidth: '422px' }}>
            <Typography variant="h5">Overview</Typography>
          </Box>
          <Grid container spacing={2} alignItems="stretch">
            <CardStatistic title="Private collections" value={statistics?.collectionCountPrivate || '0'} />
            <CardStatistic title="Public collections" value={statistics?.collectionCountPublic || '0'} />
            <CardStatistic title="Draft stories" value={statistics?.storyCountDraft || '0'} />
            <CardStatistic title="Published stories" value={statistics?.storyCountPublished || '0'} />
          </Grid>
          <Box sx={{ marginY: 4, maxWidth: '422px' }}>
            <Typography variant="h5">By collection</Typography>
          </Box>
          {statistics?.storageByCollection.map(item => (
            <Fragment key={item.id}>
              <Box sx={{ marginY: 4, maxWidth: '422px' }}>
                <Link variant="h6" href={`/a/collections/${item.id}`}>
                  {item.title}
                </Link>
              </Box>
              <Grid container spacing={2} alignItems="stretch">
                <CardStatistic
                  title="Total storage"
                  value={format(parseInt(item.total || '0'), { unitSeparator: ' ' })}
                />
                <CardStatistic
                  title="Total video storage"
                  value={format(parseInt(item.video_total_storage || '0'), { unitSeparator: ' ' })}
                />
                <CardStatistic
                  title="Total audio storage"
                  value={format(parseInt(item.audio_total_storage || '0'), { unitSeparator: ' ' })}
                />
              </Grid>
            </Fragment>
          ))}
        </Box>
      )}
    </Box>
  )
}

export default Statistics
