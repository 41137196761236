import React, { ReactNode } from 'react'
import { ContentBlock, EditorBlock } from 'draft-js'

interface iTranscriptEditorBlock {
  block: ContentBlock
  blockProps: {
    showSpeakers: boolean
  }
}

const TranscriptEditorBlock: React.FC<iTranscriptEditorBlock> = props => {
  const speakerSection = props.blockProps.showSpeakers ? (
    <div
      className="transcript-editor-speaker"
      contentEditable={false}
      style={{
        MozUserSelect: 'none',
        WebkitUserSelect: 'none',
        msUserSelect: 'none'
      }}
    >
      Speaker {props.block.getData().get('speaker') + 1}
    </div>
  ) : null

  return (
    <div className="transcript-editor-block">
      {speakerSection}
      <div className="transcript-editor-text">
        <EditorBlock {...props} />
      </div>
    </div>
  )
}

TranscriptEditorBlock.propTypes = {}

export default TranscriptEditorBlock
