import React, { createContext, useState } from 'react'

export const CollectionThemeContext = createContext<{
  actions: {
    setCollectionTheme: (theme: any) => void
  }
  store: { collectionTheme: any }
}>({
  // @ts-ignore
  actions: {},
  store: {
    collectionTheme: {}
  }
})

export const CollectionThemeProvider: React.FC = ({ children }) => {
  const [collectionTheme, setCollectionTheme] = useState({})

  return (
    <CollectionThemeContext.Provider
      value={{
        actions: {
          setCollectionTheme: setCollectionTheme
        },
        store: {
          collectionTheme: collectionTheme
        }
      }}
    >
      {children}
    </CollectionThemeContext.Provider>
  )
}
