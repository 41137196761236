import React, { useContext, useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import ListItemText from '@mui/material/ListItemText'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import { FormikValues, useFormik } from 'formik'
import { CreateQuestionnaireContext } from './Context'
import schema from './schema'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import api from '../../lib/api'
import { SimpleSnackbarContext } from '../SimpleSnackbar'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { CollectionType, QuestionnaireTemplateType } from '../../lib/types'
import axios from 'axios'
import SearchCollections from '../SearchCollections'
import LoadingButton from '@mui/lab/LoadingButton'

const CreateCollection: React.FC = () => {
  const { actions } = useContext(CreateQuestionnaireContext)
  const { actions: snackActions } = useContext(SimpleSnackbarContext)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [templates, setTemplates] = useState<QuestionnaireTemplateType[]>([])

  useEffect(() => {
    const fetchTemplates = async () => {
      const templates = await api.getQuestionnaireTemplates()
      setTemplates(templates.content)
    }
    fetchTemplates()
  }, [])

  const onSubmit = async (data: FormikValues) => {
    try {
      const questionnaire = await api.createQuestionnarie({
        collectionId: data.collection.id,
        questionnaireTemplateId: data.templateId
      })
      snackActions.open('Questionnaire created.')
      actions.close(questionnaire)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // @ts-ignore
        const { code, message } = error.response.data
        if (code) {
          snackActions.open(message)
          return
        }
      }
      snackActions.open('Internal server error.')
    }
  }

  const initialValues: { collection: CollectionType | null; templateId: string | null } = {
    collection: null,
    templateId: null
  }

  const {
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    isValid,
    values,
    handleBlur,
    handleChange,
    touched,
    errors,
    handleSubmit
  } = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit
  })

  return (
    <form>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth="md"
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            actions.close()
          }
        }}
        open
      >
        <DialogTitle>
          Create questionnaire
          <IconButton
            aria-label="close"
            onClick={() => actions.close()}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            name="templateId"
            size="small"
            fullWidth
            select
            margin="dense"
            variant="outlined"
            placeholder="Select a template"
            label="Select a template"
            value={values.templateId}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.templateId && Boolean(errors.templateId)}
            helperText={(touched.templateId && errors.templateId) || ' '}
          >
            {templates.map(option => (
              <MenuItem key={option.id} value={option.id}>
                <ListItemText primary={option.title} secondary={option.description} />
              </MenuItem>
            ))}
          </TextField>
          <SearchCollections
            handleSelect={collection => {
              if (!collection) {
                setFieldValue('collection', null)
                return
              }
              setFieldValue('collection', collection)
            }}
            name="collection"
            onBlur={() => {
              setFieldTouched('collection', true)
            }}
            error={touched.collection && Boolean(errors.collection)}
            helperText={(touched.collection && errors.collection) || ' '}
            allowAdd
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => actions.close()} sx={{ mt: 1, mr: 1 }}>
            Cancel
          </Button>
          <LoadingButton
            loading={isSubmitting}
            disabled={isSubmitting || !isValid}
            variant="contained"
            onClick={() => handleSubmit()}
            sx={{ mt: 1, mr: 1 }}
          >
            Create
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </form>
  )
}

export default CreateCollection
