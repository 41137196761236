import React, { Fragment, useContext, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import { InviteType, TeamMemberRoleEnum } from '../../../lib/types'
import useInfiniteScroll from '../../../components/hooks/useInfiniteScrool'
import { usePageableRequest } from '../../../components/hooks/usePageableRequest'
import StepInviteRecorderEdit from '../../../components/StepInviteRecorderEdit'
import StepInviteRecorderChangeMedia from '../../../components/StepInviteRecorderChangeMedia'
import RequestItem from '../../../components/RequestItem'
import Typography from '@mui/material/Typography'
import api from '../../../lib/api'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import Button from '@mui/material/Button'
import { SimpleDialogContext } from '../../../components/SimpleDialog'
import { SimpleSnackbarContext } from '../../../components/SimpleSnackbar'
import useShareInvite from '../../../components/hooks/useShareInvite'
import CollectionMove from '../Story/EditBar/CollectionMove'
import { UserContext } from '../../../components/UserProvider'
import { usePermissions } from '../../../components/hooks/usePermissions'

const Requests: React.FC<RouteComponentProps> = () => {
  const handleShare = useShareInvite()
  const { actions: simpleDialogActions } = useContext(SimpleDialogContext)
  const { actions: snackActions } = useContext(SimpleSnackbarContext)
  const [openEdit, setOpenEdit] = useState<string>('')
  const [openChangeMedia, setOpenChangeMedia] = useState<string>('')
  const [searchValue, setSearchValue] = useState<string>('')
  const { member } = useContext(UserContext)
  const { isCreator } = usePermissions()
  const history = useHistory()
  const { loading, items, hasNextPage, error, loadMore, setItems, reload } = usePageableRequest<InviteType>({
    size: 15,
    q: '',
    request: api.getInvites
  })

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: !!error,
    rootMargin: '0px 0px 400px 0px'
  })

  const handleDelete = async (id: string) => {
    simpleDialogActions.open(
      'Delete request',
      'Are you sure want to delete this request?',
      { okText: 'Yes, delete', showOk: true, cancelText: 'Back' },
      async success => {
        try {
          if (success) {
            await api.deleteInvite(id)
            snackActions.open(`Request deleted with success!`)
          }
          setItems(items.filter(p => p.id !== id))
        } catch (error) {
          snackActions.open('Something was wrong! please try again.')
        }
      }
    )
  }

  useEffect(() => {
    if (member?.role === TeamMemberRoleEnum.VIEWER) {
      history.push('a/my-library')
    }
  }, [])

  return (
    <Grid
      container
      sx={{
        padding: '24px'
      }}
      spacing={4}
    >
      <Grid item xs={12}>
        <Typography variant="h6">Requests</Typography>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <TextField
            key="search"
            name="search"
            sx={{
              minWidth: { xs: '300px', MD: '422px' }
            }}
            rows={4}
            autoComplete="off"
            placeholder="Type request or collection title to filter"
            margin="dense"
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          {items
            .filter(
              p =>
                p.title.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                p.collectionTitle.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
            )
            .map(invite => (
              <Fragment key={invite.id}>
                {openEdit === invite.id && (
                  <StepInviteRecorderEdit
                    invite={invite}
                    onClose={newInvite => {
                      if (newInvite) {
                        setItems(oldItems =>
                          oldItems.map(oldItem => {
                            if (oldItem.id === newInvite.id) return newInvite
                            return oldItem
                          })
                        )
                      }
                      setOpenEdit('')
                    }}
                  />
                )}
                {openChangeMedia === invite.id && (
                  <StepInviteRecorderChangeMedia
                    invite={invite}
                    onClose={newInvite => {
                      if (newInvite) {
                        setItems(oldItems =>
                          oldItems.map(oldItem => {
                            if (oldItem.id === newInvite.id) return newInvite
                            return oldItem
                          })
                        )
                      }
                      setOpenChangeMedia('')
                    }}
                  />
                )}
                <RequestItem invite={invite}>
                  <CollectionMove
                    currentCollectionId={invite.collectionId}
                    id={invite.id}
                    reload={reload}
                    type={'invite'}
                  />
                  <Button
                    color="secondary"
                    onClick={() => {
                      api.cloneInvite(invite.id).then(() => {
                        snackActions.open(`Story request successfully duplicated!`)
                        reload()
                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                      })
                    }}
                  >
                    Duplicate
                  </Button>
                  <Button
                    color="secondary"
                    onClick={() => {
                      api.downloadInvitesSubmissions(invite.id)
                    }}
                  >
                    Download submissions
                  </Button>
                  <Button
                    onClick={() => {
                      handleShare(invite)
                    }}
                    color="secondary"
                  >
                    Share
                  </Button>
                  <Button
                    color="secondary"
                    onClick={() => {
                      setOpenEdit(invite.id)
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    color="secondary"
                    onClick={() => {
                      setOpenChangeMedia(invite.id)
                    }}
                  >
                    Add or change media instructions
                  </Button>
                  <Button
                    color="secondary"
                    onClick={() => {
                      handleDelete(invite.id)
                    }}
                    disabled={!isCreator}
                  >
                    Delete
                  </Button>
                </RequestItem>
              </Fragment>
            ))}
          {hasNextPage && (
            <Grid item xs>
              <div ref={sentryRef} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Requests
