import { InviteType, QuestionnaireType } from '../../../lib/types'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ButtonGroup from '@mui/material/ButtonGroup'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { saveAs } from 'file-saver'
import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react'
import { SimpleDialogContext } from '../../SimpleDialog'
import TextField from '@mui/material/TextField'
import { useFormik } from 'formik'
import api from '../../../lib/api'
import { SimpleSnackbarContext } from '../../SimpleSnackbar'
import schema from './schema'
// @ts-ignore
import { CopyToClipboard } from 'react-copy-to-clipboard'
import StepInviteRecorderEdit from '../../StepInviteRecorderEdit'
import StepInviteRecorderChangeMedia from '../../StepInviteRecorderChangeMedia'
import axios from 'axios'
import Grid from '@mui/material/Grid'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import LoadingButton from '@mui/lab/LoadingButton'

const Content: React.FC<{
  invite?: InviteType
  reduced?: boolean
  shareUrl?: string
  isEventRequest: boolean
  importCsv?: boolean
  isQuestionnaire?: boolean
  questionnaireId?: string
}> = ({ invite, reduced, shareUrl, isEventRequest, importCsv, isQuestionnaire, questionnaireId }) => {
  const { actions: snackActions } = useContext(SimpleSnackbarContext)
  const { actions: dialogActions } = useContext(SimpleDialogContext)
  const [openEdit, setOpenEdit] = useState(false)
  const [openChangeMedia, setOpenChangeMedia] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [currentTab, setCurrentTab] = useState(0)
  const initialValues = {
    email: '',
    name: '',
    jobTitle: '',
    functionalArea: '',
    message: ''
  }
  const { values, handleSubmit, handleChange, handleBlur, errors, touched, isValid, dirty } = useFormik({
    initialValues,
    onSubmit: async (values, { setValues, resetForm }) => {
      if (currentTab === 0) {
        if (selectedFile && invite) {
          try {
            await api.shareCollectionInviteByCsv(invite?.id, selectedFile, values.message, isQuestionnaire)
            snackActions.open('Shared questionnaire with imported users successfully')
          } catch (error) {
            if (axios.isAxiosError(error)) {
              // @ts-ignore
              const { code, message } = error.response.data
              if (code) {
                snackActions.open(message)
              } else {
                snackActions.open(
                  'Ops! Something went wrong. Make sure you are trying to import a .csv file with the fields "name", "email", "job title" and "functional area"'
                )
              }
            }
          }
        }
      } else {
        try {
          await api.sendInviteEmails(
            invite!.id,
            [
              {
                name: values.name,
                email: values.email,
                jobTitle: values.jobTitle,
                functionalArea: values.functionalArea
              }
            ],
            values.message,
            Boolean(isQuestionnaire)
          )
          snackActions.open(`An email has been sent for ${values.email}.`)
          resetForm({ values: initialValues })
        } catch (e) {
          console.error(e)
        }
      }
    },
    validationSchema: schema
  })

  const handleChangeTab = (event: any, newValue: number) => {
    setCurrentTab(newValue)
  }

  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const handleUploadQuestionsCsv = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
      fileInputRef.current.click()
    }
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0]
    setSelectedFile(file)
  }

  const getSelectedFileName = () => {
    if (selectedFile) {
      return selectedFile.name
    }
    return 'No file selected'
  }

  const validateForm = () => {
    if (currentTab === 0) {
      return !selectedFile
    } else if (currentTab === 1) {
      return !values.name || !values.email
    }
  }

  const handleSendReminders = async () => {
    const confirm = window.confirm('Are you sure you want to send a email reminder to all invitees?')
    if (confirm) {
      try {
        if (!questionnaireId) {
          questionnaireId = invite?.questionnaireId
        }
        console.log('Sending the Reminder for questionnaire', questionnaireId, ' and ', invite?.id)

        await api.sendQuestionnaireReminder(questionnaireId, invite?.id)
        console.log('Invites were sent!')
        snackActions.open('Success, reminder(s) were sent!')
      } catch (error) {
        if (axios.isAxiosError(error)) {
          snackActions.open('Oops! Something went wrong. The reminder(s) were not sent!')
        }
      }
    }
  }

  // const sendQuestionnaireReminder = async () => {
  //   try {
  //     await api.sendQuestionnaireReminder(questionnaireId, invite?.id)
  //     console.log('Invites were sent!')
  //     snackActions.open('Success, reminder(s) were sent!')
  //   } catch (error) {
  //     if (axios.isAxiosError(error)) {
  //       snackActions.open('Oops! Something went wrong. The reminder(s) were not sent!')
  //     }
  //   }
  // }

  const handleTabContent = () => {
    if (currentTab === 0) {
      return (
        <Box sx={{ width: '100%' }}>
          <Typography gutterBottom>Import a csv file to invite or copy/share the link:</Typography>
          <input ref={fileInputRef} type="file" accept=".csv" style={{ display: 'none' }} onChange={handleFileChange} />
          <Button variant="outlined" sx={{ height: '56px', marginRight: '12px' }} onClick={handleUploadQuestionsCsv}>
            Import CSV
          </Button>
          <span>{getSelectedFileName()}</span>
        </Box>
      )
    } else {
      return (
        <Box sx={{ width: '100%' }}>
          <Typography gutterBottom>Enter an email to invite or copy/share the link:</Typography>
          <Grid container>
            <Grid item xs={6} md={6} sx={{ paddingRight: '0.5em' }}>
              <TextField
                name="email"
                label="Email"
                fullWidth
                value={values.email}
                error={touched.email && Boolean(errors.email)}
                helperText={(touched.email && errors.email) || ' '}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={6} md={6} sx={{ paddingRight: '0.5em' }}>
              <TextField
                name="name"
                label="Name"
                fullWidth
                value={values.name}
                error={touched.name && Boolean(errors.name)}
                helperText={(touched.name && errors.name) || ' '}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} md={6} sx={{ paddingRight: '0.5em' }}>
              <TextField
                name="jobTitle"
                label="Job title"
                fullWidth
                value={values.jobTitle}
                helperText={(touched.jobTitle && errors.jobTitle) || ' '}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={6} md={6} sx={{ paddingRight: '0.5em' }}>
              <TextField
                name="functionalArea"
                label="Functional Area"
                fullWidth
                value={values.functionalArea}
                helperText={(touched.functionalArea && errors.functionalArea) || ' '}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
          </Grid>
        </Box>
      )
    }
  }

  return (
    <>
      {!reduced && invite && (
        <>
          <Tabs value={currentTab} onChange={handleChangeTab} centered sx={{ marginBottom: '28px' }}>
            {importCsv && <Tab label="Batch Inviting - Import CSV" />}
            <Tab label="Individual Invite" />
          </Tabs>
          <Stack onSubmit={handleSubmit} component="form" alignItems="center" spacing={1}>
            {handleTabContent()}
            <TextField
              multiline={true}
              minRows={2}
              sx={{ paddingRight: '0.5em' }}
              name="message"
              label="Custom email text"
              fullWidth
              value={values.message}
              error={touched.message && Boolean(errors.message)}
              helperText={(touched.message && errors.message) || ' '}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Box style={{ width: '100%', justifyContent: 'flex-start', display: 'flex', gap: '8px' }}>
              <Button disabled={validateForm()} type="submit" size="large" variant="outlined" sx={{ minWidth: '8em' }}>
                Send invite
              </Button>
              <LoadingButton
                onClick={handleSendReminders}
                size="large"
                variant="outlined"
                sx={{ minWidth: '8em', justifyContent: 'flex-end', marginLeft: '1em' }}
              >
                Send reminder to invitee(s)
              </LoadingButton>
            </Box>
          </Stack>
        </>
      )}

      <Box
        sx={{
          minWidth: 350,
          p: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexFlow: 'column'
        }}
      >
        <Typography sx={{ mb: 4 }}>Share link:</Typography>
        <img
          src={`https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=${invite?.url ?? shareUrl}`}
          width="200px"
          height="200px"
        />
        <Box
          sx={{
            mt: 2
          }}
        >
          <ButtonGroup variant="outlined">
            <CopyToClipboard text={invite?.url ?? shareUrl} options={{ format: 'text' }}>
              <Button>Copy link to clipboard</Button>
            </CopyToClipboard>
            <Button
              onClick={() =>
                saveAs(
                  `https://api.qrserver.com/v1/create-qr-code/?size=600x600&data=${invite?.url ?? shareUrl}`,
                  `${invite?.url ?? shareUrl}.jpg`
                )
              }
            >
              Download QR Code
            </Button>
          </ButtonGroup>
        </Box>
        {isEventRequest && (
          <Box
            sx={{
              mt: 2
            }}
          >
            <ButtonGroup variant="outlined">
              <Button
                onClick={() => {
                  setOpenEdit(true)
                }}
              >
                Edit this request
              </Button>
              <Button
                onClick={() => {
                  setOpenChangeMedia(true)
                }}
              >
                Add / change media instructions
              </Button>
            </ButtonGroup>
          </Box>
        )}
        {openEdit && invite && (
          <StepInviteRecorderEdit
            invite={invite}
            onClose={() => {
              setOpenEdit(false)
            }}
          />
        )}
        {openChangeMedia && invite && (
          <StepInviteRecorderChangeMedia
            invite={invite}
            onClose={() => {
              setOpenChangeMedia(false)
            }}
          />
        )}
      </Box>
    </>
  )
}

export default () => {
  const { actions: simpleDialogActions } = useContext(SimpleDialogContext)

  const [invite, setInvite] = useState<InviteType | undefined>(undefined)
  const [reduced, setReduced] = useState(false)
  const [isQuestionnaire, setIsQuestionnaire] = useState(false)
  const [shareUrl, setShareUrl] = useState<string | undefined>(undefined)
  const [isEventRequest, setIsEventRequest] = useState(false)
  const [importCsv, setImportCsv] = useState(false)
  const [customText, setCustomText] = useState('')
  const [questionnaireId, setQuestionnaireId] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (!invite && !shareUrl) return
    simpleDialogActions.open(
      'Share',
      <Content
        invite={invite}
        reduced={reduced}
        shareUrl={shareUrl}
        isEventRequest={isEventRequest}
        importCsv={importCsv}
        isQuestionnaire={isQuestionnaire}
        questionnaireId={questionnaireId}
      />,
      { cancelText: 'Close', showOk: false },
      () => {
        setInvite(undefined)
        setShareUrl(undefined)
        setImportCsv(false)
        setCustomText('')
        setQuestionnaireId(undefined)
      }
    )
  }, [invite, shareUrl, importCsv, customText, isQuestionnaire])

  const handleCopy = async (
    inviteType?: InviteType,
    reduced?: boolean,
    shareUrl?: string,
    isEventRequest?: boolean,
    importCv?: boolean,
    isQuest?: boolean,
    questionnaireId?: string
  ) => {
    isEventRequest && setIsEventRequest(true)
    reduced && setReduced(reduced)
    inviteType && setInvite(inviteType)
    shareUrl && setShareUrl(shareUrl)
    importCv && setImportCsv(importCv)
    isQuest && setIsQuestionnaire(isQuest)
    questionnaireId && setQuestionnaireId(questionnaireId)
  }

  return handleCopy
}
