import React, { createContext, useState } from 'react'
import Component from './Component'
import { CollectionThemeProps } from '../../lib/types'

export const SignupDialogContext = createContext<{
  actions: {
    open: () => void
    close: () => void
    setCollectionAsType: () => void
    setStoryAsType: () => void
    setSignedUpTrue: () => void
    setIsNewAccountTrue: () => void
    setIsCollectionPrivate: (isPrivate: boolean) => void
    setCollectionId: (id: string) => void
    openWithStyles: (style: CollectionThemeProps, logoUrl?: string) => void
  }
  store: { type: string; signedUp: boolean; isNewAccount: boolean; isCollectionPrivate: boolean; collectionId: string }
}>({
  // @ts-ignore
  actions: {},
  store: {
    type: '',
    signedUp: false,
    isNewAccount: false,
    isCollectionPrivate: false,
    collectionId: ''
  }
})

export const SignupDialogProvider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [type, setType] = useState('')
  const [signedUp, setSignedUp] = useState(false)
  const [isNewAccount, setIsNewAccount] = useState(false)
  const [isCollectionPrivate, setIsCollectionPrivate] = useState(false)
  const [collectionId, setCollectionId] = useState('')
  const [logoUrl, setLogoUrl] = useState('')
  const [collectionTheme, setCollectionTheme] = useState<CollectionThemeProps>({})

  const open = () => {
    setIsOpen(true)
  }

  const close = () => {
    setIsOpen(false)
  }

  const setCollectionAsType = () => {
    setType('Collection')
  }

  const setStoryAsType = () => {
    setType('Story')
  }

  const setSignedUpTrue = () => {
    setSignedUp(true)
  }

  const setIsNewAccountTrue = () => {
    setIsNewAccount(true)
  }

  const openWithStyles = (style: CollectionThemeProps, logoUrl?: string) => {
    setCollectionTheme(style)
    logoUrl && setLogoUrl(logoUrl)
    setIsOpen(true)
  }

  return (
    <SignupDialogContext.Provider
      value={{
        actions: {
          open,
          close,
          setCollectionAsType,
          setStoryAsType,
          setSignedUpTrue,
          setIsNewAccountTrue,
          setIsCollectionPrivate,
          setCollectionId,
          openWithStyles
        },
        store: {
          type: type,
          signedUp: signedUp,
          isNewAccount: isNewAccount,
          isCollectionPrivate: isCollectionPrivate,
          collectionId: collectionId
        }
      }}
    >
      {isOpen && <Component collectionTheme={collectionTheme} logoUrl={logoUrl} />}
      {children}
    </SignupDialogContext.Provider>
  )
}
