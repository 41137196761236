import React, { createContext, useState } from 'react'
import Component from './Component'
import { QuestionnaireType } from '../../lib/types'

// @ts-ignore
export const UpdateQuestionnairesDialogContext = createContext<{
  actions: {
    open: (callback: (ids: string[] | null) => void, questionnaires: QuestionnaireType[]) => void
    close: (reload?: boolean) => void
    callback: ((ids: string[] | null) => void) | undefined
  }
  store: {
    isOpen: boolean
  }
}>({
  // @ts-ignore
  actions: {}
})

export const UpdateQuestionnairesDialogProvider: React.FC = ({ children }) => {
  const [questionnaires, setQuestionnaires] = useState<QuestionnaireType[]>([])

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const [callBack, setCallBack] = useState<((ids: string[] | null) => void) | undefined>(undefined)
  const open = (callback: (ids: string[] | null) => void, questionnaireList: QuestionnaireType[]) => {
    setQuestionnaires(questionnaireList)
    setCallBack(() => callback)
    setIsOpen(true)
  }

  const close = () => {
    setIsOpen(false)
  }

  return (
    <UpdateQuestionnairesDialogContext.Provider
      value={{
        actions: {
          close,
          open,
          callback: callBack
        },
        store: {
          isOpen
        }
      }}
    >
      <Component questionnaires={questionnaires} />
      {children}
    </UpdateQuestionnairesDialogContext.Provider>
  )
}
