import React, { useContext, useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'
import { useTheme } from '@mui/material/styles'
import { Box } from '@mui/material'
import PinturaVideo from '../PinturaVideo'
import { VideoEditDialogContext } from './Context'
import { SimpleDialogContext } from '../SimpleDialog'
import { SimpleSnackbarContext } from '../SimpleSnackbar'
import api from '../../lib/api'

const VideoEditDialog: React.FC<{ handleClose: () => void }> = ({ handleClose }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xl'))
  const { store } = useContext(VideoEditDialogContext)
  const [file, setFile] = useState<File | undefined>(undefined)
  const [imageState, setImageState] = useState('')

  const { actions: simpleDialogActions } = useContext(SimpleDialogContext)
  const { actions: snackActions } = useContext(SimpleSnackbarContext)

  const exit = () => {
    if (imageState) {
      simpleDialogActions.open(
        'Save your work',
        'Do you wish to save your changes before exiting the Editor? Please note that once changes are saved they cannot be undone.',
        { okText: `Yes, save and exit`, cancelText: 'No, exit without saving', showOk: true },
        async success => {
          if (success) {
            try {
              await api.editVideo(store.storyId, JSON.stringify(imageState), file as File, store.userId)
              snackActions.open('Your video is processing. Please refresh the page shortly to view the changes!')
            } catch (e) {
              snackActions.open('Ops! Something went wrong processing your video. Please try again.')
              console.log(e)
            } finally {
              handleClose()
            }
          } else {
            handleClose()
          }
        }
      )
    } else {
      handleClose()
    }
  }

  return (
    <Dialog fullScreen={fullScreen} open={true} fullWidth maxWidth="xl">
      <DialogTitle>Rakonto video editor</DialogTitle>
      <Box justifyContent="center" alignItems="center">
        <PinturaVideo
          url={store.url}
          storyId={store.storyId}
          userId={store.userId}
          handleClose={handleClose}
          setLastImageState={setImageState}
          setInitialFile={setFile}
        />
      </Box>
      <DialogActions>
        <Button color="secondary" onClick={exit}>
          Exit editor without saving
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default VideoEditDialog
