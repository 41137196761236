import React, { useRef, useState } from 'react'
import {
  Editor,
  EditorState,
  CharacterMetadata,
  getDefaultKeyBinding,
  ContentState,
  SelectionState,
  DraftHandleValue,
  ContentBlock,
  BlockMap,
  BlockMapBuilder
} from 'draft-js'
import Immutable from 'immutable'
import clsx from 'clsx'

import updateBlock from './helpers/updateBlock'
import TranscriptEditorBlock from './TranscriptEditorBlock'
import { TRANSCRIPT_WORD, TRANSCRIPT_SPACE } from './helpers/TranscriptEntities'
import Box from '@mui/material/Box'
import FindReplace from './FindReplace'

interface iTranscriptEditor {
  editorState?: EditorState
  speakers?: Immutable.List<string>
  onChange: (value: { newEditorState: EditorState; newSpeakers: Immutable.List<string> }) => void
  onSelectionChange?: (value: unknown) => void
  onKeyboardEvent?: (value: unknown) => void
  disabled?: boolean
  showSpeakers?: boolean
  isEditable?: boolean
  setEditorState: (editorState: EditorState) => void
}

const TranscriptEditor: React.FC<iTranscriptEditor> = ({
  editorState = EditorState.createEmpty(),
  onChange,
  speakers = Immutable.List(),
  onSelectionChange = null,
  onKeyboardEvent = null,
  disabled = false,
  showSpeakers = true,
  isEditable = false,
  setEditorState
}) => {
  const editor = useRef<Editor>(null)
  const [findText, setFindText] = useState('')
  const [replaceText, setReplaceText] = useState('')
  const sendSelectionChange = (contentState: ContentState, selectionState: SelectionState) => {
    const startKey = selectionState.getIsBackward() ? selectionState.getFocusKey() : selectionState.getAnchorKey()
    const startOffset = selectionState.getIsBackward()
      ? selectionState.getFocusOffset()
      : selectionState.getAnchorOffset()
    const endKey = selectionState.getIsBackward() ? selectionState.getAnchorKey() : selectionState.getFocusKey()
    const endOffset = selectionState.getIsBackward()
      ? selectionState.getAnchorOffset()
      : selectionState.getFocusOffset()

    const startEntityKey = contentState.getBlockForKey(startKey).getEntityAt(startOffset)
    const endEntityKey = contentState.getBlockForKey(endKey).getEntityAt(endOffset)

    const startEntity = startEntityKey && contentState.getEntity(startEntityKey)
    const endEntity = endEntityKey && contentState.getEntity(endEntityKey)

    onSelectionChange &&
      onSelectionChange({
        startTime: startEntity && startEntity.getData().start,
        startWordID: startEntity && startEntity.getData().id,
        startBlockKey: startKey,
        startCharacterOffset: startOffset,
        endTime: endEntity && endEntity.getData().end,
        endID: endEntity && endEntity.getData().id,
        endBlockKey: endKey,
        endCharacterOffset: endOffset
      })
  }

  const handleChange = (newEditorState: EditorState) => {
    if (disabled) {
      return
    }

    const previousEditorState = editorState
    const lastChangeType = newEditorState.getLastChangeType()

    const selectionState = newEditorState.getSelection()
    const previousSelectionState = previousEditorState.getSelection()

    let contentState = newEditorState.getCurrentContent()

    if (onSelectionChange && selectionState !== previousSelectionState) {
      sendSelectionChange(contentState, selectionState)
    }

    if (lastChangeType !== 'undo' && contentState !== previousEditorState.getCurrentContent()) {
      const startKey = selectionState.getStartKey()
      const previousStartKey = previousSelectionState.getStartKey()

      const blockMap = contentState.getBlockMap()

      // @ts-ignore
      const newBlockMap = blockMap.reduce<BlockMap>((_newBlockMap, contentBlock, blockKey) => {
        let newContentBlock = contentBlock!

        // Is this the block currently being edited?
        if (blockKey === startKey) {
          // Has everything been deleted from the block?
          if (newContentBlock?.getCharacterList().isEmpty()) {
            // Remove it
            return _newBlockMap
          }

          const startOffset = selectionState.getStartOffset()
          // Have we merged blocks?
          if (blockMap.size < previousEditorState.getCurrentContent().getBlockMap().size) {
            // Do we have two adjacent words?
            if (
              contentState.getEntity(newContentBlock.getCharacterList().get(startOffset).getEntity()).getType() ===
                TRANSCRIPT_WORD &&
              contentState
                .getEntity(
                  newContentBlock
                    .getCharacterList()
                    .get(startOffset - 1)
                    .getEntity()
                )
                .getType() === TRANSCRIPT_WORD
            ) {
              // Add a space
              newContentBlock = newContentBlock
                .set(
                  'characterList',
                  newContentBlock.getCharacterList().insert(
                    startOffset,
                    CharacterMetadata.applyEntity(
                      CharacterMetadata.create(),
                      (() => {
                        contentState = contentState.createEntity('TRANSCRIPT_SPACE', 'IMMUTABLE', undefined)
                        return contentState.getLastCreatedEntityKey()
                      })()
                    )
                  )
                )
                .set(
                  'text',
                  `${newContentBlock?.getText().slice(0, startOffset)}` +
                    ` ${newContentBlock?.getText().slice(startOffset)}`
                ) as ContentBlock
            }
          }

          // Update the entities
          const updatedBlock = updateBlock(newContentBlock, contentState)
          newContentBlock = newContentBlock.merge({
            text: updatedBlock.getText(),
            characterList: updatedBlock.getCharacterList()
          }) as ContentBlock

          // Have we created a leading space? (e.g. when splitting a block)
          if (
            contentState.getEntity(newContentBlock.getCharacterList().first().getEntity()).getType() ===
            TRANSCRIPT_SPACE
          ) {
            // Remove the leading space
            newContentBlock = newContentBlock
              .set('characterList', newContentBlock.getCharacterList().shift())
              .set('text', newContentBlock?.getText().substring(1)) as ContentBlock
          }

          // Is this block missing data? (e.g. it's been split)
          if (newContentBlock.getData().isEmpty()) {
            // Copy the previous block's data
            newContentBlock = newContentBlock.set('data', _newBlockMap!.last().getData()) as ContentBlock
          }
          // Otherwise is this the block previously being edited? (e.g. that was split)
        } else if (blockKey === previousStartKey) {
          // Have we created a trailing space?
          if (
            contentState.getEntity(newContentBlock.getCharacterList().last().getEntity()).getType() === TRANSCRIPT_SPACE
          ) {
            // Remove the trailing space
            newContentBlock = newContentBlock
              .set('characterList', newContentBlock.getCharacterList().pop())
              .set('text', newContentBlock.getText().substring(0, newContentBlock.getText().length - 1)) as ContentBlock
          }
        }

        return _newBlockMap!.set(blockKey!, newContentBlock)
      }, BlockMapBuilder.createFromArray([]))

      contentState = contentState.set('blockMap', newBlockMap) as ContentState

      onChange &&
        onChange({
          newEditorState: EditorState.push(previousEditorState, contentState, lastChangeType),
          newSpeakers: speakers
        })
      return
    }
    onChange &&
      onChange({
        newEditorState,
        newSpeakers: speakers
      })
  }

  const handleBeforeInput = (chars: string): DraftHandleValue => {
    // Don't allow inserting additional spaces between words
    if (chars === ' ') {
      const contentState = editorState.getCurrentContent()
      const selectionState = editorState.getSelection()
      const startKey = selectionState.getStartKey()
      const startOffset = selectionState.getStartOffset()
      const selectedBlock = editorState.getCurrentContent().getBlockForKey(startKey)
      const entityKeyBefore = selectedBlock.getEntityAt(startOffset - 1)
      if (entityKeyBefore && contentState.getEntity(entityKeyBefore).getType() === TRANSCRIPT_SPACE) {
        return 'handled'
      }
    }
    return 'not-handled'
  }

  const blockRenderer = () => {
    return {
      component: TranscriptEditorBlock,
      props: {
        showSpeakers
      }
    }
  }

  const focus = () => {
    editor?.current?.focus()
  }

  const handleReturn = (): DraftHandleValue => {
    const contentState = editorState.getCurrentContent()
    const selectionState = editorState.getSelection()
    const startKey = selectionState.getStartKey()
    const startOffset = selectionState.getStartOffset()
    const selectedBlock = editorState.getCurrentContent().getBlockForKey(startKey)
    const entityKeyBefore = selectedBlock.getEntityAt(startOffset - 1)
    const entityKeyAfter = selectedBlock.getEntityAt(startOffset)
    if (
      (entityKeyBefore && contentState.getEntity(entityKeyBefore).getType() === TRANSCRIPT_SPACE) ||
      (entityKeyAfter && contentState.getEntity(entityKeyAfter).getType() === TRANSCRIPT_SPACE)
    ) {
      return 'not-handled'
    }
    return 'handled'
  }

  const handlePastedText = (): DraftHandleValue => {
    return 'handled'
  }
  const handleEditorChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState)
    handleChange(newEditorState)
  }

  return (
    <Box>
      <div className={clsx(['transcript-editor', { isEditable: isEditable }])}>
        <FindReplace
          editorState={editorState}
          setEditorState={handleEditorChange}
          isEditable={isEditable}
          handleChange={handleChange}
        />
        <Editor
          readOnly={!isEditable}
          ref={editor}
          editorState={editorState}
          onChange={handleChange}
          handleReturn={handleReturn}
          handleBeforeInput={handleBeforeInput}
          keyBindingFn={e => {
            if (getDefaultKeyBinding(e)) {
              return getDefaultKeyBinding(e)
            }
            if (onKeyboardEvent) {
              onKeyboardEvent(e)
            }
            return null
          }}
          handlePastedText={handlePastedText}
          blockRendererFn={blockRenderer}
        />
      </div>
    </Box>
  )
}
export default TranscriptEditor
