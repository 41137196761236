import React, { createContext, useCallback, useEffect, useState } from 'react'
import { ImageType, TeamMemberType, UserType } from '../../lib/types'
import api from '../../lib/api'
import Cookies from 'js-cookie'
import initials from 'initials'
import * as Sentry from '@sentry/browser'
import { hotjar } from 'react-hotjar'

// @ts-ignore
export const UserContext = createContext<{
  refetch: () => Promise<void>
  setUser: (user: UserType) => void
  member: TeamMemberType | null
  updatePicture: (picture: ImageType | null) => void
  user: UserType & { initials: string; fullName: string }
  isLoading: boolean
}>(
  // @ts-ignore
  {}
)

export const UserProvider: React.FC<{ initialUser?: UserType | null }> = ({ children, initialUser }) => {
  const [userString, setUserString] = useState<string>()
  const [loading, setLoading] = useState<boolean>(true)
  const [member, setMember] = useState<null | TeamMemberType>(null)
  const tenantCookie = Cookies.get('tenant')

  const save = (user: UserType) => {
    const userStringified = JSON.stringify(user)
    Cookies.set('user', userStringified)
    setUserString(userStringified)
  }

  const fetch = useCallback(async () => {
    setLoading(true)
    try {
      let user
      if (tenantCookie) {
        const tenantMember = JSON.parse(tenantCookie)
        const response = await api.switchLibrary(tenantMember.userId, tenantMember.tenantId)
        if (response) setMember(response.member)
        user = await api.getMe(tenantMember?.userId)
      } else {
        setMember(null)
        user = await api.getMe()
      }

      if (user) {
        Sentry.setUser({ email: user.email, id: user.id })
        try {
          hotjar.identify('USER_ID', { email: user.email, id: user.id })
        } catch (e) {}
      }
      save(user)
    } finally {
      setLoading(false)
    }
  }, [setUserString, setLoading])

  useEffect(() => {
    fetch()
  }, [])

  const updatePicture = (picture: ImageType | null) => {
    const userLocal = JSON.parse(userString as string)
    userLocal.picture = picture
    const userLocalString = JSON.stringify(userLocal)
    Cookies.set('user', userLocalString)
    setUserString(userLocalString)
  }

  useEffect(() => {
    setUserString(JSON.stringify(initialUser))

    if (initialUser) {
      Sentry.setUser({ email: initialUser.email, id: initialUser.id })
      try {
        hotjar.identify('USER_ID', { email: initialUser.email, id: initialUser.id })
      } catch (e) {}
    }
  }, [initialUser])

  return (
    <UserContext.Provider
      value={{
        refetch: fetch,
        isLoading: loading,
        setUser: save,
        member: member,
        updatePicture,
        user: useCallback(() => {
          if (userString) {
            const user = JSON.parse(userString)
            const fullName = `${user.firstName} ${user.lastName}`
            return { ...user, initials: initials(fullName), fullName }
          }
        }, [userString])()
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
