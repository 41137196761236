import React, { useState, createContext } from 'react'
import Component from './Component'

// @ts-ignore
export const StepInviteContributorContext = createContext<{
  actions: {
    open: (id: string, reload?: () => void) => void
    close: () => void
  }
}>({
  // @ts-ignore
  actions: {}
})

export const StepInviteContributorProvider: React.FC = ({ children }) => {
  const [stepStoryUpload, setStepInviteContributor] = useState<boolean>(false)
  const [storyId, setStoryId] = useState<string>('')
  const [callback, setCallback] = useState<(() => void) | undefined>(undefined)

  const open = (id: string, reload?: () => void) => {
    setStoryId(id)
    setStepInviteContributor(true)
    if (reload) setCallback(() => reload)
  }

  const close = () => {
    setStoryId('')
    setStepInviteContributor(false)
    if (callback) callback()
  }

  return (
    <StepInviteContributorContext.Provider
      value={{
        actions: {
          open,
          close
        }
      }}
    >
      {stepStoryUpload && <Component storyId={storyId} />}
      {children}
    </StepInviteContributorContext.Provider>
  )
}
