import * as React from 'react'
import { useContext, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { FormDialogContext } from './Context'
import { Form, Formik, FormikValues } from 'formik'
import TextField from '@mui/material/TextField'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import LoadingButton from '@mui/lab/LoadingButton'

const FormDialog: React.FC = () => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const { store, actions } = useContext(FormDialogContext)
  const [isLoading, setIsLoading] = useState(false)

  const submit = async (values: FormikValues) => {
    setIsLoading(true)
    await store.submit(values)
    setIsLoading(false)
    actions.close()
  }

  return (
    <Formik
      initialValues={store.initialValues}
      enableReinitialize
      validationSchema={store.validationSchema}
      onSubmit={submit}
    >
      {({ isSubmitting, handleBlur, values, handleChange, touched, errors, handleSubmit }) => (
        <Form>
          <Dialog fullScreen={fullScreen} open={true} fullWidth maxWidth="sm">
            <DialogTitle>{store.title}</DialogTitle>
            <DialogContent>
              <DialogContentText sx={{ paddingBottom: 3 }}>{store.content}</DialogContentText>
              {store.fields?.map(field => {
                if (field.type === 'select') {
                  return (
                    <>
                      <TextField
                        key={field.name}
                        name={field.name}
                        fullWidth
                        select
                        margin="dense"
                        variant="outlined"
                        type={field.type}
                        label={field.label}
                        placeholder={field.placeholder}
                        value={values[field.name]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched[field.name] && Boolean(errors[field.name])}
                        helperText={(touched[field.name] && errors[field.name]) || ' '}
                      >
                        {field.options}
                      </TextField>
                      {store.additionalInfo && (
                        <>
                          {store.additionalInfoTitle && (
                            <Typography sx={{ color: '#b9b9b9' }}>{store.additionalInfoTitle}</Typography>
                          )}
                          <Box
                            sx={{
                              border: '1px solid #1e1e1e',
                              borderRadius: '8px',
                              padding: '16px',
                              backgroundColor: '#1D2120'
                            }}
                          >
                            {store.additionalInfo(values[field.name])}
                          </Box>
                        </>
                      )}
                    </>
                  )
                }
                return (
                  <TextField
                    key={field.name}
                    name={field.name}
                    multiline={!!field.rows}
                    minRows={field.rows}
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    type={field.type}
                    placeholder={field.placeholder}
                    label={field.label}
                    value={values[field.name]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched[field.name] && Boolean(errors[field.name])}
                    helperText={(touched[field.name] && errors[field.name]) || ' '}
                  />
                )
              })}
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={actions.close}>
                {store.cancelText}
              </Button>
              {store.loadingButton ? (
                <LoadingButton variant="contained" loading={isLoading} onClick={() => handleSubmit()}>
                  {store.okText}
                </LoadingButton>
              ) : (
                <Button variant="outlined" autoFocus onClick={() => handleSubmit()}>
                  {store.okText}
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  )
}

export default FormDialog
