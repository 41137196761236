import React, { createContext, useState } from 'react'
import Component from './Component'
import { QuizInviteType } from '../../lib/types'

// @ts-ignore
export const CreateQuestionnaireContext = createContext<{
  actions: {
    open: (callback: (questionnaire: QuizInviteType | QuizInviteType[] | null) => void) => void
    close: (questionnaire?: QuizInviteType) => void
  }
}>({
  // @ts-ignore
  actions: {}
})

export const CreateQuestionnaireProvider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [callBack, setCallBack] = useState<((questionnaire: QuizInviteType | null) => void) | undefined>(undefined)

  const open = (callback: (questionnaire: QuizInviteType | null) => void, title?: string) => {
    setIsOpen(true)
    setCallBack(() => callback)
  }

  const close = (questionnaire?: QuizInviteType) => {
    setIsOpen(false)
    if (callBack) callBack(questionnaire || null)
    setCallBack(undefined)
  }

  return (
    <CreateQuestionnaireContext.Provider
      value={{
        actions: {
          open,
          close
        }
      }}
    >
      {isOpen && <Component />}
      {children}
    </CreateQuestionnaireContext.Provider>
  )
}
