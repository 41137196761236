import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import api from '../lib/api'

const ShortId: React.FC<RouteComponentProps<{ id: string }>> = ({ match, history, location }) => {
  const { id } = match.params
  const search = location.search

  useEffect(() => {
    const getId = async () => {
      try {
        const response = await api.getShortId(id)
        window.location.href = response.url + (search ? `&${search.substring(1)}` : '')
      } catch (e) {
        history.push('/')
      }
    }

    getId()
  }, [id])

  return null
}

export default ShortId
