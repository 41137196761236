import React, { createContext, useContext, useEffect, useState } from 'react'
import Component from './Component'
import { useLocation } from 'react-router-dom'
import { UserContext } from '../UserProvider'
import Cookies from 'js-cookie'
import { TeamMemberType } from '../../lib/types'
import api from '../../lib/api'
import { SimpleSnackbarContext } from '../SimpleSnackbar'

// @ts-ignore
export const SwitchLibraryDialogContext = createContext<{
  actions: {
    open: () => void
    close: (member?: TeamMemberType, reload?: boolean) => void
    switchLibrary: (id: string) => void
  }
  store: {
    isOpen: boolean
  }
}>({
  // @ts-ignore
  actions: {},
  store: {
    isOpen: false
  }
})

export const SwitchLibraryDialogProvider: React.FC = ({ children }) => {
  const location = useLocation()
  const { user } = useContext(UserContext)
  const tenantCookie = Cookies.get('tenant')
  const alreadySelectedLibraryCookie = Cookies.get('alreadySelectedLibrary')
  const [isOpen, setIsOpen] = useState(false)
  const { actions: snackActions } = useContext(SimpleSnackbarContext)

  const open = () => {
    setIsOpen(true)
  }

  const close = (member?: TeamMemberType, reload?: boolean) => {
    setIsOpen(false)
    if (member) {
      Cookies.set('tenant', JSON.stringify({ userId: member.userId, tenantId: member.tenant.id }))
    }
    if (user && user.hasOwnLibrary && !tenantCookie && !member) {
      Cookies.set('alreadySelectedLibrary', 'true')
    }
    if (member || reload) {
      window.location.reload()
    }
  }

  const switchLibrary = async (tenantId: string) => {
    if (tenantId === 'default') {
      const backupToken = Cookies.get('backupToken')
      if (backupToken) {
        Cookies.set('token', backupToken)
        Cookies.remove('tenant')
        Cookies.set('alreadySelectedLibrary', 'true')
        snackActions.open('Switched to your own library!')
        close(undefined, true)
      } else {
        snackActions.open('Something went wrong! please try again.')
      }
      return
    }
    try {
      const response = await api.switchLibrary(user.id, tenantId)
      if (response) close(response.member)
    } catch (error) {
      // @ts-ignore
      const { data, message } = error.response
      if (data?.code) {
        snackActions.open(message)
        throw error
      }
      snackActions.open('Something went wrong! please try again.')
      throw error
    }
  }

  useEffect(() => {
    if (user?.userOfTenant && !tenantCookie && !alreadySelectedLibraryCookie) {
      setIsOpen(true)
    }
  }, [user, location])

  return (
    <SwitchLibraryDialogContext.Provider
      value={{
        actions: {
          open,
          close,
          switchLibrary
        },
        store: {
          isOpen: isOpen
        }
      }}
    >
      <Component />
      {children}
    </SwitchLibraryDialogContext.Provider>
  )
}
