import * as yup from 'yup'

export default yup.object().shape(
  {
    email: yup.string().when('name', {
      is: (value: string) => !!value,
      then: yup.string().email().required().label('Email'),
      otherwise: yup.string().email().label('Email')
    }),
    name: yup.string().when('email', {
      is: (value: string) => !!value,
      then: yup.string().required().label('Name'),
      otherwise: yup.string().label('Name')
    })
  },
  // @ts-ignore
  ['name', 'email']
)
