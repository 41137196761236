import React, { useState, createContext } from 'react'
import Component from './Component'

// @ts-ignore
export const AddReferralCodeDialogContext = createContext<{
  actions: {
    open: () => void
  }
}>({
  // @ts-ignore
  actions: {}
})

export const AddReferralCodeDialogProvider: React.FC = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false)

  const handleOpen = () => {
    setOpen(true)
  }

  return (
    <AddReferralCodeDialogContext.Provider
      value={{
        actions: {
          open: handleOpen
        }
      }}
    >
      {open && <Component handleClose={() => setOpen(false)} />}
      {children}
    </AddReferralCodeDialogContext.Provider>
  )
}
