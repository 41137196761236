/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useRef } from 'react'

import { PinturaEditor } from '@pqina/react-pintura'

import '@pqina/pintura/pintura.css'
import {
  createDefaultImageWriter,
  createDefaultMediaWriter,
  getEditorDefaults,
  PinturaDefaultImageWriterResult,
  setPlugins
} from '@pqina/pintura'

import '@pqina/pintura-video/pinturavideo.css'
import { plugin_trim, plugin_trim_locale_en_gb } from '@pqina/pintura-video'
import { SimpleDialogContext } from '../SimpleDialog'
import api from '../../lib/api'
import { SimpleSnackbarContext } from '../SimpleSnackbar'

setPlugins(plugin_trim)

const PinturaVideo: React.FC<{
  url: string
  storyId: string
  userId: string
  handleClose: () => void
  setInitialFile: (file: File) => void
  setLastImageState: (state: any) => void
}> = ({ url, storyId, userId, handleClose, setInitialFile, setLastImageState }) => {
  const editorRef = useRef(null)
  const editorDefaults = getEditorDefaults({
    locale: {
      ...plugin_trim_locale_en_gb
    },
    imageWriter: createDefaultMediaWriter([createDefaultImageWriter()])
  })
  const { actions: simpleDialogActions } = useContext(SimpleDialogContext)
  const { actions: snackActions } = useContext(SimpleSnackbarContext)

  const submit = (res: PinturaDefaultImageWriterResult) => {
    simpleDialogActions.open(
      'Save edited video',
      'Are you sure you want to save the edited video? This action cannot be undone.',
      { okText: `Yes, I'm sure`, cancelText: 'Cancel', showOk: true },
      async success => {
        if (success) {
          try {
            await api.editVideo(storyId, JSON.stringify(res.imageState), res.src as File, userId)
            snackActions.open('Your video is processing. Please refresh the page shortly to view the changes!')
          } catch (e) {
            snackActions.open('Ops! Something went wrong processing your video. Please try again.')
            console.log(e)
          } finally {
            handleClose()
          }
        }
      }
    )
  }

  const saveLastHistory = () => {
    // @ts-ignore
    if (editorRef.current.editor?.history) {
      // @ts-ignore
      const histories = editorRef.current.editor.history.get()
      const lastHistory = histories[histories.length - 1]
      setLastImageState(lastHistory)
    }
  }
  return (
    <div style={{ height: '80vh' }}>
      <PinturaEditor
        {...editorDefaults}
        ref={editorRef}
        src={url}
        utils={['trim', 'crop', 'filter', 'finetune', 'retouch', 'sticker', 'fill', 'frame', 'redact', 'resize']}
        onProcess={res => submit(res)}
        onWritehistory={saveLastHistory}
        onLoad={res => setInitialFile(res.dest)}
      />
    </div>
  )
}

export default PinturaVideo
