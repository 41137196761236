import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import ImageListItemBar from '@mui/material/ImageListItemBar'
import Typography from '@mui/material/Typography'
import { galleryEntries } from './StoriesExample'
import ImageViewer from '../../../../../components/ImageViewer'

interface iPhotos {
  textColor?: string
}

const AdjustedPhotosPublic: React.FC<iPhotos> = ({ textColor }) => {
  const [gallery, setGallery] = useState<(any & { storyId: string; storyTitle: string })[]>([])
  const [current, setCurrent] = useState<number | null>(null)
  // console.log('Stories Size: ' + list.length)
  useEffect(() => {
    galleryEntries.forEach(g => {
      setGallery(old => [...old, { ...g, storyId: 'story id', storyTitle: 'Images' }])
    })
  }, [])
  // console.log('Gallery Size: ' + gallery.length)
  return (
    <Box
      sx={{
        marginBottom: 3
      }}
    >
      {current !== null && (
        <ImageViewer
          images={gallery}
          onClose={() => {
            setCurrent(null)
          }}
          index={current}
        />
      )}
      {gallery.length ? (
        <ImageList cols={5} gap={5}>
          {gallery.map((photo, index) => (
            <ImageListItem key={photo.id} rows={1}>
              <img
                style={{
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setCurrent(index)
                }}
                src={`${photo.image.url}`}
                srcSet={`${photo.image.url}`}
                alt={photo.image.originalName}
                loading="lazy"
              />
              <ImageListItemBar
                position="below"
                subtitle={
                  <Typography variant="caption" fontSize="1em">
                    {photo.storyTitle}
                  </Typography>
                }
                title={photo.image.originalName}
                actionPosition="right"
              />
            </ImageListItem>
          ))}
        </ImageList>
      ) : (
        <Typography sx={{ padding: '16px 0px', color: textColor }} align="center">
          No photos have been submitted for this collection
        </Typography>
      )}
    </Box>
  )
}

export default AdjustedPhotosPublic
