import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { LanguageEnum, QuestionnaireTemplateType, QuizInputType } from '../../../lib/types'
import useInfiniteScroll from '../../../components/hooks/useInfiniteScrool'
import { usePageableRequest } from '../../../components/hooks/usePageableRequest'
import QuizItem from '../../../components/QuizItem'
import Typography from '@mui/material/Typography'
import api from '../../../lib/api'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import Button from '@mui/material/Button'
import { FormDialogContext } from '../../../components/FormDialog'
import { SimpleDialogContext } from '../../../components/SimpleDialog'
import { SimpleSnackbarContext } from '../../../components/SimpleSnackbar'
import axios from 'axios'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import { usePermissions } from '../../../components/hooks/usePermissions'

const Templates: React.FC = () => {
  const history = useHistory()
  const { actions: formDialogActions } = useContext(FormDialogContext)
  const { actions: simpleDialogActions } = useContext(SimpleDialogContext)
  const { actions: snackActions } = useContext(SimpleSnackbarContext)
  const [searchValue, setSearchValue] = useState<string>('')
  const { loading, items, hasNextPage, error, loadMore, setItems, reload } =
    usePageableRequest<QuestionnaireTemplateType>({
      size: 15,
      q: '',
      request: api.getQuestionnaireTemplates
    })

  const { isCreator } = usePermissions()

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: !!error,
    rootMargin: '0px 0px 400px 0px'
  })

  const handleDelete = async (id: string) => {
    simpleDialogActions.open(
      'Delete questionnaire template',
      'Are you sure do you want delete this questionnaire template?',
      { okText: 'Delete', cancelText: 'Cancel', showOk: true },
      async success => {
        if (success) {
          try {
            await api.deleteQuestionnaireTemplate(id)
            setItems(items.filter(item => item.id !== id))
            snackActions.open('Questionnaire template deleted successfully.')
          } catch (error) {
            if (axios.isAxiosError(error)) {
              // @ts-ignore
              const { code, message } = error.response.data
              if (code) {
                snackActions.open(message)
                return
              }
            }
            snackActions.open('Internal server error.')
          }
        }
      }
    )
  }
  const handleDuplicate = async (questionnaireTemplate: QuestionnaireTemplateType) => {
    formDialogActions.open(
      'Duplicate questionnaire template',
      'Are you sure do you want duplicate this questionnaire template?',
      [
        {
          label: 'Language',
          name: 'language',
          placeholder: 'Language',
          type: 'select',
          options: Object.entries(LanguageEnum).map(([code, name]) => (
            <MenuItem key={code} value={code}>
              {name.charAt(0).toUpperCase() + name.slice(1)}
            </MenuItem>
          ))
        }
      ],
      {
        language: questionnaireTemplate.language || ''
      },
      null,
      async (data: QuizInputType) => {
        try {
          const clone = await api.duplicateQuestionnaireTemplate(questionnaireTemplate.id, data.language)
          setItems([clone, ...items])
          snackActions.open('Questionnaire template duplicated successfully!')
        } catch (error) {
          if (axios.isAxiosError(error)) {
            // @ts-ignore
            const { code, message } = error.response.data
            if (code) {
              snackActions.open(message)
              return
            }
          }
          snackActions.open('Internal server error.')
        }
      },
      { okText: 'Duplicate', cancelText: `Cancel`, loadingButton: true }
    )
  }

  const createNewQuiz = () => {
    formDialogActions.open(
      'Create questionnaire template',
      '',
      [
        {
          label: 'Title',
          name: 'title',
          placeholder: 'Title',
          type: 'text'
        },
        {
          label: 'Description',
          name: 'description',
          placeholder: 'Description',
          rows: 4,
          type: 'text'
        },
        {
          label: 'Language',
          name: 'language',
          placeholder: 'Language',
          type: 'select',
          options: Object.entries(LanguageEnum).map(([code, name]) => (
            <MenuItem key={code} value={code}>
              {name.charAt(0).toUpperCase() + name.slice(1)}
            </MenuItem>
          ))
        }
      ],
      { title: '', description: '', language: null },
      null,
      async (data: QuizInputType) => {
        try {
          await api.addQuestionnaireTemplate(data)
          snackActions.open('Questionnaire template created successfully!')
          reload()
        } catch (error) {
          if (axios.isAxiosError(error)) {
            // @ts-ignore
            const { code, message } = error.response.data
            if (code) {
              snackActions.open(message)
              return
            }
          }
          snackActions.open('Internal server error.')
        }
      },
      { okText: 'create', cancelText: `cancel`, loadingButton: true }
    )
  }

  return (
    <Box component="form" sx={{ width: '100%', padding: 2 }}>
      <Box sx={{ paddingY: 2 }}>
        <Typography variant="h6">Templates</Typography>
      </Box>
      <Divider sx={{ marginY: 2 }} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              gap: '8px',
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: 'space-between'
            }}
          >
            <TextField
              key="search"
              name="search"
              sx={{
                minWidth: { xs: '300px', md: '422px' }
              }}
              rows={4}
              autoComplete="off"
              placeholder="Type template title or description to filter"
              margin="dense"
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
            <Button variant="outlined" sx={{ height: '56px' }} onClick={createNewQuiz} disabled={!isCreator}>
              New template
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            {items
              .filter(
                p =>
                  p.title.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                  p.description.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
              )
              .map(quiz => (
                <QuizItem key={quiz.id} quiz={quiz}>
                  <Button
                    color="secondary"
                    onClick={() => {
                      handleDuplicate(quiz)
                    }}
                  >
                    Duplicate
                  </Button>
                  <Button
                    color="secondary"
                    onClick={() => {
                      history.push(`/a/questionnaire-template/${quiz.id}`)
                    }}
                  >
                    Edit
                  </Button>
                  <Button color="secondary" onClick={() => handleDelete(quiz.id)}>
                    Delete
                  </Button>
                </QuizItem>
              ))}
            {hasNextPage && (
              <Grid item xs>
                <div ref={sentryRef} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Templates
