import React, { useContext } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dropzone, { DropEvent, FileRejection } from 'react-dropzone'
import { SimpleSnackbarContext } from '../../SimpleSnackbar'
import { CollectionThemeProps } from '../../../lib/types'

interface iDroparea {
  onDrop: <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void
  filter?: 'AUDIO' | 'VIDEO' | null
  collectionTheme?: CollectionThemeProps
}

const Droparea: React.FC<iDroparea> = ({ onDrop, collectionTheme }) => {
  const { actions: snackActions } = useContext(SimpleSnackbarContext)

  const handleDrop = <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => {
    if (fileRejections.length > 0) {
      snackActions.open(`This file is not accepted: ${fileRejections.map(f => f.file.name).join(', ')}`)
      return
    }
    onDrop(acceptedFiles, fileRejections, event)
  }

  return (
    <Dropzone multiple={false} noClick noKeyboard accept={'audio/*, video/*'} onDrop={handleDrop}>
      {({ getRootProps, getInputProps, open }) => (
        <Box
          sx={{
            marginTop: '4px'
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />

          <Button
            size="large"
            onClick={open}
            variant="outlined"
            sx={{
              fontFamily: collectionTheme?.questionnaireTextFontFamily,
              color: collectionTheme?.questionnaireRecordButtonColor,
              borderColor: collectionTheme?.questionnaireRecordButtonColor,
              ':hover': {
                borderColor: collectionTheme?.questionnaireRecordButtonColor,
                backgroundColor: collectionTheme?.questionnaireRecordButtonHoverColor
              }
            }}
          >
            Choose File
          </Button>
        </Box>
      )}
    </Dropzone>
  )
}

export default Droparea
