import * as yup from 'yup'

export const schema = yup.object().shape({
  instructions: yup.string().label('Instructions'),
  recordingTypeValue: yup.string(),
  expire: yup.date().label('Date').nullable(),
  size: yup.number().min(1).required().label('Time limit'),
  title: yup.string().required().label('Title'),
  callToAction: yup
    .string()
    .matches(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/, 'Must start with http:// or https://')
    .nullable()
})
