import React, { useEffect, useState } from 'react'
import { SearchResultType, UserType } from '../../lib/types'
import Box from '@mui/material/Box'
import Card from '../Card'
import { useHistory } from 'react-router-dom'
import api from '../../lib/api'

const CollectionCard: React.FC<{ data: SearchResultType }> = ({ data }) => {
  const history = useHistory()
  const [publishedStoriesCount, setPublishedStoriesCount] = useState(0)

  useEffect(() => {
    const getPublishedStoriesCount = async () => {
      let publishedStoriesCountResponse
      try {
        publishedStoriesCountResponse = await api.countStoriesPublished(data.id)
      } catch (e) {
        console.log(e)
      }
      setPublishedStoriesCount(publishedStoriesCountResponse?.count ?? 0)
    }

    getPublishedStoriesCount()
  }, [data])

  return (
    <Box
      key={data.id}
      onClick={e => {
        if ((e.target as HTMLElement).id === 'shareButton') return
        history.push(`/a/collections/${data.id}?storyId=${data.items?.length ? data.items![0] : ''}`)
      }}
      sx={{
        cursor: 'pointer',
        marginBottom: 2
      }}
    >
      <Card
        loading={false}
        shareLink={data.publicAcl ? `${window.location.origin}/public/collections/${data.id}` : ''}
        type={'COLLECTION'}
        title={data.title}
        subTitle={`${data?.items?.length || 0} stories`}
        owner={
          {
            firstName: data.ownerFirstName,
            lastName: data.ownerLastname,
            picture: {
              url: data.ownerPicture
            }
          } as UserType
        }
        thumbnail={data.picture!}
        numberOfPublishedStories={publishedStoriesCount}
        id={data.id}
      />
    </Box>
  )
}

export default CollectionCard
