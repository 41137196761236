import React, { useContext, useEffect, useState } from 'react'
import { CollectionThemeProps, InviteType, QuizInviteType } from '../../../../lib/types'
import { format, parseJSON } from 'date-fns'
import Box from '@mui/material/Box'
import Step2 from './steps/Step2'
import Step1 from './steps/Step1'
import Step3 from './steps/Step3'
import api from '../../../../lib/api'
import { SimpleDialogContext } from '../../../../components/SimpleDialog'

interface iQuestionnaireProps {
  invite: InviteType
  id: string
  token: string
  recorderName: string
  recorderEmail: string
  recorderJobTitle: string
  recorderFunctionalArea: string
  quiz: QuizInviteType
  collectionTheme?: CollectionThemeProps
}

const Questionnaire: React.FC<iQuestionnaireProps> = ({
  quiz,
  invite,
  token,
  id,
  recorderName,
  recorderEmail,
  recorderJobTitle,
  recorderFunctionalArea,
  collectionTheme
}) => {
  const questions = quiz.questions.sort((a, b) => a.number - b.number)

  const [activeStep, setActiveStep] = useState<string>(() => {
    const lastItem = localStorage.getItem(`io.rakonto.questionnaire.${quiz.id}.lastQuestion`)
    return lastItem || 'start'
  })
  const [user, setUser] = useState<{
    name: string
    email: string
    jobTitle: string
    functionalArea: string
  }>(() => {
    const hasLastItem = localStorage.getItem(`io.rakonto.questionnaire.${quiz.id}.lastQuestion`)
    const email = localStorage.getItem(`io.rakonto.questionnaire.${quiz.id}.guestUser.email`)
    const name = localStorage.getItem(`io.rakonto.questionnaire.${quiz.id}.guestUser.name`)
    const jobTitle = recorderJobTitle
    const functionalArea = recorderFunctionalArea
    if (email && name) {
      if (!hasLastItem) setActiveStep(questions[0].id)
      return { name, email, jobTitle, functionalArea }
    }
    return {
      name: recorderName,
      email: recorderEmail,
      jobTitle: recorderJobTitle,
      functionalArea: recorderFunctionalArea
    }
  })
  const [progress, setProgress] = useState(0)

  const handleSendEmailQuestionSubmit = async (i: number) => {
    let question = questions[i]
    if (!question) {
      question = questions[i - 1]
    }
    console.log('HandleSendEmailQuestionSubmit: ' + question.id)
    const record = {
      email: user.email,
      name: user.name,
      jobTitle: user.jobTitle,
      functionalArea: user.functionalArea
    }

    console.log('Question ID: ' + question.id)
    console.log('Question Title: ' + question.title)

    console.log('Email: ' + record.email)
    console.log('Name: ' + record.name)
    console.log('Job: ' + record.jobTitle)
    console.log('Functional Area: ' + record.functionalArea)

    if (!record.jobTitle) {
      record.jobTitle = '_'
    }
    if (!record.functionalArea) {
      record.functionalArea = '_'
    }
    console.log(
      'Handle Submit: ' + record.name + ', ' + record.email + ', ' + record.jobTitle + ', ' + record.functionalArea
    )

    try {
      console.log('Send email: ' + invite!.id + ', ' + record.email)

      await api.sendQuestionEmail(question.id, record, token)
    } catch (e) {
      console.log('Error to send Email: ' + e)
      console.error(e)
    }
  }

  const handleSendEmailCompleteQuestionnaire = async () => {
    const record = {
      email: user.email,
      name: user.name,
      jobTitle: user.jobTitle,
      functionalArea: user.functionalArea
    }

    console.log('Invite ID: ' + invite.id)
    console.log('Invite Title: ' + invite.title)
    console.log('CTA: ' + invite.callToAction)
    console.log('CTA Button: ' + invite.callToActionButtonLabel)
    console.log('CTA Instructions: ' + invite.callToActionInstructions)

    console.log('Email: ' + record.email)
    console.log('Name: ' + record.name)
    console.log('Job: ' + record.jobTitle)
    console.log('Functional Area: ' + record.functionalArea)
    console.log('Questionnnaire ID: ' + invite.questionnaireId)

    if (!record.jobTitle) {
      record.jobTitle = '_'
    }
    if (!record.functionalArea) {
      record.functionalArea = '_'
    }
    console.log(
      'Handle Submit: ' + record.name + ', ' + record.email + ', ' + record.jobTitle + ', ' + record.functionalArea
    )

    try {
      console.log('Finishing Questionnaire: ' + invite!.id + ', ' + record.email)
      await api.finishQuestionnaire(invite.questionnaireId, record.email, record.jobTitle, record.functionalArea, token)
    } catch (e) {
      console.log('Error to Finish Questionnaire: ' + e)
      console.error(e)
    }

    try {
      console.log('Send email: ' + invite!.id + ', ' + record.email)

      await api.sendQuestionnaireCompletionConfirmationEmail(invite!.id, record, '', true, token)
    } catch (e) {
      console.log('Error to send Email: ' + e)
      console.error(e)
    }
  }
  const setNextStep = (i: number) => {
    if (questions[questions.length - 1].id === activeStep) {
      handleSendEmailCompleteQuestionnaire()
        .then(() => setActiveStep('final'))
        .catch(() => setActiveStep('final'))
        .finally(() => setActiveStep('final'))
    } else {
      setActiveStep(questions[i + 1].id)
      localStorage.setItem(`io.rakonto.questionnaire.${quiz.id}.lastQuestion`, `${questions[i + 1].id}`)
    }
    setProgress(0)
  }

  const handleQuestionSubmission = async (file: File, i: number) => {
    let questionTitle = quiz.title + ' - ' + i + 'th - Last Response '
    let question = questions[i]

    if (!question) {
      question = questions[i - 1]
    }
    questionTitle = question.collectionInvite?.title

    await api.sendQuestionSubmission(
      id,
      question.id,
      token,
      {
        name: user.name,
        email: user.email,
        allowEmail: true,
        allowShareInfo: false,
        jobTitle: user.jobTitle,
        functionalArea: user.functionalArea
      },
      file,
      (event: { loaded: number; total: number }) => {
        const value = Math.round((event.loaded * 100) / event.total)
        setProgress(value)
        console.log('Uploading answer: ' + value + '%')
        if (value >= 100) {
          setNextStep(i)
        }
      }
    )
    setNextStep(i)
  }

  useEffect(() => {
    if (activeStep === 'final') {
      console.log('Final Invite ID: ' + invite.id)
      console.log('Final Invite Title: ' + invite.title)
      console.log('Final CTA: ' + invite.callToAction)
      console.log('Final CTA Button: ' + invite.callToActionButtonLabel)
      console.log('Final CTA Instructions: ' + invite.callToActionInstructions)

      localStorage.removeItem(`io.rakonto.questionnaire.${quiz.id}.lastQuestion`)
      localStorage.removeItem(`io.rakonto.questionnaire.${quiz.id}.guestUser.email`)
      localStorage.removeItem(`io.rakonto.questionnaire.${quiz.id}.guestUser.name`)
    }
  }, [activeStep])

  const { actions: simpleDialogActions } = useContext(SimpleDialogContext)

  const handleQuestionSkipp = async (skipped: boolean, i: number) => {
    let question = questions[i]

    if (!question) {
      question = questions[i - 1]
    }

    await api
      .sendQuestionSkipp(token, {
        questionnaireId: question.questionnaireId,
        questionId: question.id,
        name: user.name,
        email: user.email,
        jobTitle: user.jobTitle,
        functionalArea: user.functionalArea,
        skipped: skipped
      })
      .then(value => {
        console.log(`Skipped: ${value.id}`)
        setNextStep(i)
      })
  }

  const handleNext = (i: number) => async (file: File | null) => {
    if (!file) {
      if (collectionTheme) {
        simpleDialogActions.openWithStyles(
          'Skip Question',
          'Are you sure you want to skip this question?',
          collectionTheme!,
          { okText: 'Yes', showOk: true, cancelText: 'Cancel' },
          async (skipYes: boolean) => {
            if (skipYes) {
              handleQuestionSkipp(skipYes, i)
            }
          }
        )
      } else {
        simpleDialogActions.open(
          'Skip Question',
          'Are you sure you want to skip this question?',
          { okText: 'Yes', showOk: true, cancelText: 'Cancel' },
          async (skippYes: boolean) => {
            if (skippYes) {
              await handleQuestionSkipp(skippYes, i)
            }
          }
        )
      }
    } else {
      await handleQuestionSubmission(file, i)
    }
  }

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100%',
        display: 'flex',
        backgroundColor: collectionTheme?.questionnaireBackgroundColor
      }}
    >
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ width: '100%', maxWidth: 1080, padding: { xs: 3, md: 4 } }}>
          {activeStep === 'start' && (
            <Step1
              collectionTheme={collectionTheme}
              questionnaire={quiz}
              user={user}
              invite={invite!}
              handleNext={(user: { name: string; email: string; jobTitle: string; functionalArea: string }) => {
                setUser(user)
                localStorage.setItem(`io.rakonto.questionnaire.${quiz.id}.guestUser.email`, user.email)
                localStorage.setItem(`io.rakonto.questionnaire.${quiz.id}.guestUser.name`, user.name)
                setActiveStep(questions[0].id)
              }}
              token={token}
              quizInvite={quiz}
            />
          )}
          {questions.map((question, i) => {
            return (
              <div key={question.id}>
                {activeStep === question.id && (
                  <>
                    <Step2
                      collectionTheme={collectionTheme}
                      title={`Question ${i + 1} of ${quiz.questions.length}
                            - due ${format(parseJSON(question.collectionInvite.dueAt), 'd MMM')}
                            `}
                      invite={question.collectionInvite}
                      question={question.question}
                      questionObj={question}
                      progress={progress}
                      handleNext={handleNext(i)}
                    />
                  </>
                )}
              </div>
            )
          })}
          {activeStep === 'final' && <Step3 invite={invite} user={user} collectionTheme={collectionTheme} />}
        </Box>
      </Box>
    </Box>
  )
}

export default Questionnaire
