import { CompositeDecorator, EditorState } from 'draft-js'

import TranscriptEditorWord from '../TranscriptEditorWord'
import TranscriptEditorSpace from '../TranscriptEditorSpace'
import TranscriptEditorSpanPlayed from '../TranscriptEditorSpanPlayed'
import { TRANSCRIPT_WORD, TRANSCRIPT_SPACE } from './TranscriptEntities'

const wordsDecorator = new CompositeDecorator([
  {
    strategy: (contentBlock, callback, contentState) => {
      contentBlock.findEntityRanges(character => {
        const entityKey = character.getEntity()
        if (entityKey === null) {
          return false
        }
        const entityType = contentState.getEntity(entityKey).getType()
        return entityType === TRANSCRIPT_WORD
      }, callback)
    },
    component: TranscriptEditorWord
  },
  {
    strategy: (contentBlock, callback, contentState) => {
      contentBlock.findEntityRanges(character => {
        const entityKey = character.getEntity()
        if (entityKey === null) {
          return false
        }
        const entityType = contentState.getEntity(entityKey).getType()
        return entityType === TRANSCRIPT_SPACE
      }, callback)
    },
    component: TranscriptEditorSpace
  }
])

export const withWords = (editorState: EditorState) => EditorState.set(editorState, { decorator: wordsDecorator })

const timeDecorator = (time: number) =>
  new CompositeDecorator([
    {
      component: TranscriptEditorSpanPlayed,
      strategy: (block, callback, contentState) => {
        block.findEntityRanges(character => {
          const entityKey = character.getEntity()
          if (entityKey === null) {
            return false
          }
          const entity = contentState.getEntity(entityKey)
          return entity.getType() === TRANSCRIPT_WORD && entity.getData().end < time
        }, callback)
      }
    }
  ])

export const withTime = (editorState: EditorState, time: number) =>
  EditorState.set(editorState, { decorator: timeDecorator(time) })
