import React, { useContext, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { RequestContentDialogContext } from './Context'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { usePageableRequest } from '../hooks/usePageableRequest'
import { InviteContributorType } from '../../lib/types'
import api from '../../lib/api'
import useInfiniteScroll from '../hooks/useInfiniteScrool'
import { StepInviteContributorContext } from '../StepInviteContributor'
import { format, parseISO } from 'date-fns'
import { SimpleSnackbarContext } from '../SimpleSnackbar'
import useShareInvite from '../hooks/useShareInvite'
import StepInviteContributorEdit from '../StepInviteContributorEdit'
const RequestContentDialog = () => {
  const { actions, store } = useContext(RequestContentDialogContext)
  const { actions: inviteContributorActions } = useContext(StepInviteContributorContext)
  const { actions: snackActions } = useContext(SimpleSnackbarContext)
  const { loading, items, hasNextPage, error, loadMore, reload, setItems } = usePageableRequest<InviteContributorType>({
    size: 10,
    q: store.storyId,
    request: api.getInviteContributorByStoryId
  })
  const handleShare = useShareInvite()
  const [openEdit, setOpenEdit] = useState<string>('')

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: !!error,
    rootMargin: '0px 0px 400px 0px'
  })
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="md"
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          actions.close()
        }
      }}
      open
    >
      <DialogTitle>
        Content Requests
        <IconButton
          aria-label="close"
          onClick={actions.close}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid
          container
          sx={{
            padding: '24px'
          }}
          spacing={4}
        >
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={() => inviteContributorActions.open(store.storyId, reload)}>New Request</Button>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              {items.map(invite => (
                <Box
                  key={invite.id}
                  sx={{
                    minHeight: '120px',
                    width: '100%',
                    bgcolor: '#313131',
                    borderRadius: '20px',
                    marginBottom: 3,
                    display: 'flex',
                    alignItems: 'center',
                    flexFlow: { xs: 'column', md: 'row' },
                    paddingX: '16px'
                  }}
                >
                  {openEdit === invite.id && (
                    <StepInviteContributorEdit
                      invite={invite}
                      onClose={newInvite => {
                        if (newInvite) {
                          setItems(oldItems =>
                            oldItems.map(oldItem => {
                              if (oldItem.id === newInvite.id) return newInvite
                              return oldItem
                            })
                          )
                        }
                        setOpenEdit('')
                      }}
                    />
                  )}
                  <Box
                    sx={{
                      flex: { xs: 'unset', md: '1' },
                      width: { xs: '100%', md: 'unset' },
                      paddingX: '8px'
                    }}
                  >
                    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                      <Typography>
                        <b>{invite.title}</b>
                      </Typography>
                      <Typography variant="caption" color="secondary">
                        {format(parseISO(invite.createdAt), 'PPPp')}
                      </Typography>
                    </Box>
                  </Box>
                  <Button
                    color="secondary"
                    onClick={() => {
                      api.duplicateInviteContributor(invite.id).then(() => {
                        snackActions.open(`Story request successfully duplicated!`)
                        reload()
                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                      })
                    }}
                  >
                    Duplicate
                  </Button>
                  <Button
                    color="secondary"
                    onClick={() => {
                      setOpenEdit(invite.id)
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    color="secondary"
                    onClick={() => {
                      handleShare(undefined, undefined, invite.url)
                    }}
                  >
                    Share
                  </Button>
                  <Button
                    color="secondary"
                    onClick={() => {
                      api.deleteInviteContributor(invite.id).then(() => {
                        snackActions.open(`Story request successfully deleted!`)
                        reload()
                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                      })
                    }}
                  >
                    Delete
                  </Button>
                </Box>
              ))}
              {hasNextPage && (
                <Grid item xs>
                  <div ref={sentryRef} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default RequestContentDialog
