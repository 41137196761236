import React, { useEffect, useState } from 'react'
import { CollectionThemeProps, InviteType, QuizInviteQuestionType, SubtitleType } from '../../../../../lib/types'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { intervalToDuration } from 'date-fns'
import Box from '@mui/material/Box'
import ButtonGroup from '@mui/material/ButtonGroup'
import CircularProgress from '@mui/material/CircularProgress'
import LoadingButton from '@mui/lab/LoadingButton'
import QuestionnaireInputFileArea from '../../../../../components/QuestionnaireInputFileArea'
import api from '../../../../../lib/api'
import Link from '@mui/material/Link'

const Step2: React.FC<{
  invite: InviteType
  title: string
  question: string
  questionObj: QuizInviteQuestionType
  handleNext: (file: File | null) => void
  progress: number
  collectionTheme?: CollectionThemeProps
}> = ({ progress, title, question, questionObj, invite, handleNext, collectionTheme }) => {
  const [file, setFile] = useState<File | null>(null)
  const duration = intervalToDuration({ start: 0, end: invite.requestedMediaLength * 1000 })

  const [subtitles, setSubtitles] = useState<SubtitleType[]>([])
  useEffect(() => {
    const getSubtitles = async () => {
      if (invite?.storyId) {
        // const response = await api.getSubtitleByStoryId(invite.storyId)
        // setSubtitles(response.content)
      }
    }
    // getSubtitles()
  }, [])

  const policy = 'https://olympics.com/en/privacy-policy'
  const terms =
    'https://objectstorage.eu-zurich-1.oraclecloud.com/n/idxwux4l0c8w/b/public/o/ioc_terms_of_service_2023-04-12.pdf'

  // if (invite.organization && invite.organization.privacyPolicy) {
  //   policy = invite.organization.privacyPolicy
  // }
  // if (invite.organization && invite.organization.termsAndConditions) {
  //   terms = invite.organization.termsAndConditions
  // }

  const releaseForm =
    'https://objectstorage.eu-zurich-1.oraclecloud.com/n/idxwux4l0c8w/b/public/o/ioc_releaseform_2023-04-12.pdf'

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        textAlign="center"
        sx={{
          color: collectionTheme?.questionnaireBodyTextColor,
          fontFamily: collectionTheme?.questionnaireTextFontFamily
        }}
      >
        <Box mb={1} mt={3} textAlign="center">
          <Typography
            variant="subtitle1"
            textAlign="center"
            fontSize={'0.9em'}
            fontFamily={collectionTheme?.questionnaireTextFontFamily}
          >
            {title}
          </Typography>
          <Typography
            textAlign={'center'}
            variant={'h4'}
            marginY={2}
            fontSize={'1.0em'}
            fontFamily={collectionTheme?.questionnaireTextFontFamily}
          >
            {questionObj.question}
          </Typography>
        </Box>
      </Grid>
      {!progress ? (
        <Grid
          item
          xs={12}
          mt={4}
          mb={9}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            margin: '10px'
          }}
        >
          <QuestionnaireInputFileArea
            file={file}
            callback={file => {
              setFile(file)
            }}
            hardStop={invite.hardStop}
            countdown={invite.requestedMediaLength}
            disableChangeMediaType={!!invite.requestedMediaType}
            startType={invite.requestedMediaType || null}
            media={invite.video}
            subtitles={subtitles}
            collectionTheme={collectionTheme}
          />
        </Grid>
      ) : (
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 10, flexFlow: 'column' }}
        >
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress size={100} variant="determinate" value={progress || 100} />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Typography
                variant="h6"
                component="div"
                color="text.secondary"
                fontFamily={collectionTheme?.questionnaireTextFontFamily}
              >
                {`${Math.round(progress || 100)}%`}
              </Typography>
            </Box>
          </Box>
          <Typography
            mt={2}
            variant="h6"
            component="div"
            color="text.secondary"
            fontFamily={collectionTheme?.questionnaireTextFontFamily}
            sx={{
              color: collectionTheme?.questionnaireBodyTextColor,
              fontFamily: collectionTheme?.questionnaireTextFontFamily
            }}
          >
            Uploading your answer - this may take a few minutes. Please do not refresh or close your browser.
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} textAlign="center">
        <Box
          mb={3}
          mt={3}
          textAlign="center"
          sx={{
            color: collectionTheme?.questionnaireBodyTextColor,
            fontFamily: collectionTheme?.questionnaireTextFontFamily
          }}
        >
          <Typography
            textAlign={'center'}
            variant={'h6'}
            marginY={2}
            fontSize={'1em'}
            fontFamily={collectionTheme?.questionnaireTextFontFamily}
          >
            {'Recommendations for a helpful response: ' + questionObj.recommendation}
          </Typography>
          {invite.requestedMediaLength && (
            <Grid item xs={12} textAlign="center">
              <Typography
                variant="caption"
                mb={2}
                textAlign="center"
                fontSize="0.9rem"
                fontFamily={collectionTheme?.questionnaireTextFontFamily}
              >
                {`If possible, please keep your recording duration under ${String(
                  (duration.hours || 0) * 60 + (duration.minutes || 0)
                )} minute${(duration.minutes || 0) > 1 ? 's' : ''}${
                  duration.seconds ? ` and ${String(duration.seconds).padStart(2, '0')} seconds` : ''
                }.`}
              </Typography>
            </Grid>
          )}
          <Typography
            variant="caption"
            mt={2}
            textAlign="center"
            fontSize="0.9rem"
            fontFamily={collectionTheme?.questionnaireTextFontFamily}
          >
            Your response will be used in accordance with the{' '}
            <Link href={terms} target="_blank" sx={{ color: collectionTheme?.questionnaireLinksColor }}>
              {' '}
              International Olympic Committee Terms
            </Link>
            ,
            <Link href={releaseForm} target="_blank" sx={{ color: collectionTheme?.questionnaireLinksColor }}>
              {' '}
              Release Form
            </Link>{' '}
            and{' '}
            <Link href={policy} target="_blank" sx={{ color: collectionTheme?.questionnaireLinksColor }}>
              {' '}
              Privacy Policy
            </Link>
          </Typography>
        </Box>
      </Grid>
      <Box
        sx={{
          fontSize: '0.8rem',
          display: 'flex',
          width: '100%',
          position: 'fixed',
          bottom: 24,
          right: 24,
          zIndex: 11
        }}
      >
        <Box sx={{ flex: 1 }} />
        <ButtonGroup>
          <LoadingButton
            disabled={!!progress}
            onClick={() => handleNext(null)}
            sx={{
              fontSize: '0.8rem',
              color: collectionTheme?.questionnaireContainedButtonTextColor,
              backgroundColor: collectionTheme?.questionnaireContainedButtonBackgroundColor,
              ':hover': { backgroundColor: collectionTheme?.questionnaireContainedButtonHoverColor },
              fontFamily: collectionTheme?.questionnaireTextFontFamily
            }}
            variant="contained"
            size="medium"
          >
            Skip
          </LoadingButton>
          <LoadingButton
            onClick={() => handleNext(file)}
            disabled={!file}
            loading={!!progress}
            type="submit"
            sx={{
              fontSize: '0.8rem',
              color: collectionTheme?.questionnaireContainedButtonTextColor,
              backgroundColor: collectionTheme?.questionnaireContainedButtonBackgroundColor,
              ':hover': { backgroundColor: collectionTheme?.questionnaireContainedButtonHoverColor },
              fontFamily: collectionTheme?.questionnaireTextFontFamily,
              ':disabled': {
                backgroundColor: collectionTheme?.questionnaireContainedDisabledButtonBackgroundColor,
                color: collectionTheme?.questionnaireContainedDisabledButtonColor
              }
            }}
            variant="contained"
            size="medium"
          >
            Submit
          </LoadingButton>
        </ButtonGroup>
      </Box>
    </Grid>
  )
}

export default Step2
