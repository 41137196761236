import React, { useContext, useState } from 'react'
import Grid from '@mui/material/Grid'
import { RakontoAdminType } from '../../../lib/types'
import useInfiniteScroll from '../../../components/hooks/useInfiniteScrool'
import { usePageableRequest } from '../../../components/hooks/usePageableRequest'
import Typography from '@mui/material/Typography'
import api from '../../../lib/api'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import DeleteIcon from '@mui/icons-material/Delete'
import { FormDialogContext } from '../../../components/FormDialog'
import { SimpleDialogContext } from '../../../components/SimpleDialog'
import { SimpleSnackbarContext } from '../../../components/SimpleSnackbar'
import { emailSchema } from './schemas'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import AssignAdminItem from '../../../components/AssignAdminItem'

const AssignAdmin: React.FC = () => {
  const { actions: formDialogActions } = useContext(FormDialogContext)
  const { actions: simpleDialogActions } = useContext(SimpleDialogContext)
  const { actions: snackActions } = useContext(SimpleSnackbarContext)
  const [searchValue, setSearchValue] = useState<string>('')

  const { loading, items, hasNextPage, error, loadMore, setItems } = usePageableRequest<RakontoAdminType>({
    size: 15,
    q: '',
    request: api.getRakontoAdmins
  })

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: !!error,
    rootMargin: '0px 0px 400px 0px'
  })

  const addAdmin = () => {
    formDialogActions.open(
      'Add admin',
      'Enter the email address of the person you would like to manage your library.',
      [
        {
          label: 'Email',
          name: 'email',
          placeholder: 'Type email',
          type: 'text'
        }
      ],
      { email: '' },
      emailSchema,
      async ({ email }: { email: string }) => {
        try {
          const newAdmin = await api.addRakontoAdmin(email)
          setItems([...items, newAdmin])
          snackActions.open('User successfully added as admin!')
        } catch (error) {
          snackActions.open('This user cannot be added as admin.')
        }
      },
      { okText: 'Add user', cancelText: `cancel` }
    )
  }

  const removeAdmin = async (id: string) => {
    simpleDialogActions.open(
      'Remove admin',
      "Are you sure? This admin will be removed and can't access your account.",
      { okText: 'Yes, remove', showOk: true, cancelText: 'cancel' },
      async success => {
        if (success) {
          try {
            await api.deleteRakontoAdmin(id)
            setItems(items.filter(item => item.id !== id))
            snackActions.open('Admin removed with success.')
          } catch (error) {
            snackActions.open('Error when trying to remove the admin.')
          }
        }
      }
    )
  }

  return (
    <Box sx={{ width: '100%', minHeight: '100%', bgcolor: 'background.paper', padding: 2 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography sx={{ mr: 2 }} variant="h6">
            Assign Admin
          </Typography>
          <Button onClick={addAdmin} startIcon={<PersonAddIcon />} size="large" variant="outlined">
            Add admin
          </Button>
        </Grid>
        <Grid item xs={12} marginBottom={2}>
          <Typography color="secondary" maxWidth="800px">
            The Assign Admin feature allows you to grant others access to manage your entire library on your behalf.
            Please note that they will not have access to change your profile, password, organization, team member
            assignments, subscription, services or billing information. Also, only users with Professional or Enterprise
            accounts can be assigned as admins.
          </Typography>
        </Grid>
        {items.length > 5 && (
          <Grid item xs={12} marginBottom={2}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <TextField
                key="search"
                name="search"
                fullWidth
                sx={{
                  maxWidth: '422px'
                }}
                autoComplete="off"
                placeholder="Search for a team member"
                margin="dense"
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
                onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container>
            {items
              .filter(admin => admin.user.email.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
              .map(admin => (
                <AssignAdminItem key={admin.id} member={admin}>
                  <IconButton onClick={() => removeAdmin(admin.id)}>
                    <DeleteIcon />
                  </IconButton>
                </AssignAdminItem>
              ))}
            {hasNextPage && (
              <Grid item xs>
                <div ref={sentryRef} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default AssignAdmin
