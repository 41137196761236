import React, { createContext, useState } from 'react'
import Component from './Component'
import { StoryType } from '../../lib/types'
import { VideoChapter } from './BookmarkVideoJs'

// @ts-ignore
export const VideoBookmarkDialogContext = createContext<{
  actions: {
    open: (story?: StoryType, chapters?: VideoChapter[]) => void
  }
  store: {
    story?: StoryType
    chapters?: VideoChapter[]
  }
}>({
  // @ts-ignore
  actions: {}
})

export const VideoBookmarkDialogProvider: React.FC = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false)
  const [selectedStory, setSelectedStory] = useState<StoryType | undefined>(undefined)
  const [chapters, setChapters] = useState<VideoChapter[] | undefined>(undefined)

  const handleOpen = (story?: StoryType, videoChapters?: VideoChapter[]) => {
    setOpen(true)
    story && setSelectedStory(story)
    videoChapters && setChapters(videoChapters)
  }

  return (
    <VideoBookmarkDialogContext.Provider
      value={{
        actions: {
          open: handleOpen
        },
        store: {
          story: selectedStory,
          chapters: chapters
        }
      }}
    >
      {open && <Component handleClose={() => setOpen(false)} />}
      {children}
    </VideoBookmarkDialogContext.Provider>
  )
}
