import ButtonGroup from '@mui/material/ButtonGroup'
import Button from '@mui/material/Button'
import MovieIcon from '@mui/icons-material/Movie'
import HeadphonesIcon from '@mui/icons-material/Headphones'
import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { isAndroid } from 'react-device-detect'
import { CollectionThemeProps } from '../../../lib/types'

interface iMobileUpload {
  onDrop: (value: File | null) => void
  filter?: 'AUDIO' | 'VIDEO' | null
  collectionTheme?: CollectionThemeProps
}

const MobileUpload: React.FC<iMobileUpload> = ({ onDrop, filter, collectionTheme }) => {
  const handleCapture = (files: FileList | null) => {
    if (files) {
      if (files.length !== 0) {
        onDrop(files[0])
      }
    }
  }

  return (
    <Box
      sx={{
        width: { xs: '100%', md: 422 },
        height: 300,
        border: '1px dashed #7b7b7c',
        borderRadius: '20px'
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0px 85px'
        }}
      >
        <Typography
          sx={{
            marginBottom: 6,
            fontFamily: collectionTheme?.questionnaireTextFontFamily,
            color: collectionTheme?.questionnaireBodyTextColor
          }}
          fontWeight="700"
          align="center"
          variant="h6"
          gutterBottom
        >
          {`Record or upload ${!filter ? 'video or audio' : filter.toLowerCase()}`}
        </Typography>
        <ButtonGroup disableElevation size="large" variant="outlined">
          {(!filter || filter === 'VIDEO') && (
            <Button
              sx={{
                color: collectionTheme?.questionnaireRecordButtonColor,
                borderColor: collectionTheme?.questionnaireRecordButtonColor,
                ':hover': {
                  borderColor: collectionTheme?.questionnaireRecordButtonColor,
                  backgroundColor: collectionTheme?.questionnaireRecordButtonHoverColor
                }
              }}
              component="label"
              startIcon={<MovieIcon />}
            >
              Video{' '}
              {isAndroid ? (
                <input
                  accept="video/*"
                  type="file"
                  capture="user"
                  onChange={e => handleCapture(e.target?.files)}
                  hidden
                />
              ) : (
                <input accept="video/*" type="file" onChange={e => handleCapture(e.target?.files)} hidden />
              )}
            </Button>
          )}
          {(!filter || filter === 'AUDIO') && (
            <Button
              sx={{
                color: collectionTheme?.questionnaireRecordButtonColor,
                borderColor: collectionTheme?.questionnaireRecordButtonColor,
                ':hover': {
                  borderColor: collectionTheme?.questionnaireRecordButtonColor,
                  backgroundColor: collectionTheme?.questionnaireRecordButtonHoverColor
                }
              }}
              component="label"
              endIcon={<HeadphonesIcon />}
            >
              Audio
              <input accept="audio/*" type="file" onChange={e => handleCapture(e.target?.files)} hidden />
            </Button>
          )}
        </ButtonGroup>
      </Box>
    </Box>
  )
}

export default MobileUpload
