import React from 'react'
import { ContentState } from 'draft-js'

interface iTranscriptEditorWord {
  entityKey: string
  contentState: ContentState
}

const TranscriptEditorWord: React.FC<iTranscriptEditorWord> = ({ entityKey, children, contentState }) => {
  const entity = contentState.getEntity(entityKey)
  const titleString = `${entity.getData().start.toFixed(2)} - ${entity.getData().end.toFixed(2)}`
  return (
    <span
      title={titleString}
      data-start={entity.getData().start}
      data-end={entity.getData().end}
      className="transcript-editor-entity__word"
    >
      {children}
    </span>
  )
}

export default TranscriptEditorWord
