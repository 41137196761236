import React, { useContext, useEffect, useState } from 'react'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  InviteType,
  LanguageEnum,
  QuestionVideoTypeEnum,
  QuizInputType,
  QuizInviteQuestionType,
  QuizInviteType
} from '../../../lib/types'
import QuizQuestionItem from '../../../components/QuizQuestionItem'
import Typography from '@mui/material/Typography'
import api from '../../../lib/api'
import { RouteComponentProps } from 'react-router-dom'
import Box from '@mui/material/Box'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import Button from '@mui/material/Button'
import { SimpleSnackbarContext } from '../../../components/SimpleSnackbar'
import useShareInvite from '../../../components/hooks/useShareInvite'
import axios from 'axios'
import CircularLoadingCentred from '../../../components/CircularLoadingCentred'
import StepInviteRecorderChangeMedia from '../../../components/StepInviteRecorderChangeMedia'
import QuestionConfigureModal from './QuestionConfigureModal'
import { UserContext } from '../../../components/UserProvider'
import MenuItem from '@mui/material/MenuItem'
import { capitalize } from '../../../components/Capitalize'
import { FormDialogContext } from '../../../components/FormDialog'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import Stack from '@mui/material/Stack'
import handleTenantSignIn from '../../../components/HandleTenantSignIn'

const QuestionnaireQuestion: React.FC<RouteComponentProps<{ quizId: string }>> = ({ match, history }) => {
  const { quizId } = match.params
  const handleShare = useShareInvite()
  const [openChangeMedia, setOpenChangeMedia] = useState<string>('')
  const [modalType, setModalType] = useState<QuestionVideoTypeEnum>(QuestionVideoTypeEnum.MEDIA)
  const { actions: snackActions } = useContext(SimpleSnackbarContext)
  const { user, member } = useContext(UserContext)
  const { actions: formDialogActions } = useContext(FormDialogContext)
  const [searchValue, setSearchValue] = useState<string>('')
  const [quiz, setQuiz] = useState<QuizInviteType | null>(null)
  const [items, setItems] = useState<QuizInviteQuestionType[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [saveLoading, setSaveLoading] = useState<boolean>(false)
  const [openEdit, setOpenEdit] = useState<{
    invite: InviteType
    isLastQuestion: boolean
    question?: QuizInviteQuestionType
    isConfigureAll?: boolean
    questionnaireId: string
  } | null>(null)

  const splitQuizId = quizId.split('&')
  const questionnaireId = splitQuizId[0]
  const tenantId = splitQuizId.length > 1 ? splitQuizId[1].split('=')[1] : null

  const fetch = async () => {
    try {
      setLoading(true)
      if (tenantId) {
        await handleTenantSignIn(user, tenantId)
      }
      const result = await api.getQuestionnarie(questionnaireId)
      setQuiz(result)
      if (result.questions.length) setItems(result.questions.sort((a, b) => a.number - b.number))
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // @ts-ignore
        const { code, message } = error.response.data
        if (code) {
          snackActions.open(message)
          return
        }
      }
      snackActions.open('Internal server error.')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetch()
  }, [])

  if (loading) {
    return <CircularLoadingCentred />
  }

  const isShareEnable = items.reduce((acc, item) => {
    console.log(item.number + ' - ' + items.length)
    if (item.number <= items.length) {
      acc = false
    }
    return acc
  }, true)

  const handleSave = async () => {
    setSaveLoading(true)
    try {
      await Promise.all(
        items
          .filter(item => item.number < items.length)
          .map(async (item, i) => {
            const invite = item.collectionInvite
            const payload = {
              storyId: '',
              collectionId: invite.collectionId,
              organizationId: invite.organization?.id || null,
              title: invite!.title,
              description: invite!.description,
              instructions: invite!.instructions,
              dueAt: invite!.dueAt || null,
              requestedMediaLength: Number(invite!.requestedMediaLength),
              requestedMediaType: invite.requestedMediaType,
              callToActionInstructions: null,
              callToActionButtonLabel: null,
              callToAction: null,
              hardStop: invite?.hardStop,
              template: false,
              requestFiles: invite?.requestFiles,
              requestImages: invite?.requestImages
            }
            // items[i + 1].collectionInvite.url,
            return await api.updateInvite(invite.id, payload)
          })
      )
      await fetch()
    } catch (e) {
      console.log(e)
    } finally {
      setSaveLoading(false)
    }
  }
  const options = [{ decision: 'approve' }, { decision: 'disapprove' }]
  const handleReview = (quetionnaireId: string) => {
    formDialogActions.open(
      'Review Questionnaire',
      '',
      [
        {
          label: 'Decision',
          name: 'decision',
          placeholder: 'Decision',
          type: 'select',
          options: options.map(opt => (
            <MenuItem key={opt.decision} value={opt.decision}>
              {capitalize(opt.decision)}
            </MenuItem>
          ))
        },
        {
          label: 'Reason',
          name: 'reason',
          placeholder: 'Reason',
          rows: 4,
          type: 'text'
        }
      ],
      { title: '', description: '', language: null },
      null,
      async (data: { decision: string; reason: string }) => {
        if (!data.decision) {
          snackActions.open('Please, select the decision as approve or disapprove.')
          return
        }
        const decision = data.decision === 'approve'
        try {
          await api.doReview({ questionnaireId: quetionnaireId, approved: decision, reason: data.reason })
          snackActions.open('Questionnaire reviewed successfully!')
        } catch (error) {
          if (axios.isAxiosError(error)) {
            // @ts-ignore
            const { code, message } = error.response.data
            if (code) {
              snackActions.open(message)
              return
            }
          }
          snackActions.open('Internal server error.')
        }
      },
      { okText: 'Submit', cancelText: `Cancel` }
    )
  }

  const editQuestionnaire = () => {
    formDialogActions.open(
      'Edit questionnaire',
      '',
      [
        {
          label: 'Title',
          name: 'title',
          placeholder: 'Title',
          type: 'text'
        },
        {
          label: 'Description',
          name: 'description',
          placeholder: 'Description',
          rows: 4,
          type: 'text'
        },
        {
          label: 'Language',
          name: 'language',
          placeholder: 'Language',
          type: 'select',
          options: Object.entries(LanguageEnum).map(([code, name]) => (
            <MenuItem key={code} value={code}>
              {name.charAt(0).toUpperCase() + name.slice(1)}
            </MenuItem>
          ))
        }
      ],
      {
        title: quiz?.title || '',
        description: quiz?.description || '',
        language: quiz?.language || ''
      },
      null,
      async (data: QuizInputType) => {
        try {
          if (!quiz || !quiz.id) return
          await api.updateQuestionnaire({
            ...quiz,
            title: data.title,
            description: data.description,
            language: data.language
          })
          await fetch()
        } catch (error) {
          if (axios.isAxiosError(error)) {
            // @ts-ignore
            const { code, message } = error.response.data
            if (code) {
              snackActions.open(message)
              return
            }
          }
          snackActions.open('Internal server error.')
        }
      },
      { okText: 'confirm', cancelText: `cancel` }
    )
  }

  return (
    <>
      {openEdit && (
        <QuestionConfigureModal
          invite={openEdit.invite}
          isConfigureAll={openEdit.isConfigureAll}
          questionnaireId={openEdit.questionnaireId}
          allowCallToAction={openEdit.isLastQuestion}
          question={openEdit.question}
          onClose={newInvite => {
            if (newInvite) {
              fetch()
            }
            setOpenEdit(null)
          }}
        />
      )}
      <Grid
        container
        sx={{
          padding: '24px'
        }}
        spacing={4}
      >
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              sx={{ cursor: 'pointer' }}
              underline="hover"
              onClick={() => history.push('/a/questionnaire?tab=questionnaire')}
            >
              Questionnaire
            </Link>
            <Stack direction="row" alignItems="center">
              <Typography>{quiz?.title}</Typography>
              <Box ml={1}>
                <IconButton onClick={editQuestionnaire}>
                  <EditIcon />
                </IconButton>
              </Box>
            </Stack>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              gap: '4px',
              justifyContent: 'space-between',
              alignItems: { xs: 'start', sm: 'center' },
              flexDirection: { xs: 'column', sm: 'row' }
            }}
          >
            <TextField
              key="search"
              name="search"
              sx={{
                minWidth: { xs: '300px', md: '422px' }
              }}
              rows={4}
              autoComplete="off"
              placeholder="Type question to filter"
              margin="dense"
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
            <Box sx={{ display: 'flex', gap: '4px' }}>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  if (quiz?.questions[0]) {
                    setOpenEdit({
                      invite: quiz?.questions[0]?.collectionInvite,
                      isLastQuestion: quiz?.questions[0]?.number >= items.length - 1,
                      isConfigureAll: true,
                      questionnaireId: quiz?.id
                    })
                  }
                }}
              >
                Configure all
              </Button>
              {quiz?.reviewers && member?.id && quiz?.reviewers.map(rev => rev.reviewerId).includes(member.id) && (
                <Button color="primary" variant="outlined" onClick={() => handleReview(quiz.id)}>
                  Review
                </Button>
              )}
              {quiz?.published && (
                <LoadingButton
                  variant="contained"
                  onClick={async () => {
                    await handleSave()
                    const invite = items[0]?.collectionInvite
                    handleShare(invite, undefined, undefined, undefined, true)
                  }}
                  loading={saveLoading}
                  color="primary"
                >
                  Share Questionnaire
                </LoadingButton>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            {items
              .filter(q => q.title.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
              .map((question, i) => (
                <>
                  <QuizQuestionItem key={question.id} question={question}>
                    <Box sx={{ display: 'flex' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                          color="secondary"
                          onClick={() => {
                            setOpenEdit({
                              invite: question.collectionInvite,
                              isLastQuestion: i >= items.length - 1,
                              questionnaireId: quiz?.id
                                ? quiz?.id
                                : question.questionnaireId
                                ? question.questionnaireId
                                : '',
                              question: question
                            })
                          }}
                        >
                          Configure
                        </Button>
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Button
                          color="secondary"
                          onClick={() => {
                            setOpenChangeMedia(question.collectionInvite.id)
                            setModalType(QuestionVideoTypeEnum.INTRO)
                          }}
                        >
                          Add Intro Video
                        </Button>
                        <Button
                          color="secondary"
                          onClick={() => {
                            setOpenChangeMedia(question.collectionInvite.id)
                            setModalType(QuestionVideoTypeEnum.MEDIA)
                          }}
                        >
                          Change/Add media
                        </Button>
                        <Button
                          color="secondary"
                          onClick={() => {
                            setOpenChangeMedia(question.collectionInvite.id)
                            setModalType(QuestionVideoTypeEnum.OUTRO)
                          }}
                        >
                          Add Outro Video
                        </Button>
                      </Box>
                    </Box>
                  </QuizQuestionItem>
                  {openChangeMedia === question.collectionInvite.id && (
                    <StepInviteRecorderChangeMedia
                      type={modalType}
                      invite={question.collectionInvite}
                      question={question}
                      onClose={newInvite => {
                        if (newInvite) fetch()
                        setOpenChangeMedia('')
                      }}
                    />
                  )}
                </>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default QuestionnaireQuestion
