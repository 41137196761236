import React, { useContext, useEffect, useState } from 'react'
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import SecurityIcon from '@mui/icons-material/Security'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import CloudIcon from '@mui/icons-material/Cloud'
import GroupIcon from '@mui/icons-material/Group'
import AnalyticsIcon from '@mui/icons-material/Analytics'
import ColorLensIcon from '@mui/icons-material/ColorLens'
import Password from './Password'
import Subscription from './Subscription'
import Storage from './Storage'
import Statistics from './Statistics'
import Info from './Info'
import Preferences from './Preferences'
import ProfessionalServices from './ProfessionalServices'
import AssignAdmin from './AssignAdmin'
import { parse } from 'qs'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import EngineeringIcon from '@mui/icons-material/Engineering'
import { UserContext } from '../../../components/UserProvider'
import PsychologyIcon from '@mui/icons-material/Psychology'
import CollectionThemes from './CollectionTheme'
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount'
import TeamMembers from './TeamMembers'
import { usePermissions } from '../../../components/hooks/usePermissions'

const Profile: React.FC<RouteComponentProps> = () => {
  const { user, member } = useContext(UserContext)
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.down('md'))
  const [t, setTab] = useState('info')
  const history = useHistory()
  const location = useLocation()
  const { isTeamManager, isAdministrator } = usePermissions()

  const isRakontoAdmin = user?.rakontoAdmin
  const onTabClick = (tab: string) => {
    history.push(`/a/profile?tab=${tab}`)
  }

  useEffect(() => {
    const { tab } = parse(location?.search === '' ? 'tab=info' : (location?.search as string), {
      ignoreQueryPrefix: true
    })
    setTab(tab as string)
  }, [location])

  return (
    <>
      <Typography
        sx={{
          paddingTop: 4,
          paddingBottom: 2,
          paddingLeft: 3
        }}
        variant="h4"
      >
        My Profile
      </Typography>
      <Box
        sx={{
          height: `100%`,
          display: 'flex',
          flexFlow: isMd ? 'column' : 'row'
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <Tabs
            value={t}
            orientation={isMd ? 'horizontal' : 'vertical'}
            variant="fullWidth"
            onChange={(event, value) => onTabClick(value)}
          >
            <Tab
              sx={{ minWidth: { xs: '40px', md: '70px' } }}
              value="info"
              label={
                <Tooltip placement="right" title="Info">
                  <AccountCircleIcon />
                </Tooltip>
              }
            />
            {(user.hasOwnLibrary || (!user.tenant && !user.userOfTenant)) && (
              <Tab
                sx={{ minWidth: { xs: '40px', md: '70px' } }}
                value="password"
                label={
                  <Tooltip placement="right" title="Password">
                    <SecurityIcon />
                  </Tooltip>
                }
              />
            )}
            <Tab
              sx={{ minWidth: { xs: '40px', md: '70px' } }}
              value="preferences"
              label={
                <Tooltip placement="right" title="Preferences">
                  <PsychologyIcon />
                </Tooltip>
              }
            />
            {isRakontoAdmin && (
              <Tab
                sx={{ minWidth: { xs: '40px', md: '70px' } }}
                value="assignAdmin"
                label={
                  <Tooltip placement="right" title="Admin Switch">
                    <SwitchAccountIcon />
                  </Tooltip>
                }
              />
            )}
            {(member || user?.tenant) && isTeamManager && (
              <Tab
                sx={{ minWidth: { xs: '40px', md: '70px' } }}
                value="teamMembers"
                label={
                  <Tooltip placement="right" title="Team members">
                    <GroupIcon />
                  </Tooltip>
                }
              />
            )}
            {isAdministrator && (
              <Tab
                sx={{ minWidth: { xs: '40px', md: '70px' } }}
                value="subscription"
                label={
                  <Tooltip placement="right" title="Subscription">
                    <CreditCardIcon />
                  </Tooltip>
                }
              />
            )}
            <Tab
              sx={{ minWidth: { xs: '40px', md: '70px' } }}
              value="storage"
              label={
                <Tooltip placement="right" title="Storage">
                  <CloudIcon />
                </Tooltip>
              }
            />
            <Tab
              sx={{ minWidth: { xs: '40px', md: '70px' } }}
              value="statistics"
              label={
                <Tooltip placement="right" title="Statistics">
                  <AnalyticsIcon />
                </Tooltip>
              }
            />
            <Tab
              sx={{ minWidth: { xs: '40px', md: '70px' } }}
              value="professionalServices"
              label={
                <Tooltip placement="right" title="Professional Services">
                  <EngineeringIcon />
                </Tooltip>
              }
            />
            {isAdministrator && (
              <Tab
                sx={{ minWidth: { xs: '40px', md: '70px' } }}
                value="collectionThemes"
                label={
                  <Tooltip placement="right" title="Collection Themes">
                    <ColorLensIcon />
                  </Tooltip>
                }
              />
            )}
          </Tabs>
        </Box>
        <Box
          sx={{
            flex: '1',
            height: '100%'
          }}
        >
          {t === 'info' && <Info />}
          {t === 'preferences' && <Preferences />}
          {t === 'assignAdmin' && <AssignAdmin />}
          {t === 'teamMembers' && <TeamMembers />}
          {t === 'password' && <Password />}
          {t === 'subscription' && <Subscription />}
          {t === 'storage' && <Storage />}
          {t === 'statistics' && <Statistics />}
          {t === 'professionalServices' && <ProfessionalServices />}
          {t === 'collectionThemes' && <CollectionThemes />}
        </Box>
      </Box>
    </>
  )
}

export default Profile
