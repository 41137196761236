import React, { useContext } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Cover from '../../../../../components/Cover'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import { UserContext } from '../../../../../components/UserProvider'
import AdjustedPhotosPublic from './AdjustedPhotosPublic'
import { CollectionThemeProps } from '../../../../../lib/types'

const CollectionViewer: React.FC<{ collectionTheme: CollectionThemeProps }> = ({ collectionTheme }) => {
  const { user } = useContext(UserContext)
  const logoUrl = user.organizations.length > 0 ? user.organizations[0]?.logo?.url : ''
  return (
    <Box
      sx={{
        padding: '16px',
        maxWidth: '800px',
        backgroundColor: collectionTheme.backgroundColor,
        position: 'relative',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: '16px'
      }}
    >
      <Box
        sx={{
          margin: 'auto',
          backgroundColor: collectionTheme.backgroundColor,
          width: '100%',
          height: 'auto',
          position: 'relative'
        }}
      >
        <Box>
          <AppBar position={'static'} sx={{ backgroundColor: collectionTheme.storyTitleBGColor }}>
            <Toolbar>
              <Box
                sx={{
                  display: 'flex',
                  width: '100%'
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Box
                    sx={{
                      marginRight: 'auto'
                    }}
                  >
                    {logoUrl ? (
                      <Box
                        sx={{
                          height: 57,
                          width: 192.5,
                          backgroundImage: `url(${logoUrl})`,
                          backgroundSize: 'contain',
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat'
                        }}
                      />
                    ) : (
                      <Box
                        component="img"
                        sx={{
                          width: 135,
                          height: 40,
                          alignItems: 'center',
                          pb: '1px'
                        }}
                        src={'/images/logo-withe.svg'}
                        alt="logo-white"
                      />
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    flexShrink: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center'
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: collectionTheme.collectionTitleColor,
                      fontFamily: collectionTheme.collectionTitleFontFamily,
                      fontSize: collectionTheme.collectionTitleFontSize
                        ? collectionTheme.collectionTitleFontSize + ' !important'
                        : ''
                    }}
                  >
                    Collection Title
                  </Typography>
                </Box>
                <Box
                  sx={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Typography variant="h6" sx={{ marginLeft: 'auto' }}>
                    {''}
                  </Typography>
                </Box>
              </Box>
            </Toolbar>
          </AppBar>
        </Box>
        <Cover
          author={user}
          src={''}
          subtitle={'Subtitle'}
          title={'Collection Title'}
          description={'Collection description'}
          buttonLabel="View stories"
          onClick={() => console.log('clicked')}
          height={'400px'}
          theme={collectionTheme}
        />
        <Box
          sx={{
            width: '100%',
            padding: '0 24px',
            height: 65,
            display: 'flex',
            alignItems: 'center',
            backgroundColor: collectionTheme.storyCountBGColor,
            boxShadow: 6
          }}
        >
          <Typography
            variant="h6"
            sx={{
              width: '100%',
              color: collectionTheme.currentStoryTitleColor ?? collectionTheme.textColor,
              fontFamily: collectionTheme.currentStoryTitleFontFamily,
              fontSize: collectionTheme.currentStoryTitleFontSize + ' !important'
            }}
          >
            Story 1 of 5 | Current story title
          </Typography>
        </Box>
        <Box
          sx={{
            position: 'relative',
            width: '98%',
            left: '1%',
            height: 65,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginY: 2,
            paddingX: 3,
            backgroundColor: collectionTheme.barBackgroundColor
          }}
          component={Paper}
        >
          <Button
            variant="outlined"
            sx={{
              color: collectionTheme.barButtonsTextColor,
              borderColor: collectionTheme.barButtonsTextColor,
              ':hover': {
                borderColor: collectionTheme.barButtonsTextColor,
                backgroundColor: collectionTheme.barButtonsHoverColor
              }
            }}
          >
            <Typography
              variant="body1"
              fontSize="1.1rem"
              textAlign="center"
              padding="0.2rem"
              sx={{
                fontFamily: collectionTheme.barButtonsFontFamily,
                fontSize: collectionTheme.buttonOnBarFontSize
              }}
            >
              Add your voice
            </Typography>
          </Button>
          <Button
            variant="outlined"
            sx={{
              color: collectionTheme.barButtonsTextColor,
              borderColor: collectionTheme.barButtonsTextColor,
              ':hover': {
                borderColor: collectionTheme.barButtonsTextColor,
                backgroundColor: collectionTheme.barButtonsHoverColor
              }
            }}
          >
            <Typography
              variant="body1"
              fontSize="1.1rem"
              textAlign="center"
              padding="0.2rem"
              sx={{
                fontFamily: collectionTheme.barButtonsFontFamily,
                fontSize: collectionTheme.buttonOnBarFontSize
              }}
            >
              View My Library
            </Typography>
          </Button>
        </Box>
        <Box width="100%" height="100%" top="2em">
          <Typography
            variant="h5"
            textAlign="center"
            sx={{ color: collectionTheme.textColor, fontFamily: collectionTheme.bodyFontFamily }}
          >
            Photos
          </Typography>
          <AdjustedPhotosPublic />
        </Box>
      </Box>
    </Box>
  )
}

export default CollectionViewer
