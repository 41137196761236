// @ts-nocheck
import Immutable from 'immutable'
// @ts-ignore
import { Transcript, TranscriptSegment, TranscriptWord } from 'transcript-model'
import { ContentState } from 'draft-js'

const convertToTranscript = (contentState: ContentState, speakers) => {
  const segments = contentState
    .getBlockMap()
    .toArray()
    .map(block => {
      const words: string[] = []

      block.findEntityRanges(
        character => {
          const entityKey = character.getEntity()
          if (entityKey === null) {
            return false
          }
          return contentState.getEntity(entityKey).getType() === 'TRANSCRIPT_WORD'
        },
        (start, end) => {
          const entity = contentState.getEntity(block.getEntityAt(start))
          const text = block.getText().substring(start, end)
          console.log('start: ' + start + ', end: ' + end + ' --> ' + text)
          // console.log(text + '-->' + block.getText())
          words.push(
            new TranscriptWord({
              start: entity.data.start,
              end: entity.data.end,
              id: entity.data.id,
              text
            })
          )
        }
      )

      return new TranscriptSegment({
        words: new Immutable.List(words),
        speaker: block.data.get('speaker')
      })
    })

  return new Transcript({
    segments: new Immutable.List(segments),
    speakers
  })
}

export default convertToTranscript
