import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Player from '../../Player'
import { CollectionThemeProps, SubtitleType, VideoDetails } from '../../../lib/types'
import Button from '@mui/material/Button'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'

const InstructionArea: React.FC<{
  media: VideoDetails
  subtitles?: SubtitleType[]
  collectionTheme?: CollectionThemeProps
}> = ({ media, subtitles, collectionTheme }) => {
  const [play, setPlay] = useState(false)

  const handlePlay = () => {
    setPlay(true)
  }

  return (
    <Box
      sx={{
        width: { xs: '100%', md: 422 },
        height: { xs: 300, md: 422 },
        border: '1px dashed #7b7b7c',
        borderRadius: '20px',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      {play ? (
        <Box sx={{ width: { xs: '100%', md: 421 }, height: { xs: 300, md: 420 }, borderRadius: '20px' }}>
          <Player
            type={'VIDEO'}
            subtitles={subtitles || []}
            media={media}
            mobileMaxHeight={'300px'}
            maxHeight={'422px'}
            autoplay={true}
            borderRadius={'20px'}
            playsInline={true}
          />
        </Box>
      ) : (
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: { xs: 300, md: 422 },
            backgroundImage: `url(${media.thumbnailUrl || '/images/CoverDefault.png'})`,
            backgroundSize: media.thumbnailUrl ? 'contain' : 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Button
            onClick={handlePlay}
            variant="contained"
            sx={{ backgroundColor: collectionTheme?.questionnaireContainedButtonBackgroundColor ?? 'primary' }}
          >
            <PlayArrowIcon />
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default InstructionArea
