import Cookies from 'js-cookie'
import React from 'react'
import { useLinkedIn } from 'react-linkedin-login-oauth2'
import { useHistory } from 'react-router-dom'
import { SimpleSnackbarContext } from '../../../components/SimpleSnackbar'
import { parse } from 'qs'
import api from '../../../lib/api'
import handleTenantSignIn from '../../../components/HandleTenantSignIn'

const Component = React.memo(() => {
  const history = useHistory()
  const { returnUrl } = parse(location.search, { ignoreQueryPrefix: true })
  const { actions: snackActions } = React.useContext(SimpleSnackbarContext)

  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_APP_ID || '',
    redirectUri: `${window.location.origin}/u/linkedin`,
    scope: 'r_emailaddress r_liteprofile',
    onSuccess: async code => {
      try {
        const userInfo = await api.signinLinkedin({ token: code })
        Cookies.set('token', userInfo.token)
        Cookies.set('backupToken', userInfo.token)
        if (userInfo.user) {
          await handleTenantSignIn(userInfo.user)
        }
        if (returnUrl) {
          await history.push(returnUrl as string)
          return
        }
        if (!userInfo.user.onboardAt) {
          history.push('/a/onboarding')
          return
        }
        history.push('/a/my-library')
      } catch (error) {
        snackActions.open('Something was wrong! please try again.')
      }
    }
  })

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'white',
        alignItems: 'center',
        borderRadius: '8px',
        height: '40px',
        width: '40px',
        cursor: 'pointer',
        zIndex: 2
      }}
    >
      <img
        onClick={linkedInLogin}
        src={'/images/LinkedIn_icon_circle.png'}
        alt="Sign in with Linked In"
        width={'28px'}
        height={'28px'}
      />
    </div>
  )
})

export default Component
