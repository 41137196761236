import { CharacterMetadata, ContentBlock, ContentState } from 'draft-js'
import { TRANSCRIPT_SPACE } from './TranscriptEntities'

const updateBlock = (contentBlock: ContentBlock, contentState: ContentState) =>
  contentBlock.getCharacterList().reduce<ContentBlock>(
    // @ts-ignore
    (acc, character, index) => {
      // Is this the first character?
      if (!acc!.getCharacterList().isEmpty()) {
        const previousCharacter = acc!.getCharacterList().last()
        // Does the previous character have an entity?
        if (previousCharacter.getEntity()) {
          // Does the previous character have a different entity?
          if (character!.getEntity()) {
            const entity = contentState.getEntity(character!.getEntity())
            const previousEntity = contentState.getEntity(previousCharacter.getEntity())
            // Does the different entity have the same type?
            if (entity.getType() === previousEntity.getType() && entity !== previousEntity) {
              // Merge the entities
              contentState.mergeEntityData(previousCharacter.getEntity(), entity.getData())
              acc = acc!.set('text', acc!.getText() + contentBlock.getText()[index!]) as ContentBlock
              acc = acc!.set(
                'characterList',
                acc!.getCharacterList().push(CharacterMetadata.applyEntity(character!, previousCharacter.getEntity()))
              ) as ContentBlock
              return acc
            } else if (entity.getType() === TRANSCRIPT_SPACE && previousEntity.getType() === TRANSCRIPT_SPACE) {
              return acc
            }
          }
        } else {
          // Is this character a space?
          if (contentState.getEntity(character!.getEntity()).getType() === TRANSCRIPT_SPACE) {
            // Set previous character's entity to that of the character before it
            acc = acc!.set('text', acc!.getText() + contentBlock.getText()[index!]) as ContentBlock
            acc = acc!.set(
              'characterList',
              acc!
                .getCharacterList()
                .set(-1, CharacterMetadata.applyEntity(previousCharacter, acc!.getCharacterList().get(-2).getEntity()))
                .push(character!)
            ) as ContentBlock
            return acc
          }
          // Otherwise set previous character's entity to this character's
          acc = acc!.set('text', acc!.getText() + contentBlock.getText()[index!]) as ContentBlock
          acc = acc!.set(
            'characterList',
            acc!
              .getCharacterList()
              .set(-1, CharacterMetadata.applyEntity(previousCharacter, character!.getEntity()))
              .push(character!)
          ) as ContentBlock
          return acc
        }
      }
      acc = acc!.set('text', acc!.getText() + contentBlock.getText()[index!]) as ContentBlock
      acc = acc!.set('characterList', acc!.getCharacterList().push(character!)) as ContentBlock
      return acc
    },
    new ContentBlock()
  )

export default updateBlock
