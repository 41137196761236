import React, { useContext, useState } from 'react'
import { Box } from '@mui/material'
import CollectionViewer from './CollectionViewer'
import SignupModal from './SignupModal'
import RequestsModal from './RequestsModal'
import SimpleDialog from './SimpleDialog'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { CollectionTheme, CollectionThemeProps } from '../../../../../lib/types'
import api from '../../../../../lib/api'
import TextField from '@mui/material/TextField'
import { SimpleSnackbarContext } from '../../../../../components/SimpleSnackbar'
import MenuItem from '@mui/material/MenuItem'
import Step1 from './QuestionnaireSteps/Step1'
import Step2 from './QuestionnaireSteps/Step2'
import ButtonGroup from '@mui/material/ButtonGroup'
import Step3 from './QuestionnaireSteps/Step3'

const CollectionThemeCreator: React.FC<{
  colTheme: CollectionTheme
  setSelectedTheme: (theme: CollectionTheme | undefined) => void
}> = ({ colTheme, setSelectedTheme }) => {
  const collectionTheme = colTheme?.properties

  function lightenColor(hex: any, amount: any) {
    // Convert hex to RGB first
    let r = parseInt(hex.substring(1, 3), 16)
    let g = parseInt(hex.substring(3, 5), 16)
    let b = parseInt(hex.substring(5, 7), 16)

    // Darken the RGB values
    r = Math.round(r * amount) > 255 ? 255 : Math.round(r * amount)
    g = Math.round(g * amount) > 255 ? 255 : Math.round(g * amount)
    b = Math.round(b * amount) > 255 ? 255 : Math.round(b * amount)

    // Convert RGB back to hex
    let result = ((r << 16) + (g << 8) + b).toString(16)
    while (result.length < 6) {
      result = '0' + result
    }
    return '#' + result
  }

  const { actions: snackActions } = useContext(SimpleSnackbarContext)

  const [themeName, setThemeName] = useState(collectionTheme?.themeName ?? 'New Theme')
  const [textColor, setTextColor] = useState(collectionTheme?.textColor ?? '#FFFFFF')
  const [backgroundColor, setBackgroundColor] = useState(collectionTheme?.backgroundColor ?? '#1D211F')
  const [storyTitleBGColor, setStoryTitleBGColor] = useState(collectionTheme?.storyTitleBGColor ?? '#2E2E2E')
  const [storyCountBGColor, setStoryCountBGColor] = useState(collectionTheme?.storyCountBGColor ?? '#141414')
  const [videoButtonBGColor, setVideoButtonBGColor] = useState(collectionTheme?.videoButtonBGColor ?? '#6EC069')
  const [videoButtonHoverColor, setVideoButtonHoverColor] = useState(collectionTheme?.videoButtonHoverColor ?? '')
  const [videoButtonFontFamily, setVideoButtonFontFamily] = useState(collectionTheme?.videoButtonFontFamily ?? '')
  const [videoButtonTextColor, setVideoButtonTextColor] = useState(collectionTheme?.videoButtonTextColor ?? '#000000')
  const [barBackgroundColor, setBarBackgroundColor] = useState(collectionTheme?.barBackgroundColor ?? '#232323')
  const [barButtonsFontFamily, setBarButtonsFontFamily] = useState(collectionTheme?.barButtonsFontFamily ?? '')
  const [barButtonsTextColor, setBarButtonsTextColor] = useState(collectionTheme?.barButtonsTextColor ?? '#6EC069')
  const [barButtonsHoverColor, setBarButtonsHoverColor] = useState(collectionTheme?.barButtonsHoverColor ?? '')
  const [collectionViewerFontFamily, setCollectionViewerFontFamily] = useState(
    collectionTheme?.collectionViewerFontFamily ?? ''
  )
  const [bodyFontFamily, setBodyFontFamily] = useState(collectionTheme?.bodyFontFamily ?? '')
  const [bodyTextColor, setBodyTextColor] = useState(collectionTheme?.bodyTextColor ?? '#FFFFFF')
  const [collectionTitleFontFamily, setCollectionTitleFontFamily] = useState(
    collectionTheme?.collectionTitleFontFamily ?? ''
  )
  const [collectionTitleColor, setCollectionTitleColor] = useState(collectionTheme?.collectionTitleColor ?? '#FFFFFF')
  const [collectionTitleFontSize, setCollectionTitleFontSize] = useState(collectionTheme?.collectionTitleFontSize ?? '')
  const [currentStoryTitleFontFamily, setCurrentStoryTitleFontFamily] = useState(
    collectionTheme?.currentStoryTitleFontFamily ?? ''
  )
  const [currentStoryTitleColor, setCurrentStoryTitleColor] = useState(
    collectionTheme?.currentStoryTitleColor ?? '#FFFFFF'
  )
  const [currentStoryTitleFontSize, setCurrentStoryTitleFontSize] = useState(
    collectionTheme?.currentStoryTitleFontSize ?? ''
  )
  const [buttonOnBarFontSize, setButtonOnBarFontSize] = useState(collectionTheme?.buttonOnBarFontSize ?? '')

  const [signupTextFontFamily, setSignupTextFontFamily] = useState(collectionTheme?.signupTextFontFamily ?? '')
  const [signupTextColor, setSignupTextColor] = useState(collectionTheme?.signupTextColor ?? '#FFFFFF')
  const [signupBackgroundColor, setSignupBackgroundColor] = useState(
    collectionTheme?.signupBackgroundColor ?? '#141414'
  )
  const [signupButtonBGColor, setSignupButtonBGColor] = useState(collectionTheme?.signupButtonBGColor ?? '#6EC069')
  const [signupButtonHoverColor, setSignupButtonHoverColor] = useState(
    collectionTheme?.signupButtonHoverColor ?? '#6EC069'
  )
  const [signupButtonTextColor, setSignupButtonTextColor] = useState(
    collectionTheme?.signupButtonTextColor ?? '#000000'
  )
  const [signupCheckBoxColor, setSignupCheckBoxColor] = useState(collectionTheme?.signupCheckBoxColor ?? '#6EC069')
  const [signupLinksColor, setSignupLinksColor] = useState(collectionTheme?.signupLinksColor ?? '#6EC069')
  const [signupInputOutlineColor, setSignupInputOutlineColor] = useState(
    collectionTheme?.signupInputOutlineColor ?? '#6EC069'
  )
  const [signupInputTextColor, setSignupInputTextColor] = useState(collectionTheme?.signupInputTextColor ?? '#A7A7A7')
  const [signupInputBGColor, setSignupInputBGColor] = useState(collectionTheme?.signupInputBGColor ?? '#424242')

  const [requestsTextFontFamily, setRequestsTextFontFamily] = useState(collectionTheme?.requestsTextFontFamily ?? '')
  const [requestsTextColor, setRequestsTextColor] = useState(collectionTheme?.requestsTextColor ?? '#FFFFFF')
  const [requestsBackgroundColor, setRequestsBackgroundColor] = useState(
    collectionTheme?.requestsBackgroundColor ?? '#141414'
  )
  const [cardTextColor, setCardTextColor] = useState(collectionTheme?.cardTextColor ?? '#FFFFFF')
  const [cardBackgroundColor, setCardBackgroundColor] = useState(collectionTheme?.cardBackgroundColor ?? '#313131')
  const [cardHoverColor, setCardHoverColor] = useState(collectionTheme?.cardHoverColor ?? '#373E36')

  const [notificationTextFontFamily, setNotificationTextFontFamily] = useState(
    collectionTheme?.notificationTextFontFamily ?? ''
  )
  const [notificationTextColor, setNotificationTextColor] = useState(
    collectionTheme?.notificationTextColor ?? '#FFFFFF'
  )
  const [notificationBackgroundColor, setNotificationBackgroundColor] = useState(
    collectionTheme?.notificationBackgroundColor ?? '#1D211F'
  )
  const [notificationCancelButtonColor, setNotificationCancelButtonColor] = useState(
    collectionTheme?.notificationCancelButtonColor ?? '#6EC069'
  )
  const [notificationCancelButtonHoverColor, setNotificationCancelButtonHoverColor] = useState(
    collectionTheme?.notificationCancelButtonHoverColor ?? lightenColor('#6EC069', 0.3)
  )
  const [notificationConfirmButtonColor, setNotificationConfirmButtonColor] = useState(
    collectionTheme?.notificationConfirmButtonColor ?? '#A6A6A6'
  )
  const [notificationConfirmButtonHoverColor, setNotificationConfirmButtonHoverColor] = useState(
    collectionTheme?.notificationConfirmButtonHoverColor ?? lightenColor('#A6A6A6', 0.3)
  )
  const [notificationCloseIconColor, setNotificationCloseIconColor] = useState(
    collectionTheme?.notificationCloseIconColor ?? '#FFFFFF'
  )

  const [questionnaireTextFontFamily, setQuestionnaireTextFontFamily] = useState(
    collectionTheme?.questionnaireTextFontFamily ?? ''
  )
  const [questionnaireHeaderBackgroundColor, setQuestionnaireHeaderBackgroundColor] = useState(
    collectionTheme?.questionnaireHeaderBackgroundColor ?? '#2E2E2E'
  )
  const [questionnaireBackgroundColor, setQuestionnaireBackgroundColor] = useState(
    collectionTheme?.questionnaireBackgroundColor ?? '#1D211F'
  )
  const [questionnaireBodyTextColor, setQuestionnaireBodyTextColor] = useState(
    collectionTheme?.questionnaireBodyTextColor ?? '#ffffff'
  )
  const [questionnaireLinksColor, setQuestionnaireLinksColor] = useState(
    collectionTheme?.questionnaireLinksColor ?? '#6EC069'
  )
  const [questionnaireInputBackgroundColor, setQuestionnaireInputBackgroundColor] = useState(
    collectionTheme?.questionnaireInputBackgroundColor ?? '#424242'
  )
  const [questionnaireInputTextColor, setQuestionnaireInputTextColor] = useState(
    collectionTheme?.questionnaireInputTextColor ?? '#A7A7A7'
  )
  const [questionnaireInputLabelColor, setQuestionnaireInputLabelColor] = useState(
    collectionTheme?.questionnaireInputLabelColor ?? '#A7A7A7'
  )
  const [questionnaireInputOutlineColor, setQuestionnaireInputOutlineColor] = useState(
    collectionTheme?.questionnaireInputOutlineColor ?? '#6EC069'
  )
  const [questionnaireContainedButtonTextColor, setQuestionnaireContainedButtonTextColor] = useState(
    collectionTheme?.questionnaireContainedButtonTextColor ?? ''
  )
  const [questionnaireContainedButtonBackgroundColor, setQuestionnaireContainedButtonBackgroundColor] = useState(
    collectionTheme?.questionnaireContainedButtonBackgroundColor ?? '#6EC069'
  )
  const [questionnaireContainedButtonHoverColor, setQuestionnaireContainedButtonHoverColor] = useState(
    collectionTheme?.questionnaireContainedButtonHoverColor ?? ''
  )
  const [questionnaireContainedDisabledButtonColor, setQuestionnaireContainedDisabledButtonColor] = useState(
    collectionTheme?.questionnaireContainedDisabledButtonColor ?? 'white'
  )
  const [questionnaireContainedDisabledButtonBackgroundColor, setQuestionnaireContainedDisabledButtonBackgroundColor] =
    useState(collectionTheme?.questionnaireContainedDisabledButtonBackgroundColor ?? '')
  const [questionnaireRecordIconColor, setQuestionnaireRecordIconColor] = useState(
    collectionTheme?.questionnaireRecordIconColor ?? '#000000'
  )
  const [questionnaireRecordIconBackgroundColor, setQuestionnaireRecordIconBackgroundColor] = useState(
    collectionTheme?.questionnaireRecordIconBackgroundColor ?? '#6EC069'
  )
  const [questionnaireRecordButtonColor, setQuestionnaireRecordButtonColor] = useState(
    collectionTheme?.questionnaireRecordButtonColor ?? '#6EC069'
  )
  const [questionnaireRecordButtonHoverColor, setQuestionnaireRecordButtonHoverColor] = useState(
    collectionTheme?.questionnaireRecordButtonHoverColor ?? lightenColor('#6EC069', 0.3)
  )
  const [questionnaireOutlinedButtonColor, setQuestionnaireOutlinedButtonColor] = useState(
    collectionTheme?.questionnaireOutlinedButtonColor ?? '#6EC069'
  )
  const [questionnaireOutlinedButtonHoverColor, setQuestionnaireOutlinedButtonHoverColor] = useState(
    collectionTheme?.questionnaireOutlinedButtonHoverColor ?? lightenColor('#6EC069', 0.3)
  )

  const theme: CollectionThemeProps = {
    themeName,

    textColor,
    backgroundColor,
    storyTitleBGColor,
    storyCountBGColor,
    videoButtonBGColor,
    videoButtonHoverColor,
    videoButtonTextColor,
    videoButtonFontFamily,
    barBackgroundColor,
    barButtonsFontFamily,
    barButtonsTextColor,
    barButtonsHoverColor,
    collectionViewerFontFamily,
    bodyTextColor,
    bodyFontFamily,
    collectionTitleFontSize,
    collectionTitleFontFamily,
    collectionTitleColor,
    currentStoryTitleFontSize,
    currentStoryTitleFontFamily,
    currentStoryTitleColor,
    buttonOnBarFontSize,

    signupTextFontFamily,
    signupTextColor,
    signupBackgroundColor,
    signupButtonBGColor,
    signupButtonHoverColor,
    signupButtonTextColor,
    signupCheckBoxColor,
    signupLinksColor,
    signupInputTextColor,
    signupInputBGColor,
    signupInputOutlineColor,

    requestsTextFontFamily,
    requestsTextColor,
    requestsBackgroundColor,
    cardTextColor,
    cardBackgroundColor,
    cardHoverColor,

    notificationTextFontFamily,
    notificationTextColor,
    notificationBackgroundColor,
    notificationCancelButtonColor,
    notificationCancelButtonHoverColor,
    notificationConfirmButtonColor,
    notificationConfirmButtonHoverColor,
    notificationCloseIconColor,

    questionnaireTextFontFamily,
    questionnaireHeaderBackgroundColor,
    questionnaireBackgroundColor,
    questionnaireBodyTextColor,
    questionnaireLinksColor,
    questionnaireInputBackgroundColor,
    questionnaireInputTextColor,
    questionnaireInputOutlineColor,
    questionnaireInputLabelColor,
    questionnaireContainedButtonTextColor,
    questionnaireContainedButtonBackgroundColor,
    questionnaireContainedDisabledButtonBackgroundColor,
    questionnaireContainedDisabledButtonColor,
    questionnaireContainedButtonHoverColor,
    questionnaireRecordIconColor,
    questionnaireRecordIconBackgroundColor,
    questionnaireRecordButtonColor,
    questionnaireRecordButtonHoverColor,
    questionnaireOutlinedButtonColor,
    questionnaireOutlinedButtonHoverColor
  }
  const resetToDefault = () => {
    setTextColor('#FFFFFF')
    setBodyTextColor('#FFFFFF')
    setCollectionTitleColor('#FFFFFF')
    setCurrentStoryTitleColor('#FFFFFF')
    setBackgroundColor('#1D211F')
    setStoryTitleBGColor('#2E2E2E')
    setStoryCountBGColor('#141414')
    setVideoButtonBGColor('#6EC069')
    setVideoButtonHoverColor('')
    setVideoButtonTextColor('#000000')
    setBarBackgroundColor('#232323')
    setBarButtonsTextColor('#6EC069')
    setBarButtonsHoverColor('')
    setCollectionViewerFontFamily('')
    setCollectionTitleFontFamily('')
    setCurrentStoryTitleFontFamily('')
    setBarButtonsFontFamily('')
    setVideoButtonFontFamily('')
    setCollectionTitleFontSize('')
    setCurrentStoryTitleFontSize('')
    setButtonOnBarFontSize('')
  }

  const resetSignupToDefault = () => {
    setSignupTextFontFamily('')
    setSignupTextColor('#FFFFFF')
    setSignupBackgroundColor('#141414')
    setSignupButtonBGColor('#6EC069')
    setSignupButtonHoverColor('')
    setSignupButtonTextColor('#000000')
    setSignupCheckBoxColor('#6EC069')
    setSignupLinksColor('#6EC069')
    setSignupInputOutlineColor('#6EC069')
    setSignupInputTextColor('#A7A7A7')
    setSignupInputBGColor('#424242')
  }

  const resetCollectionInviteToDefault = () => {
    setRequestsTextFontFamily('')
    setRequestsTextColor('#FFFFFF')
    setRequestsBackgroundColor('#141414')
    setCardTextColor('#FFFFFF')
    setCardBackgroundColor('#313131')
    setCardHoverColor('#373E36')
  }

  const resetNotificationToDefault = () => {
    setNotificationTextFontFamily('')
    setNotificationTextColor('#FFFFFF')
    setNotificationBackgroundColor('#1D211F')
    setNotificationCancelButtonColor('#6EC069')
    setNotificationCancelButtonHoverColor('')
    setNotificationConfirmButtonColor('#A6A6A6')
    setNotificationConfirmButtonHoverColor('')
    setNotificationCloseIconColor('#FFFFFF')
  }

  const resetQuestionnaireToDefault = () => {
    setQuestionnaireTextFontFamily('')
    setQuestionnaireHeaderBackgroundColor('#2E2E2E')
    setQuestionnaireBackgroundColor('#1D211F')
    setQuestionnaireBodyTextColor('#ffffff')
    setQuestionnaireLinksColor('#6EC069')
    setQuestionnaireInputBackgroundColor('#424242')
    setQuestionnaireInputTextColor('#A7A7A7')
    setQuestionnaireInputLabelColor('#A7A7A7')
    setQuestionnaireInputOutlineColor('#6EC069')
    setQuestionnaireContainedButtonTextColor('')
    setQuestionnaireContainedButtonBackgroundColor('#6EC069')
    setQuestionnaireContainedButtonHoverColor('')
    setQuestionnaireContainedButtonHoverColor('')
    setQuestionnaireContainedDisabledButtonColor('white')
    setQuestionnaireContainedDisabledButtonBackgroundColor('')
    setQuestionnaireRecordIconColor('#000000')
    setQuestionnaireRecordIconBackgroundColor('#6EC069')
    setQuestionnaireRecordButtonColor('#6EC069')
    setQuestionnaireRecordButtonHoverColor(lightenColor('#6EC069', 0.3))
    setQuestionnaireOutlinedButtonColor('#6EC069')
    setQuestionnaireOutlinedButtonHoverColor(lightenColor('#6EC069', 0.3))
  }

  const resetAllToDefault = () => {
    resetToDefault()
    resetSignupToDefault()
    resetCollectionInviteToDefault()
    resetNotificationToDefault()
    resetQuestionnaireToDefault()
  }

  const setPropsToAll = () => {
    setSignupTextColor(bodyTextColor)
    setSignupBackgroundColor(backgroundColor)
    setSignupButtonBGColor(videoButtonBGColor)
    setSignupButtonTextColor(videoButtonTextColor)
    setSignupCheckBoxColor(videoButtonBGColor)
    setSignupLinksColor(videoButtonBGColor)
    setSignupInputOutlineColor(videoButtonBGColor)
    setSignupInputTextColor(videoButtonTextColor)
    setSignupInputBGColor(barBackgroundColor)
    setRequestsTextColor(bodyTextColor)
    setRequestsBackgroundColor(backgroundColor)
    setCardTextColor(bodyTextColor)
    setCardBackgroundColor(barBackgroundColor)
    setCardHoverColor(lightenColor(videoButtonBGColor, 0.35))
    setNotificationTextColor(bodyTextColor)
    setNotificationBackgroundColor(backgroundColor)
    setNotificationCancelButtonColor(barButtonsTextColor)
    setNotificationConfirmButtonColor(bodyTextColor)
    setNotificationCloseIconColor(bodyTextColor)
    setSignupTextFontFamily(collectionViewerFontFamily)
    setRequestsTextFontFamily(collectionViewerFontFamily)
    setNotificationTextFontFamily(collectionViewerFontFamily)
  }

  const save = async () => {
    try {
      if (colTheme?.id) {
        const data: CollectionTheme = { id: colTheme.id, properties: theme }
        await api.updateCollectionTheme(data)
        snackActions.open('Theme updated successfully!')
      } else {
        const data: CollectionTheme = { properties: theme }
        await api.createCollectionTheme(data)
        snackActions.open('Theme created successfully!')
      }
      setSelectedTheme(undefined)
    } catch {
      snackActions.open('Something was wrong! please try again.')
    }
  }

  const fontFamilies = [
    'Arial',
    'Helvetica',
    'Verdana',
    'Tahoma',
    'Trebuchet MS',
    'Times New Roman',
    'Georgia',
    'Garamond',
    'Courier New',
    'Brush Script MT',
    'Olympic Serif',
    'Olympic Sans',
    'Olympic Headline'
  ]

  const fontSizes = ['8px', '10px', '12px', '14px', '16px', '18px', '20px', '24px', '28px', '32px', '36px', '40px']

  return (
    <Box sx={{ width: '100%', height: 'auto', backgroundColor: 'background.paper', padding: 2, paddingBottom: '88px' }}>
      <TextField
        variant={'standard'}
        label={'Theme name'}
        margin="dense"
        value={themeName}
        onChange={event => setThemeName(event.target.value)}
        InputProps={{
          style: {
            width: '100%',
            height: '48px',
            borderWidth: '1px',
            fontSize: '32px',
            fontWeight: '300'
          }
        }}
      />
      <Typography sx={{ fontSize: '32px', width: '100%', textAlign: 'center', marginY: '64px' }}>
        Collection Viewer
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: '32px', justifyContent: 'center' }}>
        <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column', justifyContent: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <TextField
              select
              label="Collection title font family"
              value={collectionTitleFontFamily}
              onChange={event => setCollectionTitleFontFamily(event.target.value)}
              variant="standard"
              fullWidth
            >
              {fontFamilies.map(fontFamily => (
                <MenuItem key={fontFamily} value={fontFamily}>
                  <span style={{ fontFamily }}>{fontFamily}</span>
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <TextField
              select
              label="Body font family"
              value={bodyFontFamily}
              onChange={event => setBodyFontFamily(event.target.value)}
              variant="standard"
              fullWidth
            >
              {fontFamilies.map(fontFamily => (
                <MenuItem key={fontFamily} value={fontFamily}>
                  <span style={{ fontFamily }}>{fontFamily}</span>
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <TextField
              select
              label="Current story title font family"
              value={currentStoryTitleFontFamily}
              onChange={event => setCurrentStoryTitleFontFamily(event.target.value)}
              variant="standard"
              fullWidth
            >
              {fontFamilies.map(fontFamily => (
                <MenuItem key={fontFamily} value={fontFamily}>
                  <span style={{ fontFamily }}>{fontFamily}</span>
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <TextField
              select
              label="Player button font family"
              value={videoButtonFontFamily}
              onChange={event => setVideoButtonFontFamily(event.target.value)}
              variant="standard"
              fullWidth
            >
              {fontFamilies.map(fontFamily => (
                <MenuItem key={fontFamily} value={fontFamily}>
                  <span style={{ fontFamily }}>{fontFamily}</span>
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <TextField
              select
              label="Buttons on bar font family"
              value={barButtonsFontFamily}
              onChange={event => setBarButtonsFontFamily(event.target.value)}
              variant="standard"
              fullWidth
            >
              {fontFamilies.map(fontFamily => (
                <MenuItem key={fontFamily} value={fontFamily}>
                  <span style={{ fontFamily }}>{fontFamily}</span>
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <TextField
              select
              label="Collection title font size"
              value={collectionTitleFontSize}
              onChange={event => setCollectionTitleFontSize(event.target.value)}
              variant="standard"
              fullWidth
            >
              {fontSizes.map(fontSize => (
                <MenuItem key={fontSize} value={fontSize}>
                  {fontSize}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <TextField
              select
              label="Current story title font size"
              value={currentStoryTitleFontSize}
              onChange={event => setCurrentStoryTitleFontSize(event.target.value)}
              variant="standard"
              fullWidth
            >
              {fontSizes.map(fontSize => (
                <MenuItem key={fontSize} value={fontSize}>
                  {fontSize}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <TextField
              select
              label="Button on bar font size"
              value={buttonOnBarFontSize}
              onChange={event => setButtonOnBarFontSize(event.target.value)}
              variant="standard"
              fullWidth
            >
              {fontSizes.map((fontSize, idx) => {
                return idx < 8 ? (
                  <MenuItem key={idx} value={fontSize}>
                    {fontSize}
                  </MenuItem>
                ) : null
              })}
            </TextField>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Collection title color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={collectionTitleColor}
              onChange={event => setCollectionTitleColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Body text color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={bodyTextColor}
              onChange={event => setBodyTextColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Current story title color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={currentStoryTitleColor}
              onChange={event => setCurrentStoryTitleColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Background color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={backgroundColor}
              onChange={event => setBackgroundColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Logo and collection title background:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={storyTitleBGColor}
              onChange={event => setStoryTitleBGColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Current story title background:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={storyCountBGColor}
              onChange={event => setStoryCountBGColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Player button background color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={videoButtonBGColor}
              onChange={event => setVideoButtonBGColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Player button hover background:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={videoButtonHoverColor}
              onChange={event => setVideoButtonHoverColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Player button text color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={videoButtonTextColor}
              onChange={event => setVideoButtonTextColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Bar background color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={barBackgroundColor}
              onChange={event => setBarBackgroundColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Button on bar text color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={barButtonsTextColor}
              onChange={event => setBarButtonsTextColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Button on bar hover color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={barButtonsHoverColor}
              onChange={event => setBarButtonsHoverColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'grid', gap: '8px' }}>
            <Button variant="outlined" onClick={() => resetToDefault()}>
              <Typography variant="body1" fontSize="1.1rem" textAlign="center" padding="0.2rem">
                Reset to default
              </Typography>
            </Button>
            <Button variant="outlined" onClick={() => setPropsToAll()}>
              <Typography variant="body1" fontSize="1.1rem" textAlign="center" padding="0.2rem">
                Apply to all components
              </Typography>
            </Button>
          </Box>
        </Box>
        <CollectionViewer collectionTheme={theme} />
      </Box>

      <Typography sx={{ fontSize: '32px', width: '100%', textAlign: 'center', marginY: '64px' }}>
        Signup Modal
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: '32px', justifyContent: 'center' }}>
        <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column', justifyContent: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <TextField
              select
              label="Text font family"
              value={signupTextFontFamily}
              onChange={event => setSignupTextFontFamily(event.target.value)}
              variant="standard"
              fullWidth
            >
              {fontFamilies.map(fontFamily => (
                <MenuItem key={fontFamily} value={fontFamily}>
                  <span style={{ fontFamily }}>{fontFamily}</span>
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Text color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={signupTextColor}
              onChange={event => setSignupTextColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Background color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={signupBackgroundColor}
              onChange={event => setSignupBackgroundColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Button text color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={signupButtonTextColor}
              onChange={event => setSignupButtonTextColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Button background color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={signupButtonBGColor}
              onChange={event => setSignupButtonBGColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Button hover background color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={signupButtonHoverColor}
              onChange={event => setSignupButtonHoverColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Checkbox color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={signupCheckBoxColor}
              onChange={event => setSignupCheckBoxColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Terms and privacy color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={signupLinksColor}
              onChange={event => setSignupLinksColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Input text color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={signupInputTextColor}
              onChange={event => setSignupInputTextColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Input background color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={signupInputBGColor}
              onChange={event => setSignupInputBGColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Input outline color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={signupInputOutlineColor}
              onChange={event => setSignupInputOutlineColor(event.target.value)}
            />
          </Box>
          <Button variant="outlined" onClick={() => resetSignupToDefault()}>
            <Typography variant="body1" fontSize="1.1rem" textAlign="center" padding="0.2rem">
              Reset to default
            </Typography>
          </Button>
        </Box>
        <SignupModal collectionTheme={theme} />
      </Box>
      <Typography sx={{ fontSize: '32px', width: '100%', textAlign: 'center', marginY: '64px' }}>
        Collection Invites Modal
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: '32px', justifyContent: 'center' }}>
        <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column', justifyContent: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <TextField
              select
              label="Text font family"
              value={requestsTextFontFamily}
              onChange={event => setRequestsTextFontFamily(event.target.value)}
              variant="standard"
              fullWidth
            >
              {fontFamilies.map(fontFamily => (
                <MenuItem key={fontFamily} value={fontFamily}>
                  <span style={{ fontFamily }}>{fontFamily}</span>
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Text color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={requestsTextColor}
              onChange={event => setRequestsTextColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Background color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={requestsBackgroundColor}
              onChange={event => setRequestsBackgroundColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Card text color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={cardTextColor}
              onChange={event => setCardTextColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Card background color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={cardBackgroundColor}
              onChange={event => setCardBackgroundColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Card hover color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={cardHoverColor}
              onChange={event => setCardHoverColor(event.target.value)}
            />
          </Box>
          <Button variant="outlined" onClick={() => resetCollectionInviteToDefault()}>
            <Typography variant="body1" fontSize="1.1rem" textAlign="center" padding="0.2rem">
              Reset to default
            </Typography>
          </Button>
        </Box>
        <RequestsModal collectionTheme={theme} />
      </Box>
      <Typography sx={{ fontSize: '32px', width: '100%', textAlign: 'center', marginY: '64px' }}>
        Notification Modal
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: '32px', justifyContent: 'center' }}>
        <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column', justifyContent: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <TextField
              select
              label="Text font family"
              value={notificationTextFontFamily}
              onChange={event => setNotificationTextFontFamily(event.target.value)}
              variant="standard"
              fullWidth
            >
              {fontFamilies.map(fontFamily => (
                <MenuItem key={fontFamily} value={fontFamily}>
                  <span style={{ fontFamily }}>{fontFamily}</span>
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Text color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={notificationTextColor}
              onChange={event => setNotificationTextColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Background color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={notificationBackgroundColor}
              onChange={event => setNotificationBackgroundColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Cancel button color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={notificationCancelButtonColor}
              onChange={event => setNotificationCancelButtonColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Cancel button hover color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={notificationCancelButtonHoverColor}
              onChange={event => setNotificationCancelButtonHoverColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Confirm button color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={notificationConfirmButtonColor}
              onChange={event => setNotificationConfirmButtonColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Confirm button hover color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={notificationConfirmButtonHoverColor}
              onChange={event => setNotificationConfirmButtonHoverColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Close icon color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={notificationCloseIconColor}
              onChange={event => setNotificationCloseIconColor(event.target.value)}
            />
          </Box>
          <Button variant="outlined" onClick={() => resetNotificationToDefault()}>
            <Typography variant="body1" fontSize="1.1rem" textAlign="center" padding="0.2rem">
              Reset to default
            </Typography>
          </Button>
        </Box>
        <SimpleDialog collectionTheme={theme} />
      </Box>
      <Typography sx={{ fontSize: '32px', width: '100%', textAlign: 'center', marginY: '64px' }}>
        Questionnaire Steps
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: '32px', justifyContent: 'center' }}>
        <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column', justifyContent: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <TextField
              select
              label="Text font family"
              value={questionnaireTextFontFamily}
              onChange={event => setQuestionnaireTextFontFamily(event.target.value)}
              variant="standard"
              fullWidth
            >
              {fontFamilies.map(fontFamily => (
                <MenuItem key={fontFamily} value={fontFamily}>
                  <span style={{ fontFamily }}>{fontFamily}</span>
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Header background color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={questionnaireHeaderBackgroundColor}
              onChange={event => setQuestionnaireHeaderBackgroundColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Background color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={questionnaireBackgroundColor}
              onChange={event => setQuestionnaireBackgroundColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Body text color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={questionnaireBodyTextColor}
              onChange={event => setQuestionnaireBodyTextColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Links color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={questionnaireLinksColor}
              onChange={event => setQuestionnaireLinksColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Input background color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={questionnaireInputBackgroundColor}
              onChange={event => setQuestionnaireInputBackgroundColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Input text color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={questionnaireInputTextColor}
              onChange={event => setQuestionnaireInputTextColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Input label color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={questionnaireInputLabelColor}
              onChange={event => setQuestionnaireInputLabelColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Input outline color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={questionnaireInputOutlineColor}
              onChange={event => setQuestionnaireInputOutlineColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Contained button text color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={questionnaireContainedButtonTextColor}
              onChange={event => setQuestionnaireContainedButtonTextColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Contained button background color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={questionnaireContainedButtonBackgroundColor}
              onChange={event => setQuestionnaireContainedButtonBackgroundColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Contained button hover color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={questionnaireContainedButtonHoverColor}
              onChange={event => setQuestionnaireContainedButtonHoverColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Disabled Contained button background color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={questionnaireContainedDisabledButtonBackgroundColor}
              onChange={event => setQuestionnaireContainedDisabledButtonBackgroundColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Disabled Contained button text color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={questionnaireContainedDisabledButtonColor}
              onChange={event => setQuestionnaireContainedDisabledButtonColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Record icon color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={questionnaireRecordIconColor}
              onChange={event => setQuestionnaireRecordIconColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Record icon background color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={questionnaireRecordIconBackgroundColor}
              onChange={event => setQuestionnaireRecordIconBackgroundColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Record button color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={questionnaireRecordButtonColor}
              onChange={event => setQuestionnaireRecordButtonColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Record button hover color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={questionnaireRecordButtonHoverColor}
              onChange={event => setQuestionnaireRecordButtonHoverColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Outlined button color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={questionnaireOutlinedButtonColor}
              onChange={event => setQuestionnaireOutlinedButtonColor(event.target.value)}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '12px' }}>
            <label htmlFor="color-picker">Outlined button hover color:</label>
            <input
              style={{ borderRadius: '4px', width: '30px', height: '30px' }}
              type="color"
              value={questionnaireOutlinedButtonHoverColor}
              onChange={event => setQuestionnaireOutlinedButtonHoverColor(event.target.value)}
            />
          </Box>
          <Button variant="outlined" onClick={() => resetQuestionnaireToDefault()}>
            <Typography variant="body1" fontSize="1.1rem" textAlign="center" padding="0.2rem">
              Reset to default
            </Typography>
          </Button>
        </Box>
        <Box maxWidth={'800px'}>
          <Typography sx={{ fontSize: '26px', width: '100%', textAlign: 'center', marginY: '32px' }}>Step 1</Typography>
          <Step1 collectionTheme={theme} />
          <Typography sx={{ fontSize: '26px', width: '100%', textAlign: 'center', marginY: '32px' }}>Step 2</Typography>
          <Step2 collectionTheme={theme} />
          <Typography sx={{ fontSize: '26px', width: '100%', textAlign: 'center', marginY: '32px' }}>
            Step 3 without customized call to action
          </Typography>
          <Step3 collectionTheme={theme} callToAction={true} />
          <Typography sx={{ fontSize: '26px', width: '100%', textAlign: 'center', marginY: '32px' }}>
            Step 3 with call to action
          </Typography>
          <Step3 collectionTheme={theme} callToAction={false} />
        </Box>
      </Box>
      <Box
        sx={{
          fontSize: '0.8rem',
          display: 'flex',
          width: '100%',
          position: 'fixed',
          bottom: 24,
          right: 24,
          zIndex: 11
        }}
      >
        <Box sx={{ flex: 1 }} />
        <ButtonGroup>
          <Button variant="contained" onClick={() => resetAllToDefault()}>
            <Typography variant="body1" fontSize="1.1rem" textAlign="center" padding="0.2rem">
              Reset all
            </Typography>
          </Button>
          <Button variant="contained" onClick={() => setSelectedTheme(undefined)}>
            <Typography variant="body1" fontSize="1.1rem" textAlign="center" padding="0.2rem">
              Cancel
            </Typography>
          </Button>
          <Button variant="contained" onClick={() => save()}>
            <Typography variant="body1" fontSize="1.1rem" textAlign="center" padding="0.2rem">
              Save
            </Typography>
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  )
}

export default CollectionThemeCreator
