import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import api from '../../lib/api'
import Cookies from 'js-cookie'
import { SimpleSnackbarContext } from '../SimpleSnackbar'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import handleTenantSignIn from '../HandleTenantSignIn'
import { SimpleDialogContext } from '../SimpleDialog'

const IocCallback: React.FC = () => {
  const history = useHistory()
  const { actions: snackActions } = React.useContext(SimpleSnackbarContext)
  const { actions: dialogActions } = React.useContext(SimpleDialogContext)
  const [loading, setLoading] = React.useState(false)
  const token = Cookies.get('token')

  const microsoftSignin = async (code: string) => {
    if (code.length === 0) return
    setLoading(true)
    try {
      const userInfo = await api.signinMicrosoft({ token: code })
      Cookies.set('token', userInfo.token)
      Cookies.set('backupToken', userInfo.token)
      if (userInfo.user && !userInfo.user.userOfTenant && !userInfo.user.tenant) {
        dialogActions.open(
          'Your access is still pending',
          'Please contact the application administrator in your domain to request access to Rakonto.',
          { cancelText: 'I understand' },
          (success: boolean) => {
            if (!success) {
              history.push('/a/signout')
            }
          }
        )
      }
      if (userInfo.user) {
        await handleTenantSignIn(userInfo.user)
      }
      if (!userInfo.user.onboardAt) {
        history.push('/a/onboarding')
        return
      }
      history.push('/a/my-library')
    } catch (error) {
      snackActions.open('Something was wrong! please try again.')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (loading || token) return
    // Get the current URL
    const currentUrl = window.location.href

    // Parse the URL to get the fragment identifier
    const fragmentIndex = currentUrl.indexOf('#')
    const fragment = fragmentIndex !== -1 ? currentUrl.substring(fragmentIndex + 1) : ''

    // Split the fragment into key-value pairs
    const keyValuePairs = fragment.split('&')

    // Create an object to store the parameters
    const params: { [key: string]: string } = {}

    // Loop through the key-value pairs and store them in the object
    keyValuePairs.forEach(keyValue => {
      const [key, value] = keyValue.split('=')
      params[key] = value
    })

    // Access the value of the 'code' parameter
    const codeParam = params.code

    microsoftSignin(codeParam).then()
  }, [history])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '16px',
        gap: '12px'
      }}
    >
      <Typography variant={'h4'} color={'primary'}>
        Redirecting...
      </Typography>
      <Typography>We are validating your login, soon you will be redirected</Typography>
    </Box>
  )
}

export default IocCallback
