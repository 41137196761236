import React, { useContext, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import api from '../../../lib/api'
import { PasswordChangeForm, UserFormType } from '../../../lib/types'
import Typography from '@mui/material/Typography'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Box from '@mui/material/Box'
import MobileStepper from '@mui/material/MobileStepper'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import Step1 from './steps/Step1'
import { useFormik } from 'formik'
import { SimpleSnackbarContext } from '../../../components/SimpleSnackbar'
import { updatePasswordSchema, updateUserSchema } from './schema'
import { UserContext } from '../../../components/UserProvider'
import Subscription from './steps/Subscription'

const ContributorInvite: React.FC = () => {
  const { user, setUser } = useContext(UserContext)
  const history = useHistory()
  const [activeStep, setActiveStep] = useState(0)
  const { actions: snackActions } = useContext(SimpleSnackbarContext)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const steps = [
    { label: 'My profile', error: false },
    { label: 'Account', error: false }
  ]

  const name = user?.email.split('@')[0]
  const firstName = user?.firstName === name && user?.lastName === name ? '' : user?.firstName || ''
  const lastName = user?.firstName === name && user?.lastName === name ? '' : user?.lastName || ''

  const infoInitialValues: UserFormType = {
    firstName,
    lastName,
    phone: user?.phone || '',
    about: user?.about || '',
    facebook: user?.facebook || '',
    twitter: user?.twitter || '',
    instagram: user?.instagram || '',
    linkedin: user?.linkedin || '',
    whatsapp: user?.whatsapp || '',
    industry: user?.industry || ''
  }

  const infoFormik = useFormik({
    initialValues: infoInitialValues,
    validationSchema: updateUserSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async data => {
      return null
    }
  })

  // Password
  const initialValuesPassword: PasswordChangeForm = { password: '', newPassword: '', confirmation: '' }

  const passwordFormik = useFormik({
    initialValues: initialValuesPassword,
    validationSchema: updatePasswordSchema,
    validateOnMount: true,
    onSubmit: async data => {
      return null
    }
  })

  // step controls
  const handleNext = useCallback(async () => {
    setIsSubmitting(true)
    if (activeStep === 1) {
      setIsSubmitting(false)
      history.push('/a/my-library?showGreetings=true')
      return
    }
    try {
      const newUser = await api.updateMe(infoFormik.values)
      if (Object.values(passwordFormik.values).some(item => !!item)) {
        await api.passwordChange(passwordFormik.values)
      }
      await api.finishOnboarding()
      setUser({ ...newUser, onboardAt: Date.now().toString() })
    } catch (error) {
      // @ts-ignore
      const { data } = error.response
      if (data.code === '1004') {
        snackActions.open('Wrong password.')
        return
      }
      snackActions.open('Something was wrong! please try again.')
    } finally {
      setIsSubmitting(false)
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }, [activeStep, setUser, infoFormik])

  return (
    <>
      <Box sx={{ width: '100%', paddingY: 5, paddingX: 2 }}>
        <Stepper sx={{ display: { xs: 'none', md: 'flex' } }} activeStep={activeStep} alternativeLabel>
          {steps.map(item => (
            <Step key={item.label}>
              <StepLabel error={item.error}>{item.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ display: { md: 'none' }, textAlign: 'center' }}>
          <Typography variant="h5">{steps[activeStep].label}</Typography>
        </Box>
      </Box>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ width: '100%', maxWidth: activeStep === 0 ? 1080 : '100%', paddingX: { xs: 3, md: 2 } }}>
          {activeStep === 0 && <Step1 infoFormik={infoFormik} passwordFormik={passwordFormik} />}
          {activeStep === 1 && <Subscription />}
        </Box>
      </Box>
      <>
        <MobileStepper
          sx={{ display: { xs: 'flex', md: 'none' }, width: '100%', position: 'fixed', bottom: 0, zIndex: 11 }}
          steps={steps.length}
          position="static"
          activeStep={activeStep}
          backButton={null}
          nextButton={
            <Button size="large" variant="contained" sx={{ fontSize: '1.2em' }} onClick={handleNext}>
              {activeStep === 1 ? 'Finish' : 'Next'}
            </Button>
          }
        />
        <Box
          sx={{
            display: { xs: 'none', md: 'flex' },
            width: '100%',
            position: 'fixed',
            bottom: 24,
            right: 24,
            zIndex: 11
          }}
        >
          <div style={{ flex: 1 }} />
          <Box sx={{ marginX: 1 }} />
          <LoadingButton
            size="large"
            variant="contained"
            sx={{ fontSize: '1.2em' }}
            onClick={handleNext}
            loading={isSubmitting}
            disabled={activeStep === 0 && !passwordFormik.isValid}
          >
            {activeStep === 1 ? 'Finish' : 'Next'}
          </LoadingButton>
        </Box>
      </>
    </>
  )
}

export default ContributorInvite
