import React, { useCallback, useContext, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import LinkIcon from '@mui/icons-material/Link'
import IconButton from '@mui/material/IconButton'
import { AssetTypes, Watcher, WatcherType } from '../../lib/types'
import api from '../../lib/api'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Button from '@mui/material/Button'
import { Drawer, Slider, TextField } from '@mui/material'
import { useFormik } from 'formik'
import { SimpleSnackbarContext } from '../SimpleSnackbar'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import LoadingButton from '@mui/lab/LoadingButton'

interface iTrimmer {
  id?: string
  assetType: AssetTypes
  handleRefresh: () => void
  onCloseClick: () => void
  type: WatcherType
  title: string
}

function valuetext(value: number) {
  return `${value} secs.`
}

const minDistance = 10

const Trimmer: React.FC<iTrimmer> = ({ title, id, onCloseClick, type, handleRefresh, assetType }) => {
  const { actions: snackActions } = useContext(SimpleSnackbarContext)
  const [watchers, setWatchers] = useState<Watcher[]>([])

  const minDistance = 10

  const [value2, setValue2] = React.useState<number[]>([5, 95])

  const handleChange2 = (event: Event, newValue: number | number[], activeThumb: number) => {
    if (!Array.isArray(newValue)) {
      return
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance)
        setValue2([clamped, clamped + minDistance])
      } else {
        const clamped = Math.max(newValue[1], minDistance)
        setValue2([clamped - minDistance, clamped])
      }
    } else {
      setValue2(newValue as number[])
    }
  }

  const onSubmit = async ({ email }: { email: string }) => {
    try {
      if (!id || !assetType) return
      await api.addWatcher({ email, id, type }, assetType)
      snackActions.open(`${email} added to watch this ${assetType}`)
    } catch (error) {
      snackActions.open(`Problem to add ${email} to watch this ${assetType}`)
    }
  }

  const removeWatcher = async (watcherId: string) => {
    const watcher = watchers.find(w => watcherId === w.id) as Watcher
    try {
      if (!assetType) return
      await api.removeWatcher(watcherId, assetType)
      snackActions.open(`${watcher.email} removed to watch this ${assetType}`)
    } catch (error) {
      snackActions.open(`Problem to remove ${watcher.email} from this ${assetType}`)
    }
  }

  const notifyWatcher = async (watcherId: string) => {
    const watcher = watchers.find(w => watcherId === w.id) as Watcher
    try {
      if (!assetType) return
      await api.notifyWatcher(watcherId, assetType)
      snackActions.open(`${watcher.email} notified to watch this ${assetType}`)
    } catch (error) {
      snackActions.open(`Problem to notify ${watcher.email}`)
    }
  }

  return (
    <Drawer anchor="bottom" open>
      <Box
        component={Paper}
        sx={{
          width: '100%',
          height: '100%',
          minHeight: 200
        }}
      >
        <Box
          sx={{
            padding: 3,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Typography
            variant="h5"
            fontWeight="700"
            sx={{
              flex: 1
            }}
          >
            {title}
          </Typography>
          <IconButton onClick={onCloseClick}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <Slider
          getAriaLabel={() => 'Minimum distance shift'}
          value={value2}
          onChange={handleChange2}
          valueLabelDisplay="auto"
          getAriaValueText={valuetext}
          disableSwap
        />
      </Box>
    </Drawer>
  )
}

export default Trimmer
