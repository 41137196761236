import React, { useContext } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { Form, Formik } from 'formik'
import schema from './schema'
import api from '../../lib/api'
import { SimpleDialogContext } from '../SimpleDialog'
import { SimpleSnackbarContext } from '../SimpleSnackbar'

interface RecordLaterFormType {
  email: string
}

const RecordLaterDialogContent: React.FC<{ inviteId: string; token: string }> = ({ inviteId, token }) => {
  const { actions: simpleDialogActions } = useContext(SimpleDialogContext)
  const { actions: snackActions } = useContext(SimpleSnackbarContext)

  const initialValues: RecordLaterFormType = {
    email: ''
  }
  const handleSubmit = async ({ email }: RecordLaterFormType) => {
    try {
      const data = { email: email }
      await api.recordLater(inviteId, data, token).then()
      simpleDialogActions.close()
      snackActions.open("Thank you! We'll send the link to your email address. It should arrive momentarily.")
    } catch (error) {
      snackActions.open('Something was wrong! please try again.')
      console.log(error)
    }
  }

  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      {({ isSubmitting, handleBlur, values, handleChange, errors, touched }) => (
        <Form>
          <Box sx={{ display: 'grid', gap: '32px' }}>
            <Typography>Please send me a link so I can record and submit my story later.</Typography>
            <Box sx={{ display: 'grid', gap: '16px' }}>
              <TextField
                name="email"
                fullWidth
                placeholder="Email address"
                label="Email address"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && Boolean(errors.email)}
                helperText={(touched.email && errors.email) || ' '}
              />
              <Button color={'primary'} variant="contained" fullWidth type="submit">
                Submit
              </Button>
            </Box>
            <Typography>
              Please note we will only send you emails and reminders specifically related to this story request (no
              spam).
            </Typography>
          </Box>
        </Form>
      )}
    </Formik>
  )
}
export default RecordLaterDialogContent
