import React, { useCallback, useContext, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import LinkIcon from '@mui/icons-material/Link'
import DesignServicesIcon from '@mui/icons-material/DesignServices'
import VisibilityIcon from '@mui/icons-material/Visibility'
import IconButton from '@mui/material/IconButton'
import { AssetTypes, Watcher, WatcherType } from '../../lib/types'
import api from '../../lib/api'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { Drawer, TextField } from '@mui/material'
import { useFormik } from 'formik'
import { SimpleSnackbarContext } from '../SimpleSnackbar'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import LoadingButton from '@mui/lab/LoadingButton'
import WatcherActionMenu from './WatcherActionMenu'
import schema from './schema'
import useShareInvite from '../hooks/useShareInvite'
import WarningIcon from '@mui/icons-material/Warning'

interface iShare {
  id?: string
  published: boolean
  assetType: AssetTypes
  handleRefresh: () => void
  onCloseClick: () => void
  type: WatcherType
  title: string
  requireEmailToViewCollection?: boolean
}

const Share: React.FC<iShare> = ({
  title,
  id,
  onCloseClick,
  type,
  published,
  handleRefresh,
  assetType,
  requireEmailToViewCollection
}) => {
  const { actions: snackActions } = useContext(SimpleSnackbarContext)
  const [watchers, setWatchers] = useState<Watcher[]>([])
  const [isPublished, setIsPublished] = useState(published)
  const [isRequired, setIsRequired] = useState(requireEmailToViewCollection)
  const [isEditor, setIsEditor] = useState(false)
  const handleShare = useShareInvite()
  const [publishedStoriesCount, setPublishedStoriesCount] = useState(1)
  const [isGettingSharedLink, setIsGettingSharedLink] = useState(false)

  useEffect(() => {
    const getPublishedStoriesCount = async () => {
      if (!id) return
      let publishedStoriesCountResponse
      try {
        publishedStoriesCountResponse = await api.countStoriesPublished(id)
      } catch (e) {
        console.log(e)
      }
      setPublishedStoriesCount(publishedStoriesCountResponse?.count ?? 0)
    }
    if (assetType === AssetTypes.collection) {
      getPublishedStoriesCount()
    }
  }, [id])

  const fetchWatchers = async () => {
    if (!id || !assetType) return
    const watchers = await api.getWatchers(id, assetType)
    setWatchers(watchers.content)
  }

  useEffect(() => {
    fetchWatchers()
  }, [id, assetType])

  const onSubmit = async ({ email }: { email: string }) => {
    try {
      if (!id || !assetType) return
      const watcherType: WatcherType = assetType === AssetTypes.story ? 'EDITOR' : isEditor ? 'EDITOR' : 'VIEWER'
      await api.addWatcher({ email, id, type: watcherType }, assetType)
      snackActions.open(`${email} added to watch this ${assetType}`)
      fetchWatchers()
    } catch (error) {
      snackActions.open(`Problem to add ${email} to watch this ${assetType}`)
    }
  }

  const { isSubmitting, values, handleBlur, handleChange, touched, errors, handleSubmit } = useFormik({
    initialValues: { email: '' },
    validationSchema: schema,
    onSubmit
  })

  const handlePublished = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      try {
        if (!id || !assetType) return
        setIsPublished(!isPublished)
        await api.publish(id, event.target.checked, assetType)
        await handleRefresh()
      } catch (error) {
        setIsPublished(!isPublished)
        snackActions.open(`Problem to make this ${assetType} ${event.target.checked ? 'private' : 'public'}`)
      }
    },
    [assetType]
  )

  const handleRequireEmailToViewCollection = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      try {
        if (!id || !assetType) return
        setIsRequired(!isRequired)
        await api.changeRequiredEmailFlag(id, assetType)
        await handleRefresh()
      } catch (error) {
        setIsRequired(!isRequired)
        snackActions.open(
          `Problem to make this ${assetType} ${event.target.checked ? 'do not require email' : 'require email'}`
        )
      }
    },
    [assetType]
  )

  const removeWatcher = async (watcherId: string) => {
    const watcher = watchers.find(w => watcherId === w.id) as Watcher
    try {
      if (!assetType) return
      await api.removeWatcher(watcherId, assetType)
      fetchWatchers()
      snackActions.open(`${watcher.email} removed to watch this ${assetType}`)
    } catch (error) {
      snackActions.open(`Problem to remove ${watcher.email} from this ${assetType}`)
    }
  }

  const notifyWatcher = async (watcherId: string) => {
    const watcher = watchers.find(w => watcherId === w.id) as Watcher
    try {
      if (!assetType) return
      await api.notifyWatcher(watcherId, assetType)
      snackActions.open(`${watcher.email} notified to watch this ${assetType}`)
    } catch (error) {
      snackActions.open(`Problem to notify ${watcher.email}`)
    }
  }

  const handleShareCollection = async () => {
    if (id) {
      setIsGettingSharedLink(true)
      try {
        const data = await api.getSharedLink(id)
        handleShare(undefined, true, data.sharedLink)
      } catch (e) {
        console.log(e)
      } finally {
        setIsGettingSharedLink(false)
      }
    }
  }

  return (
    <Drawer anchor="right" open>
      <Box
        component={Paper}
        sx={{
          width: '472px',
          height: '100%'
        }}
      >
        <Box
          sx={{
            padding: 3,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Typography
            variant="h5"
            fontWeight="700"
            sx={{
              flex: 1
            }}
          >
            {title}
          </Typography>
          <IconButton onClick={onCloseClick}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <Box
          sx={{
            padding: 3
          }}
        >
          <LoadingButton
            loading={isGettingSharedLink}
            color="secondary"
            startIcon={<LinkIcon />}
            onClick={() =>
              assetType === AssetTypes.story
                ? handleShare(undefined, true, `${window.location.origin}/public/stories/${id}`)
                : handleShareCollection()
            }
          >
            Click to share
          </LoadingButton>
          {assetType === AssetTypes.collection && publishedStoriesCount === 0 && (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '6px' }}>
              <WarningIcon color={'primary'} />
              <Typography>This collection currently has no published stories</Typography>
            </Box>
          )}
        </Box>
        {type === 'VIEWER' && (
          <Box
            sx={{
              backgroundColor: 'grey.400',
              padding: 3,
              color: 'common.black'
            }}
          >
            <Typography>Choose who will be able to view your {assetType}</Typography>
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={isPublished} onChange={handlePublished} />}
                label={isPublished ? 'Anyone can view this collection' : 'Private'}
              />
            </FormGroup>
            {isPublished && (
              <>
                <Typography>Require email address to view collection?</Typography>
                <FormGroup>
                  <FormControlLabel
                    control={<Switch checked={isRequired} onChange={handleRequireEmailToViewCollection} />}
                    label={isRequired ? 'Require e-mail' : `Don't require e-mail`}
                  />
                </FormGroup>
              </>
            )}
          </Box>
        )}
        <Box
          sx={{
            padding: '24px 24px 0px',
            display: 'flex',
            flexFlow: 'column',
            flex: '1'
          }}
        >
          <Typography gutterBottom>
            Invite people to {type === 'VIEWER' ? 'see' : 'edit'} your {assetType}
          </Typography>
          {assetType === AssetTypes.collection && (
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={isEditor} onChange={() => setIsEditor(!isEditor)} />}
                label={isEditor ? 'Editor' : 'Viewer'}
              />
            </FormGroup>
          )}
          <form>
            <TextField
              margin="normal"
              name="email"
              fullWidth
              placeholder="Email address"
              label="Email address"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.email && Boolean(errors.email)}
              helperText={(touched.email && errors.email) || ' '}
            />
            <LoadingButton
              loading={isSubmitting}
              onClick={() => {
                handleSubmit()
              }}
              fullWidth
              variant="contained"
            >
              Invite
            </LoadingButton>
          </form>
          <Box>
            <List>
              {watchers.map(watcher => (
                <ListItem
                  key={watcher.id}
                  secondaryAction={
                    <WatcherActionMenu id={watcher.id} deleteWatcher={removeWatcher} notifyWatcher={notifyWatcher} />
                  }
                >
                  {watcher.type === 'EDITOR' && <DesignServicesIcon />}
                  {watcher.type === 'VIEWER' && <VisibilityIcon />}
                  <ListItemText sx={{ marginLeft: '6px' }} primary={watcher.email} />
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
      </Box>
    </Drawer>
  )
}

export default Share
