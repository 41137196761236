import React from 'react'
import { Router } from 'react-router-dom'
import Routes from './pages/Routes'
import { createBrowserHistory } from 'history'
import { ThemeProvider } from '@mui/material/styles'
import { SimpleSnackbarProvider } from './components/SimpleSnackbar'
import { SimpleDialogProvider } from './components/SimpleDialog'
import { FormDialogProvider } from './components/FormDialog'
import { SignupDialogProvider } from './components/SignupDialog'
import { PublicRequestsDialogProvider } from './components/PublicRequestsDialog'
import CssBaseline from '@mui/material/CssBaseline'
import theme from './lib/theme'
import { MittProvider } from 'react-mitt'
import { useKonamiCode } from '@bitmap/use-konami-code'
import Cookies from 'js-cookie'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { CollectionThemeProvider } from './components/CollectionTheme'
import { ChangeReferralCodeDialogProvider } from './components/ChangeReferralCodeDialog'
import { VideoEditDialogProvider } from './components/VideoEditCodeDialog'
import { VideoBookmarkDialogProvider } from './components/VideoBookmarkDialog'
import { FileStorageProvider } from './components/FileStorage/FileStorage'
import { CacheStorageProvider } from './components/FileStorage/CacheStorage'

export const history = createBrowserHistory()

const App: React.FC = () => {
  useKonamiCode(() => {
    const token = window.prompt('token?')
    if (!token) return
    Cookies.set('token', token)
    window.location.href = '/'
  }, 'iddqd'.split(''))

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_APP_ID || ''}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MittProvider>
          <CollectionThemeProvider>
            <SimpleSnackbarProvider>
              <SimpleDialogProvider>
                <FormDialogProvider>
                  <SignupDialogProvider>
                    <ChangeReferralCodeDialogProvider>
                      <PublicRequestsDialogProvider>
                        <CacheStorageProvider>
                          <FileStorageProvider>
                            <VideoEditDialogProvider>
                              <VideoBookmarkDialogProvider>
                                <Router history={history}>
                                  <Routes />
                                </Router>
                              </VideoBookmarkDialogProvider>
                            </VideoEditDialogProvider>
                          </FileStorageProvider>
                        </CacheStorageProvider>
                      </PublicRequestsDialogProvider>
                    </ChangeReferralCodeDialogProvider>
                  </SignupDialogProvider>
                </FormDialogProvider>
              </SimpleDialogProvider>
            </SimpleSnackbarProvider>
          </CollectionThemeProvider>
        </MittProvider>
      </ThemeProvider>
    </GoogleOAuthProvider>
  )
}

export default App
