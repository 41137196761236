import React, { createContext, useState } from 'react'
import Component from './Component'

// @ts-ignore
export const VideoEditDialogContext = createContext<{
  actions: {
    open: (url?: string, storyId?: string, userId?: string) => void
  }
  store: {
    url: string
    storyId: string
    userId: string
  }
}>({
  // @ts-ignore
  actions: {}
})

export const VideoEditDialogProvider: React.FC = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false)
  const [videoUrl, setVideoUrl] = useState('')
  const [videoStoryId, setVideoStoryId] = useState('')
  const [videoUserId, setVideoUserId] = useState('')

  const handleOpen = (url?: string, storyId?: string, userId?: string) => {
    setOpen(true)
    url && setVideoUrl(url)
    storyId && setVideoStoryId(storyId)
    userId && setVideoUserId(userId)
  }

  return (
    <VideoEditDialogContext.Provider
      value={{
        actions: {
          open: handleOpen
        },
        store: {
          url: videoUrl,
          storyId: videoStoryId,
          userId: videoUserId
        }
      }}
    >
      {open && <Component handleClose={() => setOpen(false)} />}
      {children}
    </VideoEditDialogContext.Provider>
  )
}
