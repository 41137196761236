import axios from 'axios'
import Cookies from 'js-cookie'
import { history } from '../../App'

const request = axios.create({
  baseURL: '/api'
})

request.interceptors.request.use(function (config) {
  const token = Cookies.get('token')
  if (config!.url!.startsWith('g/')) return config
  config.headers.Authorization = `Bearer ${token}`
  return config
})

request.interceptors.response.use(
  response => {
    return response
  },
  e => {
    if (e?.response?.status === 401) {
      if (e.response.config.url === 'a/auth/team-member') return Promise.reject(e)
      Cookies.remove('token')
      Cookies.remove('backupToken')
      Cookies.remove('alreadySelectedLibrary')
      Cookies.remove('user')
      Cookies.remove('tenant')
      if (history.location.pathname === '/u/signin') return Promise.reject(e)
      history.push('/u/signin')
      window.location.reload()
      return Promise.reject(e)
    }

    if (e?.response?.status === 404) {
      history.push('/404')
      window.location.reload()
      return Promise.reject(e)
    }

    // if (
    //   e?.response?.status === 403 &&
    //   (e.response.config.url.startsWith('a/stories/') || e.response.config.url.startsWith('a/collections/'))
    // ) {
    //   history.push('/403')
    //   window.location.reload()
    //   return Promise.reject(e)
    // }

    return Promise.reject(e)
  }
)

export default request
