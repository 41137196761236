import React, { useEffect, useRef, useState } from 'react'
import { InviteType } from '../../../../../lib/types'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import { useField, useFormikContext } from 'formik'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Switch from '@mui/material/Switch'
import UploadSpeedTest from '../../../../../components/UploadSpeedTest/UploadSpeedTest'

const Step3: React.FC<{ invite: InviteType; progress: number; file: File | null; token: string }> = ({
  invite,
  progress,
  file,
  token
}) => {
  const [{ onChange: nameOnChange, onBlur: nameOnBlur, value: nameValue }, { error: nameError, touched: nameTouched }] =
    useField('name')
  const [
    { onChange: emailOnChange, onBlur: emailOnBlur, value: emailValue },
    { error: emailError, touched: emailTouched }
  ] = useField('email')
  const { setFieldValue } = useFormikContext()

  const [rememberme, setRememberme] = useState<boolean>(false)
  const emailRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    // Load values from localStorage when the component mounts
    const storedName = localStorage.getItem('app.rakonto.invite.name')
    const storedEmail = localStorage.getItem('app.rakonto.invite.email')
    const storedRememberme = localStorage.getItem('app.rakonto.invite.rememberme')
    if (storedName) {
      setFieldValue('name', storedName)
    }
    if (storedEmail) {
      setFieldValue('email', storedEmail)
    }
    if (storedRememberme) {
      setRememberme(storedRememberme === 'true')
    }
  }, [setFieldValue, nameOnBlur, emailOnBlur])

  useEffect(() => {
    // Save values to localStorage only if rememberme is true
    if (rememberme) {
      localStorage.setItem('app.rakonto.invite.name', nameValue)
      localStorage.setItem('app.rakonto.invite.email', emailValue)
      localStorage.setItem('app.rakonto.invite.rememberme', rememberme.toString())
    } else {
      localStorage.removeItem('app.rakonto.invite.name')
      localStorage.removeItem('app.rakonto.invite.email')
      localStorage.removeItem('app.rakonto.invite.rememberme')
    }
  }, [nameValue, emailValue, rememberme])

  const name = invite.organization?.name || `${invite.user.firstName} ${invite.user.lastName}`

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" mb={2}>
          {`Your recording is saved! To submit your recording, please enter your name and email address,
           then press Submit.
          By doing so you authorize ${name} to use your recording and any images or files you upload to
          ${name} Rakonto, and to contact you in accordance with our `}
          <Link href="https://rakonto.io/terms-and-conditions" target="_blank">
            terms
          </Link>{' '}
          and{' '}
          <Link href=" https://rakonto.io/privacy-policy" target="_blank">
            {' '}
            privacy policy
          </Link>
          .
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="Name"
          name="name"
          type="text"
          value={nameValue}
          onChange={nameOnChange}
          onBlur={nameOnBlur}
          error={nameTouched && Boolean(nameError)}
          helperText={(nameTouched && nameError) || ' '}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="Email"
          name="email"
          type="text"
          value={emailValue}
          onChange={emailOnChange}
          onBlur={emailOnBlur}
          error={emailTouched && Boolean(emailError)}
          helperText={(emailTouched && emailError) || ' '}
          fullWidth
          inputRef={emailRef}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">Remember Me</Typography>
        <Switch checked={rememberme} onChange={e => setRememberme(e.target.checked)} />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%'
        }}
      >
        <Box>
          <UploadSpeedTest fileSizeMB={4} token={token} file={file} />
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 5, flexFlow: 'column' }}
      >
        {!!progress && (
          <>
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
              <CircularProgress size={100} variant="determinate" value={progress || 100} />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Typography variant="h6" component="div" color="text.secondary">
                  {`${Math.round(progress || 100)}%`}
                </Typography>
              </Box>
            </Box>
            <Typography mt={2} variant="h6" component="div" color="text.secondary">
              uploading the story...
            </Typography>
          </>
        )}
      </Grid>
    </Grid>
  )
}

export default Step3
