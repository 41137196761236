import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import {
  QuestionnaireReviewer,
  QuestionnaireType,
  QuizInviteType,
  TeamMemberRoleEnum,
  TeamMemberType
} from '../../../lib/types'
import useInfiniteScroll from '../../../components/hooks/useInfiniteScrool'
import { usePageableRequest } from '../../../components/hooks/usePageableRequest'
import QuizItem from '../../../components/QuizItem'
import Typography from '@mui/material/Typography'
import api from '../../../lib/api'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import Button from '@mui/material/Button'
import { CreateQuestionnaireContext } from '../../../components/CreateQuestionnaire'
import { SimpleDialogContext } from '../../../components/SimpleDialog'
import { SimpleSnackbarContext } from '../../../components/SimpleSnackbar'
import axios from 'axios'
import Divider from '@mui/material/Divider'
import useShareInvite from '../../../components/hooks/useShareInvite'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import { SimpleMultiSelectDialogContext } from '../../../components/SimpleMultiSelectDialog'
import { UserContext } from '../../../components/UserProvider'
import CheckIcon from '@mui/icons-material/Check'
import DoDisturbIcon from '@mui/icons-material/DoDisturb'
import MenuItem from '@mui/material/MenuItem'
import { FormDialogContext } from '../../../components/FormDialog'
import { capitalize } from '../../../components/Capitalize'
import { usePermissions } from '../../../components/hooks/usePermissions'
import AccessTimeIcon from '@mui/icons-material/AccessTime'

const QuestionnaireComponent: React.FC = () => {
  const handleShare = useShareInvite()
  const { actions: handleInviteesStats } = useContext(CreateQuestionnaireContext)
  const { actions: createQuestionnaireDialogActions } = useContext(CreateQuestionnaireContext)
  const { actions: simpleDialogActions } = useContext(SimpleDialogContext)
  const { actions: simpleMultiSelectDialogActions } = useContext(SimpleMultiSelectDialogContext)
  const { actions: snackActions } = useContext(SimpleSnackbarContext)
  const { actions: formDialogActions } = useContext(FormDialogContext)
  const history = useHistory()
  const [searchValue, setSearchValue] = useState<string>('')
  const { user, member } = useContext(UserContext)
  const [teamMembers, setTeamMembers] = useState<TeamMemberType[]>([])
  const { isCreator } = usePermissions()

  const filterAndTransformMembersByQuestionnaire = (questionnaireReviewers: any[]) => {
    return teamMembers
      .filter(teamMember => teamMember.role !== TeamMemberRoleEnum.VIEWER && teamMember.id !== member?.id)
      .map(teamMember => ({
        id: teamMember.id,
        name: teamMember.name,
        email: teamMember.email,
        alreadyInvited: questionnaireReviewers.map(r => r.reviewerId).includes(teamMember.id)
      }))
  }

  const { loading, items, hasNextPage, error, loadMore, setItems, reload } = usePageableRequest<QuizInviteType>({
    size: 15,
    q: '',
    request: api.getQuestionnaries
  })

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: !!error,
    rootMargin: '0px 0px 400px 0px'
  })

  const handleDelete = async (id: string) => {
    simpleDialogActions.open(
      'Delete questionnaire',
      'Are you sure do you want delete this questionnaire?',
      { okText: 'Delete', cancelText: 'Cancel', showOk: true },
      async success => {
        if (success) {
          try {
            await api.deleteQuestionnarie(id)
            setItems(items.filter(item => item.id !== id))
            snackActions.open('Questionnaire deleted successfully!')
          } catch (error) {
            if (axios.isAxiosError(error)) {
              // @ts-ignore
              const { code, message } = error.response.data
              if (code) {
                snackActions.open(message)
                return
              }
            }
            snackActions.open('Internal server error.')
          }
        }
      }
    )
  }

  const handleDownloadStats = async (questionnaire: QuestionnaireType) => {
    await api.downloadQuestionnaireStats(questionnaire.id)
  }

  const handleDownloadCompleteReport = async (questionnaire: QuestionnaireType) => {
    try {
      await api.downloadQuestionnaireCompleteReport(questionnaire.id)
      snackActions.open('Your download should start soon!')
    } catch {
      snackActions.open('Ops! Something went wrong trying to download the report, please try again later.')
    }
  }

  const handleStats = async (questionnaire: QuestionnaireType) => {
    const content = ''
    await api.getQuestionnaireStats(questionnaire.id).then(response => {
      simpleDialogActions.open(
        'Statistics for: ' + questionnaire.title,
        <>
          <Box>
            <Typography fontWeight="700" fontSize={'1em'}>
              Invitations sent: {response.invites.length}
            </Typography>
            <Typography fontWeight="700" fontSize={'1em'}>
              Interviews not yet started: {response.notStarted}
            </Typography>
            <Typography fontWeight="700" fontSize={'1em'}>
              Interviews in process: {response.inProcess}
            </Typography>
            <Typography fontWeight="700" fontSize={'1em'}>
              Interviews completed: {response.completed}
            </Typography>
            <Typography fontWeight="700" fontSize={'1em'}>
              Skipped questions: {response.skipped}
            </Typography>
            <Typography fontWeight="700" fontSize={'1em'}>
              Completion percentage: {response.percentage}%
            </Typography>
          </Box>
          <br />
          <Box>
            <Button
              color="primary"
              onClick={() => {
                handleDownloadStats(questionnaire)
              }}
            >
              Download Statistics (CSV)
            </Button>
            <Button
              color="primary"
              onClick={() => {
                handleDownloadCompleteReport(questionnaire)
              }}
            >
              Download Submission Details
            </Button>
          </Box>
        </>,
        {
          cancelText: 'Close',
          showOk: false
        }
      )
    })
  }

  const handleOpenReviews = (reviewers: QuestionnaireReviewer[]) => {
    const content: ReactNode = (
      <Box sx={{ padding: '12px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
        {reviewers.map((reviewer, idx) => {
          return (
            <>
              <Box key={idx} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography>{reviewer.reviewerName}</Typography>
                {reviewer.isReviewed ? (
                  reviewer.isApproved ? (
                    <CheckIcon color={'primary'} />
                  ) : (
                    <DoDisturbIcon sx={{ color: 'red' }} />
                  )
                ) : (
                  <AccessTimeIcon />
                )}
              </Box>
              {idx !== reviewers.length - 1 && <Box sx={{ height: '1px', backgroundColor: 'gray' }} />}
            </>
          )
        })}
      </Box>
    )
    simpleDialogActions.open('Reviewers', content, { cancelText: 'Close' })
  }
  const handleSeeReviews = async (quiz: QuizInviteType) => {
    if (!quiz.reviewers || quiz.reviewers.length === 0) {
      snackActions.open('Oops! It seems there are no team members reviewing this questionnaire.')
      return
    }
    handleOpenReviews(quiz.reviewers)
  }

  const options = [{ decision: 'approve' }, { decision: 'disapprove' }]
  const handleReview = (quetionnaireId: string) => {
    formDialogActions.open(
      'Review Questionnaire',
      '',
      [
        {
          label: 'Decision',
          name: 'decision',
          placeholder: 'Decision',
          type: 'select',
          options: options.map(opt => (
            <MenuItem key={opt.decision} value={opt.decision}>
              {capitalize(opt.decision)}
            </MenuItem>
          ))
        },
        {
          label: 'Reason',
          name: 'reason',
          placeholder: 'Reason',
          rows: 4,
          type: 'text'
        }
      ],
      { title: '', description: '', language: null },
      null,
      async (data: { decision: string; reason: string }) => {
        if (!data.decision) {
          snackActions.open('Please, select the decision as approve or disapprove.')
          return
        }
        const decision = data.decision === 'approve'
        try {
          await api.doReview({ questionnaireId: quetionnaireId, approved: decision, reason: data.reason })
          snackActions.open('Questionnaire reviewed successfully!')
          reload()
        } catch (error) {
          if (axios.isAxiosError(error)) {
            // @ts-ignore
            const { code, message } = error.response.data
            if (code) {
              snackActions.open(message)
              return
            }
          }
          snackActions.open('Internal server error.')
        }
      },
      { okText: 'Submit', cancelText: `Cancel` }
    )
  }

  const handleInviteReviewer = (quiz: QuizInviteType) => {
    const filteredAndTransformedMembers = filterAndTransformMembersByQuestionnaire(quiz.reviewers)
    if (!filteredAndTransformedMembers || filteredAndTransformedMembers.length === 0) {
      snackActions.open('Oops! It seems there are no team members you can invite right now.')
      return
    }
    simpleMultiSelectDialogActions.open(
      async (ids: string[] | null) => {
        if (!ids || ids.length === 0) {
          return
        }
        const tenantId = member?.tenant.id ? member?.tenant.id : user.tenant ? user.id : undefined
        const memberId = member?.id ?? undefined

        if (!tenantId) {
          return
        }

        try {
          await api.inviteQuestionnaireReviewers({
            questionnaireId: quiz.id,
            questionnaireReviewerIds: ids,
            tenantId: tenantId,
            memberId: memberId
          })
          reload()
          snackActions.open(`Team member${ids.length > 1 ? 's' : ''} invited successfully!`)
        } catch {
          snackActions.open('Ops! Something went wrong inviting your team members, please try again.')
        }
      },
      'Invite Reviewers',
      `Below is a list of your available team members whom you can invite.`,
      filteredAndTransformedMembers,
      'Invite'
    )
  }

  const handlePublished = async (event: React.ChangeEvent<HTMLInputElement>, quiz: QuizInviteType) => {
    try {
      const newPublishedValue = event.target.checked
      await api.updateQuestionnaire({ ...quiz, published: newPublishedValue, questionnaireId: quiz.id })
      const updatedItems = items.map(item => (item.id === quiz.id ? { ...item, published: newPublishedValue } : item))

      setItems(updatedItems)
    } catch (error) {
      // @ts-ignore
      const { data } = error.response

      if (data.code) {
        snackActions.open(data.message)
        return
      }
      snackActions.open(`Problem to make this ${event.target.checked ? 'draft' : 'published'}`)
    }
  }

  const handleTogglePublic = async (event: React.ChangeEvent<HTMLInputElement>, quiz: QuizInviteType) => {
    try {
      const newPublicValue = event.target.checked
      await api.updateQuestionnaire({ ...quiz, privateQuestionnaire: newPublicValue, questionnaireId: quiz.id })
      const updatedItems = items.map(item =>
        item.id === quiz.id
          ? {
              ...item,
              privateQuestionnaire: newPublicValue
            }
          : item
      )

      setItems(updatedItems)
    } catch (error) {
      // @ts-ignore
      const { data } = error.response

      if (data.code) {
        snackActions.open(data.message)
        return
      }
      snackActions.open(`Problem to make this ${event.target.checked ? 'private' : 'public'}`)
    }
  }

  const createNewQuestionnaire = () => {
    createQuestionnaireDialogActions.open(questionnaire => {
      if (!questionnaire) return
      if (Array.isArray(questionnaire)) {
        setItems([...questionnaire, ...items])
      } else {
        setItems([questionnaire, ...items])
      }
    })
  }

  useEffect(() => {
    const getTeamMembers = async () => {
      try {
        const { content } = await api.getTeamMembers()
        setTeamMembers(content)
      } catch (e) {
        console.log(e)
      }
    }
    getTeamMembers()
  }, [])

  useEffect(() => {
    if (member?.role && member.role === TeamMemberRoleEnum.VIEWER) {
      history.push('a/my-library')
    }
  }, [member])

  const uniqueFilteredItems = items.filter((item, index, self) => {
    return (
      self.findIndex(obj => obj.id === item.id) === index &&
      (member?.role !== TeamMemberRoleEnum.EDITOR ||
        (member?.role === TeamMemberRoleEnum.EDITOR &&
          member.permissions.map(permission => permission.questionnaireId).includes(item.id))) &&
      ((item.title && item.title.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
        (item.description && item.description.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())))
    )
  })

  return (
    <Box component="form" sx={{ width: '100%', padding: 2 }}>
      <Box sx={{ paddingY: 2 }}>
        <Typography variant="h6">Questionnaire</Typography>
      </Box>
      <Divider sx={{ marginY: 2 }} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '8px',
              flexDirection: { xs: 'column', sm: 'row' }
            }}
          >
            <TextField
              key="search"
              name="search"
              sx={{
                minWidth: { xs: '300px', md: '422px' }
              }}
              rows={4}
              autoComplete="off"
              placeholder="Type questionnaire title or description to filter"
              margin="dense"
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              onKeyPress={e => e.key === 'Enter' && e.preventDefault()}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
            <Button variant="outlined" sx={{ height: '56px' }} onClick={createNewQuestionnaire} disabled={!isCreator}>
              New questionnaire
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            {uniqueFilteredItems.map(questionnaire => {
              const isShareEnable = questionnaire.questions.reduce((acc, item, i, array) => {
                if (item.number < array.length) {
                  acc = false
                }
                return acc
              }, true)
              return (
                <QuizItem key={questionnaire.id} quiz={questionnaire}>
                  {isCreator && (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={questionnaire.published}
                          onChange={event => handlePublished(event, questionnaire)}
                          value={questionnaire.published}
                        />
                      }
                      label={
                        questionnaire.published ? (
                          <Typography sx={{ color: '#BDBDBD', fontSize: '14px' }}>Published</Typography>
                        ) : (
                          <Typography sx={{ color: '#BDBDBD', fontSize: '14px' }}>Draft</Typography>
                        )
                      }
                    />
                  )}
                  {isCreator && (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={questionnaire.privateQuestionnaire}
                          onChange={event => handleTogglePublic(event, questionnaire)}
                          value={questionnaire.privateQuestionnaire}
                        />
                      }
                      label={
                        questionnaire.privateQuestionnaire ? (
                          <Typography sx={{ color: '#BDBDBD', fontSize: '14px' }}>Private</Typography>
                        ) : (
                          <Typography sx={{ color: '#BDBDBD', fontSize: '14px' }}>Public</Typography>
                        )
                      }
                    />
                  )}
                  {questionnaire.published && (
                    <Button
                      color="secondary"
                      // disabled={!isShareEnable}
                      onClick={() => {
                        const invite = questionnaire.questions[0]?.collectionInvite
                        const questionnaireId = questionnaire.id
                        if (invite) handleShare(invite, undefined, undefined, undefined, true, true, questionnaireId)
                      }}
                    >
                      Share
                    </Button>
                  )}
                  {questionnaire.published && (
                    <Button
                      color="secondary"
                      onClick={() => {
                        const stats = handleStats(questionnaire)
                      }}
                    >
                      Statistics
                    </Button>
                  )}
                  {isCreator && (user?.userOfTenant || user?.tenant) && (
                    <Button color="secondary" onClick={() => handleInviteReviewer(questionnaire)}>
                      Invite Reviewers
                    </Button>
                  )}
                  {isCreator && (user?.userOfTenant || user?.tenant) && (
                    <Button color="secondary" onClick={() => handleSeeReviews(questionnaire)}>
                      Reviews
                    </Button>
                  )}
                  <Button
                    color="secondary"
                    onClick={() => {
                      history.push(`/a/questionnaire/${questionnaire.id}`)
                    }}
                  >
                    Configure
                  </Button>

                  {isCreator && (
                    <Button color="error" onClick={() => handleDelete(questionnaire.id)}>
                      Delete
                    </Button>
                  )}
                </QuizItem>
              )
            })}
            {hasNextPage && (
              <Grid item xs>
                <div ref={sentryRef} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default QuestionnaireComponent
