import React from 'react'
import Box from '@mui/material/Box'
import Card from '../Card'
import { useHistory } from 'react-router-dom'
import { AssetTypes, SearchResultType, UserType } from '../../lib/types'

const StoryCard: React.FC<{ data: SearchResultType }> = ({ data }) => {
  const history = useHistory()

  return (
    <Box
      key={data.id}
      onClick={e => {
        if ((e.target as HTMLElement).id === 'shareButton') return
        history.push(`/a/stories/${data.id}`)
      }}
      sx={{
        cursor: 'pointer',
        marginBottom: 2
      }}
    >
      <Card
        preview={data.storyType === 'VIDEO' ? data.storyGif : ''}
        shareLink={data.publishedAt ? `${window.location.origin}/public/stories/${data.id}` : ''}
        loading={false}
        type={data.storyType}
        title={`${data.title}`}
        subTitle={data.storyCollectionTitles![0]}
        owner={
          {
            firstName: data.ownerFirstName,
            lastName: data.ownerLastname,
            picture: {
              url: data.ownerPicture
            }
          } as UserType
        }
        thumbnail={data.picture!}
        assetType={AssetTypes.story}
      />
    </Box>
  )
}

export default StoryCard
