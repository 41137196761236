import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import api from '../../lib/api'
import { Link } from '@mui/material'

interface UploadSpeedTestProps {
  fileSizeMB: number
  token: string
  file: File | null
}

const UploadSpeedTest: React.FC<UploadSpeedTestProps> = ({ fileSizeMB, token, file }) => {
  const [uploadSpeed, setUploadSpeed] = useState<number | null>(null)
  const [estimatedTime, setEstimatedTime] = useState<number | null>(null)
  const [fileSize, setFileSize] = useState<number | null>(null)
  const [errorUpload, setErrorUpload] = useState<string>('')

  const generateRandomString = (sizeInMB: number): string => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const stringLength = sizeInMB * 1024 * 1024 // Convert MB to bytes
    let result = ''
    for (let i = 0; i < stringLength; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length))
    }
    return result
  }

  const testUploadSpeed = async () => {
    const randomString = generateRandomString(fileSizeMB)
    const blob = new Blob([randomString], { type: 'text/plain' })
    try {
      const startTime = performance.now()

      const response = await api.uploadSpeedTest(blob, token)

      if (!response || response.indexOf('Error:') >= 0) {
        throw new Error('Upload failed')
      }

      const endTime = performance.now()
      const duration = (endTime - startTime) / 1000 // Duration in seconds
      const uploadSpeed = fileSizeMB / duration // Upload speed in MB/s

      setUploadSpeed(uploadSpeed)

      const fileSizeInMB = file ? file.size / 1024 / 1024 : fileSizeMB
      const estimatedTimeInSeconds = fileSizeInMB / uploadSpeed // Estimated time in seconds
      const estimatedTimeInMinutes = estimatedTimeInSeconds / 60 // Convert to minutes

      setEstimatedTime(estimatedTimeInMinutes)
      setFileSize(fileSizeInMB)
    } catch (error) {
      console.error('Upload failed:', error)
      setErrorUpload('Impossible to check your connection speed.')
    }
  }

  const retryUpload = () => {
    console.log('Retrying upload...')
    testUploadSpeed()
  }

  useEffect(() => {
    testUploadSpeed()
  }, [])

  const renderEstimatedTime = () => {
    if (estimatedTime !== null) {
      const estimatedTimeInSeconds = estimatedTime * 60
      if (estimatedTimeInSeconds < 1) {
        return 'Your estimated upload time is less than one second.'
      } else if (estimatedTime < 1) {
        return `Your estimated upload time is ${estimatedTimeInSeconds.toFixed(2)} seconds.`
      } else {
        return `Your estimated upload time is ${estimatedTime.toFixed(2)} minutes.`
      }
    }
    return ''
  }

  return (
    <Box alignItems="center">
      {uploadSpeed !== null && estimatedTime !== null ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{ textAlign: 'center' }}
        >
          {fileSize && <Typography variant="body1">Your recording is {fileSize.toFixed(2)} MB.</Typography>}
          <Typography variant="body1">Your internet upload speed is {uploadSpeed.toFixed(2)} MB/s.</Typography>
          <Typography variant="body1">{renderEstimatedTime()}</Typography>
          {uploadSpeed < 0.6 && (
            <Box mb={10}>
              <Typography variant="body1" color="error">
                Your internet connection is poor, but no worries, your content is saved on your device and you can try
                later.
              </Typography>

              <Typography variant="body1">
                <Link href="#" onClick={retryUpload} style={{ marginLeft: '10px' }}>
                  Retry speed test
                </Link>
              </Typography>
            </Box>
          )}
        </Box>
      ) : errorUpload ? (
        <Typography variant="body2" color="error">
          {errorUpload}
          <Typography variant="body1">
            <Link href="#" onClick={retryUpload} style={{ marginLeft: '10px' }}>
              Retry speed test
            </Link>
          </Typography>
        </Typography>
      ) : (
        <Box display="flex" alignItems="center">
          <CircularProgress size={30} />
          <Typography variant="body1" style={{ marginLeft: 8 }}>
            Testing your connection speed...
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default UploadSpeedTest
