import React, { Suspense, useContext } from 'react'
import { BrowserRouter as Router, Redirect, Route, RouteProps, Switch, useLocation } from 'react-router-dom'
import Cookies from 'js-cookie'
import { GuestLayoutProvider } from './g/GuestLayout'
import AuthenticatedLayout from './a/AuthenticatedLayout'
import PublicLayout from './u/PublicLayout'
import CircularLoadingCentred from '../components/CircularLoadingCentred'
import FacebookDataDeletion from './u/FacebookDataDeletion'
import { LinkedInCallback } from 'react-linkedin-login-oauth2'
import { UserContext } from '../components/UserProvider'

import * as Sentry from '@sentry/react'
import Signin from './u/Signin'
import Public from './u/Public'
import Search from './a/Search'
import Signup from './u/Signup'
import ConfirmationEmail from './u/ConfirmationEmail'
import PasswordReset from './u/PasswordReset'
import ForgotPassword from './u/ForgotPassword'
import MyLibrary from './a/MyLibrary'
import Story from './a/Story'
import Stories from './a/Stories'
import Signout from './a/Signout'
import Collections from './a/Collections'
import Collection from './a/Collection'
import Profile from './a/Profile'
import People from './a/People'
import Organization from './a/Organization'
import Requests from './a/Requests'
import Questionnaire from './a/Questionnaire'
import QuestionnaireQuestion from './a/QuestionnaireQuestion'
import QuestionnaireTemplateQuestion from './a/QuestionnaireTemplateQuestion'
import CollectionInvite from './g/CollectionInvite'
import StoryInvite from './g/StoryInvite'
import Onboarding from './a/Onboarding'
import ShortId from './ShortId'
import Error404 from './Error404'
import Error403 from './Error403'
import Error500 from './Error500'
import Embed from './u/Embed'
import Theme from './u/Theme'
import IocCallback from '../components/IocCallback'

const SentryRoute = Sentry.withSentryRouting(Route)

const ProtectedRouter: React.FC<RouteProps> = props => {
  const { user, isLoading } = useContext(UserContext)
  if (isLoading) return null
  if (!user?.onboardAt) {
    const renderComponent = () => <Redirect to="/a/onboarding" />
    return <SentryRoute component={renderComponent} render={undefined} />
  }
  return <SentryRoute {...props} />
}

const AuthenticadeRoutes: React.FC<RouteProps> = () => {
  const token = Cookies.get('token')
  const location = useLocation()

  if (!token) {
    return <Redirect to={`/u/signin?returnUrl=${location.pathname}`} />
  }

  return (
    <AuthenticatedLayout>
      <Switch>
        <SentryRoute exact path="/a/onboarding" component={Onboarding} />
        <ProtectedRouter exact path="/a/organization" component={Organization} />
        <ProtectedRouter exact path="/a/people" component={People} />
        <ProtectedRouter exact path="/a/profile" component={Profile} />
        <ProtectedRouter exact path="/a/my-library" component={MyLibrary} />
        <ProtectedRouter exact path="/a/search" component={Search} />
        <ProtectedRouter exact path="/a/Requests" component={Requests} />
        <ProtectedRouter exact path="/a/questionnaire" component={Questionnaire} />
        <ProtectedRouter exact path="/a/questionnaire/:quizId" component={QuestionnaireQuestion} />
        <ProtectedRouter exact path="/a/questionnaire-template/:quizId" component={QuestionnaireTemplateQuestion} />
        <ProtectedRouter exact path="/a/stories/:storyId" component={Story} />
        <ProtectedRouter exact path="/a/stories" component={Stories} />
        <ProtectedRouter exact path="/a/collections/:collectionId" component={Collection} />
        <ProtectedRouter exact path="/a/collections" component={Collections} />
        <SentryRoute exact path="/a/signout" component={Signout} />
        <Redirect to="/a/my-library" />
      </Switch>
    </AuthenticatedLayout>
  )
}

const GuestRoutes: React.FC<RouteProps> = ({ location }) => {
  return (
    <GuestLayoutProvider>
      <Switch>
        <SentryRoute exact path="/g/collection-invite/:id" component={CollectionInvite} />
        <SentryRoute exact path="/g/story-invite/:id" component={StoryInvite} />
        <Redirect to="/" />
      </Switch>
    </GuestLayoutProvider>
  )
}

const PublicRoutes: React.FC<RouteProps> = () => {
  const tokenItem = localStorage.getItem('token')
  const token = tokenItem ? JSON.parse(tokenItem) : undefined
  if (token) return <Redirect to="/a/my-library" />
  return (
    <PublicLayout>
      <Switch>
        <Route path="/u/signin" component={Signin} />
        <Route path="/u/linkedin" component={LinkedInCallback} />
        <Route path="/u/ioc" component={IocCallback} />
        <Route path="/u/signup" component={Signup} />
        <Route path="/u/forgot-password" component={ForgotPassword} />
        <Route path="/u/password-reset" component={PasswordReset} />
        <Route path="/u/confirmation-email" component={ConfirmationEmail} />
        <Route path="/u/facebook/data-deletion" component={FacebookDataDeletion} />
        <Redirect to="/u/signin" />
      </Switch>
    </PublicLayout>
  )
}

const Routes: React.FC = () => {
  return (
    <Router>
      <Suspense fallback={<CircularLoadingCentred />}>
        <Switch>
          <Route path="/a">
            <AuthenticadeRoutes />
          </Route>
          <Route path="/g">
            <GuestRoutes />
          </Route>
          <Route path="/u">
            <PublicRoutes />
          </Route>
          <SentryRoute exact path="/404" component={Error404} />
          <SentryRoute exact path="/403" component={Error403} />
          <SentryRoute exact path="/500" component={Error500} />
          <SentryRoute exact path="/:id([0-9A-Z]{6})" component={ShortId} />
          <SentryRoute exact path="/embed/:type(stories|collections)/:id" component={Embed} />
          <SentryRoute exact path="/public/:type(stories|collections)/:id" component={Public} />
          <SentryRoute exact path="/theme/:type(stories|collections)/:id" component={Theme} />
          <Redirect to="/a" />
        </Switch>
      </Suspense>
    </Router>
  )
}

export default Routes
