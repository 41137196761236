import React, { createContext, ReactNode, useState } from 'react'
import Component from './Component'

// @ts-ignore
export const SimpleMultiSelectDialogContext = createContext<{
  actions: {
    open: (
      callback: (ids: string[] | null) => void,
      title: string,
      content: string | ReactNode,
      items: { id: string; name: string; email?: string; alreadyInvited?: boolean }[],
      okButtonLabel: string,
      selectedItemsIds?: string[]
    ) => void
    close: (reload?: boolean) => void
    callback: ((ids: string[] | null) => void) | undefined
  }
  store: {
    isOpen: boolean
    title: string
    content: string | ReactNode
    items: { id: string; name: string; email?: string; alreadyInvited?: boolean }[]
    okButtonLabel: string
    selectedItemsIds: string[]
  }
}>({
  // @ts-ignore
  actions: {}
})

export const SimpleMultiSelectDialogProvider: React.FC = ({ children }) => {
  const [callback, setCallback] = useState<((ids: string[] | null) => void) | undefined>(undefined)
  const [title, setTitle] = useState<string>('')
  const [content, setContent] = useState<string | ReactNode>('')
  const [items, setItems] = useState<{ id: string; name: string; email?: string; alreadyInvited?: boolean }[]>([])
  const [okButtonLabel, setOkButtonLabel] = useState('')
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selectedItemsIds, setSelectedItemsIds] = useState<any[]>([])
  const open = (
    cback: (ids: string[] | null) => void,
    ttl: string,
    cntt: string | ReactNode,
    itms: { id: string; name: string; email?: string; alreadyInvited?: boolean }[],
    btnlabel: string,
    slcItms?: string[]
  ) => {
    setCallback(() => cback)
    setTitle(ttl)
    setContent(cntt)
    setItems(itms)
    setOkButtonLabel(btnlabel)
    setIsOpen(true)
    slcItms && setSelectedItemsIds(slcItms)
  }

  const close = () => {
    setIsOpen(false)
  }

  return (
    <SimpleMultiSelectDialogContext.Provider
      value={{
        actions: {
          close,
          open,
          callback: callback
        },
        store: {
          title,
          content,
          items,
          okButtonLabel,
          isOpen,
          selectedItemsIds
        }
      }}
    >
      <Component />
      {children}
    </SimpleMultiSelectDialogContext.Provider>
  )
}
