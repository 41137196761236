import * as React from 'react'
import { useContext, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { SimpleDialogContext } from './Context'
import { iSimpleDialog } from './index'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { CollectionThemeProps } from '../../lib/types'
import LoadingButton from '@mui/lab/LoadingButton'

interface iSimpleDialogComponent {
  store: iSimpleDialog
  collectionTheme: CollectionThemeProps
}

const SimpleDialog: React.FC<iSimpleDialogComponent> = ({ store, collectionTheme }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const { actions } = useContext(SimpleDialogContext)
  const [isLoading, setIsLoading] = useState(false)
  const handleClose = async (value: boolean) => {
    if (store.callback) {
      setIsLoading(true)
      await store.callback(value)
    }
    actions.close()
  }
  return (
    <Dialog
      PaperProps={{ style: { backgroundColor: collectionTheme.notificationBackgroundColor } }}
      fullScreen={fullScreen}
      maxWidth="md"
      fullWidth
      open={true}
      onClose={() => handleClose(false)}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ color: collectionTheme.notificationTextColor, fontFamily: collectionTheme.notificationTextFontFamily }}
      >
        {store.title}{' '}
        <IconButton
          aria-label="close"
          onClick={() => handleClose(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: collectionTheme.notificationCloseIconColor
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          sx={{ color: collectionTheme.notificationTextColor, fontFamily: collectionTheme.notificationTextFontFamily }}
        >
          {store.content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => handleClose(false)}
          autoFocus
          sx={{
            color: collectionTheme.notificationCancelButtonColor,
            borderColor: collectionTheme.notificationCancelButtonColor,
            ':hover': {
              borderColor: collectionTheme.notificationCancelButtonColor,
              backgroundColor: collectionTheme.notificationCancelButtonHoverColor
            },
            fontFamily: collectionTheme.notificationTextFontFamily
          }}
        >
          {store.cancelText}
        </Button>
        {store.showOk && (
          <LoadingButton
            loading={isLoading}
            color="secondary"
            onClick={() => handleClose(true)}
            sx={{
              color: collectionTheme.notificationConfirmButtonColor,
              ':hover': { backgroundColor: collectionTheme.notificationConfirmButtonHoverColor },
              fontFamily: collectionTheme.notificationTextFontFamily
            }}
          >
            {store.okText}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default SimpleDialog
