import { UserType } from '../../lib/types'
import Cookies from 'js-cookie'
import api from '../../lib/api'

const handleTenantSignIn = async (user: UserType, forcedTenantId?: string) => {
  if (!user) return
  if (forcedTenantId) {
    try {
      const memberInfo = await api.switchLibrary(user.id, forcedTenantId)
      if (memberInfo) {
        Cookies.set('token', memberInfo.tenantToken)
        Cookies.set(
          'tenant',
          JSON.stringify({ userId: memberInfo.member.userId, tenantId: memberInfo.member.tenant.id })
        )
      }
    } catch (error) {
      console.log(error)
    }
  } else {
    if (user && user.userOfTenant && user.tenantList.length > 0 && !user.hasOwnLibrary) {
      try {
        const memberInfo = await api.switchLibrary(user.id, user.tenantList[0].id)
        if (memberInfo) {
          Cookies.set('token', memberInfo.tenantToken)
          Cookies.set(
            'tenant',
            JSON.stringify({ userId: memberInfo.member.userId, tenantId: memberInfo.member.tenant.id })
          )
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}

export default handleTenantSignIn
