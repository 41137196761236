import React, { useEffect } from 'react'

import '../../lib/videojs/skins/treso/videojs.css'
import './overrides.css'

import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js'
import '../../lib/videojs/components/nuevo'
import '../../lib/videojs/components/visualizer'
import '../../lib/videojs/components/playlist'
import { SubtitleType } from '../../lib/types'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'

const defaultNuevoOptions = {
  contextText: 'Powered by Rakonto',
  contextUrl: '//app.rakonto.io',
  contextIcon: '/images/logo.svg',
  buttonRewind: true,
  buttonForward: true,
  resume: true,
  snapshot: true,
  autoplay: true,
  singlePlay: false
}

interface iVideoJs {
  embedded?: boolean
  options?: VideoJsPlayerOptions
  playlist?: any
  nuevoOptions?: any
  onReady?: any
  type?: 'audio' | 'video' | 'playlist'
  handleEnd?: () => void
  subtitles?: SubtitleType[]
  onPlay?: (time: number) => void
  onLoadedMetaData?: (item: any) => void
  onPause?: () => void
  onPressPlay?: () => void
  borderRadius?: string
  playsInline?: boolean
  chapters?: { kind: string; src: string }
}

export const VideoJS: React.FC<iVideoJs> = ({
  subtitles,
  options,
  handleEnd,
  onReady,
  type,
  nuevoOptions,
  embedded,
  playlist,
  onPlay,
  onLoadedMetaData,
  onPause,
  onPressPlay,
  borderRadius,
  playsInline,
  chapters
}) => {
  const playerRef = React.useRef<VideoJsPlayer | null>(null)
  const playerId = 'player-' + Math.floor(Math.random() * 1000000) + 1

  useEffect(() => {
    let localOptions = {
      ...options
    }
    let localNuevoOptions = {
      ...defaultNuevoOptions,
      ...nuevoOptions
    }
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      if (embedded) {
        localOptions = {
          ...localOptions,
          fill: true
        }
      }

      playerRef.current = videojs(playerId, localOptions, () => {
        onReady && onReady(playerRef.current)
        // @ts-ignore
        handleEnd && playerRef.current.on('ended', handleEnd)
        onPlay &&
          // @ts-ignore
          playerRef.current.on('timeupdate', e => {
            // @ts-ignore
            onPlay(playerRef.current?.currentTime())
          })
        // @ts-ignore
        onPressPlay && playerRef.current.on('play', onPressPlay)
        // @ts-ignore
        onLoadedMetaData &&
          // @ts-ignore
          playerRef.current.on('loadedmetadata', () => onLoadedMetaData(playerRef.current?.currentSource()))
        // @ts-ignore
        onPause && playerRef.current.on('pause', onPause)

        chapters &&
          // @ts-ignore
          playerRef.current.on('play', () => playerRef.current?.loadTracks(chapters))
      })

      const captions =
        subtitles?.map(subtitle => ({
          // If develop mode need replace proxy port = subtitle.url.replace('8080', '3000')
          kind: 'captions',
          src: subtitle.url,
          srclang: subtitle.language,
          label: subtitle.language,
          default: '1'
        })) || []

      playerRef.current!.on('nuevoReady', () => {
        if (captions.length) {
          // @ts-ignore
          playerRef.current.loadTracks(captions)
        }
      })

      if (type === 'audio') {
        //  @ts-ignore
        playerRef.current.visualizer({ video: true })
      }

      if (type === 'playlist' && playlist.length > 0) {
        //  @ts-ignore
        playerRef.current.playsinline(true)

        // @ts-ignore
        localNuevoOptions = {
          ...localNuevoOptions,
          playlistUI: true, // set to disable playlist UI completely
          playlistShow: false, // set to hide playlist UI on start
          playlistAutoHide: false, // Disable playlist UI autohide on video play event
          playlistNavigation: true, // set to show playlist navigation arrows
          playlistRepeat: false, // set to repeat playlist playback
          singlePlay: false,
          contextText: 'Powered by Rakonto'
        }
      }

      // @ts-ignore
      playerRef.current.nuevo({
        ...localNuevoOptions
      })

      // @ts-ignore
      if (type === 'playlist' && playlist.length > 0) playerRef.current.playlist(playlist)
    } else {
      // const player = playerRef.current
      // player.options(options)
    }
  }, [options, nuevoOptions, chapters])

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current.pause()
        playerRef.current.dispose()
        playerRef.current = null
      }
    }
  }, [])

  if (type === 'audio') {
    return <audio id={playerId} className="video-js" crossOrigin="anonymous" style={{ borderRadius: borderRadius }} />
  }

  return (
    <video
      id={playerId}
      className="video-js"
      crossOrigin="anonymous"
      style={{ maxWidth: '99%', maxHeight: '99%', borderRadius: '20px' }}
      playsInline={playsInline}
    />
  )
}

export const VideoJsWrapper: React.FC<{
  subtitles?: SubtitleType[]
  options: VideoJsPlayerOptions
  preview?: string
  handleEnd?: () => void
  onPlay?: (time: number) => void
  playlist?: [] | any
  onLoadedMetaData?: (item: any) => void
  onPause?: () => void
  onPressPlay?: () => void
  borderRadius?: string
  playsInline?: boolean
  chapters?: { kind: string; src: string }
}> = ({
  options,
  preview,
  subtitles,
  handleEnd,
  onPlay,
  playlist,
  onLoadedMetaData,
  onPressPlay,
  onPause,
  borderRadius,
  playsInline,
  chapters
}) => {
  const _options = {
    ...options
  }

  const nuevoOptions = {
    logo: null,
    videoInfo: playlist && playlist?.length > 0
  }

  return (
    <VideoJS
      subtitles={subtitles}
      handleEnd={handleEnd}
      options={_options}
      type={playlist ? 'playlist' : 'video'}
      nuevoOptions={nuevoOptions}
      onPlay={onPlay}
      playlist={playlist}
      onLoadedMetaData={onLoadedMetaData}
      onPause={onPause}
      onPressPlay={onPressPlay}
      borderRadius={borderRadius}
      playsInline={playsInline}
      chapters={chapters}
    />
  )
}

export const AudioJsWrapper: React.FC<{
  subtitles?: SubtitleType[]
  options: VideoJsPlayerOptions
  id: string
  handleEnd?: () => void
  onPlay?: (time: number) => void
  playlist?: any
  onLoadedMetaData?: (item: any) => void
  onPause?: () => void
  onPressPlay?: () => void
  borderRadius?: string
}> = ({
  options,
  subtitles,
  handleEnd,
  onPlay,
  id,
  playlist,
  onLoadedMetaData,
  onPause,
  onPressPlay,
  borderRadius
}) => {
  const nuevoOptions = {
    logo: options.poster as string
  }
  const _options = {
    ...options,
    poster: '/images/CoverDefault.png'
  }

  function ErrorFallback(props: FallbackProps) {
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre>{props.error.message}</pre>
        <button onClick={props.resetErrorBoundary}>Try again</button>
      </div>
    )
  }

  const myErrorHandler = (error: Error, info: { componentStack: string }) => {
    console.error(error)
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={myErrorHandler}>
      <VideoJS
        subtitles={subtitles}
        handleEnd={handleEnd}
        options={_options}
        type={playlist ? 'playlist' : 'audio'}
        nuevoOptions={nuevoOptions}
        onPlay={onPlay}
        playlist={playlist}
        onLoadedMetaData={onLoadedMetaData}
        onPause={onPause}
        onPressPlay={onPressPlay}
        borderRadius={borderRadius}
      />
    </ErrorBoundary>
  )
}
