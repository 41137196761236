import React, { useEffect, useState } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import { RouteComponentProps } from 'react-router-dom'
import { parse } from 'qs'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import QuizIcon from '@mui/icons-material/Quiz'
import PostAddIcon from '@mui/icons-material/PostAdd'
import Templates from './Templates'
import QuestionnaireComponent from './Questionnaire'
import { usePermissions } from '../../../components/hooks/usePermissions'

const Questionnaire: React.FC<RouteComponentProps> = ({ location, history }) => {
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.down('md'))
  const [t, setTab] = useState('templates')
  const { isCreator } = usePermissions()
  const onTabClick = (tab: string) => {
    history.push(`/a/questionnaire?tab=${tab}`)
  }

  useEffect(() => {
    const { tab } = parse(location?.search === '' ? 'tab=templates' : (location?.search as string), {
      ignoreQueryPrefix: true
    })
    if (!isCreator) {
      setTab('questionnaire')
    } else {
      setTab(tab as string)
    }
  }, [location])

  return (
    <>
      <Box
        sx={{
          paddingTop: 4,
          paddingBottom: 2,
          paddingLeft: 3
        }}
      >
        <Typography variant="h4">Questionnaire</Typography>
        <Typography variant="caption">
          This feature allows you to incorporate multiple questions into a single QR code or link. It takes just two
          steps:
          <br /> 1. If you have not done so already, create a template - a standard list of questions that you may use
          for multiple questionnaires.
          <br /> 2. Based on the template created in step 1, create a questionnaire with its accompanying link and QR
          code. Send the QR code or links via email, SMS, social media, etc. Responses to questions will be stored in
          the collection you specify.
        </Typography>
      </Box>
      <Box
        sx={{
          minHeight: `100%`,
          display: 'flex',
          flexFlow: isMd ? 'column' : 'row'
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <Tabs
            value={t}
            orientation={isMd ? 'horizontal' : 'vertical'}
            variant="fullWidth"
            onChange={(event, value) => onTabClick(value)}
          >
            {isCreator && (
              <Tab
                sx={{ minWidth: { xs: '40px', md: '70px' } }}
                value="templates"
                label={
                  <Tooltip placement="right" title="Templates">
                    <PostAddIcon />
                  </Tooltip>
                }
              />
            )}
            <Tab
              sx={{ minWidth: { xs: '40px', md: '70px' } }}
              value="questionnaire"
              label={
                <Tooltip placement="right" title="Questionnaires">
                  <QuizIcon />
                </Tooltip>
              }
            />
          </Tabs>
        </Box>
        <Box
          sx={{
            flex: '1',
            minHeight: '100%',
            bgcolor: 'background.paper'
          }}
        >
          {t === 'templates' && <Templates />}
          {t === 'questionnaire' && <QuestionnaireComponent />}
        </Box>
      </Box>
    </>
  )
}

export default Questionnaire
