import React from 'react'
import Box from '@mui/material/Box'
import { AudioDetails, MediaType, Resolutions, SubtitleType, VideoDetails } from '../../lib/types'
import { AudioJsWrapper, VideoJsWrapper } from './VideoJs'
import { VideoJsPlayerOptions } from 'video.js'

interface iVideoPlayer {
  media?: VideoDetails | AudioDetails
  cover?: string
  defaultRes?: Resolutions
  type?: MediaType
  subtitles?: SubtitleType[]
  autoplay?: boolean
  handleEnd?: () => void
  maxHeight?: string
  mobileMaxHeight?: string
  onPlay?: (time: number) => void
  playlist?: any
  onLoadedMetaData?: (item: any) => void
  onPause?: () => void
  onPressPlay?: () => void
  borderRadius?: string
  playsInline?: boolean
  chapters?: { kind: string; src: string }
}

const Player: React.FC<iVideoPlayer> = ({
  autoplay,
  handleEnd,
  subtitles,
  media,
  type,
  cover,
  maxHeight = '720px',
  mobileMaxHeight = '100vh',
  onPlay,
  playlist,
  onLoadedMetaData,
  onPause,
  onPressPlay,
  borderRadius = '0px',
  playsInline = false,
  chapters
}) => {
  const options: VideoJsPlayerOptions = {
    poster: cover,
    responsive: true,
    preload: 'auto',
    controls: true,
    fill: true,
    muted: false,
    autoplay: autoplay,
    notSupportedMessage:
      'Your recording is still processing. Please wait a few moments, then refresh the page and try again.'
  }

  if (type === 'VIDEO') {
    const { id, gifUrl, url } = media as VideoDetails

    options.sources = [
      {
        src: url as string,
        type: 'video/mp4'
      }
    ]
    return (
      <Box
        sx={{
          maxHeight: { xs: mobileMaxHeight, md: maxHeight },
          height: { xs: mobileMaxHeight, md: maxHeight }
        }}
      >
        <VideoJsWrapper
          subtitles={subtitles}
          key={id}
          handleEnd={handleEnd}
          preview={gifUrl}
          options={options}
          onPlay={onPlay}
          playlist={playlist}
          onLoadedMetaData={onLoadedMetaData}
          onPause={onPause}
          onPressPlay={onPressPlay}
          borderRadius={borderRadius}
          playsInline={playsInline}
          chapters={chapters}
        />
      </Box>
    )
  }

  if (type === 'AUDIO') {
    const { id, url } = media as AudioDetails
    options.sources = [
      {
        src: url!,
        type: 'audio/mp3'
      }
    ]
    return (
      <Box sx={{ maxHeight: { xs: '100vh', md: maxHeight }, height: { xs: '100vh', md: maxHeight } }}>
        <AudioJsWrapper
          subtitles={subtitles}
          key={id}
          handleEnd={handleEnd}
          id={media?.id || ''}
          options={options}
          onPlay={onPlay}
          playlist={playlist}
          onLoadedMetaData={onLoadedMetaData}
          onPause={onPause}
          onPressPlay={onPressPlay}
          borderRadius={borderRadius}
        />
      </Box>
    )
  }

  return null
}

export default Player
