import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import ButtonGroup from '@mui/material/ButtonGroup'
import Button from '@mui/material/Button'
import React, { useContext } from 'react'
import { CollectionThemeProps } from '../../../../../../lib/types'
import { useFormik } from 'formik'
import schema from '../../../../../g/CollectionInvite/Questionnaire/schema'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Box from '@mui/material/Box'
import { UserContext } from '../../../../../../components/UserProvider'

const Step1: React.FC<{
  collectionTheme: CollectionThemeProps
}> = ({ collectionTheme }) => {
  const policy = 'https://olympics.com/en/privacy-policy'
  const terms = 'https://olympics.com/en/terms-of-service'
  const { user } = useContext(UserContext)
  const logo =
    user?.organizations.length > 0 && user?.organizations[0]?.logo?.url ? user?.organizations[0]?.logo?.url : ''

  const initialValues = {
    email: '',
    name: '',
    jobTitle: '',
    functionalArea: ''
  }

  const { values, handleSubmit, handleChange, handleBlur, errors, touched, isValid, dirty } = useFormik({
    initialValues,
    onSubmit: async values => {
      console.log(values)
    },
    validationSchema: schema
  })
  return (
    <Grid
      component="form"
      container
      spacing={2}
      sx={{ backgroundColor: collectionTheme?.questionnaireBackgroundColor, borderRadius: '4px' }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" sx={{ backgroundColor: collectionTheme?.questionnaireHeaderBackgroundColor }}>
          <Toolbar>
            {logo && <Box component="img" sx={{ width: 192.5, maxHeight: 57 }} src={logo} alt="rakonto" />}
          </Toolbar>
        </AppBar>
      </Box>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          mb={2}
          sx={{
            fontSize: '1.1em',
            overflowY: 'scroll',
            maxHeight: '25em',
            whiteSpace: 'pre-wrap',
            color: collectionTheme?.questionnaireBodyTextColor,
            fontFamily: collectionTheme?.questionnaireTextFontFamily
          }}
        >
          Questionnaire Description
        </Typography>

        <hr></hr>

        <Typography
          variant="body2"
          mb={2}
          fontSize="0.8em"
          sx={{
            color: collectionTheme?.questionnaireBodyTextColor,
            fontFamily: collectionTheme?.questionnaireTextFontFamily
          }}
        >
          {`You have been invited by 'Your name here' to respond to a questionnaire.
          You authorize 'Your name here' to contact you and share
          your submission in accordance with our `}
          <Link href={terms} target="_blank" sx={{ color: collectionTheme?.questionnaireLinksColor }}>
            terms
          </Link>{' '}
          and{' '}
          <Link href={policy} target="_blank" sx={{ color: collectionTheme?.questionnaireLinksColor }}>
            {' '}
            privacy policy
          </Link>
          .
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="Email"
          name="email"
          type="text"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.email && Boolean(errors.email)}
          helperText={(touched.email && errors.email) || ' '}
          fullWidth
          InputLabelProps={{
            style: {
              fontFamily: collectionTheme?.questionnaireTextFontFamily,
              color: collectionTheme?.questionnaireInputLabelColor
            }
          }}
          InputProps={{
            style: {
              width: '100%',
              borderWidth: '1px',
              color: collectionTheme?.questionnaireInputTextColor,
              backgroundColor: collectionTheme?.questionnaireInputBackgroundColor,
              fontFamily: collectionTheme?.questionnaireTextFontFamily,
              WebkitTextFillColor: collectionTheme?.questionnaireInputTextColor
            }
          }}
          sx={{
            '& .MuiOutlinedInput-root': collectionTheme && {
              '& fieldset.MuiOutlinedInput-notchedOutline': {
                borderColor:
                  touched.email && Boolean(errors.email) ? '#FF8A80' : collectionTheme?.questionnaireInputOutlineColor
              }
            },
            '& .MuiOutlinedInput-input:-webkit-autofill': {
              '-webkit-box-shadow': `0 0 0 30px ${collectionTheme?.signupBackgroundColor ?? '#424242'} inset`
            }
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="Name"
          name="name"
          type="text"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.name && Boolean(errors.name)}
          helperText={(touched.name && errors.name) || ' '}
          fullWidth
          InputLabelProps={{
            style: {
              fontFamily: collectionTheme?.questionnaireTextFontFamily,
              color: collectionTheme?.questionnaireInputLabelColor
            }
          }}
          InputProps={{
            style: {
              width: '100%',
              borderWidth: '1px',
              color: collectionTheme?.questionnaireInputTextColor,
              backgroundColor: collectionTheme?.questionnaireInputBackgroundColor,
              fontFamily: collectionTheme?.questionnaireTextFontFamily,
              WebkitTextFillColor: collectionTheme?.questionnaireInputTextColor
            }
          }}
          sx={{
            '& .MuiOutlinedInput-root': collectionTheme && {
              '& fieldset.MuiOutlinedInput-notchedOutline': {
                borderColor:
                  touched.name && Boolean(errors.name) ? '#FF8A80' : collectionTheme?.questionnaireInputOutlineColor
              }
            },
            '& .MuiOutlinedInput-input:-webkit-autofill': {
              '-webkit-box-shadow': `0 0 0 30px ${collectionTheme?.signupBackgroundColor ?? '#424242'} inset`
            }
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Autocomplete
          freeSolo={true}
          forcePopupIcon={true}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option}>
                {option}
              </li>
            )
          }}
          options={['example', 'example 2']}
          value={values.jobTitle}
          onChange={(event, newValue) => {
            if (newValue !== null) {
              handleChange({ target: { name: 'jobTitle', value: newValue } })
            } else {
              handleChange({ target: { name: 'jobTitle', value: '' } })
            }
          }}
          inputValue={values.jobTitle}
          onInputChange={(event, newInputValue) => {
            handleChange({ target: { name: 'jobTitle', value: newInputValue } })
          }}
          renderInput={params => (
            <TextField
              {...params}
              label="Job Title"
              name="jobTitle"
              type="text"
              onBlur={handleBlur}
              error={touched.jobTitle && Boolean(errors.jobTitle)}
              helperText={(touched.jobTitle && errors.jobTitle) || ' '}
              fullWidth
              InputLabelProps={{
                style: {
                  color: collectionTheme?.questionnaireInputLabelColor,
                  fontFamily: collectionTheme?.questionnaireTextFontFamily
                }
              }}
              InputProps={{
                style: {
                  borderWidth: '1px',
                  color: collectionTheme?.questionnaireInputTextColor,
                  backgroundColor: collectionTheme?.questionnaireInputBackgroundColor,
                  fontFamily: collectionTheme?.questionnaireTextFontFamily,
                  WebkitTextFillColor: collectionTheme?.questionnaireInputTextColor
                }
              }}
              sx={{
                '& .MuiOutlinedInput-root': collectionTheme && {
                  '& fieldset.MuiOutlinedInput-notchedOutline': {
                    borderColor: collectionTheme?.questionnaireInputOutlineColor
                  }
                },
                '& .MuiOutlinedInput-input:-webkit-autofill': {
                  '-webkit-box-shadow': `0 0 0 30px ${collectionTheme?.signupBackgroundColor ?? '#424242'} inset`
                }
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Autocomplete
          freeSolo={true}
          forcePopupIcon={true}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option}>
                {option}
              </li>
            )
          }}
          options={['example', 'example 2']}
          value={values.functionalArea}
          onChange={(event, newValue) => {
            if (newValue !== null) {
              handleChange({ target: { name: 'functionalArea', value: newValue } })
            } else {
              handleChange({ target: { name: 'functionalArea', value: '' } })
            }
          }}
          inputValue={values.functionalArea}
          onInputChange={(event, newInputValue) => {
            handleChange({ target: { name: 'functionalArea', value: newInputValue } })
          }}
          renderInput={params => (
            <TextField
              {...params}
              label="Functional Area"
              name="functionalArea"
              type="text"
              onBlur={handleBlur}
              error={touched.functionalArea && Boolean(errors.functionalArea)}
              helperText={(touched.functionalArea && errors.functionalArea) || ' '}
              fullWidth
              InputLabelProps={{
                style: {
                  color: collectionTheme?.questionnaireInputLabelColor,
                  fontFamily: collectionTheme?.questionnaireTextFontFamily
                }
              }}
              InputProps={{
                style: {
                  borderWidth: '1px',
                  color: collectionTheme?.questionnaireInputTextColor,
                  backgroundColor: collectionTheme?.questionnaireInputBackgroundColor,
                  fontFamily: collectionTheme?.questionnaireTextFontFamily,
                  WebkitTextFillColor: collectionTheme?.questionnaireInputTextColor
                }
              }}
              sx={{
                '& .MuiOutlinedInput-root': collectionTheme && {
                  '& fieldset.MuiOutlinedInput-notchedOutline': {
                    borderColor: collectionTheme?.questionnaireInputOutlineColor
                  }
                },
                '& .MuiOutlinedInput-input:-webkit-autofill': {
                  '-webkit-box-shadow': `0 0 0 30px ${collectionTheme?.questionnaireBackgroundColor ?? '#424242'} inset`
                }
              }}
            />
          )}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          textAlign: 'right',
          flex: 1,
          width: '100%',
          position: 'inherit',
          float: 'right',
          zIndex: 11
        }}
      >
        <ButtonGroup>
          <Button
            type="submit"
            sx={{
              fontSize: '1.1rem',
              color: collectionTheme?.questionnaireContainedButtonTextColor,
              backgroundColor: collectionTheme?.questionnaireContainedButtonBackgroundColor,
              ':hover': { backgroundColor: collectionTheme?.questionnaireContainedButtonHoverColor },
              fontFamily: collectionTheme?.questionnaireTextFontFamily
            }}
            variant="contained"
            size="medium"
          >
            Start
          </Button>
        </ButtonGroup>
      </Grid>
    </Grid>
  )
}
export default Step1
