import React from 'react'
import { CollectionThemeProps, InviteType } from '../../../../../lib/types'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

const Step3: React.FC<{
  collectionTheme?: CollectionThemeProps
  invite: InviteType
  user: { email: string; name: string } | null
}> = ({ invite, user, collectionTheme }) => {
  const name = invite.organization?.name || `${invite.user.firstName} ${invite.user.lastName}`
  const email = invite.organization?.email || invite.user.email
  // const searchString = stringify(user, { addQueryPrefix: true })
  console.log('Invite: ' + invite + ' : ' + invite.id)
  console.log('Invite Call to Action: ' + invite?.callToAction)

  console.log('- Final Invite ID: ' + invite.id)
  console.log('- Final Invite Title: ' + invite.title)
  console.log('- Final CTA: ' + invite.callToAction)
  console.log('- Final CTA Button: ' + invite.callToActionButtonLabel)
  console.log('- Final CTA Instructions: ' + invite.callToActionInstructions)

  return (
    <Grid container>
      {invite.callToAction ? (
        <Grid textAlign="center" justifySelf="center" item xs={12} mt={6}>
          <Typography
            variant="h5"
            mb={5}
            sx={{
              color: collectionTheme?.questionnaireBodyTextColor,
              fontFamily: collectionTheme?.questionnaireTextFontFamily
            }}
          >
            {invite.callToActionInstructions}
          </Typography>
          <Button
            href={`${invite.callToAction}`}
            target="_blank"
            rel="noreferrer"
            variant="contained"
            sx={{
              color: collectionTheme?.questionnaireContainedButtonTextColor,
              backgroundColor: collectionTheme?.questionnaireContainedButtonBackgroundColor,
              ':hover': { backgroundColor: collectionTheme?.questionnaireContainedButtonHoverColor },
              fontFamily: collectionTheme?.questionnaireTextFontFamily
            }}
          >
            {invite.callToActionButtonLabel}
          </Button>
        </Grid>
      ) : (
        <Box sx={{ mb: { xs: 10, md: 'unset' } }}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              mb={2}
              textAlign="justify"
              sx={{
                color: collectionTheme?.questionnaireBodyTextColor,
                fontFamily: collectionTheme?.questionnaireTextFontFamily
              }}
            >
              {`Thank you for using Rakonto to submit your recording! We have notified ${name} that you have submitted your recording. If you have any questions
          about your recording, please contact ${name} at `}
              <Link href={`mailTo:${email}`} target="_blank" sx={{ color: collectionTheme?.questionnaireLinksColor }}>
                {email}
              </Link>
              .
            </Typography>
            <Typography
              variant="h2"
              textAlign="center"
              margin="0.8em"
              fontWeight="400"
              sx={{
                color: collectionTheme?.questionnaireBodyTextColor,
                fontFamily: collectionTheme?.questionnaireTextFontFamily
              }}
            >
              Thank You!
            </Typography>
          </Grid>
          <Grid textAlign="center" item xs={12} mt={4}>
            <Button
              autoFocus
              href={'/u/signup'}
              sx={{
                maxWidth: { xs: 'inherit', md: '400px' },
                color: collectionTheme?.questionnaireContainedButtonTextColor,
                backgroundColor: collectionTheme?.questionnaireContainedButtonBackgroundColor,
                ':hover': { backgroundColor: collectionTheme?.questionnaireContainedButtonHoverColor },
                fontFamily: collectionTheme?.questionnaireTextFontFamily
              }}
              variant="contained"
              fullWidth
            >
              Confirm and create a free Rakonto account now
            </Button>
          </Grid>
          <Grid textAlign="center" justifySelf="center" item xs={12} mt={2}>
            <Button
              href="http://rakonto.io"
              sx={{
                maxWidth: { xs: 'inherit', md: '400px' },
                color: collectionTheme?.questionnaireOutlinedButtonColor,
                borderColor: collectionTheme?.questionnaireOutlinedButtonColor,
                fontFamily: collectionTheme?.questionnaireTextFontFamily,
                ':hover': {
                  borderColor: collectionTheme?.questionnaireOutlinedButtonColor,
                  backgroundColor: collectionTheme?.questionnaireOutlinedButtonHoverColor
                }
              }}
              variant="outlined"
              fullWidth
            >
              Confirm without creating a free Rakonto account
            </Button>
          </Grid>
          <Grid textAlign="center" justifySelf="center" item xs={12} mt={2}>
            <Button
              href={'/u/signin'}
              sx={{
                maxWidth: { xs: 'inherit', md: '400px' },
                color: collectionTheme?.questionnaireLinksColor,
                fontFamily: collectionTheme?.questionnaireTextFontFamily
              }}
              fullWidth
            >
              If you already have a Rakonto account, you may login.
            </Button>
          </Grid>
        </Box>
      )}
    </Grid>
  )
}

export default Step3
