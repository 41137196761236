import React, { useContext, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'

import CircularProgress from '@mui/material/CircularProgress'
import api from '../../../../lib/api'
import { TranscriptionType } from '../../../../lib/types'
import TranscriptEditor from '../../../../components/TranscriptEditor'
import Button from '@mui/material/Button'

interface iTranscript {
  storyId: string
  handleView: () => void
  refetch: () => void
  time: number
}

const LateralTranscript: React.FC<iTranscript> = ({ storyId, handleView, refetch, time }) => {
  const [localTranscription, setLocalTranscription] = useState<TranscriptionType | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [editMode, setEditMode] = useState<boolean>(false)
  const [cooked, setCooked] = useState<unknown>(null)
  const [sendingTranscriptToDam, setSendingTranscriptToDam] = useState(false)
  const [downloadingTranscripts, setDownloadingTranscripts] = useState(false)

  const fetch = async () => {
    if (!storyId) return
    try {
      const transcripts = await api.getTranscriptions(0, 1000, [storyId])
      if (transcripts.content && transcripts.content.length > 0) {
        setLocalTranscription(transcripts.content[0])
        transcripts.content[0].cooked && setCooked(transcripts.content[0].cooked)
      }
    } catch (error) {
      console.log(error)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    setIsLoading(true)
    fetch()
  }, [])

  // save button flow
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleSaveAsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Box
        component={Paper}
        sx={{
          display: { xs: 'none', md: 'flex' },
          width: '100%',
          padding: 0,
          flexFlow: 'column',
          overflowY: 'scroll',
          maxHeight: '620px'
        }}
      >
        {isLoading ? (
          <CircularProgress variant="indeterminate" />
        ) : (
          <>
            {!localTranscription ? (
              <Typography component="pre" align="justify" paragraph>
                We haven&apos;t finished generating the transcript. Please check back later.
              </Typography>
            ) : (
              <>
                <TranscriptEditor
                  transcriptJson={localTranscription!.cooked}
                  currentTime={time}
                  isEditable={editMode}
                  onChange={t => setCooked(t)}
                />
              </>
            )}
          </>
        )}
      </Box>
    </>
  )
}

export default LateralTranscript
