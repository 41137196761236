import React, { useEffect, useState } from 'react'
import { InviteType } from '../../../../../lib/types'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useField } from 'formik'
import InputFileArea from '../../../../../components/InputFileArea'
import { intervalToDuration } from 'date-fns'
import { isMobile } from 'react-device-detect'
import RemoveFile from '../../../../../components/InputFileArea/RemoveFile'

interface Step2Props {
  invite: InviteType
  savedFile: File | null
  handleNext: () => void
  handleFile: (file: File | null) => void
  handeRemoveStoredFile: () => void
  token: string
}

const Step2: React.FC<Step2Props> = ({ invite, handleNext, handleFile, handeRemoveStoredFile, savedFile, token }) => {
  const [{ value: file }, , { setValue }] = useField<File | null>('file')
  const duration = intervalToDuration({ start: 0, end: invite.requestedMediaLength * 1000 })
  const name = invite.organization?.name || `${invite.user.firstName} ${invite.user.lastName}`
  const [updated, setUpdated] = useState(false)

  const handleFileWithStorage = (file: File | null) => {
    handleFile(file)
    setUpdated(!updated)
  }

  useEffect(() => {
    console.log('Step received a savedFile: ', savedFile)
    if (savedFile) {
      setValue(savedFile)
    }
  }, [savedFile])

  return (
    <>
      {!savedFile ? (
        <>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6" mb={2}>
                {`${name} would like you to record ${
                  invite.requestedMediaType?.toLowerCase() || 'audio or video'
                }. If possible, please keep your recording duration under ${String(
                  (duration.hours || 0) * 60 + (duration.minutes || 0)
                )} minute${(duration.minutes || 0) > 1 ? 's' : ''}${
                  duration.seconds ? ` and ${String(duration.seconds).padStart(2, '0')} seconds` : ''
                }.`}
              </Typography>
              <Typography variant="h6">When you are ready, upload or record your story.</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              mt={3}
              mb={1}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%'
              }}
            >
              <InputFileArea
                file={file}
                callback={file => {
                  setValue(file)
                  handleFileWithStorage(file)
                  if (isMobile) handleNext()
                }}
                hardStop={invite.hardStop}
                countdown={invite.requestedMediaLength}
                disableChangeMediaType={!!invite.requestedMediaType}
                startType={invite.requestedMediaType || null}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" mb={2}>
              {`${name} would like you to record ${invite.requestedMediaType?.toLowerCase() || 'audio or video'}.`}
            </Typography>
            <Typography variant="h6" mb={2}>
              You already have a recording saved. You can either send it or record a new one. To send the saved
              recording, simply press continue, or to record a new one, click re-record.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            mt={3}
            mb={1}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%'
            }}
          >
            <RemoveFile
              file={savedFile}
              quotaError={false}
              onSubscriptionClicked={() => {
                return null
              }}
              onRemove={() => {
                setValue(null)
                savedFile = null
                handeRemoveStoredFile()
                setUpdated(!updated)
                if (isMobile) handleNext()
              }}
            />
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default Step2
