import React, { useContext } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Form, Formik } from 'formik'
import schema from './schema'
import TextField from '@mui/material/TextField'
import LoadingButton from '@mui/lab/LoadingButton'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import Divider from '@mui/material/Divider'
import { SigninFormType } from '../../../lib/types'
import api from '../../../lib/api'
import { SimpleDialogContext } from '../../../components/SimpleDialog'
import { SimpleSnackbarContext } from '../../../components/SimpleSnackbar'
import { parse } from 'qs'
import { Typography } from '@mui/material'
import GoogleButton from './GoogleButton'
import FacebookButton from './FacebookButton'
import LinkedinButton from './LinkedinButton'
import MicrosoftButton from './MicrosoftButton'
import Box from '@mui/material/Box'
import handleTenantSignIn from '../../../components/HandleTenantSignIn'

const Signin: React.FC<RouteComponentProps> = ({ location, history }) => {
  const { returnUrl, tenantId } = parse(location.search, { ignoreQueryPrefix: true })
  const { actions: dialogActions } = useContext(SimpleDialogContext)
  const { actions: snackActions } = useContext(SimpleSnackbarContext)

  const handleResend = async (email: string) => {
    try {
      await api.requestConfirmEmail(email)
      dialogActions.close()
    } catch (error) {
      dialogActions.open(
        'Confirm email',
        <>
          This email has not confirmed. <br />
          In the next few minutes, we are sending another confirmation email.
          <br />
          Please, verify our email box and confirm it.
        </>
      )
    }
  }

  const handleSubmit = async ({ email, password }: SigninFormType) => {
    try {
      const userInfo = await api.signin({ email, password })
      if (userInfo.user) {
        if (tenantId) {
          await handleTenantSignIn(userInfo.user, tenantId.toString())
        } else {
          await handleTenantSignIn(userInfo.user)
        }
      }
      if (returnUrl) {
        await history.push(returnUrl.toString())
        return
      }
      if (!userInfo.user.onboardAt) {
        history.push('/a/onboarding')
        return
      }
      history.push('/a/my-library')
    } catch (error) {
      // @ts-ignore
      const { data } = error.response
      if (data.code === '1004') {
        snackActions.open('Email or password are incorrect. Please try again')
        return
      }
      if (data.code === '1005') {
        dialogActions.open(
          'Verify Email',
          <>
            Please verify your email by clicking the link in the message we sent you.
            <br />
            <br />
            <Button color={'primary'} variant="contained" fullWidth onClick={() => handleResend(email)}>
              Resend email
            </Button>
          </>
        )
        return
      }

      // @ts-ignore
      if (error?.response?.status === 401) {
        snackActions.open('Email or password are incorrect. Please try again')
        return
      }

      snackActions.open('Something was wrong while creating your User, please try again.')
    }
  }

  const initialValues: SigninFormType = { email: '', password: '' }
  return (
    <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
      {({ isSubmitting, values, handleBlur, handleChange, errors, touched }) => {
        return (
          <Form>
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                <TextField
                  size="medium"
                  name="email"
                  fullWidth
                  placeholder="Email address"
                  label="Email address"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={(touched.email && errors.email) || ' '}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="medium"
                  name="password"
                  fullWidth
                  placeholder="Password"
                  label="Password"
                  type="password"
                  autoComplete="false"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.password && Boolean(errors.password)}
                  helperText={(touched.password && errors.password) || ' '}
                />
              </Grid>
              <Grid marginTop={'16px'} item xs={12}>
                <LoadingButton
                  color={'primary'}
                  variant="contained"
                  loading={isSubmitting}
                  fullWidth
                  type="submit"
                  size="large"
                >
                  Login
                </LoadingButton>
              </Grid>
              <Grid marginTop={'16px'} textAlign={'center'} item xs={12}>
                <Link href="/u/forgot-password">Forgot password?</Link>
              </Grid>
              <Grid margin={'32px 0'} item xs={12}>
                <Divider>
                  <Typography sx={{ color: '#C7C7C7' }}>or you can login with</Typography>
                </Divider>
              </Grid>
              {process.env.REACT_APP_SOCIAL_LOGIN_ENABLED === 'true' && (
                <Box sx={{ display: 'flex', gap: '8px' }}>
                  <MicrosoftButton />
                  <FacebookButton />
                  <LinkedinButton />
                  <GoogleButton />
                </Box>
              )}
              <Grid margin={'32px 0'} item xs={12}>
                <Divider>
                  <Typography sx={{ color: '#C7C7C7' }}>or</Typography>
                </Divider>
              </Grid>
              <Grid marginTop={'16px'} item xs={12}>
                <LoadingButton href={'/u/signup'} variant="outlined" fullWidth>
                  Create new account
                </LoadingButton>
              </Grid>
              <Grid marginTop={'2rem'} textAlign={'right'} item xs={12}>
                <Typography fontSize={'0.8rem'} color={'gray'}>
                  Version 2.45.0 (build u435.b533)
                </Typography>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}

export default Signin
