import React, { useContext, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import ImageListItemBar from '@mui/material/ImageListItemBar'
import { GalleryType, StoryType } from '../../../lib/types'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import ImageViewer from '../../../components/ImageViewer'
import { Button } from '@mui/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import CircularProgress from '@mui/material/CircularProgress'
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone'
import api from '../../../lib/api'
import { SimpleSnackbarContext } from '../../../components/SimpleSnackbar'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import ListSubheader from '@mui/material/ListSubheader'
import TextField from '@mui/material/TextField'

interface iPhotos {
  list: StoryType[]
  isEditor?: boolean
}

const Photos: React.FC<iPhotos> = ({ list, isEditor }) => {
  const [gallery, setGallery] = useState<(GalleryType & { storyId: string; storyTitle: string })[]>([])
  const [current, setCurrent] = useState<number | null>(null)
  const [progress, setProgress] = useState<{ [key: string]: number }>({})
  const { actions: snackActions } = useContext(SimpleSnackbarContext)
  const storyListForSelect: { id: string; title: string }[] = list.map(story => {
    return {
      id: story.id,
      title: story.title
    }
  })
  const [selectedStoryId, setSelectedStoryId] = useState(storyListForSelect.length > 0 ? storyListForSelect[0].id : '')

  useEffect(() => {
    if (list && list.length > 0) {
      list.forEach(story =>
        story.galleryEntries.forEach(g => {
          setGallery(old => [...old, { ...g, storyId: story.id, storyTitle: story.title }])
        })
      )
    }
  }, [list])

  const onDrop: <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void =
    async acceptedFiles => {
      for (const file of acceptedFiles) {
        try {
          const image = await api.uploadImage(file, event => {
            setProgress(old => ({ ...old, [file.name]: Math.round((event.loaded * 100) / event.total) }))
          })
          setProgress(old => {
            delete old[file.name]
            return old
          })
          const newPhoto = (await api.createGallery(selectedStoryId, image.id)) as GalleryType & {
            storyId: string
            storyTitle: string
          }
          newPhoto.storyId = selectedStoryId
          newPhoto.storyTitle = storyListForSelect.find(story => story.id === selectedStoryId)
            ? storyListForSelect.find(story => story.id === selectedStoryId)?.title || ''
            : ''

          setGallery(value => [newPhoto, ...value])
        } catch (error) {
          // @ts-ignore
          const { data } = error.response
          if (data.code === '1024') {
            snackActions.open('User Storage quota exceeded.')
          }
        }
      }
    }
  const { getRootProps, getInputProps, open } = useDropzone({ onDrop, noClick: true, accept: 'image/png, image/jpeg' })
  return (
    <Box
      component={Paper}
      sx={{
        width: '100%',
        display: 'flex',
        padding: 3,
        flexFlow: 'column'
      }}
    >
      {current !== null && (
        <ImageViewer
          images={gallery}
          onClose={() => {
            setCurrent(null)
          }}
          index={current}
        />
      )}
      {isEditor && (
        <>
          <Box>
            <Typography sx={{ marginBottom: 3 }} gutterBottom>
              Create a photo gallery to accompany and enhance your collection.
            </Typography>
            <Typography marginBottom={3}>Select a Story to add photos:</Typography>
            <TextField
              key={'selectedStory'}
              name={'selectedStory'}
              fullWidth
              select
              label={'Story title'}
              margin="dense"
              placeholder="Select a subject type for your collection"
              value={storyListForSelect.find(story => story.id === selectedStoryId)?.id ?? ''}
              onChange={event => setSelectedStoryId(event.target.value)}
            >
              {storyListForSelect.length > 0 ? (
                storyListForSelect.map(story => (
                  <MenuItem key={story.id} value={story.id}>
                    {story.title}
                  </MenuItem>
                ))
              ) : (
                <ListSubheader>You have no stories.</ListSubheader>
              )}
            </TextField>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Button variant="outlined" onClick={open} sx={{ mt: 1, mr: 1 }}>
                Upload pictures
              </Button>
            </div>
            <List>
              {Object.entries(progress).map(([k, v]) => (
                <ListItem key={k}>
                  <ListItemText primary={k} />
                  <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                    <CircularProgress variant="determinate" value={v} />
                    <Box
                      sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <Typography variant="caption" component="div" color="text.secondary">
                        {`${Math.round(v || 0)}%`}
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
              ))}
            </List>
          </Box>
        </>
      )}
      <Divider sx={{ margin: '24px 0' }} />
      {gallery.length ? (
        <ImageList variant="masonry" cols={3} gap={8}>
          {gallery.map((photo, index) => (
            <ImageListItem key={photo.id}>
              <img
                style={{
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setCurrent(index)
                }}
                src={`${photo.image.url}`}
                srcSet={`${photo.image.url}`}
                alt={photo.image.originalName}
                loading="lazy"
              />
              <ImageListItemBar
                position="below"
                subtitle={
                  <Link href={`/a/stories/${photo.storyId}`} variant="caption">
                    {photo.storyTitle}
                  </Link>
                }
                title={photo.image.originalName}
                actionPosition="right"
              />
            </ImageListItem>
          ))}
        </ImageList>
      ) : (
        <Typography sx={{ padding: '16px 0px' }} align="center">
          No images here.
        </Typography>
      )}
    </Box>
  )
}

export default Photos
