import React, { createContext, useContext, ReactNode } from 'react'
import Dexie from 'dexie'

// Defina a interface do arquivo
interface FileEntry {
  id: string
  file: Blob
}

// Tamanho do chunk em bytes (por exemplo, 1 MB)
const CHUNK_SIZE = 1024 * 1024

// Função para converter File em Blob em chunks
const fileToBlobChunks = (file: File): Promise<Blob[]> => {
  return new Promise((resolve, reject) => {
    const chunks: Blob[] = []
    const reader = new FileReader()
    let offset = 0
    const readNextChunk = () => {
      const chunk = file.slice(offset, offset + CHUNK_SIZE)
      reader.readAsArrayBuffer(chunk)
    }

    reader.onloadend = () => {
      if (reader.result) {
        chunks.push(new Blob([reader.result], { type: file.type }))
        offset += CHUNK_SIZE

        if (offset < file.size) {
          readNextChunk()
        } else {
          resolve(chunks)
        }
      } else {
        reject(new Error('Failed to convert file to Blob'))
      }
    }

    reader.onerror = error => {
      reject(error)
    }

    readNextChunk()
  })
}

// Crie uma instância do Dexie e defina o esquema do banco de dados
class FileStorageDB extends Dexie {
  files: Dexie.Table<FileEntry, string>

  constructor() {
    super('FileStorageDB')
    this.version(1).stores({
      files: 'id, file'
    })
    this.files = this.table('files')
  }
}

const db = new FileStorageDB()

// Defina a interface do contexto
interface FileStorageContextProps {
  insertFile: (id: string, file: File) => Promise<void>
  getFile: (id: string) => Promise<File | null>
  removeFile: (id: string) => Promise<void>
}

// Crie um contexto para o FileStorage
const FileStorageContext = createContext<FileStorageContextProps | undefined>(undefined)

export const FileStorageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const insertFile = async (id: string, file: File) => {
    try {
      console.time('fileToBlobChunks')
      console.log('Starting insertFile: ', id)
      const fileChunks = await fileToBlobChunks(file)
      console.timeEnd('fileToBlobChunks')
      console.log('File converted to Blob chunks: ', id)

      console.time('db.put')
      for (const chunk of fileChunks) {
        await db.files.put({ id: `${id}_${fileChunks.indexOf(chunk)}`, file: chunk })
      }
      console.timeEnd('db.put')
      console.log('Recording saved locally: ', id)
    } catch (error) {
      console.error('Error to persist recording locally:', error)
    }
  }

  const getFile = async (id: string): Promise<File | null> => {
    try {
      console.log('Getting file: ', id)
      const chunks = await db.files.where('id').startsWith(id).toArray()
      if (chunks.length === 0) return null

      const file = new Blob(
        chunks.map(chunk => chunk.file),
        { type: chunks[0].file.type }
      )
      console.log('File retrieved: ', id)
      return new File([file], id, { type: file.type })
    } catch (error) {
      console.error('Error getting the file:', error)
      return null
    }
  }

  const removeFile = async (id: string) => {
    try {
      console.log('Removing file: ', id)
      await db.files.where('id').startsWith(id).delete()
      console.log('File removed: ', id)
    } catch (error) {
      console.error('Error removing the file:', error)
    }
  }

  return (
    <FileStorageContext.Provider value={{ insertFile, getFile, removeFile }}>{children}</FileStorageContext.Provider>
  )
}

// Hook para usar o FileStorage
export const useFileStorage = (): FileStorageContextProps => {
  const context = useContext(FileStorageContext)
  if (context === undefined) {
    throw new Error('useFileStorage deve ser usado dentro de um FileStorageProvider')
  }
  return context
}
