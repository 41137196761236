import { useContext } from 'react'
import { UserContext } from '../UserProvider'
import { TeamMemberRoleEnum } from '../../lib/types'

export function usePermissions() {
  const { user, member } = useContext(UserContext)
  const creatorOrAbove = [TeamMemberRoleEnum.CREATOR, TeamMemberRoleEnum.MANAGER, TeamMemberRoleEnum.ADMINISTRATOR]

  const isNotTeamMember = !user?.tenant && !user?.userOfTenant
  const isTeamMemberButIsInOwnLibrary = user?.userOfTenant && !member
  const isEditor =
    isNotTeamMember ||
    isTeamMemberButIsInOwnLibrary ||
    (user?.tenant && !member) ||
    (user.userOfTenant && member?.role && member?.role !== TeamMemberRoleEnum.VIEWER)

  const isCreator =
    isNotTeamMember ||
    isTeamMemberButIsInOwnLibrary ||
    (user?.tenant && !member) ||
    (user.userOfTenant && member?.role && creatorOrAbove.includes(member?.role))

  const isTeamManager =
    isNotTeamMember ||
    isTeamMemberButIsInOwnLibrary ||
    (user?.tenant && !member) ||
    member?.role === TeamMemberRoleEnum.ADMINISTRATOR ||
    member?.role === TeamMemberRoleEnum.MANAGER

  const isAdministrator =
    isNotTeamMember ||
    isTeamMemberButIsInOwnLibrary ||
    (user?.tenant && !member) ||
    member?.role === TeamMemberRoleEnum.ADMINISTRATOR

  const isTenant = user?.userOfTenant || user?.tenant

  return { isEditor, isCreator, isTeamManager, isAdministrator, isTenant }
}
