import React, { useContext } from 'react'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { CollectionThemeProps } from '../../../../../lib/types'
import { PublicRequestsDialogContext } from '../../../../../components/PublicRequestsDialog'

const RequestsModal: React.FC<{ collectionTheme: CollectionThemeProps }> = ({ collectionTheme }) => {
  const { actions } = useContext(PublicRequestsDialogContext)
  const items = [
    {
      id: 1,
      title: 'Invite Request Title',
      description: 'Designated area for describing the instructions for someone to contribute with your collection'
    },
    {
      id: 2,
      title: 'Invite Request Title',
      description: 'Designated area for describing the instructions for someone to contribute with your collection'
    },
    {
      id: 3,
      title: 'Invite Request Title',
      description: 'Designated area for describing the instructions for someone to contribute with your collection'
    }
  ]

  return (
    <Box
      sx={{
        maxWidth: '800px',
        border: '1px gray dashed',
        backgroundColor: collectionTheme.requestsBackgroundColor,
        padding: '16px',
        borderRadius: '8px'
      }}
    >
      <Typography
        sx={{
          width: '100%',
          fontSize: '24px',
          borderBottom: '1px solid gray',
          backgroundColor: collectionTheme.requestsBackgroundColor,
          color: collectionTheme.requestsTextColor,
          position: 'relative',
          fontFamily: collectionTheme.requestsTextFontFamily
        }}
      >
        Add your voice
        <IconButton
          aria-label="close"
          onClick={actions.close}
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            color: collectionTheme.requestsTextColor
          }}
        >
          <CloseIcon />
        </IconButton>
      </Typography>
      <Box sx={{ backgroundColor: collectionTheme.requestsBackgroundColor }}>
        <Grid
          container
          sx={{
            padding: '24px'
          }}
          spacing={4}
        >
          <Grid item xs={12}>
            <Typography
              variant="h6"
              sx={{ color: collectionTheme.requestsTextColor, fontFamily: collectionTheme.requestsTextFontFamily }}
            >
              Want to share your story? Select a story request and record your own experience!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              {items.map(invite => (
                <Box
                  key={invite.id}
                  sx={{
                    minHeight: '120px',
                    width: '100%',
                    borderRadius: '20px',
                    marginBottom: 3,
                    display: 'flex',
                    alignItems: 'center',
                    flexFlow: { xs: 'column', md: 'row' },
                    backgroundColor: collectionTheme.cardBackgroundColor
                  }}
                >
                  <Button
                    sx={{
                      borderRadius: '20px',
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: { xs: 'column', md: 'row' },
                      padding: 2,
                      ':hover': { backgroundColor: collectionTheme.cardHoverColor }
                    }}
                  >
                    <Box
                      sx={{
                        flex: { xs: 'unset', md: '1' },
                        width: { xs: '100%', md: 'unset' },
                        paddingX: '8px'
                      }}
                    >
                      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                        <Typography
                          sx={{
                            color: collectionTheme.cardTextColor ?? 'white',
                            fontFamily: collectionTheme.requestsTextFontFamily
                          }}
                        >
                          <b>{invite.title}</b>
                        </Typography>
                      </Box>
                    </Box>
                    <div
                      className={'text-overflow-box'}
                      style={{
                        color: collectionTheme.cardTextColor ?? 'white',
                        fontFamily: collectionTheme.requestsTextFontFamily
                      }}
                    >
                      {invite.description}
                    </div>
                  </Button>
                </Box>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default RequestsModal
