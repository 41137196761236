import React, { useContext, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { FileType, StoryType } from '../../../lib/types'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import List from '@mui/material/List'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone'
import api from '../../../lib/api'
import { SimpleSnackbarContext } from '../../../components/SimpleSnackbar'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'

interface iFiles {
  list: StoryType[]
  isEditor: boolean
}

const Files: React.FC<iFiles> = ({ list, isEditor }) => {
  const [stories, setStories] = useState<{ id: string; title: string; files: FileType[] }[]>([])
  const [progress, setProgress] = useState<{ [key: string]: number }>({})
  const { actions: snackActions } = useContext(SimpleSnackbarContext)
  const [selectedStoryId, setSelectedStoryId] = useState(list.length > 0 ? list[0].id : '')
  const onDrop: <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void =
    async acceptedFiles => {
      for (const file of acceptedFiles) {
        try {
          const uploadedFile = await api.uploadFile(selectedStoryId, file, event => {
            setProgress(old => ({ ...old, [file.name]: Math.round((event.loaded * 100) / event.total) }))
          })
          setProgress(old => {
            delete old[file.name]
            return old
          })
          setStories(oldStories => {
            return oldStories.map(story => {
              if (story.id === selectedStoryId) {
                const updatedFiles = [...story.files, uploadedFile]
                return { ...story, files: updatedFiles }
              }
              return story
            })
          })
        } catch (error) {
          // @ts-ignore
          const { data } = error.response
          if (data.code === '1024') {
            snackActions.open('User Storage quota exceeded.')
          }
        }
      }
    }

  const { getRootProps, getInputProps, open } = useDropzone({ onDrop, noClick: true })

  useEffect(() => {
    if (list && list.length > 0) {
      const updatedStories = list
        .filter(story => !!story.files.length)
        .map(story => ({
          id: story.id,
          title: story.title,
          files: [...story.files]
        }))

      setStories(updatedStories)
    }
  }, [list])

  return (
    <Box
      component={Paper}
      sx={{
        width: '100%',
        display: 'flex',
        padding: 3,
        flexFlow: 'column'
      }}
    >
      {isEditor && (
        <>
          <Box>
            <Typography sx={{ marginBottom: 3 }} gutterBottom>
              Add files to enhance your collection.
            </Typography>
            <Typography marginBottom={3}>Select a Story to add files:</Typography>
            <TextField
              key={'selectedStory'}
              name={'selectedStory'}
              fullWidth
              select
              label={'Story title'}
              margin="dense"
              placeholder="Select a subject type for your collection"
              value={list.find(story => story.id === selectedStoryId)?.id ?? ''}
              onChange={event => setSelectedStoryId(event.target.value)}
            >
              {list.length > 0 ? (
                list.map(story => (
                  <MenuItem key={story.id} value={story.id}>
                    {story.title}
                  </MenuItem>
                ))
              ) : (
                <ListSubheader>You have no stories.</ListSubheader>
              )}
            </TextField>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Button variant="outlined" onClick={open} sx={{ mt: 1, mr: 1 }}>
                Upload files
              </Button>
            </div>
            <List>
              {Object.entries(progress).map(([k, v]) => (
                <ListItem key={k}>
                  <ListItemText primary={k} />
                  <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                    <CircularProgress variant="determinate" value={v} />
                    <Box
                      sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <Typography variant="caption" component="div" color="text.secondary">
                        {`${Math.round(v || 0)}%`}
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
              ))}
            </List>
          </Box>
        </>
      )}

      <Divider sx={{ margin: '24px 0' }} />
      <Box
        sx={{
          marginBottom: 3
        }}
      >
        {stories && stories.length ? (
          <List>
            {stories
              .filter(story => !!story.files.length)
              .map(story => (
                <li key={`section-${story.id}`}>
                  <ul>
                    <ListSubheader>
                      <Link href={`/a/stories/${story.id}`}>{story.title}</Link>
                    </ListSubheader>
                    {story.files.map(file => (
                      <ListItem key={file.id}>
                        <ListItemText primary={<Link href={file.url}>{file.originalName}</Link>} />
                      </ListItem>
                    ))}
                  </ul>
                </li>
              ))}
          </List>
        ) : (
          <Typography sx={{ padding: '16px 0px' }} align="center">
            No files here.
          </Typography>
        )}
      </Box>
    </Box>
  )
}

export default Files
