import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Skeleton from '@mui/material/Skeleton'
import ShareIcon from '@mui/icons-material/Share'
import CardImage from './CardImage'
import { AssetTypes, MediaType, UserType } from '../../lib/types'
import HeadphonesIcon from '@mui/icons-material/Headphones'
import LocalMoviesIcon from '@mui/icons-material/LocalMovies'
import AuthorAvatar from '../AuthorAvatar'
import Tooltip from '@mui/material/Tooltip'
import useShareInvite from '../hooks/useShareInvite'
import api from '../../lib/api'
import LoadingButton from '@mui/lab/LoadingButton'

interface iCard {
  title: string
  subTitle: string
  thumbnail: string
  shareLink?: string
  preview?: string
  loading: boolean
  owner?: UserType
  type?: MediaType | 'COLLECTION'
  numberOfPublishedStories?: number
  assetType?: string
  id?: string
}

const Card: React.FC<iCard> = ({
  preview,
  title = '',
  shareLink,
  loading,
  owner,
  type,
  thumbnail = '',
  subTitle = '',
  numberOfPublishedStories = 0,
  assetType = '',
  id
}) => {
  const fullName = `${owner?.firstName} ${owner?.lastName}`
  const handleShare = useShareInvite()

  const [isGettingSharedLink, setIsGettingSharedLink] = useState(false)
  const handleShareCollection = async () => {
    if (id) {
      setIsGettingSharedLink(true)
      try {
        const data = await api.getSharedLink(id)
        handleShare(undefined, true, data.sharedLink, false, false, false, '')
      } catch (e) {
        console.log(e)
      } finally {
        setIsGettingSharedLink(false)
      }
    }
  }

  const prefix =
    type === 'AUDIO' ? (
      <HeadphonesIcon />
    ) : type === 'VIDEO' ? (
      <LocalMoviesIcon />
    ) : type === 'COLLECTION' ? (
      <LocalMoviesIcon />
    ) : null

  return (
    <Box
      sx={{
        width: { xs: '85svw', sm: 416 },
        height: 402,
        backgroundColor: 'common.black',
        borderRadius: 2,
        marginRight: 3
      }}
    >
      <Box
        sx={{
          height: 251
        }}
      >
        {loading ? (
          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              width: '100%',
              height: '100%'
            }}
          />
        ) : (
          <CardImage preview={preview} thumbnail={thumbnail} type={type} />
        )}
      </Box>
      <Box
        sx={{
          height: 3,
          backgroundColor: 'primary.light'
        }}
      />
      <Box
        sx={{
          marginLeft: 2,
          marginRight: 2,
          marginTop: 3
        }}
      >
        <Box>
          {loading ? (
            <Skeleton animation="wave" variant="text" height={40} width="80%" component="div" />
          ) : (
            <Typography sx={{ fontWeight: 700, color: 'grey.A200' }} noWrap variant="h5" component="div">
              {title}
            </Typography>
          )}
          {loading ? (
            <Skeleton animation="wave" variant="text" height={20} width="40%" />
          ) : (
            <Typography sx={{ fontWeight: 700, color: 'grey.A400' }} noWrap variant="h6" gutterBottom component="div">
              {subTitle}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexFlow: 'row',
              alignItems: 'center',
              '& > *': {
                marginRight: 1
              }
            }}
          >
            {loading && <Skeleton animation="wave" variant="rectangular" width={24} height={24} />}
            <AuthorAvatar prefix={prefix} fullName={fullName} loading={loading} thumbnail={owner?.picture?.url} />
          </Box>
          {shareLink && (numberOfPublishedStories !== 0 || assetType === AssetTypes.story) && (
            <Tooltip arrow title="Click to Copy the Public Link to Share this Collection">
              <LoadingButton
                loading={isGettingSharedLink}
                onClick={e => {
                  e.stopPropagation()
                  if (assetType === AssetTypes.story) {
                    handleShare(undefined, true, shareLink, false, false, false, '')
                  } else {
                    handleShareCollection()
                  }
                }}
                color="secondary"
              >
                <ShareIcon />
              </LoadingButton>
            </Tooltip>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default Card
