import React, { useContext, useEffect, useRef } from 'react'
import { SearchResultType, TeamMemberRoleEnum } from '../../../../lib/types'
import useInfiniteScroll from '../../../../components/hooks/useInfiniteScrool'
import { usePageableRequest } from '../../../../components/hooks/usePageableRequest'
import Grid from '@mui/material/Grid'
import Card from '../../../../components/Card'
import CollectionCard from '../../../../components/CollectionCard'
import { useHistory } from 'react-router-dom'
import api from '../../../../lib/api'
import { Box, Typography } from '@mui/material'
import { CreateEventContext } from '../../../../components/CreateEvent'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { UserContext } from '../../../../components/UserProvider'

interface iCollectionsSlider {
  q: string
}

const CollectionsSlider: React.FC<iCollectionsSlider> = ({ q }) => {
  const history = useHistory()
  const { loading, items, hasNextPage, error, loadMore, reload } = usePageableRequest<SearchResultType>({
    size: 15,
    q: q,
    request: api.searchCollections
  })
  const { store: createEventStore } = useContext(CreateEventContext)
  const { member } = useContext(UserContext)

  // @ts-ignore
  if (error?.status === 401) {
    history.push('/u/signin')
  }
  useEffect(() => {
    if (createEventStore.reload) {
      reload()
    }
  }, [createEventStore.reload])

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: !!error,
    rootMargin: '0px 0px 400px 0px'
  })

  const gridRef = useRef<HTMLDivElement>(null)

  const scrollLeft = () => {
    if (!gridRef.current) return
    gridRef.current.scrollBy({
      left: -gridRef.current.clientWidth,
      behavior: 'smooth'
    })
  }

  const scrollRight = () => {
    if (!gridRef.current) return
    gridRef.current.scrollBy({
      left: gridRef.current.clientWidth,
      behavior: 'smooth'
    })
  }

  const permittedCollections = member?.permissions?.map(p => p?.collectionId) ?? []
  const filteredItems =
    member?.role === TeamMemberRoleEnum.VIEWER || member?.role === TeamMemberRoleEnum.EDITOR
      ? items.filter(item => permittedCollections.includes(item.id))
      : items

  return (
    <>
      <Typography variant="h5" sx={{ fontWeight: 700 }} gutterBottom>
        Collections
      </Typography>
      <Box sx={{ display: 'flex', gap: '16px' }}>
        <Box sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'center' }}>
          <IconButton onClick={scrollLeft}>
            <ChevronLeftIcon />
          </IconButton>
        </Box>

        <Grid
          ref={gridRef}
          wrap="nowrap"
          sx={{
            overflowX: 'auto',
            '::-webkit-scrollbar': {
              display: 'none'
            }
          }}
          container
        >
          {filteredItems.map(item => (
            <CollectionCard key={item.id} data={item} />
          ))}
          {hasNextPage && (
            <Grid>
              <Card loading={true} title={''} subTitle={''} thumbnail={''} preview={''} />
              <div ref={sentryRef} />
            </Grid>
          )}
        </Grid>
        <Box sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'center' }}>
          <IconButton onClick={scrollRight}>
            <ChevronRightIcon />
          </IconButton>
        </Box>
      </Box>
    </>
  )
}

export default CollectionsSlider
