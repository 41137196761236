import { VideoChapter } from './BookmarkVideoJs'

export const createChapters = (chapters: VideoChapter[]) => {
  if (!chapters || !chapters.length) return null
  const chaps = chapters.map(chapter => {
    return `
        ${chapter.begin} --> ${chapter.end}
        ${chapter.title}
      `
  })

  const vttContent = `WEBVTT

      ${chaps.join('\n\n')}
    `

  const vttBlob = new Blob([vttContent], { type: 'text/vtt' })

  const vttURL = URL.createObjectURL(vttBlob)
  return { kind: 'chapters', src: vttURL }
}
