import React from 'react'
import Box from '@mui/material/Box'

const Component = () => {
  const url = `https://login.microsoftonline.com/${process.env.REACT_APP_IOC_TENANT_ID}/oauth2/v2.0/authorize?client_id=${process.env.REACT_APP_MICROSOFT_APP_ID}&scope=user.read%20openid%20profile%20offline_access&redirect_uri=https%3A%2F%2Fioc.rakonto.io%2Fu%2Fioc&client-request-id=767ffad9-7496-4e3c-afc3-3c3ff952d79c&response_mode=fragment&response_type=code&x-client-SKU=msal.js.browser&x-client-VER=2.38.1&client_info=1&code_challenge=${process.env.REACT_APP_IOC_CODE_CHALLENGE}&code_challenge_method=S256`

  const handleClick = () => {
    window.open(url, '_blank')
  }
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start'
      }}
    >
      <Box
        onClick={handleClick}
        sx={{
          backgroundColor: 'white',
          height: '40px',
          width: '40px',
          borderRadius: '8px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer'
        }}
      >
        <img width={'28px'} height={'28px'} src={'/images/Microsoft_logo.png'} alt="Facebook" />
      </Box>
    </div>
  )
}

export default Component
