import React, { useContext, useEffect, useState } from 'react'
import { InviteType, MediaType, OrganizationType, QuizInviteQuestionType } from '../../../../lib/types'
import { SimpleSnackbarContext } from '../../../../components/SimpleSnackbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { FormikProvider, FormikValues, useFormik } from 'formik'
import api from '../../../../lib/api'
import { schema } from './schema'
import Form from './Form'
import Box from '@mui/material/Box'
import { UserContext } from '../../../../components/UserProvider'
import ConfigureAllForm from './ConfigureAllForm'

type initialValuesType = {
  description: string
  instructions: string
  recordingType: MediaType | 'NONE'
  expire: Date
  size: number
  title: string
  allowExpire: boolean
  allowOrganization: boolean
  callToActionInstructions: string
  callToActionButtonLabel: string
  callToAction: string
  hardStop: boolean
  template?: boolean
}

const QuestionnaireQuestion: React.FC<{
  invite: InviteType
  question?: QuizInviteQuestionType
  isConfigureAll?: boolean
  questionnaireId: string
  allowCallToAction?: boolean
  onClose: (invite?: InviteType | null) => void
}> = ({ invite, onClose, isConfigureAll, questionnaireId, allowCallToAction, question }) => {
  const { actions: snackActions } = useContext(SimpleSnackbarContext)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const { user, member } = useContext(UserContext)
  const [organization, setOrganization] = useState<OrganizationType | null>(null)

  const initialValues: initialValuesType = {
    description: invite.description,
    instructions: invite.instructions,
    recordingType: invite.requestedMediaType || 'NONE',
    expire: invite.dueAt,
    size: invite.requestedMediaLength,
    title: invite.title,
    allowExpire: !!invite.dueAt,
    allowOrganization: !!invite.organization,
    callToActionInstructions: invite.callToActionInstructions,
    callToActionButtonLabel: invite.callToActionButtonLabel,
    callToAction: invite.callToAction,
    hardStop: invite.hardStop,
    template: invite.template
  }

  const onSubmit = async (values: FormikValues) => {
    let orgId
    if (!user.tenant && !user.userOfTenant) {
      orgId = user.organizations.length ? user.organizations[0].id : null
    } else {
      orgId = organization ? organization.id : null
    }
    if (isConfigureAll && questionnaireId) {
      try {
        const newInvite = await api.bulkEditQuestionnaire(questionnaireId, {
          storyId: '',
          collectionId: invite.collectionId,
          title: values!.title,
          description: values!.description,
          instructions: undefined,
          organizationId: values.allowOrganization ? orgId : null,
          dueAt: values.allowExpire ? values!.expire : null,
          requestedMediaLength: Number(values!.size),
          requestedMediaType: values.recordingType === 'NONE' ? null : values.recordingType,
          callToActionInstructions: values?.callToActionInstructions,
          callToActionButtonLabel: values?.callToActionButtonLabel,
          callToAction: values?.callToAction,
          hardStop: values?.hardStop,
          template: false,
          requestFiles: values?.requestFiles,
          requestImages: values?.requestImages
        })

        onClose(newInvite)

        snackActions.open('Invite updated.')
      } catch (error) {
        // @ts-ignore
        const { data, message } = error.response
        if (data?.code) {
          snackActions.open(message)
          throw error
        }
        snackActions.open('Something was wrong! please try again.')
        throw error
      }
    } else {
      try {
        const newInvite = await api.updateInvite(invite.id, {
          storyId: '',
          collectionId: invite.collectionId,
          organizationId: values.allowOrganization ? orgId : null,
          title: values!.title,
          description: values!.description,
          instructions: values!.instructions,
          dueAt: values.allowExpire ? values!.expire : null,
          requestedMediaLength: Number(values!.size),
          requestedMediaType: values.recordingType === 'NONE' ? null : values.recordingType,
          callToActionInstructions: values?.callToActionInstructions,
          callToActionButtonLabel: values?.callToActionButtonLabel,
          callToAction: values?.callToAction,
          hardStop: values?.hardStop,
          template: false,
          requestFiles: values?.requestFiles,
          requestImages: values?.requestImages
        })

        if (question) {
          await api.updateQuestionnaireQuestion({
            id: question.id,
            recommendation: values!.instructions,
            questionnaireTemplateId: question.questionnaireTemplateId,
            question: values!.description,
            number: question.number,
            title: values!.title,
            collectionInviteId: newInvite.id,
            collectionInvite: newInvite,
            questionnaireId: questionnaireId
          })
        }

        onClose(newInvite)

        snackActions.open('Invite updated.')
      } catch (error) {
        // @ts-ignore
        const { data, message } = error.response
        if (data?.code) {
          snackActions.open(message)
          throw error
        }
        snackActions.open('Something was wrong! please try again.')
        throw error
      }
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    validateOnBlur: true,
    onSubmit
  })

  useEffect(() => {
    formik.handleReset(initialValues)
  }, [])

  const fetch = async () => {
    try {
      const { content } = await api.getOrganizations()
      if (content.length) setOrganization(content[0])
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    fetch()
  }, [])

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="md"
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose()
        }
      }}
      open
    >
      <DialogTitle>
        {isConfigureAll ? 'Configure parameters for all questions' : 'Configure parameters of the question'}
        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ width: '100%', marginY: 3, minHeight: 328 }}>
          <FormikProvider value={formik}>
            {isConfigureAll ? (
              <ConfigureAllForm allowCallToAction={true} />
            ) : (
              <Form allowCallToAction={allowCallToAction} />
            )}
          </FormikProvider>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => onClose()} sx={{ mt: 1, mr: 1 }}>
          Close
        </Button>
        <LoadingButton
          variant="contained"
          loading={formik.isSubmitting}
          disabled={!formik.isValid}
          onClick={() => formik.handleSubmit()}
          sx={{ mt: 1, mr: 1 }}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default QuestionnaireQuestion
