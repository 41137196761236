import React, { useState, createContext } from 'react'
import Component from './Component'
import { CollectionTheme, CollectionType } from '../../lib/types'

// @ts-ignore
export const CreateEventContext = createContext<{
  actions: {
    open: (callback: (collection: CollectionType | null) => void, title?: string, themes?: CollectionTheme[]) => void
    close: (collection?: CollectionType) => void
  }
  store: {
    reload: boolean
  }
}>({
  // @ts-ignore
  actions: {},
  store: { reload: false }
})

export const CreateEventProvider: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [initialTitle, setInitialTitle] = useState<string>('')

  const [callBack, setCallBack] = useState<((collection: CollectionType | null) => void) | undefined>(undefined)
  const [reload, setReload] = useState(false)
  const [thms, setThemes] = useState<CollectionTheme[]>([])

  const open = (callback: (collection: CollectionType | null) => void, title?: string, themes?: CollectionTheme[]) => {
    if (reload) {
      setReload(false)
    }
    setIsOpen(true)
    if (title) setInitialTitle(title)
    if (themes) setThemes(themes)
    setCallBack(() => callback)
  }

  const close = (collection?: CollectionType) => {
    setReload(true)
    setIsOpen(false)
    setInitialTitle('')
    if (callBack) callBack(collection || null)
    setCallBack(undefined)
  }

  return (
    <CreateEventContext.Provider
      value={{
        actions: {
          open,
          close
        },
        store: {
          reload: reload
        }
      }}
    >
      {isOpen && <Component initialTitle={initialTitle} themes={thms} />}
      {children}
    </CreateEventContext.Provider>
  )
}
