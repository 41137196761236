import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { UpdateQuestionnairesDialogContext } from './Context'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { QuestionnaireType } from '../../lib/types'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { capitalize } from '../Capitalize'
import { SimpleDialogContext } from '../SimpleDialog'

interface iUpdateQuestionnaireQuestionsDialogComponent {
  questionnaires: QuestionnaireType[]
}

const UpdateQuestionnaireQuestionsDialog: React.FC<iUpdateQuestionnaireQuestionsDialogComponent> = ({
  questionnaires
}) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const { actions, store } = useContext(UpdateQuestionnairesDialogContext)
  const { actions: dialogActions } = useContext(SimpleDialogContext)
  const [selectedQuestionnairesIds, setSelectedQuestionnairesIds] = useState<string[]>([])
  const [checkAll, setCheckAll] = useState(false)

  useEffect(() => {
    if (questionnaires.length === 0) return
    if (selectedQuestionnairesIds.length === 0 && checkAll) {
      setCheckAll(false)
    }
    if (selectedQuestionnairesIds.length === questionnaires.length && !checkAll) {
      setCheckAll(true)
    }
  }, [selectedQuestionnairesIds])

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = event.target.value

    setSelectedQuestionnairesIds(prevSelectedIds => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter(selectedId => selectedId !== id)
      } else {
        return [...prevSelectedIds, id]
      }
    })
  }
  const handleCheckAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setCheckAll(true)
      setSelectedQuestionnairesIds(questionnaires.map(q => q.id))
    } else {
      setCheckAll(false)
      setSelectedQuestionnairesIds([])
    }
  }

  const handleUpdateTemplate = async () => {
    actions.callback && actions.callback(selectedQuestionnairesIds)
    setSelectedQuestionnairesIds([])
    actions.close(true)
  }
  const handleUpdateQuestionnaires = () => {
    dialogActions.open(
      'Update Related Questionnaires',
      <>
        <Typography>Are you sure you want to update the related questionnaires? This cannot be undone.</Typography>
      </>,
      {
        okText: 'Yes, update questionnaires as well',
        showOk: true,
        cancelText: `No, I'm not sure`
      },
      async value => {
        if (value) {
          await handleUpdateTemplate()
        }
      }
    )
  }
  const handleSubmit = async () => {
    if (selectedQuestionnairesIds.length > 0) {
      handleUpdateQuestionnaires()
    } else {
      await handleUpdateTemplate()
    }
  }

  return (
    <Dialog fullScreen={fullScreen} maxWidth="md" fullWidth open={store.isOpen} onClose={() => actions.close()}>
      <DialogTitle id="alert-dialog-title">
        Update Questionnaire Template and Related Questionnaires
        <IconButton
          aria-label="close"
          onClick={() => {
            setSelectedQuestionnairesIds([])
            actions.close()
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography>
          Below, you will find a list of questionnaires that are currently based on this template.
          <br />
          Select any of then you want to apply those changes (optional):
        </Typography>
        <Box sx={{ paddingY: '12px', display: 'flex', flexDirection: 'column' }}>
          <FormControlLabel
            control={<Switch checked={checkAll} onChange={handleCheckAll} />}
            label={checkAll ? 'Uncheck all' : 'Check all'}
          />
          {questionnaires.length > 0 &&
            questionnaires.map(questionnaire => {
              return (
                <FormControlLabel
                  key={questionnaire.id}
                  control={
                    <Switch
                      checked={selectedQuestionnairesIds.includes(questionnaire.id)}
                      onChange={handleCheckboxChange}
                      value={questionnaire.id}
                    />
                  }
                  label={capitalize(questionnaire.title + ' - ' + questionnaire.language)}
                />
              )
            })}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={() => {
            setSelectedQuestionnairesIds([])
            actions.close()
          }}
        >
          Cancel
        </Button>
        <Button variant="outlined" onClick={handleSubmit}>
          Update template
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UpdateQuestionnaireQuestionsDialog
