import React, { useEffect } from 'react'
import api from '../../../lib/api'
import Cookies from 'js-cookie'
import { SimpleSnackbarContext } from '../../../components/SimpleSnackbar'
import { useHistory } from 'react-router-dom'
import { parse } from 'qs'
import useScript from 'react-script-hook'
import Box from '@mui/material/Box'
import handleTenantSignIn from '../../../components/HandleTenantSignIn'

const Component = React.memo(() => {
  const history = useHistory()
  const { returnUrl } = parse(location.search, { ignoreQueryPrefix: true })
  const { actions: snackActions } = React.useContext(SimpleSnackbarContext)
  const [ready, error] = useScript({
    src: `https://connect.facebook.net/en_US/sdk.js?t=${Date.now()}`,
    async: true,
    defer: true,
    crossorigin: 'anonymous',
    id: 'facebook-jssdk'
  })

  const callback = async (accessToken: string) => {
    try {
      const userInfo = await api.signinFacebook({ token: accessToken })
      Cookies.set('token', userInfo.token)
      Cookies.set('backupToken', userInfo.token)
      if (userInfo.user) {
        await handleTenantSignIn(userInfo.user)
      }
      if (returnUrl) {
        await history.push(returnUrl as string)
        return
      }
      if (!userInfo.user.onboardAt) {
        history.push('/a/onboarding')
        return
      }
      history.push('/a/my-library')
    } catch (error) {
      snackActions.open('Something was wrong! please try again.')
    }
  }

  const handleFacebookLoginClick = () => {
    // @ts-ignore
    if (window.FB) {
      // @ts-ignore
      window.FB.getLoginStatus((response: any) => {
        if (response.status !== 'connected') {
          // @ts-ignore
          window.FB.login((loginResponse: any) => {
            if (loginResponse.authResponse) {
              callback(loginResponse.authResponse.accessToken)
            }
          })
        } else {
          callback(response.authResponse.accessToken)
        }
      })
    }
  }

  useEffect(() => {
    // @ts-ignore
    if (!window.FB) {
      return
    }

    // @ts-ignore
    window.FB.init({
      appId: process.env.REACT_APP_FACEBOOK_APP_ID || '',
      autoLogAppEvents: true,
      xfbml: true,
      version: 'v14.0'
    })

    // @ts-ignore
    window.fbcallback = function () {
      // @ts-ignore
      window.FB.getLoginStatus((response: any) => {
        if (response.status === 'connected') {
          callback(response.authResponse.accessToken)
        }
      })
    }

    return () => {
      // @ts-ignore
      window.fbcallback = null
      // @ts-ignore
      window.FB = null
      // @ts-ignore
      window.document.getElementById('facebook-jssdk')?.remove()
    }
    // @ts-ignore
  }, [window.FB])

  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '40px', height: '40px' }}>
      {
        // @ts-ignore
        !error && window.FB && (
          <Box
            onClick={handleFacebookLoginClick}
            style={{
              cursor: 'pointer',
              width: '40px',
              height: '40px',
              borderRadius: '8px',
              backgroundColor: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <img width={'28px'} height={'28px'} src={'/images/2021_Facebook_icon.png'} alt="Facebook" />
          </Box>
        )
      }
    </div>
  )
})

export default Component
