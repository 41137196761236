import React, { useContext } from 'react'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { SimpleDialogContext } from '../../../../../components/SimpleDialog'
import Typography from '@mui/material/Typography'
import { CollectionThemeProps } from '../../../../../lib/types'

const SimpleDialog: React.FC<{ collectionTheme: CollectionThemeProps }> = ({ collectionTheme }) => {
  const { actions: simpleDialogActions } = useContext(SimpleDialogContext)

  const showSuccess = () => {
    simpleDialogActions.open(
      'Check your email!',
      'Thank you! We just sent you an email. Please open it and press the View Collection button.',
      { okText: 'Ok' },
      simpleDialogActions.close
    )
  }
  return (
    <Box
      sx={{
        padding: '16px',
        maxWidth: '800px',
        backgroundColor: collectionTheme.notificationBackgroundColor,
        position: 'relative',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: '16px'
      }}
    >
      <Box>
        <Typography
          sx={{
            fontSize: '1.25rem',
            fontWeight: '500',
            color: collectionTheme.notificationTextColor,
            fontFamily: collectionTheme.notificationTextFontFamily
          }}
        >
          Check your email!
        </Typography>
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: collectionTheme.notificationCloseIconColor
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box>
        <Box
          id="alert-dialog-description"
          sx={{ color: collectionTheme.notificationTextColor, fontFamily: collectionTheme.notificationTextFontFamily }}
        >
          Thank you! We just sent you an email. Please open it and press the View Collection button.
        </Box>
      </Box>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="outlined"
          sx={{
            color: collectionTheme.notificationCancelButtonColor,
            borderColor: collectionTheme.notificationCancelButtonColor,
            ':hover': {
              borderColor: collectionTheme.notificationCancelButtonColor,
              backgroundColor: collectionTheme.notificationCancelButtonHoverColor
            },
            fontFamily: collectionTheme.notificationTextFontFamily
          }}
        >
          Cancel
        </Button>
        <Button
          color="secondary"
          onClick={() => showSuccess()}
          sx={{
            color: collectionTheme.notificationConfirmButtonColor,
            ':hover': { backgroundColor: collectionTheme.notificationConfirmButtonHoverColor },
            fontFamily: collectionTheme.notificationTextFontFamily
          }}
        >
          Confirm
        </Button>
      </Box>
    </Box>
  )
}

export default SimpleDialog
