import React, { useContext, useState } from 'react'
import {
  CollectionThemeProps,
  EmailRecords,
  InviteType,
  QuestionnaireConsent,
  QuizInviteType
} from '../../../../../lib/types'
import api from '../../../../../lib/api'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import { useFormik } from 'formik'
import Button from '@mui/material/Button'
import schema from '../schema'
import { SimpleDialogContext } from '../../../../../components/SimpleDialog'
import { SimpleSnackbarContext } from '../../../../../components/SimpleSnackbar'
import Autocomplete from '@mui/material/Autocomplete'
import Link from '@mui/material/Link'
import Checkbox from '@mui/material/Checkbox'
import Box from '@mui/material/Box'

const Step1: React.FC<{
  questionnaire: QuizInviteType
  invite: InviteType
  user: { name: string; email: string; jobTitle: string; functionalArea: string }
  handleNext: (user: { name: string; email: string; jobTitle: string; functionalArea: string }) => void
  token: string
  quizInvite: QuizInviteType
  collectionTheme?: CollectionThemeProps
}> = ({ questionnaire, invite, handleNext, user, token, quizInvite, collectionTheme }) => {
  const [namePerson, setNamePerson] = useState<string[]>([])
  const [jobTitleList, setJobTitleList] = useState<string[]>([])
  const [functionalAreaList, setFunctionalAreaList] = useState<string[]>([])
  const [isTermsAccepted, setIsTermsAccepted] = useState(false)
  const { actions: simpleDialogActions } = useContext(SimpleDialogContext)
  const { actions: snackActions } = useContext(SimpleSnackbarContext)

  const getQueryParam = (param: string): string => {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get(param) || ''
  }

  // Definindo os valores iniciais do formulário
  const initialValues = {
    email: getQueryParam('email'),
    name: getQueryParam('name'),
    jobTitle: getQueryParam('job'),
    functionalArea: getQueryParam('fa')
  }

  const releaseForm =
    'https://objectstorage.eu-zurich-1.oraclecloud.com/n/idxwux4l0c8w/b/public/o/ioc_releaseform_2023-04-12.pdf'
  const terms =
    'https://objectstorage.eu-zurich-1.oraclecloud.com/n/idxwux4l0c8w/b/public/o/ioc_terms_of_service_2023-04-12.pdf'
  const policy = 'https://olympics.com/en/privacy-policy'

  // if (invite.organization && invite.organization.privacyPolicy) {
  //   policy = invite.organization.privacyPolicy
  // }
  //
  // if (invite.organization && invite.organization.termsAndConditions) {
  //   terms = invite.organization.termsAndConditions
  // }

  const handleConsentSave = async () => {
    let questionnaireId = invite.questionnaireId
    if (!questionnaireId) {
      questionnaireId = quizInvite.questions[0].questionnaireId
    }

    const record: QuestionnaireConsent = {
      email: initialValues.email,
      name: initialValues.email,
      jobTitle: initialValues.jobTitle,
      functionalArea: initialValues.functionalArea,
      questionnaireId: questionnaireId
    }
    const result = await api.saveConsentQuestionnaire(record, token)
    console.log(result)
    snackActions.open(`Your consent has been saved,`)
  }

  const { values, handleSubmit, handleChange, handleBlur, errors, touched, isValid, dirty } = useFormik({
    initialValues,
    onSubmit: async values => {
      handleConsentSave()
      if (!questionnaire.privateQuestionnaire) {
        handleNext(values)
        return
      }
      if (!invite?.id || !values.email) {
        snackActions.open('Ops! Something went wrong, please try again')
        return
      }
      try {
        const result = await api.getJobTitleAndFunctionalAreaByUserEmail(questionnaire.id, values.email, invite.token)
        if (!result.length) {
          simpleDialogActions.open(
            'Invitation not found',
            `The provided email address, ${
              values.email
            }, has not been invited to respond to this questionnaire. Please contact ${
              invite?.organization?.name ?? invite?.user?.firstName
            } at ${invite?.organization?.email ?? invite?.user?.email} for further details.`,
            { cancelText: 'Close' }
          )
          return
        }
        handleNext(values)
      } catch (e) {
        console.log(e)
        snackActions.open('Ops! Something went wrong, please try again')
      }
    },
    validationSchema: schema
  })

  const nameOfInviter = invite.organization?.name || `${invite.user.firstName} ${invite.user.lastName}`

  const handleCustomBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
    handleBlur(e)
    if ((touched.email && Boolean(errors.email)) || values.email.length === 0) return
    try {
      const response = await api.getJobTitleAndFunctionalAreaByUserEmail(questionnaire.id, values.email, token)

      const namePerson = Array.from(new Set(response.map(invite => invite.name).filter(name => name !== null)))
      const jobTitles = Array.from(new Set(response.map(invite => invite.jobTitle).filter(title => title !== null)))
      const functionalAreas = Array.from(
        new Set(response.map(invite => invite.functionalArea).filter(area => area !== null))
      )

      if (namePerson.length > 0) {
        if (values.name.length === 0) {
          values.name = namePerson[0]
        }
        setNamePerson(namePerson)
      }

      if (jobTitles.length > 0) {
        if (values.jobTitle.length === 0) {
          values.jobTitle = jobTitles[0]
        }
        setJobTitleList(jobTitles)
      }

      if (functionalAreas.length > 0) {
        if (values.functionalArea.length === 0) {
          values.functionalArea = functionalAreas[0]
        }
        setFunctionalAreaList(functionalAreas)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleTermsAccept = () => {
    setIsTermsAccepted(!isTermsAccepted)
    if (!isTermsAccepted) {
      // handleConsentSave()
    }
  }

  const handleDisabledButtonClick = () => {
    simpleDialogActions.open(
      'Check the box',
      'To proceed please check the box authorising the International Olympic Committee to use your recorded responses and contact you in accordance with its terms, release form and privacy policy.',
      { cancelText: 'Close' }
    )
  }

  return (
    <Grid component="form" onSubmit={handleSubmit} container spacing={2}>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          mb={2}
          sx={{
            fontSize: '1.1em',
            overflowY: 'scroll',
            maxHeight: '25em',
            whiteSpace: 'pre-wrap',
            color: collectionTheme?.questionnaireBodyTextColor,
            fontFamily: collectionTheme?.questionnaireTextFontFamily
          }}
        >
          {quizInvite ? quizInvite?.description : invite?.description}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="Email"
          name="email"
          type="text"
          value={values.email}
          onChange={handleChange}
          onBlur={handleCustomBlur}
          error={touched.email && Boolean(errors.email)}
          helperText={(touched.email && errors.email) || ' '}
          fullWidth
          InputLabelProps={{
            style: {
              fontFamily: collectionTheme?.questionnaireTextFontFamily,
              color: collectionTheme?.questionnaireInputLabelColor
            }
          }}
          InputProps={{
            style: {
              width: '100%',
              borderWidth: '1px',
              color: collectionTheme?.questionnaireInputTextColor,
              backgroundColor: collectionTheme?.questionnaireInputBackgroundColor,
              fontFamily: collectionTheme?.questionnaireTextFontFamily,
              WebkitTextFillColor: collectionTheme?.questionnaireInputTextColor
            }
          }}
          inputProps={{
            autoComplete: 'off',
            form: {
              autoComplete: 'off'
            }
          }}
          sx={{
            '& .MuiOutlinedInput-root': collectionTheme
              ? {
                  '& fieldset.MuiOutlinedInput-notchedOutline': {
                    borderColor:
                      touched.email && Boolean(errors.email)
                        ? '#FF8A80'
                        : collectionTheme?.questionnaireInputOutlineColor
                  }
                }
              : {},
            '& .MuiOutlinedInput-input:-webkit-autofill': {
              '-webkit-box-shadow': `0 0 0 30px ${
                collectionTheme?.questionnaireInputBackgroundColor ?? '#424242'
              } inset`
            }
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="Name"
          name="name"
          type="text"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.name && Boolean(errors.name)}
          helperText={(touched.name && errors.name) || ' '}
          fullWidth
          inputProps={{
            readOnly: true,
            autoComplete: 'off',
            form: {
              autoComplete: 'off'
            }
          }}
          InputLabelProps={{
            style: {
              fontFamily: collectionTheme?.questionnaireTextFontFamily,
              color: collectionTheme?.questionnaireInputLabelColor
            }
          }}
          InputProps={{
            readOnly: true,
            style: {
              width: '100%',
              borderWidth: '1px',
              color: collectionTheme?.questionnaireInputTextColor,
              backgroundColor: collectionTheme?.questionnaireInputBackgroundColor,
              fontFamily: collectionTheme?.questionnaireTextFontFamily,
              WebkitTextFillColor: collectionTheme?.questionnaireInputTextColor
            }
          }}
          sx={{
            '& .MuiOutlinedInput-root': collectionTheme
              ? {
                  '& fieldset.MuiOutlinedInput-notchedOutline': {
                    borderColor:
                      touched.name && Boolean(errors.name) ? '#FF8A80' : collectionTheme?.questionnaireInputOutlineColor
                  }
                }
              : {},
            '& .MuiOutlinedInput-input:-webkit-autofill': {
              '-webkit-box-shadow': `0 0 0 30px ${
                collectionTheme?.questionnaireInputBackgroundColor ?? '#424242'
              } inset`
            }
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Autocomplete
          freeSolo={false} // Define como false para não permitir digitação livre
          forcePopupIcon={true}
          renderOption={(props, option) => (
            <li {...props} key={option}>
              {option}
            </li>
          )}
          options={jobTitleList} // Lista de opções para o título do trabalho
          value={values.jobTitle}
          onChange={(event, newValue) => {
            // Atualiza o valor selecionado
            handleChange({ target: { name: 'jobTitle', value: newValue ?? '' } })
          }}
          inputValue={values.jobTitle}
          renderInput={params => (
            <TextField
              {...params}
              label="Job Title"
              name="jobTitle"
              error={touched.jobTitle && Boolean(errors.jobTitle)}
              helperText={(touched.jobTitle && errors.jobTitle) || ' '}
              fullWidth
              InputLabelProps={{
                style: {
                  fontFamily: collectionTheme?.questionnaireTextFontFamily,
                  color: collectionTheme?.questionnaireInputLabelColor
                }
              }}
              InputProps={{
                ...params.InputProps,
                readOnly: true, // Torna o campo visualmente somente leitura
                style: {
                  borderWidth: '1px',
                  color: collectionTheme?.questionnaireInputTextColor,
                  backgroundColor: collectionTheme?.questionnaireInputBackgroundColor,
                  fontFamily: collectionTheme?.questionnaireTextFontFamily,
                  WebkitTextFillColor: collectionTheme?.questionnaireInputTextColor
                }
              }}
              sx={{
                '& .MuiOutlinedInput-root': collectionTheme
                  ? {
                      '& fieldset.MuiOutlinedInput-notchedOutline': {
                        borderColor: collectionTheme?.questionnaireInputOutlineColor
                      }
                    }
                  : {},
                '& .MuiOutlinedInput-input:-webkit-autofill': {
                  '-webkit-box-shadow': `0 0 0 30px ${
                    collectionTheme?.questionnaireInputBackgroundColor ?? '#424242'
                  } inset`
                }
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Autocomplete
          freeSolo={false} // Define como false para não permitir digitação livre
          forcePopupIcon={true}
          renderOption={(props, option) => (
            <li {...props} key={option}>
              {option}
            </li>
          )}
          options={functionalAreaList}
          value={values.functionalArea}
          onChange={(event, newValue) => {
            handleChange({ target: { name: 'functionalArea', value: newValue ?? '' } })
          }}
          inputValue={values.functionalArea}
          // Remove a função onInputChange para evitar alterações com base na digitação do usuário
          renderInput={params => (
            <TextField
              {...params}
              label="Functional Area"
              name="functionalArea"
              error={touched.functionalArea && Boolean(errors.functionalArea)}
              helperText={(touched.functionalArea && errors.functionalArea) || ' '}
              fullWidth
              InputLabelProps={{
                style: {
                  fontFamily: collectionTheme?.questionnaireTextFontFamily,
                  color: collectionTheme?.questionnaireInputLabelColor
                }
              }}
              InputProps={{
                ...params.InputProps,
                readOnly: true, // Torna o campo visualmente readOnly
                style: {
                  borderWidth: '1px',
                  color: collectionTheme?.questionnaireInputTextColor,
                  backgroundColor: collectionTheme?.questionnaireInputBackgroundColor,
                  fontFamily: collectionTheme?.questionnaireTextFontFamily,
                  WebkitTextFillColor: collectionTheme?.questionnaireInputTextColor
                }
              }}
              sx={{
                '& .MuiOutlinedInput-root': collectionTheme
                  ? {
                      '& fieldset.MuiOutlinedInput-notchedOutline': {
                        borderColor: collectionTheme?.questionnaireInputOutlineColor
                      }
                    }
                  : {},
                '& .MuiOutlinedInput-input:-webkit-autofill': {
                  '-webkit-box-shadow': `0 0 0 30px ${
                    collectionTheme?.questionnaireInputBackgroundColor ?? '#424242'
                  } inset`
                }
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <hr></hr>
        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
          <Checkbox
            checked={isTermsAccepted}
            onChange={handleTermsAccept}
            sx={{
              color: collectionTheme?.questionnaireBodyTextColor,
              '&.Mui-checked': { color: collectionTheme?.questionnaireLinksColor }
            }}
          />
          <Typography
            variant="body2"
            mb={2}
            fontSize="1em"
            sx={{
              color: collectionTheme?.questionnaireBodyTextColor,
              fontFamily: collectionTheme?.questionnaireTextFontFamily
            }}
          >
            {`You have been invited by the IKL Team at the International Olympic Committee to respond to a questionnaire.
          You authorise the IKL Team at the International Olympic Committee to contact you and share
          your submission in accordance with its `}
            <Link href={terms} target="_blank" sx={{ color: collectionTheme?.questionnaireLinksColor }}>
              terms
            </Link>
            ,{' '}
            <Link href={releaseForm} target="_blank" sx={{ color: collectionTheme?.questionnaireLinksColor }}>
              release form
            </Link>
            {' and '}
            <Link href={policy} target="_blank" sx={{ color: collectionTheme?.questionnaireLinksColor }}>
              privacy policy
            </Link>
            .
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          textAlign: 'right',
          flex: 1,
          width: '100%',
          float: 'right',
          position: 'relative',
          zIndex: 11
        }}
      >
        <Button
          type="button"
          sx={{
            fontSize: '1.1rem',
            position: 'absolute',
            top: '26%',
            left: '92.5%',
            backgroundColor: 'transparent',
            border: 'none',
            color: 'transparent',
            zIndex: !isTermsAccepted ? 2 : -1,
            ':hover': { backgroundColor: 'transparent', color: 'transparent', cursor: 'default' },
            ':disabled': {
              backgroundColor: 'transparent',
              color: 'transparent'
            }
          }}
          onClick={handleDisabledButtonClick}
          disabled={isTermsAccepted}
          size="medium"
        >
          Start
        </Button>
        <Button
          type="submit"
          sx={{
            fontSize: '1.1rem',
            color: collectionTheme?.questionnaireContainedButtonTextColor,
            backgroundColor: collectionTheme?.questionnaireContainedButtonBackgroundColor,
            ':hover': { backgroundColor: collectionTheme?.questionnaireContainedButtonHoverColor },
            fontFamily: collectionTheme?.questionnaireTextFontFamily,
            ':disabled': {
              backgroundColor: collectionTheme?.questionnaireContainedDisabledButtonBackgroundColor,
              color: collectionTheme?.questionnaireContainedDisabledButtonColor
            }
          }}
          variant="contained"
          size="medium"
          disabled={!isTermsAccepted}
        >
          Start questionnaire
        </Button>
      </Grid>
    </Grid>
  )
}

export default Step1
