import React, { useEffect, useState } from 'react'
import { EditorState, ContentState, Modifier, ContentBlock, CompositeDecorator } from 'draft-js'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import ButtonGroup from '@mui/material/ButtonGroup'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
interface FindReplaceProps {
  editorState: EditorState
  setEditorState: (newState: EditorState) => void
  isEditable: boolean
  handleChange: (newEditorState: EditorState) => void
}

const BlueHighlight: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <span style={{ backgroundColor: 'blue', color: 'white' }}>{children}</span>
)

type CallbackType = (start: number, end: number) => void
function FindReplace({ editorState, setEditorState, isEditable, handleChange }: FindReplaceProps) {
  const [findText, setFindText] = useState('')
  const [replaceText, setReplaceText] = useState('')
  const [foundText, setFoundText] = useState('')

  function blueHighlightStrategy(contentBlock: ContentBlock, callback: CallbackType, contentState: ContentState) {
    const text = contentBlock.getText()
    const searchText = findText // Supondo que este valor está armazenado no contentState

    let matchArr, start
    const regex = new RegExp(searchText, 'gi')

    while ((matchArr = regex.exec(text)) !== null) {
      start = matchArr.index
      callback(start, start + matchArr[0].length)
    }
  }

  const createBlueTextDecorator = (find: string) =>
    new CompositeDecorator([
      {
        strategy: (contentBlock, callback, contentState) => blueHighlightStrategy(contentBlock, callback, contentState),
        component: BlueHighlight
      }
    ])

  const updateDecorator = () => {
    const newDecorator = createBlueTextDecorator(findText)
    const newEditorState = EditorState.set(editorState, { decorator: newDecorator })
    setEditorState(newEditorState)
  }

  function findTextEntities(block: ContentBlock, searchText: string) {
    const text = block.getText()
    const results = []
    let match
    const regex = new RegExp(searchText, 'gi') // 'g' para global e 'i' para case-insensitive

    while ((match = regex.exec(text)) !== null) {
      results.push({
        start: match.index,
        end: match.index + match[0].length
      })
    }

    return results
  }

  const handleReplaceClick = () => {
    const contentState = editorState.getCurrentContent()
    const blockMap = contentState.getBlockMap()

    if (blockMap && findText.trim().length > 0) {
      const newBlockMap = blockMap.map((block?: ContentBlock) => {
        if (!block) return block // Continua a retornar o próprio block

        const blockText = block.getText()
        const found = findTextEntities(block, findText)
        setFoundText(found.length + ' occurrence(s)')
        // findTextEntities(block, findText)
        // updateDecorator()

        if (replaceText && replaceText !== '') {
          const yesOrNo = true
          // confirm(
          //   found.length +
          //     ' occurrences for ' +
          //     findText +
          //     ' were found in the transcription. Do you want to proceed with the replace?'
          // )
          if (yesOrNo) {
            const replacedText = blockText.replace(new RegExp(findText, 'g'), replaceText)
            return block.merge({
              text: replacedText
            })
          } else {
            return block
          }
        } else {
          return block
        }
      })

      // Certifique-se de converter o novo BlockMap de volta para ContentState
      const newContentState = contentState.set('blockMap', newBlockMap) as ContentState

      const newEditorState = EditorState.push(editorState, newContentState, 'insert-characters')
      setEditorState(newEditorState)
    }
  }

  const handleFindChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const inputValue = event.target.value
    // Verifica se o valor contém espaços ou é uma única palavra
    if (!inputValue.includes(' ')) {
      setFindText(inputValue)
    }
  }

  const handleReplaceChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const inputValue = event.target.value
    if (!inputValue.includes(' ')) {
      setReplaceText(inputValue)
    }
  }

  // useEffect(() => {
  //   updateDecorator()
  // }, [findText]) // Atualiza o decorator sempre que findText mudar

  if (!isEditable) return null

  return (
    <Box sx={{ display: isEditable ? 'flex' : 'none', margin: '5px' }}>
      <ButtonGroup variant="outlined" aria-label="Basic button group">
        <TextField
          type="text"
          placeholder="Find word"
          variant="outlined"
          value={findText}
          onChange={handleFindChange}
          sx={{ flex: 1 }}
        />
        <TextField
          type="text"
          placeholder="Replace word"
          variant="outlined"
          value={replaceText}
          onChange={handleReplaceChange} // Corrigido para usar chaves corretamente
          sx={{ flex: 1 }}
        />
        <Button onClick={handleReplaceClick} variant="contained" sx={{ minWidth: '50px' }}>
          {replaceText ? 'Replace' : '  Find  '}
        </Button>
      </ButtonGroup>
      <Box sx={{ flex: 1, verticalAlign: 'center', marginLeft: '2em' }}>
        <Typography variant={'body1'} sx={{ flex: 1, paddingTop: '15px' }}>
          {foundText}
        </Typography>
      </Box>
    </Box>
  )
}

export default FindReplace
