import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import ImageListItemBar from '@mui/material/ImageListItemBar'
import { CollectionThemeProps, GalleryType, StoryType } from '../../../lib/types'
import Typography from '@mui/material/Typography'
import ImageViewer from '../../../components/ImageViewer'

interface iPhotos {
  list: StoryType[]
  textColor?: string
  backgroundColor?: string
  collectionTheme?: CollectionThemeProps
}

const PhotosPublic: React.FC<iPhotos> = ({ list, textColor, backgroundColor, collectionTheme }) => {
  const [gallery, setGallery] = useState<(GalleryType & { storyId: string; storyTitle: string })[]>([])
  const [current, setCurrent] = useState<number | null>(null)
  // console.log('Stories Size: ' + list.length)
  useEffect(() => {
    if (list && list.length > 0) {
      list.forEach(story =>
        story.galleryEntries.forEach(g => {
          setGallery(old => [...old, { ...g, storyId: story.id, storyTitle: story.title }])
        })
      )
    }
  }, [list])
  // console.log('Gallery Size: ' + gallery.length)
  return (
    <Box
      sx={{
        marginBottom: 3,
        backgroundColor: backgroundColor
      }}
    >
      {current !== null && (
        <ImageViewer
          images={gallery}
          onClose={() => {
            setCurrent(null)
          }}
          index={current}
        />
      )}
      {gallery.length ? (
        <ImageList cols={5} gap={5}>
          {gallery.map((photo, index) => (
            <ImageListItem key={photo.id} rows={1}>
              <img
                style={{
                  cursor: 'pointer'
                }}
                onClick={() => {
                  setCurrent(index)
                }}
                src={`${photo.image.url}`}
                srcSet={`${photo.image.url}`}
                alt={photo.image.originalName}
                loading="lazy"
              />
              <ImageListItemBar
                position="below"
                subtitle={
                  <Typography variant="caption" fontSize="1em">
                    {photo.storyTitle}
                  </Typography>
                }
                title={photo.image.originalName}
                actionPosition="right"
              />
            </ImageListItem>
          ))}
        </ImageList>
      ) : (
        <Typography
          sx={{ padding: '16px 0px', color: textColor, fontFamily: collectionTheme?.collectionViewerFontFamily }}
          align="center"
        >
          No photos have been submitted for this collection
        </Typography>
      )}
    </Box>
  )
}

export default PhotosPublic
