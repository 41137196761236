import React, { createContext, useContext, ReactNode } from 'react'

// Nome do cache
const CACHE_NAME = 'file-storage-cache'

// Função para salvar um arquivo no cache
const cacheFile = async (id: string, file: File) => {
  const cache = await caches.open(CACHE_NAME)
  const response = new Response(file)
  await cache.put(id, response)
  console.log('File cached: ', id)
}

// Função para recuperar um arquivo do cache
const getFileFromCache = async (id: string): Promise<File | null> => {
  const cache = await caches.open(CACHE_NAME)
  const response = await cache.match(id)
  if (!response) return null
  const blob = await response.blob()
  return new File([blob], id, { type: blob.type })
}

// Função para remover um arquivo do cache
const removeFileFromCache = async (id: string) => {
  const cache = await caches.open(CACHE_NAME)
  await cache.delete(id)
  console.log('File removed from cache: ', id)
}

// Defina a interface do contexto
interface CacheStorageContextProps {
  insertFile: (id: string, file: File) => Promise<void>
  getFile: (id: string) => Promise<File | null>
  removeFile: (id: string) => Promise<void>
}

// Crie um contexto para o CacheStorage
const CacheStorageContext = createContext<CacheStorageContextProps | undefined>(undefined)

export const CacheStorageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const insertFile = async (id: string, file: File) => {
    try {
      console.log('Cache: Starting insertFile: ', id)
      await cacheFile(id, file)
      console.log('Cache: Recording saved locally: ', id)
    } catch (error) {
      console.error('Cache: Error to persist recording locally:', error)
    }
  }

  const getFile = async (id: string): Promise<File | null> => {
    try {
      console.log('Cache: Getting file: ', id)
      const file = await getFileFromCache(id)
      console.log('Cache: File retrieved: ', id)
      return file
    } catch (error) {
      console.error('Cache: Error getting the file:', error)
      return null
    }
  }

  const removeFile = async (id: string) => {
    try {
      console.log('Cache: Removing file: ', id)
      await removeFileFromCache(id)
      console.log('Cache: File removed: ', id)
    } catch (error) {
      console.error('Error removing the file:', error)
    }
  }

  return (
    <CacheStorageContext.Provider value={{ insertFile, getFile, removeFile }}>{children}</CacheStorageContext.Provider>
  )
}

// Hook para usar o CacheStorage
export const useCacheStorage = (): CacheStorageContextProps => {
  const context = useContext(CacheStorageContext)
  if (context === undefined) {
    throw new Error('Cache: useCacheStorage deve ser usado dentro de um CacheStorageProvider')
  }
  return context
}
