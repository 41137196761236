import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import {
  LanguageEnum,
  QuestionnaireTemplateType,
  QuizInputType,
  QuizQuestionInputType,
  QuizQuestionType
} from '../../../lib/types'
import QuizQuestionItem from '../../../components/QuizQuestionItem'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import api from '../../../lib/api'
import { RouteComponentProps } from 'react-router-dom'
import Box from '@mui/material/Box'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import Button from '@mui/material/Button'
import { FormDialogContext } from '../../../components/FormDialog'
import { SimpleDialogContext } from '../../../components/SimpleDialog'
import { SimpleSnackbarContext } from '../../../components/SimpleSnackbar'
import axios from 'axios'
import CircularLoadingCentred from '../../../components/CircularLoadingCentred'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import MenuItem from '@mui/material/MenuItem'
import { UpdateQuestionnairesDialogContext } from '../../../components/UpdateQuestionnaireDialog'

const QuestionnaireTemplateQuestion: React.FC<RouteComponentProps<{ quizId: string }>> = ({ match, history }) => {
  const { quizId } = match.params
  const { actions: formDialogActions } = useContext(FormDialogContext)
  const { actions: simpleDialogActions } = useContext(SimpleDialogContext)
  const { actions: snackActions } = useContext(SimpleSnackbarContext)
  const { actions: updateQuestionnairesDialog } = useContext(UpdateQuestionnairesDialogContext)
  const [questionnaireTemplate, setQuestionnaireTemplate] = useState<QuestionnaireTemplateType | null>(null)
  const [items, setItems] = useState<QuizQuestionType[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [enableSort, setEnableSort] = useState<boolean>(false)
  const [storeSort, setStoreSort] = useState<QuizQuestionType[]>([])

  const fetch = async () => {
    try {
      setLoading(true)
      const result = await api.getQuestionnaireTemplate(quizId)
      setQuestionnaireTemplate(result)
      if (result.questions.length) setItems(result.questions.sort((a, b) => a.number - b.number))
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // @ts-ignore
        const { code, message } = error.response.data
        if (code) {
          snackActions.open(message)
          return
        }
      }
      snackActions.open('Internal server error.')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetch()
  }, [])

  const handleDelete = async (id: string) => {
    simpleDialogActions.open(
      'Delete question',
      'Do you really want to delete this template question?',
      { okText: 'Delete', cancelText: 'Cancel', showOk: true },
      async success => {
        if (success) {
          try {
            await api.deleteQuestionnaireTemplateQuestion(id)
            const filteredItems = items.filter(item => item.id !== id)
            await Promise.all(
              filteredItems.map(async (item, i) => {
                try {
                  return await api.updateQuestionnaireTemplateQuestion(item.id, { ...item, number: i + 1 })
                } catch (error) {
                  if (axios.isAxiosError(error)) {
                    // @ts-ignore
                    const { code, message } = error.response.data
                    if (code) {
                      snackActions.open(message)
                    }
                  }
                }
              })
            )
            await fetch()
          } catch (error) {
            if (axios.isAxiosError(error)) {
              // @ts-ignore
              const { code, message } = error.response.data
              if (code) {
                snackActions.open(message)
                return
              }
            }
            snackActions.open('Internal server error.')
          }
        }
      }
    )
  }

  const createNewQuestion = () => {
    formDialogActions.open(
      'Add a new question',
      '',
      [
        {
          label: 'Title',
          name: 'title',
          placeholder: 'title',
          type: 'text'
        },
        {
          label: 'Question',
          name: 'question',
          rows: 4,
          placeholder: 'Question',
          type: 'text'
        },
        {
          label: 'Recommendation',
          name: 'recommendation',
          rows: 4,
          placeholder: 'recommendation',
          type: 'text'
        }
      ],
      { question: '' },
      null,
      async (data: QuizQuestionInputType) => {
        try {
          await api.addQuestionnaireTemplateQuestion({
            ...data,
            questionnaireTemplateId: quizId,
            number: items.length > 0 ? items[items.length - 1].number + 1 : 1
          })
          await fetch()
        } catch (error) {
          if (axios.isAxiosError(error)) {
            // @ts-ignore
            const { code, message } = error.response.data
            if (code) {
              snackActions.open(message)
              return
            }
          }
          snackActions.open('Internal server error.')
        }
      },
      { okText: 'create', cancelText: `cancel` }
    )
  }

  const editQuestionnaire = () => {
    formDialogActions.open(
      'Edit questionnaire',
      '',
      [
        {
          label: 'Title',
          name: 'title',
          placeholder: 'Title',
          type: 'text'
        },
        {
          label: 'Description',
          name: 'description',
          placeholder: 'Description',
          rows: 4,
          type: 'text'
        },
        {
          label: 'Language',
          name: 'language',
          placeholder: 'Language',
          type: 'select',
          options: Object.entries(LanguageEnum).map(([code, name]) => (
            <MenuItem key={code} value={code}>
              {name.charAt(0).toUpperCase() + name.slice(1)}
            </MenuItem>
          ))
        }
      ],
      {
        title: questionnaireTemplate?.title || '',
        description: questionnaireTemplate?.description || '',
        language: questionnaireTemplate?.language || ''
      },
      null,
      async (data: QuizInputType) => {
        if (questionnaireTemplate?.questionnaires && questionnaireTemplate?.questionnaires.length > 0) {
          updateQuestionnairesDialog.open(async (selectedIds: string[] | null) => {
            try {
              await api.updateQuestionnaireTemplate(quizId, {
                ...data,
                questionnairesIds: selectedIds || undefined
              })
              await fetch()
            } catch (error) {
              if (axios.isAxiosError(error)) {
                // @ts-ignore
                const { code, message } = error.response.data
                if (code) {
                  snackActions.open(message)
                  return
                }
              }
              snackActions.open('Internal server error.')
            }
          }, questionnaireTemplate?.questionnaires)
        } else {
          try {
            await api.updateQuestionnaireTemplate(quizId, data)
            await fetch()
          } catch (error) {
            if (axios.isAxiosError(error)) {
              // @ts-ignore
              const { code, message } = error.response.data
              if (code) {
                snackActions.open(message)
                return
              }
            }
            snackActions.open('Internal server error.')
          }
        }
      },
      { okText: 'confirm', cancelText: `cancel` }
    )
  }

  const editQuestion = (question: QuizQuestionType) => {
    formDialogActions.open(
      'Edit question',
      '',
      [
        {
          label: 'Title',
          name: 'title',
          placeholder: 'title',
          type: 'text'
        },
        {
          label: 'Question',
          name: 'question',
          rows: 4,
          placeholder: 'Question',
          type: 'text'
        },
        {
          label: 'Recommendation',
          name: 'recommendation',
          rows: 4,
          placeholder: 'recommendation',
          type: 'text'
        }
      ],
      {
        question: question?.question || '',
        recommendation: question?.recommendation || '',
        title: question?.title || ''
      },
      null,
      async (data: QuizInputType) => {
        if (questionnaireTemplate?.questionnaires && questionnaireTemplate?.questionnaires.length > 0) {
          updateQuestionnairesDialog.open(async (selectedIds: string[] | null) => {
            try {
              await api.updateQuestionnaireTemplateQuestion(question.id, {
                ...question,
                ...data,
                questionnaireIds: selectedIds || undefined
              })
              await fetch()
            } catch (error) {
              if (axios.isAxiosError(error)) {
                // @ts-ignore
                const { code, message } = error.response.data
                if (code) {
                  snackActions.open(message)
                  return
                }
              }
              snackActions.open('Internal server error.')
            }
          }, questionnaireTemplate?.questionnaires)
        } else {
          try {
            await api.updateQuestionnaireTemplateQuestion(question.id, { ...question, ...data })
            await fetch()
          } catch (error) {
            if (axios.isAxiosError(error)) {
              // @ts-ignore
              const { code, message } = error.response.data
              if (code) {
                snackActions.open(message)
                return
              }
            }
            snackActions.open('Internal server error.')
          }
        }
      },
      { okText: 'confirm', cancelText: `cancel` }
    )
  }

  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return

    const result = Array.from(items)
    const [removed] = result.splice(source.index, 1)
    result.splice(destination.index, 0, removed)

    setItems(result)
  }

  const saveSort = async () => {
    if (questionnaireTemplate?.questionnaires && questionnaireTemplate?.questionnaires.length > 0) {
      updateQuestionnairesDialog.open(async (selectedIds: string[] | null) => {
        try {
          await Promise.all(
            items.map(async (item, i) => {
              try {
                return await api.updateQuestionnaireTemplateQuestion(item.id, {
                  ...item,
                  number: i + 1,
                  questionnaireIds: selectedIds || undefined
                })
              } catch (error) {
                if (axios.isAxiosError(error)) {
                  // @ts-ignore
                  const { code, message } = error.response.data
                  if (code) {
                    snackActions.open(message)
                  }
                }
              }
            })
          )
          await fetch()
          setEnableSort(false)
        } catch (error) {
          if (axios.isAxiosError(error)) {
            // @ts-ignore
            const { code, message } = error.response.data
            if (code) {
              snackActions.open(message)
              return
            }
          }
          snackActions.open('Internal server error.')
        }
      }, questionnaireTemplate?.questionnaires)
    } else {
      try {
        await Promise.all(
          items.map(async (item, i) => {
            try {
              return await api.updateQuestionnaireTemplateQuestion(item.id, { ...item, number: i + 1 })
            } catch (error) {
              if (axios.isAxiosError(error)) {
                // @ts-ignore
                const { code, message } = error.response.data
                if (code) {
                  snackActions.open(message)
                }
              }
            }
          })
        )
        await fetch()
        setEnableSort(false)
      } catch (e) {
        snackActions.open('Internal server error.')
      }
    }
  }

  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const handleExportQuestionsDocx = async () => {
    try {
      await api.exportQuestionnaireTemplateDocx(quizId)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // @ts-ignore
        const { code, message } = error.response.data
        if (code) {
          snackActions.open(message)
        } else {
          snackActions.open('Ops! Something went wrong, please try again.')
        }
      }
    }
  }

  const handleUploadQuestionsCsv = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
      fileInputRef.current.click()
    }
  }

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files && e.target.files[0]
    if (selectedFile) {
      try {
        await api.uploadQuestionnaireTemplateCsv(quizId, selectedFile).then(() => fetch())
        snackActions.open('Questions imported successfully')
      } catch (error) {
        if (axios.isAxiosError(error)) {
          // @ts-ignore
          const { code, message } = error.response.data
          if (code) {
            snackActions.open(message)
          } else {
            snackActions.open(
              'Ops! Something went wrong. Make sure you are trying to import a .csv file with at least the columns "Number", "Question", "Recommendation", and "Title"'
            )
          }
        }
      }
    }
  }

  if (loading) {
    return <CircularLoadingCentred />
  }

  return (
    <Grid
      container
      sx={{
        padding: '24px'
      }}
      spacing={4}
    >
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            sx={{ cursor: 'pointer' }}
            underline="hover"
            onClick={() => history.push('/a/questionnaire?tab=templates')}
          >
            Questionnaire templates
          </Link>
          <Stack direction="row" alignItems="center">
            <Typography>{questionnaireTemplate?.title}</Typography>
            <Box ml={1}>
              <IconButton onClick={editQuestionnaire}>
                <EditIcon />
              </IconButton>
            </Box>
          </Stack>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <div />
          <Stack spacing={1} direction="row">
            {enableSort ? (
              <Box sx={{ display: { xs: 'grid', sm: 'flex' }, gridTemplateColumns: '1fr 1fr', gap: '4px' }}>
                <Button variant="outlined" sx={{ height: '56px', minWidth: 90 }} onClick={saveSort}>
                  Save
                </Button>
                <Button
                  variant="outlined"
                  sx={{ height: '56px', minWidth: 90 }}
                  onClick={() => {
                    setItems(storeSort)
                    setEnableSort(false)
                  }}
                >
                  Cancel
                </Button>
              </Box>
            ) : (
              <Box sx={{ display: { xs: 'grid', sm: 'flex' }, gridTemplateColumns: '1fr 1fr', gap: '4px' }}>
                <Button
                  variant="outlined"
                  sx={{ height: '56px' }}
                  onClick={() => {
                    setStoreSort(items)
                    setEnableSort(true)
                  }}
                >
                  Change question order
                </Button>
                <Button disabled={enableSort} variant="outlined" sx={{ height: '56px' }} onClick={createNewQuestion}>
                  New question
                </Button>
                <>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }} // Escondido, mas não de uma forma que impeça interações
                    onChange={e => {
                      handleFileChange(e)
                      console.log(e.target.files)
                    }}
                  />
                  <Button variant="outlined" sx={{ height: '56px' }} onClick={handleUploadQuestionsCsv}>
                    Import questions
                  </Button>
                </>
                {items.length > 0 && (
                  <Button variant="outlined" sx={{ height: '56px' }} onClick={handleExportQuestionsDocx}>
                    Download questions
                  </Button>
                )}
              </Box>
            )}
          </Stack>
        </Box>
      </Grid>
      {enableSort && (
        <Grid item xs={12}>
          You can change the order questions appear. Simply drag and drop questions into the desired sequence then press
          Save.
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid component={DragDropContext} container onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable-list">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {items.map(question => (
                  <Draggable
                    isDragDisabled={!enableSort}
                    key={question.id}
                    draggableId={question.id}
                    index={question.number - 1}
                  >
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <QuizQuestionItem question={question}>
                          <Button disabled={enableSort} color="secondary" onClick={() => editQuestion(question)}>
                            Edit
                          </Button>
                          <Button disabled={enableSort} color="secondary" onClick={() => handleDelete(question.id)}>
                            Delete
                          </Button>
                        </QuizQuestionItem>
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default QuestionnaireTemplateQuestion
